import React from "react";
import { connect } from "react-redux";
import StatusBar from "components/Onboarding/utils/StatusBar/StatusBar";
import BioDataForm from "../BioDataForm/BioDataForm";
import OTPVerification from "../../OTPVerification/OTPVerification";
import OnboardingHeader from "components/Onboarding/utils/OnboardingHeader/OnboardingHeader";
import { NIN } from "components/constants";
import {
	updateAccountInfo,
	updateSignupOption,
	showOTPForm,
	nextStep,
} from "redux/actions/onboardingActions";
import { slicePhone } from "utils/helpers";

const SignUpWithBioData = ({
	updateAccountInfo,
	updateSignupOption,
	showOTPForm,
	onboarding,
	nextStep,
	history,
}) => {
	const { onboardData, showOTP } = onboarding || {};
	const { accountInformation } = onboardData || {};
	let { phone } = accountInformation || {};

	return (
		<>
			{showOTP ? (
				<OTPVerification
					history={history}
					onboardData={onboardData}
					nextStep={nextStep}
					showOTPForm={showOTPForm}
					updateAccountInfo={updateAccountInfo}
					resendOptData={{ phone: slicePhone(phone) }}
				/>
			) : (
				<>
					<OnboardingHeader title="Sign up to begin trading">
						<StatusBar />
					</OnboardingHeader>

					<BioDataForm
						onboardData={onboardData}
						updateAccountInfo={updateAccountInfo}
						updateOption={() => updateSignupOption(NIN)}
						showOTPForm={showOTPForm}
						foooterText="Sign up with NIN"
						history={history}
						nextStep={nextStep}
					/>
				</>
			)}
		</>
	);
};

export default connect(({ onboarding }) => ({ onboarding }), {
	updateAccountInfo,
	updateSignupOption,
	showOTPForm,
	nextStep,
})(SignUpWithBioData);
