import React, { useState } from "react";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import { CREATE_USER } from "api/mutations/auth";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";
import { formValidator } from "utils/FormValidator/FormValidator";
import Disclaimer from "components/Onboarding/utils/Disclaimer/Disclaimer";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "components/constants";
import { getError, timeDateFormat } from "utils/helpers";
import {
	previousStep,
	updateInvestmentInfo,
	clearOnboarding,
} from "redux/actions/onboardingActions";

const InvestmentInformationForm = ({
	previousStep,
	updateInvestmentInfo,
	clearOnboarding,
	onboarding,
	enums,
	history,
}) => {
	const [formErrors, setformErrors] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const [terms, setTerms] = useState(false);

	const [createUser, { loading }] = useMutation(CREATE_USER, {
		onCompleted: () => emailVerification(),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const { investmentInformation, accountInformation } =
		onboarding?.onboardData || {};

	const handleSelect = (name, value) => {
		updateInvestmentInfo({ [name]: value });
		setformErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleChange = ({ target: { name, value } }) => {
		value = value.replace(/[^0-9]/g, "");
		updateInvestmentInfo({ [name]: value });
		setformErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { knowledge, objective, risk_tolerance, source_of_wealth } =
			investmentInformation || {};
		const { answer, question, token, otp, referral } =
			accountInformation || {};
		const userData = {
			dob: timeDateFormat(accountInformation?.dob)?.date,
			email: accountInformation?.email,
			first_name: accountInformation?.first_name,
			username: accountInformation?.username,
			// country: accountInformation?.country?.id,
			country: 156,
			investment_profile: {
				knowledge: knowledge?.value,
				net_worth: parseInt(investmentInformation?.net_worth),
				objective: objective?.value,
				risk_tolerance: risk_tolerance?.value,
				source_of_wealth: source_of_wealth?.value,
			},
			last_name: accountInformation?.last_name,
			password: accountInformation?.password,
			secret_question: { answer, question },
			settlement_type: accountInformation?.settlement_type,
			referral,
			token,
			otp,
			type: accountInformation?.type?.value,
		};

		const { formErrors, isValid } = formValidator(investmentInformation);
		if (isValid) {
			setErrorMsg();
			createUser({ variables: { data: userData } });
		} else setformErrors(formErrors);
	};

	const emailVerification = () => {
		const { email } = accountInformation;
		clearOnboarding();
		history.push({ pathname: "/auth/mail", state: { email } });
	};

	return (
		<div className="px-sm-5 px-0">
			<Toast msg={errorMsg} variant={ERROR} />

			<div className="col-12 px-4 mx-3 mb-4">
				<p className="heading4 ms-n2">Investment Information</p>
			</div>
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-sm-6 col-12 px-5">
						<CustomSelect
							name="objective"
							label="Investment Objective"
							placeholder="Investment Objective"
							value={investmentInformation?.objective}
							onChange={(e) => handleSelect("objective", e)}
							error={formErrors?.objective}
							options={enums?.investment_objective}
							getOptionLabel={({ comment }) => comment}
							required
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CustomSelect
							name="risk_tolerance"
							label="Risk Tolerance"
							placeholder="Risk Tolerance"
							value={investmentInformation?.risk_tolerance}
							onChange={(e) => handleSelect("risk_tolerance", e)}
							error={formErrors?.risk_tolerance}
							options={enums?.risk_tolerance}
							getOptionLabel={({ comment }) => comment}
							required
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CustomSelect
							name="knowledge"
							label="Investment Knowledge"
							placeholder="Investment Knowledge"
							value={investmentInformation?.knowledge}
							onChange={(e) => handleSelect("knowledge", e)}
							error={formErrors?.knowledge}
							options={enums?.investment_knowledge}
							getOptionLabel={({ comment }) => comment}
							required
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CustomInputBox
							name="net_worth"
							label="Estimated Net Worth"
							placeholder="Estimated Net Worth"
							defaultValue={investmentInformation?.net_worth}
							onChange={handleChange}
							error={formErrors?.net_worth}
							required
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CustomSelect
							name="source_of_wealth"
							label="Source of Wealth"
							placeholder="Source of Wealth"
							value={investmentInformation?.source_of_wealth}
							onChange={(e) =>
								handleSelect("source_of_wealth", e)
							}
							options={enums?.source_of_wealth}
							error={formErrors?.source_of_wealth}
							getOptionLabel={({ comment }) => comment}
							required
						/>
					</div>

					<div className="col-12 px-5">
						<div className="mb-4">
							<CustomCheckBox
								label={
									<>
										<small>
											I agree with the
											<a
												href="https://meristemng.com/docs/MSBL_TandC.pdf"
												target="_blank"
												rel="noopener noreferrer"
												className="px-1"
											>
												<strong className="text--primary">
													Terms of Service
												</strong>
											</a>
											and
											<a
												href="https://meristemng.com/docs/Privacy_Notice.pdf"
												target="_blank"
												rel="noopener noreferrer"
												className="px-1"
											>
												<strong className="text--primary">
													Privacy Policy
												</strong>
											</a>
										</small>
									</>
								}
								checked={terms}
								onClick={() => {
									setTerms((prev) => !prev);
								}}
							/>
						</div>
					</div>

					<div className="col-sm-6 col-12 pt-4 px-5">
						<div className="form-group">
							<button
								className="btn btn--lg btn--primary--bordered"
								onClick={previousStep}
							>
								Back
							</button>
						</div>
					</div>

					<div className="col-sm-6 col-12 pt-sm-4 pt-0 px-5">
						<button
							className="btn btn--lg btn--primary"
							disabled={!terms || loading}
							type="submit"
						>
							{loading ? <Spinner /> : "Continue"}
						</button>
					</div>

					<div className="col-sm-6 col-12" />

					<div className="col-sm-6 col-12 pt-3 px-5 text--gray">
						<div className="form-group text-end">
							<Disclaimer history={history} />
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default connect(({ onboarding }) => ({ onboarding }), {
	updateInvestmentInfo,
	previousStep,
	clearOnboarding,
})(InvestmentInformationForm);
