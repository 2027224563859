import React from "react";
import TopButtons from "../DashContent/DashContentRightView/TopButtons/TopButtons";
import WatchListRightView from "./WatchListRightView/WatchListRightView";
import AllWatchList from "./AllWatchList/AllWatchList";

const WatchList = ({ history }) => {
	return (
		<>
			<div className="dash__content">
				<div className="row">
					<AllWatchList history={history} />
					<WatchListRightView history={history} />
				</div>
			</div>
			<div className="bottom__button d-md-none d-block">
				<TopButtons />
			</div>
		</>
	);
};

export default WatchList;
