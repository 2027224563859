import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import Disclaimer from "../../../Onboarding/utils/Disclaimer/Disclaimer";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomPassword from "utils/CustomInputBox/CustomPassword/CustomPassword";
import OTPVerificationSuccess from "../../../Onboarding/OTPVerification/OTPVerificationSuccess/OTPVerificationSuccess";
import { getError } from "utils/helpers";
import { CONFIRM_PASSWORD_RESET } from "api/mutations/auth";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { ERROR } from "../../../constants";
import Toast from "utils/Alert/Toast/Toast";
import OnboardingHeader from "../../../Onboarding/utils/OnboardingHeader/OnboardingHeader";

const ResetPassword = ({ history }) => {
	const [formError, setFormError] = useState({});
	const [password, setPassword] = useState();
	const [errorMsg, setErrorMsg] = useState();
	const [showModal, setShowModal] = useState(false);
	const [confirmReset, { loading }] = useMutation(CONFIRM_PASSWORD_RESET, {
		onCompleted: () => setShowModal(true),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const { token } = useParams();

	const handleChange = ({ target: { value } }) => {
		setPassword(value);
		setFormError({ password: "" });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator({ password });
		if (isValid) {
			setErrorMsg();
			confirmReset({ variables: { password, token } });
		} else setFormError(formErrors);
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />
			<OnboardingHeader title="Reset Password" />

			<form onSubmit={handleSubmit}>
				<div className="row justify-content-center px-5 pt-5">
					<div className="col-12 px-5 mb-4">
						<CustomPassword
							name="password"
							label="New Password"
							placeholder="***********"
							value={password}
							onChange={handleChange}
							error={formError?.password}
							required
						/>
					</div>

					<div className="col-sm-6 col-12 px-5 mb-4">
						<button
							className="btn btn--lg btn--primary"
							type="submit"
							disabled={loading}
						>
							{loading ? <Spinner /> : "Confirm"}
						</button>
					</div>

					<div className="col-sm-6 col-12 px-5 text-end text--gray">
						<Disclaimer history={history} isLogin />
					</div>
				</div>
			</form>

			{showModal && (
				<OTPVerificationSuccess
					msg="Password Change Successfully"
					btnText="Login"
					toggle={() => history.replace("/auth/login")}
				/>
			)}
		</>
	);
};

export default ResetPassword;
