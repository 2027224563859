import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_PORTFOLIOS } from "api/queries/portfolio";
import AccountSummaryHeader from "../AccountSummaryHeader/AccountSummaryHeader";
import AccountSummaryNavs from "../AccountSummaryNavs/AccountSummaryNavs";
import FrequentlyTraded from "../FrequentlyTraded/FrequentlyTraded";
import RecentTransactions from "../RecentTransactions/RecentTransactions";
import AccountSummaryAssetAllocation from "../AccountSummaryAssetAllocation/AccountSummaryAssetAllocation";
import BalanceAndChart from "../BalanceAndChart/BalanceAndChart";
import TableError from "utils/Table/TableError/TableError";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "../../../../constants";
import { portfolioOption } from "components/Dashboard/common/helpers";
import "./AccountSummaryContent.scss";

const AccountSummaryContent = ({ id, margin }) => {
	const history = useHistory();
	const { account, marginAccount } = portfolioOption(true);
	const [selected, setSelected] = useState(
		margin ? marginAccount?.id : id || account?.id
	);

	const [getPortfolio, { error, data }] = useLazyQuery(GET_PORTFOLIOS);

	useEffect(() => {
		getPortfolio({ variables: { id: id ?? selected } });
		// eslint-disable-next-line
	}, [selected]);

	// console.log("data", data?.portfolio);

	const totalValuation =
		data?.portfolio?.reduce((acc, arr) => acc + arr.valuation, 0) || 0;

	return (
		<>
			{error && <Toast msg="Error Processing Request" variant={ERROR} />}

			<div className="dash__content assest__allocation">
				<AccountSummaryHeader history={history} />

				<AccountSummaryNavs />

				{margin && !id ? (
					<TableError errorMsg="Sorry you dont have access to this page. As you don't have a margin portfolio" />
				) : (
					<>
						<BalanceAndChart
							margin={margin}
							portfolio_id={id}
							portfolio={data?.portfolio}
							handlePortfolio={setSelected}
						/>

						<FrequentlyTraded history={history} />

						<div className="row mb-5">
							<RecentTransactions id={selected} />
							<AccountSummaryAssetAllocation
								history={history}
								data={data}
								totalValuation={totalValuation}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default AccountSummaryContent;
