import React from "react";
import Modal from "utils/Modal/Modal";
import AddWatchListForm from "./AddWatchListForm/AddWatchListForm";

const AddWatchListModal = ({ toggle, watchlist, isUpdate }) => {
	return (
		<Modal show={true}>
			<div className="text-center pb-4">
				<p className="heading4 border-bottom">
					{isUpdate ? "Rename Watch List" : "Create New List"}
				</p>
			</div>
			<AddWatchListForm
				watchlist={watchlist}
				isUpdate={isUpdate}
				toggle={toggle}
			/>
		</Modal>
	);
};

export default AddWatchListModal;
