import React from "react";
import { Link } from "react-router-dom";
import { tooltip } from "components/Dashboard/common/helpers";
import { getCurrency, toPriceStat } from "utils/helpers";
import { Loader } from "utils/TableLoader/TableLoader";
import { toCurrency } from "utils/helpers";

const MarketUpdateScroll = ({ currentStock }) => {
	const { price_change, price_change_p, name, price, currency, label } =
		currentStock || {};
	const isLoser = price_change < 0 ? true : false;

	return (
		<Link
			to={`/market/${name}`}
			className="dash__market"
			title={label}
			{...tooltip}
		>
			<span className="fw-bold d-block text--gray text-truncate">
				{label}
			</span>
			<p className="heading2 mb-n2">
				{getCurrency(currency)}
				{toCurrency(price)}
			</p>
			<small
				className={`fw-bold ${
					isLoser ? "text-danger" : "text--primary"
				}`}
			>
				{toPriceStat(price_change, price_change_p)}
			</small>
		</Link>
	);
};

export const LoadingScrollItem = ({ count = 4 }) => (
	<>
		{Array(count)
			.fill()
			.map((_, i) => (
				<div key={i} className="dash__market">
					<Loader height={10} />
					<div className="my-4">
						<Loader height={50} />
					</div>
					<Loader height={10} className="mb-3" />
					<Loader height={10} />
				</div>
			))}
	</>
);

export default MarketUpdateScroll;
