import React from "react";
import Table from "utils/Table/Table";
// import { marketOverviewData } from "../../data";
import { calcTotal, toCurrency, toPrice } from "utils/helpers";
import { TableLoader } from "utils/TableLoader/TableLoader";
import { ReactComponent as MeriLogo } from "assets/images/icons/meri-logo.svg";
import { getBest } from "../../../common/helpers";
import TableError from "utils/Table/TableError/TableError";

const tableHeading = [
	"Logo",
	"Security/Name",
	"Prev Close",
	"Last Trade (₦)",
	"Qty Traded",
	"Day's Volume",
	"Open Price (₦)",
	"Close Price (₦)",
	"High Price (₦)",
	"Low Price (₦)",
	"Price Change (₦)",
	"Change (%)",
	"Bid Depth",
	"Best Bid (₦)",
	"Offer Depth",
	"Best Offer (₦)",
];

const MarketOverviewTable = ({ loading, stocks = [], rowClick }) => {
	const getTableData = () =>
		stocks?.map((stock) => {
			const { offer_levels, bid_levels, trades } = stock || {};
			const qtyTraded = trades?.length ? trades[0]?.tradeSize : 0;
			const bestOffer = getBest(offer_levels)?.price;
			const bestBid = getBest(bid_levels)?.price;
			const bidDepth = calcTotal(bid_levels, "qty");
			const offerDepth = calcTotal(offer_levels, "qty");

			return {
				image: stock?.stock?.logo_url ? (
					<img
						src={stock?.stock?.logo_url}
						alt=""
						className="fliud-mg"
					/>
				) : (
					<MeriLogo width={30} height={30} />
				),
				id: stock?.label,
				prevClose: stock?.previous_close,
				lastTrade: stock?.price,
				qtyTraded: qtyTraded,
				daysVolume: stock?.volume,
				openPrice: stock?.open,
				closePrice: stock?.close,
				highPrice: stock?.high,
				lowPrice: stock?.low,
				priceChange: toPrice(stock?.price_change),
				changePercentage: toPrice(stock?.price_change_p),
				bidDepth: toCurrency(bidDepth),
				bestBid: toCurrency(bestBid),
				offerDepth: toCurrency(offerDepth),
				bestOffer: toCurrency(bestOffer),
			};
		});

	const tableData = loading ? TableLoader(14) : getTableData();

	return (
		<>
			<Table
				data={tableData}
				headings={tableHeading}
				cls=" faint__bg"
				maxHeight={400}
				rowClick={rowClick}
			/>
			{!loading && stocks.length === 0 && (
				<TableError errorMsg="No Market Found" />
			)}
		</>
	);
};

export default MarketOverviewTable;
