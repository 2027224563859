import React from "react";
import { useQuery } from "@apollo/client";
import CustomSelect from "../CustomSelect/CustomSelect";

const AsyncSelect = ({
	label,
	name,
	value,
	error,
	isMulti,
	placeholder,
	required,
	onChange,
	defaultValue,
	query,
	queryData,
}) => {
	const { loading, data } = useQuery(query);
	const options = data ? data[queryData] : [];

	return (
		<CustomSelect
			name={name}
			value={value}
			label={label}
			error={error}
			isMulti={isMulti}
			isLoading={loading}
			options={options}
			placeholder={placeholder}
			onChange={onChange}
			required={required}
			defaultValue={defaultValue}
			getOptionLabel={({ comment }) => comment}
		/>
	);
};

export default AsyncSelect;
