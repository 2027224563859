import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_PORTFOLIOS } from "api/queries/portfolio";
import AssetAllocationBalance from "../AssetAllocationRightView/AssetAllocationBalance/AssetAllocationBalance";
import AssestAllocationDoughnut from "./AssestAllocationDoughnut/AssestAllocationDoughnut";
import AssetAllocationValues from "./AssetAllocationValues/AssetAllocationValues";
import TableError from "utils/Table/TableError/TableError";
import MeriLoader from "utils/Loader/MeriLoader/MeriLoader";
import Toast from "utils/Alert/Toast/Toast";
import { getError, calcTotal } from "utils/helpers";
import { ERROR } from "../../../../constants";

const AssetAllocationLeftView = () => {
	const [portfolio, setPortfolio] = useState([]);

	const [getPortfolio, { loading, error }] = useLazyQuery(GET_PORTFOLIOS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => setPortfolio(data?.portfolio || []),
	});

	const totalValuation = calcTotal(portfolio, "valuation")?.toFixed(2) || 0;
	const totalGain = calcTotal(portfolio, "gain")?.toFixed(2) || 0;

	const getPercent = (v) =>
		parseFloat(((v * 360) / totalValuation).toFixed(2));

	const getData = () => {
		const newData = portfolio?.map(
			({ quantityHeld, securityLabel, valuation }) => ({
				quantityHeld,
				valuation,
				securityLabel,
				percent: getPercent(valuation),
			})
		);
		return newData;
	};

	useEffect(() => {
		getPortfolio();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="col-lg-8 col-12 left__view">
			{error && <Toast error={getError(error)} variant={ERROR} />}

			<span className="heading2">Asset Allocation</span>

			<div className="d-lg-none d-block">
				<AssetAllocationBalance mobile />
			</div>

			<AssetAllocationValues
				loading={loading}
				totalGain={totalGain}
				totalValuation={totalValuation}
				getPortfolio={getPortfolio}
				setPortfolio={setPortfolio}
			/>

			<div className="row doughut__wrapper rounded p-md-5 p-0">
				{loading && <MeriLoader hideLogo />}

				{!loading && portfolio?.length === 0 ? (
					<TableError />
				) : (
					<AssestAllocationDoughnut datas={getData()} />
				)}
			</div>
		</div>
	);
};

export default AssetAllocationLeftView;
