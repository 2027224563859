import { useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getMode } from "storage/localStorage";
import { DARK_MODE } from "components/constants";

const darkColor = { color: "#042d1a", highlightColor: "#66726c" };

export const TableLoader = (cols, rows = 5) => {
	let colors = { ...darkColor };

	let mode = getMode();

	colors = mode === DARK_MODE ? { ...darkColor } : {};

	const skeletonRows = Array(cols)
		.fill()
		.reduce((acc, _, i) => {
			return {
				...acc,
				[`load${i}`]: (
					<SkeletonTheme
						color={colors?.color}
						highlightColor={colors?.highlightColor}
					>
						<Skeleton height={10} width={100} />
					</SkeletonTheme>
				),
			};
		}, {});

	return Array(rows)
		.fill()
		.map(() => ({ ...skeletonRows }));
};

export const Loader = ({ height, width, ...props }) => {
	const [colors, setColors] = useState(darkColor);
	let mode = getMode();

	useEffect(() => {
		mode === DARK_MODE ? setColors(darkColor) : setColors({});
	}, [mode]);

	return (
		<SkeletonTheme
			color={colors?.color}
			highlightColor={colors?.highlightColor}
		>
			<Skeleton height={height} width={width} {...props} />
		</SkeletonTheme>
	);
};
