import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as Alert } from "assets/images/icons/tri-alert.svg";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { SET_SETTLEMENT_ACCOUNT } from "api/mutations/account";
import { getError } from "utils/helpers";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "../../../../../constants";

const SetSettlementAccountForm = ({ reference, toggle, nextStep }) => {
	const [errorMsg, setErrorMsg] = useState();

	const [setAccount, { loading }] = useMutation(SET_SETTLEMENT_ACCOUNT, {
		onCompleted: () => nextStep(),
		onError: (error) => {
			setErrorMsg(getError(error));
		},
	});

	const handleSubmit = () => {
		setAccount({ variables: { reference } });
	};

	return (
		<div className="text-center px-3 pb-3">
			<Toast msg={errorMsg} variant={ERROR} />

			<Alert className="my-5" />

			<p className="fw-bold">
				Are you sure you want to use this account as your settlement
				account?
			</p>

			<div className="flex__between mt-5 px-3">
				<button
					className="btn btn--lg btn--primary--bordered me-3"
					onClick={toggle}
					disabled={loading}
				>
					No
				</button>
				<button
					className="btn btn--lg btn--primary ms-3"
					onClick={handleSubmit}
					disabled={loading}
				>
					{loading ? <Spinner /> : "Yes"}
				</button>
			</div>
		</div>
	);
};

export default SetSettlementAccountForm;
