import React from "react";
import { useHistory } from "react-router-dom";
import Scroll from "utils/Scroll/Scroll";
import { ReactComponent as Scroll1 } from "assets/images/icons/scroll1.svg";
import { ReactComponent as Scroll2 } from "assets/images/icons/scroll2.svg";
import { ReactComponent as Scroll3 } from "assets/images/icons/scroll3.svg";
import { ReactComponent as Arrow } from "assets/images/icons/arrow-right.svg";
import { fetchUserData } from "storage/sessionStorage";
import { calcTotal, toCurrency } from "utils/helpers";
import { NASD, NSE, NGX } from "../../../../constants";

const ScrollingAllocation = () => {
	const history = useHistory();
	const { portfolio_accounts } = fetchUserData();
	const NSEPortfolios = portfolio_accounts?.filter(
		(v) => v.exchange === NGX && !v?.margin
	);
	const NASDPortfolios = portfolio_accounts?.filter(
		(v) => v.exchange === NASD && !v?.margin
	);

	const MarginPortfolios = portfolio_accounts?.find(
		({ margin }) => margin === true
	);

	const totalValuation = calcTotal(portfolio_accounts, "valuation");
	const equity = calcTotal(NSEPortfolios, "valuation");
	const NASDValue = calcTotal(NASDPortfolios, "valuation");

	return (
		<div className=" my-5 py-4">
			<Scroll>
				<ScrollItem
					icon={<Scroll1 />}
					title="Total Portfolio Valuation"
					amount={toCurrency(totalValuation)}
					handleClick={() => history.push("/portfolio")}
				/>
				{MarginPortfolios && (
					<ScrollItem
						icon={<Scroll2 />}
						title="Margin"
						amount={toCurrency(MarginPortfolios?.valuation)}
						handleClick={() => history.push("/margin-trade")}
					/>
				)}
				<ScrollItem
					icon={<Scroll3 />}
					title="NGX"
					amount={toCurrency(equity)}
					handleClick={() =>
						history.push({
							pathname: "/portfolio",
							state: { exchange: NSE },
						})
					}
				/>
				<ScrollItem
					icon={<Scroll3 />}
					title="NASD"
					amount={toCurrency(NASDValue)}
					handleClick={() =>
						history.push({
							pathname: "/portfolio",
							state: { exchange: NASD },
						})
					}
				/>
			</Scroll>
		</div>
	);
};

const ScrollItem = ({ icon, title, amount, handleClick }) => (
	<div className="alloc">
		{icon}
		<div className="ms-4">
			<span className="fw-bold text--gray">{title}</span>
			<p className="heading4">NGN {amount}</p>
			<div className="pointer d-inline-block" onClick={handleClick}>
				<small className="text--primary fw-bold ">View </small>
				<Arrow />
			</div>
		</div>
	</div>
);

export default ScrollingAllocation;
