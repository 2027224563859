import React from "react";
import Table from "utils/Table/Table";
import { TableLoader } from "utils/TableLoader/TableLoader";
import Toast from "utils/Alert/Toast/Toast";
import { fundTableHeading, withdrawTableHeading } from "../../extra";
import { ERROR, FUND, WITHDRAW } from "../../../../constants";
import { getError, timeDateFormat, toCurrency, titleCase } from "utils/helpers";

const WalletTransactions = ({ type, transactionQuery }) => {
	const tableHeading =
		type === FUND ? fundTableHeading : withdrawTableHeading;

	const { loading, error, data } = transactionQuery;

	const { payment_transaction: transactions } = data || {};

	const getTableData = () => {
		const dateValue = (val) =>
			`${timeDateFormat(val)?.date} ${timeDateFormat(val)?.time}`;

		const withdrawAccountDetails = (val) => (
			<span>
				{val?.destination_account_name ? (
					<>
						{val?.destination_account_name}
						<br />
						<b>{val?.destination_account_number}</b> (
						{val?.destination_bank_name})
					</>
				) : (
					"Not Available"
				)}
			</span>
		);

		if (type === FUND)
			return transactions?.map((transaction) => ({
				reference: transaction?.reference,
				// account: fundAccountDetails(transaction?.user.account),
				amount: toCurrency(transaction?.amount),
				channel: titleCase(transaction?.channel),
				status: transaction?.completed ? "Successful" : "Pending",
				date: dateValue(transaction?.created_at),
			}));
		if (type === WITHDRAW)
			return transactions?.map((transaction) => ({
				reference: transaction?.reference,
				account: withdrawAccountDetails(transaction),
				amount: toCurrency(transaction?.amount),
				status: transaction?.completed ? "Successful" : "Pending",
				date: dateValue(transaction?.created_at),
			}));
	};

	const tableData = loading ? TableLoader(6) : getTableData();

	return (
		<>
			{error && <Toast msg={getError(error)} variant={ERROR} />}
			<div className="mt-5 pt-5">
				<h2 className="fw-bold mb-5">
					{type === FUND ? "Funding" : "Withdrawal"} History
				</h2>
				<Table
					data={tableData}
					headings={tableHeading}
					rowClick={() => {}}
					cls="faint__bg"
				/>

				{!loading && transactions?.length === 0 && (
					<p className="heading3 text-danger text-center my-5">
						No Transaction Found!!!
					</p>
				)}
			</div>{" "}
		</>
	);
};

export default WalletTransactions;
