import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/images/icons/closecover.svg";
import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";
import MarginDocument from "assets/margin-trading.pdf";
import MarginDisclaimer from "../MarginDisclaimer/MarginDisclaimer";
import { margin_req_status } from "../../../../../constants";
import { downloadPDF } from "utils/helpers";

const MarginStatusModal = ({ margin, marginRequest, toggle, nextStep }) => {
	const [showDisclaimer, setShowDisclaimer] = useState(
		!margin && !marginRequest
	);

	const hideDisclaimer = () => setShowDisclaimer((prev) => !prev);

	return (
		<>
			{showDisclaimer ? (
				<MarginDisclaimer
					toggle={toggle}
					hideDisclaimer={hideDisclaimer}
				/>
			) : (
				<MarginStatus
					status={marginRequest}
					nextStep={nextStep}
					toggle={toggle}
				/>
			)}
		</>
	);
};

const MarginStatus = ({ status, nextStep, toggle }) => {
	const downloadDoc = () => {
		fetch(MarginDocument).then((response) => {
			response.blob().then((blob) => {
				downloadPDF(blob, "Margin-request-agreement.pdf");
				nextStep();
			});
		});
	};

	const render = () => {
		switch (status) {
			case margin_req_status.CREATED:
				return <Pending />;

			case margin_req_status.DECLINED:
				return <Declined />;

			default:
				return <NoMarginRequest />;
		}
	};
	return (
		<div className="p-4 fw-bold text--gray">
			<div className="flex__between border-bottom pb-2">
				<span className="heading3 ">Application Status</span>
				<CloseIcon
					className="pointer ms-auto"
					width={18}
					onClick={toggle}
				/>
			</div>

			{render()}

			{status !== margin_req_status.CREATED && (
				<button
					className="btn btn--sm btn--primary px-5  mx-auto"
					onClick={downloadDoc}
				>
					<DownloadIcon className="me-3 white" />
					<span>Margin Request Form</span>
				</button>
			)}
		</div>
	);
};

const NoMarginRequest = () => (
	<p className=" py-5 text-center">
		Thank you for your interest in Margin Trading. <br />
		Kindly download and fill the agreement form. Once the form is completely
		filled, upload it in the next form
	</p>
);

const Pending = () => (
	<>
		<div className="bg-warning rounded px-3 py-2 mt-5 col-2 mx-auto text-center">
			Pending
		</div>
		<p className=" py-4 text-center">
			Your application is currently under review. Kindly check back on
			this page within the next few days.
		</p>
	</>
);

const Declined = () => (
	<>
		<div className="bg-warning rounded px-3 py-2 mt-5 col-2 mx-auto text-center">
			Declined
		</div>
		<p className=" py-4 text-center">Your application has been declined.</p>
	</>
);

export default MarginStatusModal;
