import EmailSent from "./common/EmailSent/EmailSent";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ForgotPassword/ResetPassword/ResetPassword";
import Login from "./Login/Login";
import VerifiyEmail from "./VerifiyEmail/VerifiyEmail";

const defaultPath = "/auth";

const routes = [
	{
		path: `${defaultPath}/login`,
		component: Login,
		exact: true,
	},
	{
		path: `${defaultPath}/forgot-password`,
		component: ForgotPassword,
		exact: true,
	},

	{
		path: `${defaultPath}/reset-password/:token`,
		component: ResetPassword,
		exact: true,
	},
	{
		path: `${defaultPath}/mail`,
		component: EmailSent,
		exact: true,
	},

	{
		path: `${defaultPath}/verify/:token`,
		component: VerifiyEmail,
		exact: true,
	},
];

export default routes;
