import React, { useState, useEffect } from "react";
import BankingNavs from "../common/BankingNavs/BankingNavs";
import BankingWrapper from "../common/BankingWrapper/BankingWrapper";
import { ReactComponent as EyeIcon } from "assets/images/icons/eye-off.svg";
import { fetchUserData } from "storage/sessionStorage";

const WalletInformation = ({ history }) => {
	const [accountDetails, setAccountDetails] = useState({});
	const [acctNumber, setAcctNumber] = useState();

	const { account_name, account_number, bank_name } = accountDetails || {};
	const star = "*****";

	useEffect(() => {
		const { account } = fetchUserData();
		if (account) {
			setAccountDetails(account[0]);
			toggleAccount(account[0]?.account_number);
		}
		// eslint-disable-next-line
	}, []);

	const thick = acctNumber === account_number;

	const toggleAccount = (newNumber) => {
		if (newNumber) {
			const text = newNumber?.slice(0, 5);
			if (text === star) setAcctNumber(account_number);
			else {
				const hideText = newNumber.replace(text, star);
				setAcctNumber(hideText);
			}
		}
	};

	return (
		<BankingWrapper history={history}>
			<BankingNavs />
			<div className="div__wrapper p-2">
				{accountDetails ? (
					<>
						<div className="bg__gray rounded px-3 py-2 flex__between">
							<span className="heading4">Bank Name</span>
						</div>
						<div className="flex__between mb-5 p-4 fw-bold text--gray">
							{bank_name}
						</div>

						<div className="bg__gray rounded px-3 py-2">
							<span className="heading4">Account Name</span>
						</div>
						<div className="flex__between mb-5 p-4 fw-bold text--gray">
							{account_name}
						</div>

						<div className="bg__gray rounded px-3 py-2">
							<span className="heading4">Account Number</span>
						</div>
						<div className="flex__between mb-5 p-4 fw-bold text--gray">
							{acctNumber}
							<div>
								<div
									className={`d-inline-block me-4 ${
										thick ? "show__password" : ""
									}`}
								>
									<EyeIcon
										className="pointer"
										onClick={() =>
											toggleAccount(acctNumber)
										}
									/>
								</div>
							</div>
						</div>
					</>
				) : (
					<div className="py-5 my-5 flex__center text-danger heading3">
						No Account Available
					</div>
				)}
			</div>
		</BankingWrapper>
	);
};

export default WalletInformation;
