import React from "react";
import DashboardPortfolios from "../../common/DashboardPortfolios/DashboardPortfolios";
import DashboardWatchlist from "./DashboardWatchlist/DashboardWatchlist";
import TopButtons from "./TopButtons/TopButtons";

const DashContentRightView = ({ history }) => {
	return (
		<div className="col-xl-3 col-lg-4 right__view d-lg-block d-none">
			<TopButtons history={history} />
			<DashboardWatchlist history={history} />
			<DashboardPortfolios history={history} />
		</div>
	);
};

export default DashContentRightView;
