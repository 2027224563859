import React, { useState } from "react";
import AddStockToWatchListModal from "../../modals/AddStockToWatchListModal/AddStockToWatchListModal";

const AddStocksToWatchlistButton = ({ watchlist }) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	return (
		<>
			<span onClick={toggle}>Add item to list</span>

			{showModal && (
				<AddStockToWatchListModal
					watchlist={watchlist}
					toggle={toggle}
				/>
			)}
		</>
	);
};

export default AddStocksToWatchlistButton;
