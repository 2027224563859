import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CANCEL_ORDER } from "api/mutations/stock";
import CancelOrderForm from "./CancelOrderForm/CancelOrderForm";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import Modal from "utils/Modal/Modal";
import { ERROR } from "components/constants";

const CancelOrderModal = ({ id, toggle, refetch }) => {
	const [errorMsg, setErrorMsg] = useState();
	const [successMsg, setSuccessMsg] = useState();

	const [cancelOrder, { loading }] = useMutation(CANCEL_ORDER, {
		onCompleted: ({ cancel_order }) =>
			setSuccessMsg(cancel_order?.message || "Order has been cancelled"),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleAPICall = () => {
		setErrorMsg();
		cancelOrder({ variables: { id } });
	};

	const refetchOnSuccess = () => {
		toggle();
		refetch();
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<Modal show={true}>
				<CancelOrderForm
					id={id}
					loading={loading}
					successMsg={successMsg}
					toggle={toggle}
					refetch={refetchOnSuccess}
					handleAPICall={handleAPICall}
				/>
			</Modal>
		</>
	);
};

export default CancelOrderModal;
