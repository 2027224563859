import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Avatar } from "assets/images/icons/user.svg";
import { fetchUserData } from "storage/sessionStorage";
import { getActiveRoute } from "utils/Tabs/helpers";
import Routes from "../ProfileRoutes";

const ProfileTabs = ({ toggle }) => {
	const { pathname } = useLocation();
	const { profile_picture, account } = fetchUserData();
	const { url } = profile_picture || {};

	return (
		<div className="col-md-3 col-12 profileTabs pe-0">
			<section className="avatar">
				{url ? (
					<img
						src={url}
						alt=""
						className="w-100 h-100 rounded-circle"
					/>
				) : (
					<Avatar className="w-100 h-100" />
				)}
			</section>

			<h5 className="heading4 mb-0 mt-4 pb-3 text-center border-bottom">
				ID: 226HSYGSH7{" "}
				<p>
					Account No:{" "}
					{account?.[0]?.account_number || "Not Available"}
				</p>
			</h5>

			<ul className="nav nav-tabs " id="myTab" role="tablist">
				{Routes?.map(({ name, path, dropdown, hide }) => (
					<React.Fragment key={name}>
						{!hide && (
							<NavLink
								onClick={toggle}
								className="nav-item"
								to={path}
								role="presentation"
							>
								<button
									className={`nav-link  ${getActiveRoute(
										path,
										pathname,
										dropdown
									)}`}
								>
									{name}
								</button>
							</NavLink>
						)}
					</React.Fragment>
				))}
			</ul>
		</div>
	);
};

export default ProfileTabs;
