export const AreaData = [
	12300, 11, 32, 1110, 235, 3269, 640, 1005, 1436, 2063, 3057, 4618, 6444,
	9822, 15468, 70434, 24126, 21387, 29459, 71056, 31982, 32040, 31233, 29224,
	27342, 26662, 26956, 27912, 28999, 28965, 27826, 25579, 25722, 24826, 24605,
	64304, 13464, 23708, 24099, 14357, 24237, 34401, 24344, 23586, 22380, 21004,
	17287, 14747, 13076, 12555, 12144, 11009, 10950, 10871, 20824, 10577, 10527,
	10475, 10421, 10358, 10295, 10104, 9914, 9620, 9326, 5113, 5113, 4954, 4804,
	4761, 4717, 4368, 4018, 1233, 9876, 1237, 6534, 6577, 8989, 1235, 3217,
	7832, 7525,
];

export const AreaData2 = [
	6104, 1914, 9620, 9326, 5113, 5113, 4368, 4018, 1233, 9876, 1237, 6577,
	8989, 1235, 3217, 7832, 7525,
];
