import { BUY } from "components/constants";
import { MARGIN, NASD, NSE } from "components/constants";
import { fetchUserData } from "storage/sessionStorage";
import { titleCase } from "utils/helpers";

export const getBest = (data, value = "price") =>
	data?.reduce(
		(prev, current) => (prev[value] > current[value] ? prev : current),
		{}
	);

export const equityOptions = [
	{ label: "NGX", value: NSE },
	{ label: "NASD", value: NASD },
	{ label: "Margin", value: MARGIN },
];

export const portfolioOptions = () => {
	const { portfolio_accounts = [] } = fetchUserData();
	let options = portfolio_accounts?.map(({ label, id }) => ({
		label,
		value: id,
	}));
	return options;
};

export const portfolioOption = (noMargin) => {
	const { portfolio_accounts = [] } = fetchUserData();
	let account = portfolio_accounts?.[0];
	if (account?.margin && noMargin) {
		account = portfolio_accounts?.[1] || {};
	}
	const marginAccount = portfolio_accounts.find((acc) => acc.margin);
	let options = portfolio_accounts?.map(({ label, id }) => ({
		label,
		value: id,
	}));

	return {
		marginAccount,
		account,
		options,
	};
};

export const portfolioOptionsWithNoMargin = () => {
	const { portfolio_accounts } = fetchUserData();
	let options = portfolio_accounts?.reduce((arr, val) => {
		if (val?.margin) {
			return arr;
		} else
			return [
				...arr,
				{
					label: val.label,
					value: val?.id,
				},
			];
	}, []);
	return options;
};

export const tooltip = {
	"data-bs-toggle": "tooltip",
	"data-bs-placement": "top",
	"data-bs-custom-class": "custom-tooltip",
};

export const getCommissions = ({ type = BUY, amount = 0 }) => {
	const { order_fee = [] } = fetchUserData();
	const fee = order_fee?.find((v) => titleCase(v.type) === titleCase(type));
	const percent = 0.01;
	const commission = amount * fee.commission_percentage * percent;
	const cscsFees = amount * fee.cscs_percentage * percent;
	const ngxFees = amount * fee.exchange_percentage * percent;
	const secFees = amount * fee.sec_percentage * percent;
	const stampDuty = amount * fee.stamp_duty_percentage * percent;
	return {
		commission,
		fees: cscsFees + ngxFees + secFees + stampDuty + fee?.cscs_alert,
	};
};
