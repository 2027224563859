import React, { useState } from "react";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import CustomIpnutBox from "utils/CustomInputBox/CustomInputBox";
import { formValidator } from "utils/FormValidator/FormValidator";
import Modal from "utils/Modal/Modal";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import { SECONDARY } from "../../../../constants";

const defaultFilters = {
	increase: false,
	decrease: false,
};

const GetNotifiedModal = ({ toggle }) => {
	const [price, setPrice] = useState("");
	const [formError, setFormError] = useState({});
	const [filter, setFilter] = useState(defaultFilters);

	const handleCheck = (name) => {
		setFilter((prev) => ({
			...defaultFilters,
			[name]: !prev[name],
		}));
		setPrice();
		setFormError({});
	};

	const handleChange = ({ target: { value } }) => {
		value = value.replace(/[^0-9]/g, "");
		setPrice(value);
		setFormError({});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator({ price });
		isValid ? toggle() : setFormError(formErrors);
	};

	return (
		<Modal show={true} size="sm">
			<ModalHeader
				type={SECONDARY}
				toggle={toggle}
				title="Get Notified When"
			/>

			<form onSubmit={handleSubmit} className="px-3">
				<CustomCheckBox
					label="Price Decreases"
					checked={filter?.decrease}
					onClick={() => handleCheck("decrease")}
				/>
				{filter?.decrease && (
					<div className="my-3 no-margin">
						<CustomIpnutBox
							name="price"
							value={price}
							onChange={handleChange}
							error={formError?.price}
						/>
					</div>
				)}

				<div className="py-3">
					<CustomCheckBox
						label="Price Increases"
						checked={filter?.increase}
						onClick={() => handleCheck("increase")}
					/>
					{filter?.increase && (
						<div className="mt-3 no-margin">
							<CustomIpnutBox
								name="price"
								value={price}
								onChange={handleChange}
								error={formError?.price}
							/>
						</div>
					)}
				</div>

				<button
					type="submit"
					className="btn btn--lg btn--primary mt-4 mb-3"
				>
					Save
				</button>
			</form>
		</Modal>
	);
};

export default GetNotifiedModal;
