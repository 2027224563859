import React from "react";
import Scroll from "utils/Scroll/Scroll";
import { sectorIcons } from "../../helpers";
import { ReactComponent as Arrow } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as Meritrade } from "assets/images/icons/meri-logo.svg";
import "./InvestmentCategories.scss";
import { GET_SECTOR } from "api/queries/stock";
import { useQuery } from "@apollo/client";

const InvestmentCategories = ({ setSector }) => {
	const { data } = useQuery(GET_SECTOR);
	return (
		<div className="left__view py-4">
			<Scroll>
				<Category
					clear
					sector="All Sectors"
					bgColor="sector__0"
					setSector={setSector}
				/>
				{data?.stock?.map(({ sector, name }, i) => (
					<Category
						key={name}
						sector={sector}
						bgColor={`sector__${i >= 30 ? i - 30 : i + 1}`}
						setSector={setSector}
					/>
				))}
			</Scroll>
		</div>
	);
};

const Category = ({ clear, sector, bgColor, setSector }) => {
	const Icons = sectorIcons[sector] || Meritrade;
	if (!sector) return null;
	return (
		<div
			className={`pointer alloc invest__cat sectors ${bgColor}`}
			onClick={() => setSector(clear ? "" : sector)}
		>
			<div className="img-cover rounded flex__center">
				<Icons />
			</div>

			<div className="ms-4">
				<p className="fw-bold ">{sector}</p>

				<small className="fw-bold pointer">View </small>
				<Arrow className="white" />
			</div>
		</div>
	);
};

export default InvestmentCategories;
