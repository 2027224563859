import { stockTransType } from "components/constants";
import React from "react";
import MarketTabs from "../common/MarketTabs";
import BuyStockPriceForm from "./BuyStockPriceForm";

const BuyStockOptions = ({ order, orderTerms, toggle, nextStep }) => {
	return (
		<>
			<MarketTabs />
			<div className="tab-content" id="myTabContent">
				<div
					className="tab-pane fade show active"
					id="markets"
					role="tabpanel"
				>
					<BuyStockPriceForm
						price_type={stockTransType.MARKET}
						order={order}
						toggle={toggle}
						nextStep={nextStep}
						orderTerms={orderTerms}
					/>
				</div>
				<div className="tab-pane fade" id="limit" role="tabpanel">
					<BuyStockPriceForm
						price_type={stockTransType.LIMIT}
						order={order}
						toggle={toggle}
						nextStep={nextStep}
						orderTerms={orderTerms}
					/>
				</div>
				<div className="tab-pane fade" id="stop_limit" role="tabpanel">
					<BuyStockPriceForm
						price_type={stockTransType.STOP_LIMIT}
						order={order}
						toggle={toggle}
						nextStep={nextStep}
						orderTerms={orderTerms}
					/>
				</div>
			</div>
		</>
	);
};

export default BuyStockOptions;
