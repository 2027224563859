import React from "react";

const LineText = ({ title, value }) => {
	return (
		<div className="flex__between nowrap fw-bold my-4 py-2">
			<span className="text--gray text-nowrap">{title}</span>{" "}
			<div className="border-bottom w-100 mx-2" />
			<span className="text--primary text-nowrap">{value}</span>
		</div>
	);
};

export default LineText;
