export const fundTableHeading = [
	"Transaction Reference",
	"Amount(#)",
	"Channel",
	"Status",
	"Date",
];

export const withdrawTableHeading = [
	"Transaction Reference",
	"Account Details",
	"Amount(#)",
	"Status",
	"Date",
];
