import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import { RESOLVE_BANK } from "api/queries/others/bank";
import { REQUEST_OTP } from "api/mutations/auth";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import BankSelect from "utils/Select/BankSelect/BankSelect";
import { getError } from "utils/helpers";

const ResolveAccountForm = ({
	check,
	setCheck,
	showCheck,
	nextStep,
	setErrorMsg,
}) => {
	const [resolveError, setResolveError] = useState();
	const [formErrors, setFormErrors] = useState();
	const [bankInfo, setBankInfo] = useState({});
	const [request, setRequest] = useState({
		bank: "",
		account_number: "",
	});

	useEffect(() => {
		const { account_number, bank } = request;
		if (account_number?.length === 10 && bank) {
			setResolveError();
			resolveBank({
				variables: { account_number, bank_code: bank?.code },
			});
		}
		// eslint-disable-next-line
	}, [request]);

	const [resolveBank, { loading, error }] = useLazyQuery(RESOLVE_BANK, {
		fetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			setBankInfo(data?.resolve_bank || {});
		},
		onError: () => {
			setBankInfo();
			setResolveError("Error Validating Account");
		},
	});

	const [requestOtp, { loading: qLoading }] = useMutation(REQUEST_OTP, {
		onCompleted: () =>
			nextStep({
				account_name: bankInfo?.account_name,
				account_number: bankInfo?.account_number,
				bank_code: request?.bank?.code,
				bank_name: request?.bank?.name,
				sort_code: request?.bank?.sort_code,
			}),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const checkValidity = () => {
		const { account_number, bank } = request;
		return account_number?.length !== 10 || !bank;
	};

	const handleChange = ({ target: { name, value } }) => {
		value = value.replace(/[^0-9]/g, "");
		handleSelect(name, value);
	};

	const handleSelect = (name, value) => {
		setRequest((prev) => ({
			...prev,
			[name]: value,
		}));
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleSubmit = () => {
		const { formErrors, isValid } = formValidator(request);
		isValid
			? requestOtp({
					variables: {
						data: { mutation: "insert_settlement_account_one" },
					},
			  })
			: setFormErrors(formErrors);
	};

	const renderResolve = () => {
		if (loading)
			return (
				<div className="text-end fw-bold text--gray mt-n4 pb-4">
					<span>Please Wait...</span>
					<CustomSpinner cls="spinner-border-sm ms-2" />
				</div>
			);
		else if (resolveError)
			return (
				<div className="text-end text-danger fw-bold mt-n4 pb-4">
					<span>{resolveError}</span>
				</div>
			);
		else if (bankInfo)
			return (
				<div className="text-end text--gray text-uppercase fw-bold mt-n4 pb-4">
					<span>{bankInfo?.account_name}</span>
				</div>
			);
		else return "";
	};

	return (
		<>
			<CustomInputBox
				name="account_number"
				label="Enter your Account Number"
				placeholder="1007767562"
				value={request?.account_number}
				onChange={handleChange}
				error={formErrors?.account_number}
				maxLength={10}
				disabled={loading}
				required
			/>

			<BankSelect
				name="bank"
				label="Select Bank"
				placeholder="Select Bank"
				error={formErrors?.bank}
				value={request?.bank}
				disabled={loading}
				onChange={(e) => handleSelect("bank", e)}
				required
			/>

			{renderResolve()}

			{showCheck && (
				<CustomCheckBox
					label="Use this account as my Settlement Account"
					checked={check}
					onClick={() => setCheck((prev) => !prev)}
				/>
			)}

			<div className="col-6 mx-auto mt-5">
				<button
					type="button"
					className="btn btn--lg btn--primary"
					disabled={loading || qLoading || !!error || checkValidity()}
					onClick={handleSubmit}
				>
					{qLoading ? <CustomSpinner /> : "Submit"}
				</button>
			</div>
		</>
	);
};

export default ResolveAccountForm;
