import React from "react";
import QuickTradeButton from "../../../MarketUpdates/button/QuickTradeButton/QuickTradeButton";
import InitiateMarginTradeButton from "../../../Portfolio/buttons/InitiateMarginTradeButton/InitiateMarginTradeButton";

const TopButtons = ({ history }) => {
	return (
		<div className="flex__between mt-1 mb-lg-5 mb-0">
			<QuickTradeButton />

			<InitiateMarginTradeButton history={history} />
		</div>
	);
};

export default TopButtons;
