import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { DELETE_WATCHLIST_ITEM } from "api/mutations/watchlist";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import { ERROR } from "../../../constants";
import Spinner from "utils/CustomSpinner/CustomSpinner";

const DeleteWatchListItemModal = ({ id, stock, toggle }) => {
	const [errorMsg, setErrorMsg] = useState();
	const [successMsg, setSuccessMsg] = useState();

	const [deleteWatchlistItem, { loading }] = useMutation(
		DELETE_WATCHLIST_ITEM,
		{
			onCompleted: () => setSuccessMsg("Watchlist Item Deleted"),
			onError: (error) => setErrorMsg(getError(error)),
		}
	);

	const handleAPICall = () => {
		setErrorMsg();
		deleteWatchlistItem({ variables: { id, stock: stock?.name || "" } });
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<Modal show={true}>
				<div className="text-center pb-4">
					<p className="heading4 border-bottom">
						Delete Watchlist Item
					</p>

					{successMsg ? (
						<SuccessState
							btnText="Done"
							msg={successMsg}
							toggle={toggle}
						/>
					) : (
						<>
							<div className="pt-4 pb-5">
								Are you sure you want to delete{" "}
								{stock?.label
									? stock?.label
									: "this Watchlistitem"}
							</div>

							<div className="flex__between px-3">
								<button
									className="btn btn--lg btn--primary--bordered me-3"
									onClick={toggle}
								>
									No
								</button>
								<button
									className="btn btn--lg btn--secondary ms-3"
									onClick={handleAPICall}
									disabled={loading}
								>
									{loading ? <Spinner /> : "Yes"}
								</button>
							</div>
						</>
					)}
				</div>
			</Modal>
		</>
	);
};

export default DeleteWatchListItemModal;
