import React from "react";
import TableRow from "./TableRow";

const TableBody = ({ data, rowClick }) => {
	return (
		<tbody className="table__body">
			{data.map((val, i) => (
				<TableRow
					key={i}
					id={val?.id || ""}
					rowValues={val?.value || val}
					rowClick={rowClick}
				/>
			))}
		</tbody>
	);
};

export default TableBody;
