import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router";
import CustomToggle from "utils/CustomToggle/CustomToggle";
import SettingsNavs from "./SettingsNavs/SettingsNavs";
import { getMode } from "storage/localStorage";
import { switchMode } from "redux/actions/modeActions";
import { DARK_MODE } from "components/constants";
import { LIGHT_MODE } from "components/constants";
import Routes from "./routes";
import "./Settings.scss";

const Settings = ({ switchMode }) => {
	const [theme, setTheme] = useState(getMode() === DARK_MODE ? true : false);

	const switchTheme = () => {
		const mode = getMode() === DARK_MODE ? LIGHT_MODE : DARK_MODE;
		switchMode(mode);
		setTheme((prev) => !prev);
	};

	const getRoutes = () => (
		<Switch>
			{Routes.map((route, i) => (
				<Route
					key={i}
					path={route.path}
					exact={route.exact}
					component={route.component}
				/>
			))}
			<Route
				exact
				path="*"
				component={() => <Redirect to="/auth/login" />}
			/>
		</Switch>
	);
	return (
		<div className="col settings__content">
			<div className="settings__header flex__between">
				<span className="heading2 m-0 col-5">Settings</span>
				<span className="col-7 d-flex justify-content-end">
					<CustomToggle checked={theme} handleToggle={switchTheme} />
					<span className="paragraph2">Dark Mode</span>
				</span>
			</div>
			<div className="d-flex flex-wrap">
				<div className="col-md-6 col-12">
					<SettingsNavs />

					{getRoutes()}
				</div>
			</div>
		</div>
	);
};

export default connect(null, { switchMode })(Settings);
