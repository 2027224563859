import React, { useState } from "react";
import StatementNavs from "../StatementNavs/StatementNavs";
import StatementFilters from "../StatementFilters/StatementFilters";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";

const StatementWrapper = ({
	children,
	type,
	data,
	total,
	filters,
	refetch,
	isCash,
	options,
	portfolio,
	setPortfolio,
}) => {
	const [isToday, setIsToday] = useState(true);
	const [otherFilters, setOtherFilters] = useState(filters);

	const handleChange = (selected) => {
		const currentValue = selected?.value || selected?.id;
		setPortfolio(selected);
		handleSubmit({
			data: otherFilters,
			id: currentValue,
		});
	};

	const handleSubmit = ({ data = {}, id }) => {
		setOtherFilters(data);
		const account_id = id ?? portfolio.value ?? portfolio.id;
		isCash
			? refetch({ variables: { ...data, account_id } })
			: refetch({ variables: { ...data, portfolio_id: account_id } });
	};

	return (
		<div className="dash__content full__screen">
			<p className="heading2">
				Account Statement{" "}
				<span>({isToday ? "Today" : "Date Range"})</span>
			</p>

			<div className="row">
				<div className="col-md-10">
					<StatementNavs />
				</div>

				<div className="col-md-2">
					<CustomSelect
						name="portfolio"
						placeholder="Select Portfolio"
						value={portfolio}
						onChange={(e) => handleChange(e)}
						options={options}
						formGroupCls="mb-0"
					/>
				</div>
			</div>

			<div className="d-flex justify-content-between align-items-end mb-5 content__slider">
				<div className="text--primary pe-4">
					<p className="heading1 text--primary fw-normal mb-0">
						{total}
					</p>
					<span className="fw-bold">Total Statement</span>
				</div>

				<StatementFilters
					type={type}
					isCash={isCash}
					data={data}
					defaultFilters={filters}
					applyFilter={handleSubmit}
					setIsToday={setIsToday}
				/>
			</div>

			{children}
		</div>
	);
};

export default StatementWrapper;
