import React, { useState, useRef, useEffect } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import OTPInput from "utils/OTPInput/OTPInput";
import { MFA_LOGIN } from "api/mutations/auth";
import { resetApp } from "redux/actions/appActions";
import { storeUserData } from "storage/sessionStorage";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import { ERROR } from "../../../../constants";

const MFAValidationForm = ({ url, token, toggle, updateUsername }) => {
	const [errorMsg, setErrorMsg] = useState("");
	const [otp, setOtp] = useState("");

	const btnRef = useRef();

	useEffect(() => {
		if (otp?.length === 6) {
			btnRef.current.focus();
		}
	}, [otp]);

	const history = useHistory();
	const client = useApolloClient();
	const dispatch = useDispatch();

	const [mfaLogin, { loading }] = useMutation(MFA_LOGIN, {
		onCompleted: ({ mfa_login }) => {
			client.clearStore();
			dispatch(resetApp());
			storeUserData({ accessToken: mfa_login?.token });
			updateUsername?.();
			history.replace(url); //route to specified url
			window.location.reload();
		},
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleSubmit = () => {
		mfaLogin({ variables: { data: { otp, token } } });
	};

	return (
		<form className="form-group px-4 pt-5" onSubmit={handleSubmit}>
			<Toast msg={errorMsg} variant={ERROR} />

			<h2 className="heading1 text-center mb-4">2FA Authentication</h2>

			<div className="flex__center py-3">
				<OTPInput
					name="OTPInput"
					value={otp}
					onChange={(value) => setOtp(value)}
				/>
			</div>

			<div className="flex__between mt-5 px-3">
				<button
					className="btn btn--lg btn--primary--bordered me-3"
					onClick={toggle}
					disabled={loading}
				>
					Cancel
				</button>
				<button
					ref={btnRef}
					className="btn btn--lg btn--primary ms-3"
					onClick={handleSubmit}
					disabled={loading || otp?.length !== 6}
				>
					{loading ? <CustomSpinner /> : "Submit"}
				</button>
			</div>
		</form>
	);
};

export default MFAValidationForm;
