import React, { useState } from "react";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import QuickTradeForm from "./QuickTradeForm/QuickTradeForm";

const QuickTradeModal = ({ toggle, order, refetch }) => {
	const [successMsg, setSuccessMsg] = useState("");

	const size = successMsg ? "" : "lg";

	const handleSuccessMsg = (message) => {
		setSuccessMsg(message);
		refetch && refetch();
	};

	return (
		<Modal size={size} show={true} cls="med__modal">
			{successMsg ? (
				<SuccessState msg={successMsg} btnText="Done" toggle={toggle} />
			) : (
				<QuickTradeForm
					toggle={toggle}
					setSuccessMsg={handleSuccessMsg}
					order={order}
				/>
			)}
		</Modal>
	);
};

export default QuickTradeModal;
