import React, { useState } from "react";
import Modal from "utils/Modal/Modal";
import { ReactComponent as BackIcon } from "assets/images/icons/closecover.svg";
import { ReactComponent as Avatar } from "assets/images/icons/user.svg";
import LivenessUploadUpload from "./LivenessUploadUpload";
import LivenessCheckContent from "./LivenessCheckContent";

const LivenessCheck = ({
	imageFile = "",
	toggleOCR,
	onSubmit,
	liveness_check,
	updateRegStatus,
}) => {
	const [image, setImage] = useState(imageFile);
	const [init, setInit] = useState(true);

	const disabled = image && liveness_check;

	const renderByStage = () => {
		if (init)
			return (
				<div className="w-100 text-center py-4">
					<p className="heading4">
						{`Image Upload ${
							!liveness_check ? "And Liveness Verification" : ""
						} `}
					</p>

					<div className="d-flex justify-content-center py-5">
						<LivenessUploadUpload
							image={image}
							setImage={setImage}
						/>
						{!liveness_check && (
							<div className="mx-5">
								<Avatar width={130} height={130} />
								<span
									className="btn text--primary mt-4"
									onClick={() => setInit(false)}
								>
									Start liveness verification
								</span>
							</div>
						)}
					</div>

					<div className="text-center">
						<button
							type="button"
							className="btn btn--sm btn--primary mx-auto mt-4  px-5"
							disabled={!disabled}
							onClick={() => onSubmit(disabled ? image : "")}
						>
							Finish
						</button>
					</div>
				</div>
			);

		return (
			<LivenessCheckContent
				setInit={() => setInit(true)}
				updateRegStatus={updateRegStatus}
			/>
		);
	};

	return (
		<Modal show={true}>
			<BackIcon
				className="selfie-modal-back-icon pointer"
				onClick={toggleOCR}
			/>
			{renderByStage()}
		</Modal>
	);
};

export default LivenessCheck;
