import React from "react";
import PortfolioSummaryChart from "../../../DashContent/DashContentLeftView/PortfolioSummaryChart/PortfolioSummaryChart";
import { calcTotal, profitStatus, toCurrency } from "utils/helpers";

const BalanceAndChart = ({
	portfolio_id,
	portfolio,
	margin,
	handlePortfolio,
}) => {
	const total = calcTotal(portfolio, "valuation")?.toFixed(2) || 0;
	const dailyGain = calcTotal(portfolio, "gain")?.toFixed(2) || 0;
	const totalGain = portfolio?.reduce((acc, arr) => {
		return acc + arr?.stock?.price_change * arr?.quantityHeld;
	}, 0);

	return (
		<>
			<div className="flex__between mb-5 content__slider">
				<div className="me-5">
					<p className="heading4 m-0">Total Valuation</p>
					<span className="heading2">NGN{toCurrency(total)}</span>
				</div>

				<div>
					<p className="heading4 m-0">Daily P/L </p>
					<span className="heading2">
						{profitStatus(dailyGain, true)}
					</span>
				</div>

				<div>
					<p className="heading4 m-0">Total P/L </p>
					<span className="heading2">
						{profitStatus(totalGain, true)}
					</span>
				</div>
			</div>
			<div className="py-5 rounded">
				<PortfolioSummaryChart
					id={portfolio_id}
					margin={margin}
					handlePortfolio={handlePortfolio}
				/>
			</div>
		</>
	);
};

export default BalanceAndChart;
