import React from "react";
import { connect } from "react-redux";
import { NIN, NORMAL } from "../../constants";
import SignUpWithNIN from "./SignUpWithNIN/SignUpWithNIN";
import SignUpWithBioData from "./SignUpWithBioData/SignUpWithBioData";

const AccountInformation = ({ onboarding, history }) => {
	const { signupOption } = onboarding || {};

	const render = () => {
		switch (signupOption) {
			case NORMAL:
			default:
				return (
					<SignUpWithBioData
						foooterText="Sign up with BVN"
						history={history}
					/>
				);
			case NIN:
				return <SignUpWithNIN history={history} />;
		}
	};

	return <>{render()}</>;
};

export default connect(({ onboarding }) => ({ onboarding }))(
	AccountInformation
);
