import React from "react";
import NewsIcon from "assets/images/images/news.png";
import { ReactComponent as Redirect } from "assets/images/icons/redirect.svg";
import { truncate } from "utils/helpers";
import { getStockSector } from "../../../../DashContent/utils";

const News = ({ news }) => {
	const { imageUrl, url, category, summary, headLine } = news || {};
	const cat = category?.length ? category[0] : "";

	return (
		<div className="col-lg-4 col-sm-6 col-12 mb-5 ps-sm-0 ps-2 pe-sm-4 pe-2">
			<div className="row div__wrapper m-0 h-100">
				<div className="col-sm-12 col-5 p-0">
					<div className="new__image__wrapper">
						<img src={imageUrl || NewsIcon} alt="" />
					</div>
				</div>
				<div className="col-sm-12 col-7  px-3 pt-3 pb-5 fw-bold">
					<div className="flex__between d-sm-flex d-none mb-3">
						{getStockSector(cat)}
					</div>

					<p className="fw-bold d-sm-none d-md-block ">
						{truncate(headLine, 26)}
					</p>
					<span className="text--gray d-block pb-4">
						{truncate(summary, 50)}
					</span>

					{url && (
						<a
							href={url}
							target="_blank"
							rel="noreferrer"
							className="flex__between news__footer dash"
						>
							<Redirect className="ms-auto" />
						</a>
					)}
				</div>
			</div>
		</div>
	);
};

export default News;
