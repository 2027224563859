import { margin_req_status } from "components/constants";
import React, { useState } from "react";
import { fetchUserData } from "storage/sessionStorage";
import MarginTradeStatusModal from "../../modals/MarginTradeStatusModal/MarginTradeStatusModal";

const InitiateMarginTradeButton = ({ history }) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	let { portfolio_accounts, margin_request } = fetchUserData() || {};
	const status = margin_request?.[0]?.status || "";
	const margin =
		status === margin_req_status.APPROVED ||
		portfolio_accounts?.find(({ margin }) => margin === true);

	return (
		<>
			<button
				className="btn btn--primary--bordered btn--lg ms-3"
				onClick={margin ? () => history.push("/margin-trade") : toggle}
			>
				Margin Trading
			</button>

			{showModal && (
				<MarginTradeStatusModal toggle={toggle} margin={margin} />
			)}
		</>
	);
};

export default InitiateMarginTradeButton;
