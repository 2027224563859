import React from "react";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";

const OTPVerificationSuccess = ({ btnText, msg, toggle }) => {
	return (
		<Modal show={true}>
			<SuccessState btnText={btnText} msg={msg} toggle={toggle} />
		</Modal>
	);
};

export default OTPVerificationSuccess;
