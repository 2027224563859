import React, { useState } from "react";

import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import SetSettlementAccountForm from "./SetSettlementAccountForm/SetSettlementAccountForm";

const SetSettlementAccountModal = ({ reference, toggle }) => {
	const [currentStep, setcurrentStep] = useState(1);

	const nextStep = () => setcurrentStep((prev) => prev + 1);

	const handleToggle = () => {
		setcurrentStep(1);
		toggle();
	};

	const refresh = () => {
		handleToggle();
		window.location.reload(true);
	};

	const render = () => {
		switch (currentStep) {
			case 1:
			default:
				return (
					<SetSettlementAccountForm
						reference={reference}
						toggle={handleToggle}
						nextStep={nextStep}
					/>
				);

			case 2:
				return (
					<SuccessState
						btnText="Done"
						msg="Settlement Account Changed Successfully"
						toggle={refresh}
					/>
				);
		}
	};
	return <Modal show={true}>{render()}</Modal>;
};

export default SetSettlementAccountModal;
