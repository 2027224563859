import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import OTPInput from "utils/OTPInput/OTPInput";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import Toast from "utils/Alert/Toast/Toast";
import { VERIFY_IDENTITY_OTP } from "api/mutations/auth";
import { getError } from "utils/helpers";
import { ERROR, SUCCESS } from "../../../../../constants";
import { REQUEST_OTP } from "api/mutations/auth";

const AttachNinOtpVerification = ({
	ninInfo,
	nextStep,
	previousStep,
	updateNinInfo,
}) => {
	const [otp, setOTP] = useState();
	const [message, setMessage] = useState();
	const { phone, email, token, nin } = ninInfo || {};

	const [validateOTP, { loading }] = useMutation(VERIFY_IDENTITY_OTP, {
		onCompleted: ({ verify_identity_otp }) => {
			updateNinInfo(verify_identity_otp);
			nextStep();
		},
		onError: (error) => setMsgStatus(getError(error)),
	});

	const [requestOtp, { qLoading }] = useMutation(REQUEST_OTP, {
		onCompleted: ({ request_otp }) => {
			updateNinInfo(request_otp);
			setMsgStatus("OTP Resend Successfully", SUCCESS);
		},
		onError: (error) => setMsgStatus(getError(error)),
	});

	const setMsgStatus = (msg, variant = ERROR) => setMessage({ msg, variant });

	const handleResendOtp = () => {
		setMessage({});
		requestOtp({ variables: { data: { nin } } });
	};

	const handleSubmit = () => {
		setMessage({});
		validateOTP({ variables: { otp, token } });
	};

	return (
		<div className="row px-5 pt-5">
			<Toast {...message} />

			<p className="heading3 fw-bold mb-4">
				Enter OTP sent to {email} or {phone}
			</p>

			<OTPInput
				name="otp"
				value={otp}
				onChange={(value) => setOTP(value)}
			/>

			<p className="mt-4 d-flex align-items-center">
				Didn't get OTP?.{" "}
				{qLoading ? (
					<span className="ms-3">
						<CustomSpinner />
					</span>
				) : (
					<b
						className="pointer text--primary ms-3"
						onClick={handleResendOtp}
					>
						Resend OTP
					</b>
				)}
			</p>

			<div className="flex__between my-5">
				<button
					className="btn btn--lg btn--primary--bordered me-2"
					onClick={previousStep}
				>
					Back
				</button>
				<button
					className="btn btn--lg btn--primary ms-2"
					disabled={!otp || otp?.length < 6 || loading}
					onClick={handleSubmit}
				>
					{loading ? <CustomSpinner /> : "Verify"}
				</button>
			</div>
		</div>
	);
};

export default AttachNinOtpVerification;
