import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "utils/Modal/Modal";
import SubmitMarginAgreementForm from "./SubmitMarginAgreementForm/SubmitMarginAgreementForm";
import DownloadMarginDoc from "./DownloadMarginDoc/DownloadMarginDoc";
import MarginStatusModal from "./MarginStatusModal/MarginStatusModal";
import { updateRegStatus } from "redux/actions/kycActions";

const MarginTradeStatusModal = ({ toggle, margin, kyc, updateRegStatus }) => {
	const [currentStep, setCurrentStep] = useState(1);

	const nextStep = () => setCurrentStep((prev) => prev + 1);

	const handleToggle = () => {
		setCurrentStep(1);
		toggle && toggle();
	};

	const render = () => {
		switch (currentStep) {
			case 1:
			default:
				return (
					<MarginStatusModal
						margin={margin}
						marginRequest={kyc?.status?.marginRequest || ""}
						toggle={handleToggle}
						nextStep={nextStep}
					/>
				);

			case 2:
				return (
					<DownloadMarginDoc
						toggle={handleToggle}
						nextStep={nextStep}
					/>
				);

			case 3:
				return (
					<SubmitMarginAgreementForm
						nextStep={nextStep}
						toggle={handleToggle}
						updateRegStatus={updateRegStatus}
					/>
				);
			case 4:
				return (
					<SubmitMarginAgreementForm
						confirm
						nextStep={nextStep}
						toggle={handleToggle}
						updateRegStatus={updateRegStatus}
					/>
				);
		}
	};

	return <Modal show={!margin}>{render()}</Modal>;
};

export default connect(({ kyc }) => ({ kyc }), { updateRegStatus })(
	MarginTradeStatusModal
);
