import React from "react";
import News from "../../../Research/ResearchNews/LatestNewsResearch/News/News";

const RelatedNews = ({ news }) => {
	return (
		<div className="mt-5 pt-md-5 pt-0">
			<span className="heading2 fw-bold d-md-block d-none">Related News</span>
			{news?.length ? (
				<div className="row mt-4">
					{news?.map(
						({ status, type, image, text, title }, i) =>
							i < 3 && (
								<News
									status={status}
									text={text}
									title={title}
									image={image}
									type={type}
									key={i}
								/>
							)
					)}
				</div>
			) : (
				<h2 className="text-danger fw-bold mt-4 text-center">
					No News Available
				</h2>
			)}
		</div>
	);
};

export default RelatedNews;
