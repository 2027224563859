import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CustomPhoneInput = ({
	name,
	error,
	disabled,
	code = "ng",
	onChange,
	value,
	cls,
	label,
	required,
	placeholder,
	disableDropdown,
}) => {
	const isError = error ? "error" : " ";
	const isRequired = required ? "required" : " ";
	const isDisabled = disabled ? "disabled" : " ";

	return (
		<div className={`form-group  ${cls}`}>
			{label && (
				<label
					htmlFor={name}
					className={`form-group-label ${isRequired}`}
				>
					{label}
				</label>
			)}
			<div
				className={`input-wrapper d-flex align-items-center ${isDisabled} ${isError} `}
			>
				<PhoneInput
					name={name}
					country={code.toLowerCase()}
					value={value}
					placeholder={placeholder}
					disabled={disabled}
					onChange={onChange}
					disableDropdown={disableDropdown}
				/>
			</div>
			{typeof error === "string" && (
				<small className="input-text-error">{error}</small>
			)}
		</div>
	);
};

export default CustomPhoneInput;
