import React from "react";
import Skeleton from "react-loading-skeleton";
import { priceStatus, timeDateFormat } from "utils/helpers";
import TableError from "utils/Table/TableError/TableError";

const MarketTrades = ({ loading, trade, currency, showTitle }) => {
	return (
		<div className="dash__port div__wrapper mb-5 w-100 h-100">
			{showTitle && (
				<div className="bg__gray text-center py-2 px-3 mb-3 rounded d-md-block d-none">
					<span className="heading4">Market Trades</span>
				</div>
			)}

			{loading ? (
				<LoadingState />
			) : (
				<>
					{!trade || trade?.length === 0 ? (
						<TableError errorMsg="Market Trade is Empty" />
					) : (
						<>
							<div className="fw-bold d-md-block pt-3 d-none">
								<Trades price="Price (NGN)" volume="Quantity" />
							</div>
							<div className="fw-bold rounded bg__gray pt-4 mb-3 d-md-none d-block">
								<Trades price={currency} volume="Quantity" />
							</div>
							{trade?.map(({ tradePrice, volume, time }, i) => (
								<div key={`trd${i}`}>
									{i < 7 && (
										<Trades
											price={priceStatus(
												trade,
												tradePrice,
												i
											)}
											volume={volume}
											time={time}
											key={i}
										/>
									)}
								</div>
							))}
						</>
					)}
				</>
			)}
		</div>
	);
};

const Trades = ({ price, volume, time, loading }) => (
	<>
		{loading ? (
			<div className="py-2">
				<Skeleton height={15} />
			</div>
		) : (
			<div className="row px-4 py-1">
				<div className="col-5">
					<p>{price}</p>
				</div>
				<div className="col-4">
					<p>{volume}</p>
				</div>
				<div className="col-3">
					<p>{time ? timeDateFormat(time)?.timeOnly : "Time"}</p>
				</div>
			</div>
		)}
	</>
);

const LoadingState = () => {
	return Array(3)
		.fill()
		.map((_, i) => <Trades key={i} loading />);
};

export default MarketTrades;
