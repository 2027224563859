import Withdrawal from "./Banking/Withdrawal/Withdrawal";
import FundWallet from "./Banking/FundWallet/FundWallet";
import Dashboard from "./DashContent/DashContent";
import MarketUpdates from "./MarketUpdates/MarketUpdates";
import AssetAllocation from "./Portfolio/AssetAllocation/AssetAllocation";
import MarginPortfolio from "./Portfolio/MarginPortfolio/MarginPortfolio";
import MyPortfolio from "./Portfolio/MyPortfolio/MyPortfolio";
import WatchList from "./WatchList/WatchList";
import WalletInformation from "./Banking/WalletInformation/WalletInformation";
import ManageCard from "./Banking/ManageCard/ManageCard";
import OrderHistory from "./Orders/OrderHistory/OrderHistory";
import Stocks from "./Investment/Stocks/Stocks";
import Bonds from "./Investment/Bonds/Bonds";
import MoneyMarket from "./Investment/MoneyMarket/MoneyMarket";
import MoneyMarketHistory from "./Investment/MoneyMarketHistory/MoneyMarketHistory";
import Profile from "./Profile/Profile";
import Purchase from "./Statements/Purchase/Purchase";
import Sell from "./Statements/Sell/Sell";
import Cash from "./Statements/Cash/Cash";
import Research from "./Research/Research";
import Notifications from "./Notifications/Notifications";
import Settings from "./Settings/Settings";
import NotificationDetails from "./Notifications/NotificationDetails/NotificationDetails";
import PortfolioAccountSummary from "./Portfolio/PortfolioAccountSummary/PortfolioAccountSummary";
import MarginAccountSummary from "./Portfolio/MarginAccountSummary/MarginAccountSummary";
import QuickTradeButton from "./MarketUpdates/button/QuickTradeButton/QuickTradeButton";
// import MobileProfile from "./Profile/MobileProfile";

const routes = [
	{
		name: "Home",
		path: "/dashboard",
		component: Dashboard,
		exact: true,
	},
	{
		name: "Banking",
		path: "/banking",
		landingURL: "/banking",
		dropdown: [
			{
				name: "Fund Wallet",
				path: "/banking",
				component: FundWallet,
				exact: true,
			},
			{
				name: "Withdrawal",
				path: "/banking/withdrawal",
				component: Withdrawal,
				exact: true,
			},
			{
				name: "Wallet Account Info",
				path: "/banking/wallet-info",
				component: WalletInformation,
				exact: true,
			},
			{
				name: "Wallet Account Info",
				path: "/banking/cards",
				component: ManageCard,
				exact: true,
				hide: true,
			},
		],
	},
	{
		name: "Investment",
		path: "/investment",
		landingURL: "/investment/stocks",
		dropdown: [
			{
				name: "Equity",
				path: "/investment/stocks",
				component: Stocks,
				exact: true,
				dropdown: [
					{
						name: "Equity",
						path: "/investment/stocks?type=NGX",
						component: Stocks,
						exact: true,
					},
					{
						name: "Equity",
						path: "/investment/stocks?type=NASD",
						component: Stocks,
						exact: true,
					},
				],
			},
			{
				name: "Money Market",
				path: "/investment/money-market",
				component: MoneyMarket,
				exact: true,
			},
			{
				name: "Bonds",
				path: "/investment/bonds",
				component: Bonds,
				exact: true,
			},
			{
				name: "Money Market History",
				path: "/investment/money-market-history",
				component: MoneyMarketHistory,
				exact: true,
				hide: true,
			},
		],
	},
	{
		name: "Portfolio",
		path: "/portfolio",
		dropdown: [
			{
				name: "View Portfolio",
				path: "/portfolio",
				component: MyPortfolio,
				exact: true,
			},
			{
				name: "Account Summary",
				path: "/portfolio/portfolio-account-summary",
				component: PortfolioAccountSummary,
				exact: true,
			},
			{
				name: "Account Summary",
				path: "/portfolio/margin-account-summary",
				component: MarginAccountSummary,
				exact: true,
				hide: true,
			},
			{
				name: "Asset Allocation",
				path: "/portfolio/asset-allocation",
				component: AssetAllocation,
				exact: true,
			},
		],
	},
	{
		name: "Orders",
		path: "/orders",
		landingURL: "/orders/history",
		dropdown: [
			{
				name: "Order History",
				path: "/orders/history",
				component: OrderHistory,
				exact: true,
			},
			{
				path: "/orders/history",
				name: <QuickTradeButton useText />,
				component: OrderHistory,
				exact: true,
			},
		],
	},
	{
		name: "Watchlist",
		path: "/watchlist",
		component: WatchList,
		hideOnDesktop: true,
	},
	{
		name: "Statements",
		path: "/statement",
		landingURL: "/statement/purchase",
		dropdown: [
			{
				name: "Buy",
				path: "/statement/purchase",
				component: Purchase,
				exact: true,
			},
			{
				name: "Sell",
				path: "/statement/sell",
				component: Sell,
				exact: true,
			},
			{
				name: "Cash",
				path: "/statement/cash",
				component: Cash,
				exact: true,
			},
		],
	},
	{
		name: "Research",
		path: "/research",
		component: Research,
	},

	{
		name: "Settings",
		path: "/settings",
		component: Settings,
		hide: true,
	},
	{
		name: "Market Updates",
		path: "/market/:name",
		component: MarketUpdates,
		exact: true,
		hide: true,
	},

	// {
	// 	name: "Profile",
	// 	path: "/profile/account",
	// 	exact: true,
	// 	hide: true,
	// 	component: MobileProfile,
	// },
	{
		name: "Profile",
		path: "/profile",
		hide: true,
		component: Profile,
	},
	{
		name: "Notification",
		path: "/notifications/details",
		hide: true,
		component: NotificationDetails,
	},
	{
		name: "Notifications",
		path: "/notifications",
		hide: true,
		component: Notifications,
	},
	{
		name: "Margin Trade",
		path: "/margin-trade",
		component: MarginPortfolio,
		exact: true,
		hide: true,
	},
];

export default routes;
