import React, { useState } from "react";
import QuickTradeModal from "../../modal/QuickTradeModal/QuickTradeModal";

const QuickTradeButton = ({
	useText,
	btnName = "Trade Now",
	btnVariant = "btn--lg",
}) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	return (
		<>
			{useText ? (
				<span onClick={toggle}>Submit New Order</span>
			) : (
				<button
					className={`btn btn--primary me-3 ${btnVariant}`}
					onClick={toggle}
				>
					{btnName}
				</button>
			)}

			{showModal && <QuickTradeModal toggle={toggle} />}
		</>
	);
};

export default QuickTradeButton;
