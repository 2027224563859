import React from "react";
import { useQuery } from "@apollo/client";
import Scroll from "utils/Scroll/Scroll";
import FrequentlyTradedScroll, {
	FrequentlyTradedLoader,
} from "./FrequentlyTradedScroll/FrequentlyTradedScroll";
import { FREQUENTLY_TRADED } from "api/queries/portfolio";
import TableError from "utils/Table/TableError/TableError";

const FrequentlyTraded = ({ history }) => {
	const { loading, error, data } = useQuery(FREQUENTLY_TRADED);
	const trades = error ? [] : data?.frequently_traded || [];

	return (
		<>
			<p className="heading4">Frequently Traded</p>
			<Scroll>
				{loading ? (
					<FrequentlyTradedLoader />
				) : (
					<>
						{trades.map(({ stock, name }) => (
							<FrequentlyTradedScroll
								key={name}
								stock={stock}
								handleClick={() =>
									history.push(`/market/${name}`)
								}
							/>
						))}
					</>
				)}
			</Scroll>

			{trades?.length === 0 && (
				<div className="py-5">
					<TableError />
				</div>
			)}
		</>
	);
};

export default FrequentlyTraded;
