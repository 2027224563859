import React, { useState, useEffect, useRef } from "react";
import MeriLoader from "utils/Loader/MeriLoader/MeriLoader";
import API from "api/rest";
import { livenessCheck } from "api/restRoutes";

const errorMessages = {
	facial: {
		title: "Facial matching failed",
		message: (
			<p>
				Dear customer, the image you've provided does not match your
				record.
				<br />
				<strong>Tip:</strong> Please make sure you are in a well-lit
				area
			</p>
		),
	},
	failed: {
		title: "Failed",
		message: (
			<p>
				Could not verify your liveness at the moment
				<br />
				<strong className="me-3">Tip:</strong> Please make sure you are
				in a well-lit area
			</p>
		),
	},
};

const LivenessCheckContent = ({ setInit, updateRegStatus }) => {
	const videoRef = useRef({});

	const [step, setStep] = useState(1);
	const [loading, setLoading] = useState(true);
	const [countdown, setCountdown] = useState(5);
	const [recording, setRecording] = useState(false);
	const [cameraStream, setCameraStream] = useState(null);
	const [mediaRecorded, setMediaRecorded] = useState(null);
	const [errorMsg, setErrorMsg] = useState({});
	const [successMsg, setSuccessMsg] = useState();
	const [currentBlobs, setCurrentBlobs] = useState([]);
	const [startFile, setStartFile] = useState("");

	useEffect(() => {
		CountdownHandler();
		// eslint-disable-next-line
	}, [countdown]);

	useEffect(() => {
		startCamera();
		return () => {
			stopStream();
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (cameraStream) {
			const mediaRecorder = new MediaRecorder(cameraStream, {
				videoBitsPerSecond: 2009000,
			});
			setMediaRecorded(mediaRecorder);
		}
	}, [cameraStream]);

	useEffect(() => {
		if (mediaRecorded) {
			mediaRecorded.addEventListener("dataavailable", (e) =>
				setCurrentBlobs((prev) => [...prev, e.data])
			);
			mediaRecorded.addEventListener("stop", async () =>
				setStartFile(true)
			);
		}
	}, [mediaRecorded]);

	useEffect(() => {
		if (startFile && currentBlobs) {
			const blob = new Blob(currentBlobs, { type: "video/mp4" });
			const file = new File([blob], "liveness.mp4", {
				type: "video/mp4",
			});
			if (file) handleVerification(file);
			else {
				setErrorMsg(errorMessages.failed);
				setStep(2);
			}
		}
		// eslint-disable-next-line
	}, [currentBlobs, startFile]);

	const CountdownHandler = () => {
		if (countdown > 0 && loading === false) {
			setTimeout(() => setCountdown((prev) => prev - 1), 1000);
		} else if (countdown === 0 && loading === false) {
			setRecording(true);

			mediaRecorded?.start();

			setTimeout(() => {
				setTimeout(() => {
					stopStream();
					setRecording(false);
				}, 1000);
			}, 2000);
		}
	};

	const startCamera = async () => {
		videoRef.current.src = "";

		const currentCameraStream = await navigator.mediaDevices
			.getUserMedia({ video: true, audio: true })
			.then((stream) => {
				setLoading(false);
				setTimeout(() => setCountdown((prev) => prev - 1), 1200);
				return stream;
			});
		setCameraStream(currentCameraStream);

		videoRef.current.srcObject = currentCameraStream;
		videoRef.current.play();
	};

	const stopStream = () => {
		const newStream = cameraStream
			? cameraStream.getTracks().forEach(function (track) {
					track.stop();
			  })
			: null;
		const newRecording = recording ? mediaRecorded.stop() : null;

		setCameraStream(newStream);
		setMediaRecorded(newRecording);
	};

	const handleVerification = async (file) => {
		setStep(2);
		setErrorMsg({});

		const formData = new FormData();
		formData.append("video", file, "liveness.mp4");

		await API.post(livenessCheck, formData)
			.then((result) => {
				if (result?.status === 200 || result?.status === 201) {
					if (result?.data?.data?.meets_threshold) {
						setSuccessMsg("Liveness Completed");
						setStep(3);
					} else {
						setErrorMsg(errorMessages.facial);
					}
				} else setErrorMsg(errorMessages.failed);
			})
			.catch(() => setErrorMsg(errorMessages.failed));
	};

	const reset = () => {
		setErrorMsg();
		setCountdown(5);
		setLoading(true);
		setRecording(false);
		setCameraStream(null);
		setMediaRecorded(null);
		setStep(1);
		videoRef.current = {};
		startCamera();
	};

	const renderByStep = () => {
		if (step === 1)
			return (
				<>
					{loading ? (
						<div className="video-frame-circle ">
							<div className="flex__center">
								<div className=" custom-overlay">
									<MeriLoader hideLogo cls="small" />
								</div>
							</div>
						</div>
					) : (
						<>
							<video muted ref={videoRef} id="video">
								Video stream not available.
							</video>
							<div className=" custom-overlay">
								<h1 className="countdown">
									{countdown <= 0 ? null : countdown}
								</h1>
							</div>
						</>
					)}
				</>
			);

		if (step === 2) {
			return (
				<div className="flex__center py-5">
					<div className="text-center">
						{errorMsg.title ? (
							<div className="px-4">
								<h3 className="fw-bold">{errorMsg?.title}</h3>
								<p className="py-4">{errorMsg?.message}</p>

								{errorMsg.title ===
								errorMessages.facial.title ? (
									<div className="flex__between">
										<button
											type="button"
											className="btn btn--sm btn--secondary--bordered me-4 px-5"
											onClick={reset}
										>
											Retry
										</button>

										<button
											type="button"
											className="btn btn--sm btn--primary--bordered ms-4 px-5"
											onClick={() => setStep(3)}
										>
											Continue with result
										</button>
									</div>
								) : (
									<div className="flex__between">
										<button
											type="button"
											className="btn btn--sm btn--secondary--bordered me-4 px-5"
											onClick={setInit}
										>
											Back
										</button>

										<button
											type="button"
											className="btn btn--sm btn--primary--bordered ms-4 px-5"
											onClick={reset}
										>
											Retry
										</button>
									</div>
								)}
							</div>
						) : (
							<>
								<p className="heading3">
									Verification in Progress. Please Wait...
								</p>
								<MeriLoader hideLogo cls="small" />
							</>
						)}
					</div>
				</div>
			);
		}
		return (
			<div className="flex__center py-5">
				<div className="text-center">
					<p className="heading3">Verification Successful!</p>
					<span>{successMsg}</span>
					<button
						type="button"
						className="btn btn--sm btn--primary mx-auto mt-4  px-5"
						onClick={() => {
							updateRegStatus({ liveness_check: true });
							setInit();
						}}
					>
						Finish
					</button>
				</div>
			</div>
		);
	};

	return (
		<div className="w-100 text-center py-4">
			{step === 1 && (
				<p className="heading4">Fit your face in the oval frame.</p>
			)}
			<div className="video-frame py-5 mt-4">
				<div className="frame-edge" />
				<div className="frame-edge right" />
				<div className="frame-edge bottom-left" />
				<div className="frame-edge bottom-right" />

				{renderByStep()}
			</div>
		</div>
	);
};

export default LivenessCheckContent;
