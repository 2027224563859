import React from "react";
import { useHistory } from "react-router-dom";
import { toCurrency, toPriceStat, truncate } from "utils/helpers";
import { tooltip } from "../../helpers";

const DashboardPortfolio = ({ port, account }) => {
	const history = useHistory();

	const { valuation, securityLabel, gain, stock, percentGain } = port || {};
	const { securityName } = port || {};
	const isLoser = gain < 0 ? true : false;

	return (
		<li
			className="dash-portfolio"
			title={securityLabel}
			onClick={() =>
				history.push({
					pathname: `/market/${securityName}`,
					state: { currency: stock?.currency, label: securityName },
					search: `?account=${account}`,
				})
			}
			{...tooltip}
		>
			<div>
				<p className="m-0 fw-bold">{truncate(securityLabel, 15)}</p>
				<small>Name</small>
			</div>
			<div>
				<p className=" m-0 fw-bold">
					{stock?.currency} {toCurrency(valuation)}
				</p>
				<small>Valuation</small>
			</div>
			<div className="text-nowrap">
				<p
					className={`${
						isLoser ? "text-danger" : "text--primary"
					} m-0 fw-bold`}
				>
					{toPriceStat(gain, percentGain)}
				</p>
				<small>Daily P/L</small>
			</div>
		</li>
	);
};

export default DashboardPortfolio;
