import React from "react";
import {
	AccordionSummary,
	AccordionDetails,
	Accordion,
} from "@material-ui/core";
import { ReactComponent as CaretUp } from "assets/images/icons/caret-up.svg";

const OrderHistoryMobile = ({ id, data, expanded, handleToggle }) => {
	const open = expanded === id;
	const { orderNo, statusDate, marketStatus } = data || {};

	return (
		<Accordion expanded={open} onChange={handleToggle(id)}>
			<AccordionSummary
				className={`p-0 ${open ? "" : "border-bottom"}`}
				expandIcon={<CaretUp className="rot-180" />}
			>
				<div className="d-flex align-items-center w-100 ">
					<div className="w-100">
						<span className="fw-bold d-block">{orderNo}</span>
					</div>
					<span style={{ minWidth: "70px" }}>{statusDate}</span>
					<span style={{ minWidth: "70px" }}>{marketStatus}</span>
				</div>
			</AccordionSummary>

			<AccordionDetails className="border mb-4 rounded ">
				<RowList
					title="Order Type"
					value={data?.orderType}
					title2="Price Type"
					value2={data.priceType}
				/>
				<RowList
					title="Quantity"
					value={data?.orderTotal}
					title2="Term"
					value2={data.orderTermName}
				/>
				<RowList
					title="Symbol"
					value={data?.lable}
					title2="Order Status"
					value2={data.orderStatus}
				/>
				<RowList title="Quantity Filled" value={data?.quantityFilled} />
			</AccordionDetails>
		</Accordion>
	);
};

const RowList = ({ title, title2, value, value2 }) => (
	<div className="flex__between px-3 mb-3">
		<small>
			{title} <strong className="ps-3">{value}</strong>
		</small>
		<small>
			{title2} <strong className="ps-3">{value2}</strong>
		</small>
	</div>
);

export default OrderHistoryMobile;
