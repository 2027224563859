export const LIGHT_MODE = "LIGHT_MODE";
export const DARK_MODE = "DARK_MODE";

export const NORMAL = "NORMAL";
export const PRIMARY = "PRIMARY";
export const SECONDARY = "SECONDARY";
export const HEADLESS = "HEADLESS";
export const HEADLESS_CLOSE_ROUNDED = "HEADLESS_CLOSE_ROUNDED";

export const ALL = "All";

export const BVN = "BVN";
export const NIN = "NIN";
export const EMAIL = "EMAIL";
export const PHONE = "PHONE";

export const PLUS = "+";
export const MINUS = "-";

export const WALLET = "WALLET";
export const BANK = "BANK";
export const FUND = "fund";
export const WITHDRAW = "withdraw";

export const CONSUMER = "CONSUMER";
export const TECH = "TECH";
export const BANKING = "BANKING";
export const ECONOMY = "ECONOMY";
export const BUSINESS = "BUSINESS";
export const AGRICULTURE = "AGRICULTURE";
export const ENGINEERING = "ECONOMY";
export const EDUCATION = "EDUCATION";
export const POLITICS = "POLITICS";
export const HEALTH = "HEALTH";

export const PENDING = "PENDING";
export const RUNNING = "RUNNING";
export const TERMINATED = "TERMINATED";

export const DailyPL = "Daily P/L (N)";
export const DailyPL2 = "Daily P/L (%)";
export const TotalPL = "Total P/L (N)";
export const TotalPL2 = "Total P/L (%)";

export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const WARNING = "WARNING";
export const INFO = "INFO";

export const EQUITY = "EQUITY";
export const BOND = "BOND_NAT";
export const MARKET = "MARKET";
export const LIMIT = "LIMIT";
export const BUY = "BUY";
export const SELL = "SELL";
export const NASD = "NASD";
export const NSE = "NSE";
export const NGX = "NGX";
export const MARGIN = "MARGIN";
export const EXECUTING = "EXECUTING";

export const LATEST = "Latest";
export const POPULAR = "Popular";

export const PASSWORD_RESET_FORM = "PASSWORD_RESET_FORM";
export const OTP = "OTP";
export const OTP_CAP = "otp";

export const EXCHANGE = "Exchange";

export const ALL_TIME = "All Time";
export const _1D = "1D";
export const _1W = "1W";
export const _1M = "1M";
export const _3M = "3M";
export const _6M = "6M";
export const _1Y = "1Y";
export const _5Y = "5Y";

export const chartTypes = {
	SINGLELINE: "Single_line",
	CANDLESTICK: "Candle_stick",
	AREA: "Area",
	VOLUME: "Volume",
	BAR: "Bar",
};

export const margin_req_status = {
	CREATED: "CREATED",
	APPROVED: "APPROVED",
	DECLINED: "DECLINED",
};

export const ALL_FILTER = { label: "All", value: "all" };

export const stockTransType = {
	MARKET: "MARKET",
	LIMIT: "LIMIT",
	STOP_LIMIT: "STOP_LIMIT",
};
