import React from "react";
import { ReactComponent as CloseIcon } from "assets/images/icons/closecover.svg";

const MarginDisclaimer = ({ hideDisclaimer, toggle }) => {
	return (
		<div className="p-4">
			<div className="flex__between border-bottom pb-2">
				<span className="heading3 ">Disclaimer</span>
				<CloseIcon className="pointer" width={18} onClick={toggle} />
			</div>

			<p className=" pt-4 text-justify">
				Carefully consider your investment objectives, the risks,
				charges and expenses involved in Investing in the Nigerian stock
				market. It’s important to note that the same way profits can be
				made in the market, losses can also be made as well. This
				therefore implies that a good knowledge of the market is
				required before actively investing in the stock market. Kindly
				check through the resources available on the MeriTrade website
				which will educate you on how to trade profitably in the stock
				market”. Market volatility, trading volumes, system performance
				and other factors may delay system access and response times.
				Execution price, speed and liquidity are affected by many
				factors, including market volatility, size and type of order.
			</p>

			<button
				className="btn btn--sm btn--primary px-5 mt-5 mx-auto"
				onClick={hideDisclaimer}
			>
				Proceed to Apply
			</button>
		</div>
	);
};

export default MarginDisclaimer;
