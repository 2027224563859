import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_MARKET_UPDATES } from "api/queries/stock";
import MarketUpdateScroll, {
	LoadingScrollItem,
} from "./MarketUpdateScroll/MarketUpdateScroll";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "components/constants";
import { getError } from "utils/helpers";
import Scroll from "utils/Scroll/Scroll";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";

const options = [
	{ label: "Hot", value: "hot" },
	{ label: "Top Gainer", value: "gainer" },
	{ label: "Top Loser", value: "loser" },
];

const optionValues = {
	hot: { last_trade_time: "desc" },
	gainer: { price_change_p: "desc" },
	loser: { price_change_p: "asc" },
};

const MarketUpdates = () => {
	const [value, setValue] = useState(options?.[0]?.value || "");

	const [getMarketUpdates, { data, loading, error }] = useLazyQuery(
		GET_MARKET_UPDATES,
		{
			fetchPolicy: "no-cache",
		}
	);

	const { stock = [] } = data || {};

	const getStock = (order) => {
		getMarketUpdates({
			variables: { order },
		});
	};

	useEffect(() => {
		getStock(optionValues.hot);
		// eslint-disable-next-line
	}, []);

	const handleChange = (value) => {
		setValue(value);
		getStock(optionValues?.[value] || "");
	};

	return (
		<>
			{error && <Toast error={getError(error)} variant={ERROR} />}

			<div className="flex__between pt-5 mb-5">
				<p className="heading4 m-0">Market Updates</p>
				<div className="mb-4" style={{ minWidth: "170px" }}>
					<SmallSelect
						nocase
						value={value}
						onChange={({ target: { value } }) =>
							handleChange(value)
						}
						options={options}
					/>
				</div>
			</div>

			{loading ? (
				<Scroll>
					<LoadingScrollItem />
				</Scroll>
			) : (
				<Scroll>
					{stock.map((v) => (
						<MarketUpdateScroll key={v?.name} currentStock={v} />
					))}
				</Scroll>
			)}
		</>
	);
};

export default MarketUpdates;
