import React, { useState } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import CustomDatePicker from "utils/CustomDatePicker/CustomDatePicker";
import CustomSelect from "../../../../.././../utils/Select/CustomSelect/CustomSelect";
import CountrySelect from "utils/Select/CountrySelect/CountrySelect";

const NinDetails = ({
	isProfile,
	ninInfo,
	formErrors,
	handleChange,
	handleSelect,
	disableAll,
}) => {
	const [isOpen, setIsOpen] = useState(!isProfile);

	const cls = isOpen ? "open" : "";
	const disabled = disableAll ? "true" : false;

	const toggle = () => setIsOpen((prev) => !prev);

	return (
		<div className="mb-4">
			<div className={` bvn__tab ${cls}`} onClick={toggle}>
				NIN Information
			</div>

			<Collapse isOpen={isOpen}>
				<Card>
					<CardBody>
						<ul className="row bvn__details">
							<li className="col-6">
								<CustomInputBox
									placeholder="First Name"
									value={ninInfo?.first_name}
									error={formErrors?.first_name}
									disabled
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="middle_name"
									placeholder="Middle Name"
									value={ninInfo?.middle_name}
									error={formErrors?.middle_name}
									onChange={handleChange}
									disabled
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									placeholder="Last Name"
									value={ninInfo?.last_name}
									error={formErrors?.last_name}
									disabled
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									placeholder="Email"
									value={ninInfo?.email}
									error={formErrors?.email}
									disabled
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									placeholder="Phone Number"
									value={ninInfo?.phone}
									error={formErrors?.phone}
									disabled
								/>
							</li>

							<li className="col-6">
								{disableAll ? (
									<CustomInputBox
										placeholder="Date of Birth"
										value={ninInfo?.phone}
										disabled
									/>
								) : (
									<CustomDatePicker
										name="dob"
										max={new Date()}
										placeholder="Date of Birth"
										value={
											ninInfo?.dob
												? new Date(ninInfo?.dob)
												: ""
										}
										error={formErrors?.dob}
										onChange={(e) => handleSelect("dob", e)}
										disabled={disabled}
									/>
								)}
							</li>
							<li className="col-6">
								<CustomInputBox
									name="mothers_maiden_name"
									value={ninInfo?.mothers_maiden_name}
									error={formErrors?.mothers_maiden_name}
									placeholder="Mother's Miaden Name"
									onChange={handleChange}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								<CountrySelect
									name="nationality"
									value={ninInfo?.nationality}
									onChange={(e) =>
										handleSelect("nationality", e)
									}
									placeholder="Nationality"
									error={formErrors?.nationality}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="state_of_origin"
									value={ninInfo?.state_of_origin}
									error={formErrors?.state_of_origin}
									placeholder="State Of Origin"
									onChange={handleChange}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="lga_of_origin"
									value={ninInfo?.lga_of_origin}
									error={formErrors?.lga_of_origin}
									placeholder="Local Government Area"
									onChange={handleChange}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="residence_street"
									value={ninInfo?.residence_street}
									error={formErrors?.residence_street}
									placeholder="Street"
									onChange={handleChange}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="residence_city"
									value={ninInfo?.residence_city}
									error={formErrors?.residence_city}
									placeholder="City"
									onChange={handleChange}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="residence_state"
									value={ninInfo?.residence_state}
									error={formErrors?.residence_state}
									placeholder="State"
									onChange={handleChange}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="postal_code"
									value={ninInfo?.postal_code}
									error={formErrors?.postal_code}
									placeholder="Postal Code"
									onChange={handleChange}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="profession"
									value={ninInfo?.profession}
									error={formErrors?.profession}
									placeholder="Profession"
									onChange={handleChange}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="employer_name"
									value={ninInfo?.employer_name}
									error={formErrors?.employer_name}
									placeholder="Employer Name"
									onChange={handleChange}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="employer_address"
									value={ninInfo?.employer_address}
									error={formErrors?.employer_address}
									placeholder="Employer Address"
									onChange={handleChange}
									disabled={disabled}
								/>
							</li>
							<li className="col-6">
								{disableAll ? (
									<CustomInputBox
										placeholder="Religion"
										value={ninInfo?.phone}
										disabled
									/>
								) : (
									<CustomSelect
										name="religion"
										value={[ninInfo?.religion]}
										error={formErrors?.religion}
										placeholder="Religion"
										onChange={(e) =>
											handleSelect("religion", e)
										}
										options={[
											"Christian",
											"Muslim",
											"Others",
										]}
										getOptionLabel={(option) => option}
										getOptionValue={(option) => option}
										disabled={disabled}
									/>
								)}
							</li>
							<li className="col-6">
								{disableAll ? (
									<CustomInputBox
										placeholder="Marital Status"
										value={ninInfo?.phone}
										disabled
									/>
								) : (
									<CustomSelect
										name="marital_status"
										value={[ninInfo?.marital_status]}
										error={formErrors?.marital_status}
										placeholder="Marital Status"
										onChange={(e) =>
											handleSelect("marital_status", e)
										}
										options={[
											"Single",
											"Married",
											"Divorce",
										]}
										getOptionLabel={(option) => option}
										getOptionValue={(option) => option}
										disabled={disabled}
									/>
								)}
							</li>
						</ul>
					</CardBody>
				</Card>
			</Collapse>
		</div>
	);
};

export default NinDetails;
