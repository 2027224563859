import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import InvestmentInformation from "./InvestmentInformation/InvestmentInformation";
import AccountInformation from "./AccountInformation/AccountInformation";
import { updateAccountInfo } from "redux/actions/onboardingActions";
import "./Onboarding.scss";

const Onboarding = ({ location, onboarding, updateAccountInfo, history }) => {
	const { currentStep } = onboarding || {};

	useState(() => {
		const { referral } = queryString.parse(location.search);
		referral && updateAccountInfo({ referral });
	}, []);

	const render = () => {
		switch (currentStep) {
			case 2:
				return <InvestmentInformation history={history} />;

			case 1:
			default:
				return <AccountInformation history={history} />;
		}
	};
	return (
		<div className="row onboarding">
			<div className="col-lg-7 col-12">{render()}</div>
			<div className="col-lg-5 d-lg-block d-none onboarding__right__view" />
		</div>
	);
};

export default withRouter(
	connect(({ onboarding }) => ({ onboarding }), {
		updateAccountInfo,
	})(Onboarding)
);
