import { InMemoryCache, makeVar } from "@apollo/client";

export const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				authData: {
					read() {
						return authDataVar();
					},
				},
			},
		},
	},
});

export const authDataVar = makeVar({ token: "", accessToken: "", otp: "" });
