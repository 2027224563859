import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Routes from "../../../routes";

const StatementNavs = () => {
	const { pathname } = useLocation();

	const statementRoutes = Routes.find((route) => route.name === "Statements");

	return (
		<div>
			<ul className="nav nav-tabs no-flex" id="myTab" role="tablist">
				{statementRoutes?.dropdown?.map(
					({ name, path, hide }) =>
						!hide && (
							<NavLink
								key={name}
								className="nav-item pe-sm-5 pe-4"
								to={path}
								role="presentation"
							>
								<button
									className={`nav-link px-0 py-3 ${
										pathname === path ? "active" : ""
									}`}
								>
									{name}
								</button>
							</NavLink>
						)
				)}
			</ul>
		</div>
	);
};

export default StatementNavs;
