import React from "react";
import SearchForm from "utils/CustomInputBox/SearchForm/AsyncSearchForm";
import { NASD } from "../../../../../components/constants";

const MyPortfolioSearchForm = ({ history, exchange }) => {
	const showMarginBtn = exchange === NASD;

	return (
		<div
			className="row mb-4 align-items-start justify-content-between"
			style={{ maxHeight: "55px" }}
		>
			<div className="col-md-4 col-6 order-md-first order-last">
				<SearchForm history={history} />
			</div>
			{!showMarginBtn && (
				<div className="col-md-4 col-6 order-md-last  order-first">
					<div className="form-group">
						<button
							className="btn btn--sm btn--primary px-5 ms-md-auto ms-0"
							onClick={() => history.push("/margin-trade")}
						>
							Go to Margin
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default MyPortfolioSearchForm;
