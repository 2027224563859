import React, { useState } from "react";
import Card from "./Card/Card";
import TableError from "utils/Table/TableError/TableError";
import BankingWrapper from "../common/BankingWrapper/BankingWrapper";
import AddAccountModal from "../modal/AddAccountModal/AddAccountModal";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";
import AddCardButton from "../button/AddCardButton/AddCardButton";
import { fetchUserData } from "storage/sessionStorage";
import "./ManageCard.scss";

const ManageCard = ({ history }) => {
	const { settlement_account = [] } = fetchUserData();
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	return (
		<BankingWrapper
			btn={<AddCardButton toggle={toggle} />}
			history={history}
			hideHeader
		>
			<div className="flex__between ">
				<span className="d-flex align-items-center">
					<BackIcon
						height="20px"
						width="20px"
						onClick={() => history.push("/banking")}
						className="pointer"
					/>
					<span className="heading2 ms-4">Manage Accounts</span>
				</span>

				<AddCardButton useIcon toggle={toggle} />
			</div>

			{settlement_account.map((data, i) => (
				<Card key={i} data={data} />
			))}

			{settlement_account?.length === 0 && (
				<div className="py-5">
					<TableError errorMsg="No Account available" />
				</div>
			)}
			{showModal && <AddAccountModal toggle={toggle} />}
		</BankingWrapper>
	);
};

export default ManageCard;
