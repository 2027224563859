import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import ConfirmFundOTP from "../../../Banking/modal/ConfirmFundOTP/ConfirmFundOTP";
import EnableMFAForm from "../EnableMFAModal/EnableMFAForm/EnableMFAForm";
import { ENABLE_MFA } from "api/mutations/account";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "components/constants";
import { getError } from "utils/helpers";

const DisableMFAModal = ({ toggle }) => {
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState();
	const [currentStep, setcurrentStep] = useState(1);
	const [otp, setOtp] = useState();

	const nextStep = () => setcurrentStep((prev) => prev + 1);

	const prevStep = () => setcurrentStep((prev) => prev - 1);

	const [disableMFA] = useMutation(ENABLE_MFA, {
		context: { headers: { otp } },
		onCompleted: () => {
			setLoading(false);
			nextStep();
		},
		onError: (error) => {
			setLoading(false);
			setErrorMsg(getError(error));
		},
	});

	const handleToggle = () => {
		setcurrentStep(1);
		toggle();
	};

	const refresh = () => {
		handleToggle();
		window.location.reload(true);
	};

	const handleSubmit = () => {
		setLoading(true);
		setErrorMsg();
		disableMFA({ variables: { mfa: false } });
	};

	const render = () => {
		switch (currentStep) {
			case 1:
			default:
				return (
					<EnableMFAForm
						mfa={false}
						toggle={handleToggle}
						nextStep={nextStep}
					/>
				);

			case 2:
				return (
					<ConfirmFundOTP
						otp={otp}
						setOtp={setOtp}
						loading={loading}
						nextStep={handleSubmit}
						prevStep={prevStep}
						toggle={handleToggle}
					/>
				);

			case 3:
				return (
					<SuccessState
						btnText="Done"
						msg="Two Factor Authentication Disabled"
						toggle={refresh}
					/>
				);
		}
	};
	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />
			<Modal show={true}>{render()}</Modal>
		</>
	);
};

export default DisableMFAModal;
