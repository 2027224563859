import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ERROR, SUCCESS, WARNING, INFO } from "../../../components/constants";
import "react-toastify/dist/ReactToastify.css";

const Toast = ({ msg, variant, toastId }) => {
	useEffect(() => {
		msg && notify();
		// eslint-disable-next-line
	}, [msg]);

	const notify = () => {
		switch (variant) {
			case SUCCESS:
				toast.success(msg, { toastId });
				break;

			case ERROR:
				toast.error(msg, { toastId });
				break;

			case WARNING:
				toast.warn(msg, { toastId });
				break;

			case INFO:
				toast.info(msg, { toastId });
				break;

			default:
				toast(msg, { toastId });
				break;
		}
	};

	return (
		<div>
			<ToastContainer
				position="top-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				limit={1}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	);
};
export default Toast;
