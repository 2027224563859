import { gql } from "@apollo/client";

export const SEARCH_STOCK = gql`
	query SearchStock($name: String) {
		stock(
			where: {
				_or: [{ name: { _ilike: $name } }, { label: { _ilike: $name } }]
			}
			limit: 10
		) {
			name
			label
			currency
			trades
			bid_levels
			offer_levels
		}
	}
`;

export const GET_WATCHLIST = gql`
	subscription GetWatchlist {
		watchlist {
			id
			name
			watchlist_items {
				stock {
					currency
					exchange
					label
					name
					price
					sector
					type
				}
				watchlist_id
			}
		}
	}
`;

export const GET_MARKET_UPDATES = gql`
	query GetTopStock($order: [stock_order_by!]) {
		stock(order_by: $order, limit: 6, where: { type: { _eq: "EQUITY" } }) {
			name
			price
			trades
			currency
			price_change
			label
			price_change_p
		}
	}
`;

export const GET_MARKET_STATUS = gql`
	query GetMarketStatus {
		market_status {
			message
			status
		}
	}
`;

export const GET_STOCKS = gql`
	query Get_Stocks($filters: stock_bool_exp = {}) {
		stock(limit: 1000000, where: $filters) {
			label
			logo_url
			name
			price_change
			price_change_p
			sector
		}
	}
`;

export const GET_SECTOR = gql`
	query GET_ECTOR {
		stock(distinct_on: sector, order_by: { sector: asc }) {
			sector
			name
		}
	}
`;

export const QUICK_TRADE_FORM = gql`
	query QuickTradeForm($filters: stock_bool_exp = {}) {
		stock(limit: 1000000, order_by: { label: asc }, where: $filters) {
			bid_levels
			label
			last_trade_time
			name
			offer_levels
			price
			price_change
			price_change_p
			todays_change
			trades
			type
			volume
		}
		order_term {
			comment
			value
		}
	}
`;

export const STOCK_WITHOUT_TIME = gql`
	query GetStock($type: String, $exchange: String) {
		stock(
			limit: 1000000
			order_by: { name: asc }
			where: { type: { _regex: $type }, exchange: { _regex: $exchange } }
		) {
			bid_levels
			close
			currency
			exchange
			high
			issued_shares
			label
			last_trade_time
			logo_url
			low
			marginable
			name
			offer_levels
			open
			previous_close
			price
			price_change
			price_change_p
			sector
			todays_change
			todays_high
			todays_low
			trades
			type
			updated_at
			volume
			year_high
			year_low
		}
	}
`;

export const ORDER_TERMS = gql`
	query Get_Order_Terms {
		order_term {
			comment
			value
		}
	}
`;
