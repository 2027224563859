import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import PortfolioHeader from "../common/PortfolioHeader/PortfolioHeader";
import PortfolioTable from "../common/PortfolioTable/PortfolioTable";
import ValuationBalance from "../common/ValuationBalance/ValuationBalance";
import MyPortfolioSearchForm from "./MyPortfolioSearchForm/MyPortfolioSearchForm";
import { GET_PORTFOLIOS } from "api/queries/portfolio";
import { fetchUserData } from "storage/sessionStorage";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "../../../constants";
import "./MyPortfolio.scss";

const Portfolio = ({ history }) => {
	const [account, setAccount] = useState({});
	const [portfolio, setPortfolio] = useState([]);
	const [currentPortfolio, setCurrentPortfolio] = useState({});
	const { state } = useLocation();
	const { exchange } = state || {};

	let { portfolio_accounts } = fetchUserData() || {};

	const [getPortfolio, { loading, error }] = useLazyQuery(GET_PORTFOLIOS, {
		onCompleted: ({ portfolio }) => setPortfolio(portfolio),
	});

	useEffect(() => {
		let accounts = [];
		if (exchange)
			accounts = portfolio_accounts?.filter(
				(acc) => acc.exchange === exchange && !acc.margin
			);
		else accounts = portfolio_accounts?.filter(({ margin }) => !margin);
		accounts.length ? handleAccount(accounts[0]) : setPortfolio([]);
		// eslint-disable-next-line
	}, [exchange]);

	const handleAccount = (acc) => {
		setAccount(acc);
		setCurrentPortfolio(acc);
		getPortfolio({ variables: { id: acc?.id } });
	};

	return (
		<>
			{error && <Toast msg="Error Processing Request" variant={ERROR} />}

			<PortfolioHeader exchange={account?.exchange || exchange} />
			<div className="portfolio">
				<MyPortfolioSearchForm
					history={history}
					exchange={account?.exchange || exchange}
				/>
				<ValuationBalance
					portfolio={portfolio}
					selectedAccount={account?.id}
					handleAccount={handleAccount}
				/>
				<PortfolioTable
					history={history}
					loading={loading}
					portfolio={portfolio}
					account={currentPortfolio.name}
				/>
			</div>
		</>
	);
};

export default Portfolio;
