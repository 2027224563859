import React from "react";
import { useHistory } from "react-router-dom";
import { getStockPlot, toPriceStat } from "utils/helpers";
// import { ReactComponent as Flash } from "assets/images/icons/flash.svg";
import SmallStockChart from "utils/Charts/StockChart/SmallStockChart/SmallStockChart";

const WatchListBoard = ({ items }) => {
	let history = useHistory();

	return (
		<ul className="bottom__border">
			{items?.map(({ stock }) => (
				<li
					key={stock?.name}
					className="flex__between row px-md-4 px-2 py-3"
				>
					<div
						className="d-flex pointer col-5"
						onClick={() => history.push(`/market/${stock?.name}`)}
					>
						{/* <div className="med-circle border p-3 bg__gray me-4">
								<Flash />
							</div> */}
						<div className="">
							<p className="m-0 fw-bold">{stock?.name}</p>
							<small className="text--primary">
								{stock?.label}
							</small>
						</div>
					</div>
					<div className="text-right text-end col-3">
						<p className="m-0 fw-bold">{stock?.price}</p>
						<small
							className={`${
								stock?.price_change < 0
									? "text-danger"
									: "text--primary"
							} bit`}
						>
							{toPriceStat(
								stock?.price_change,
								stock?.price_change_p
							)}
						</small>
					</div>

					<div className="col-4" style={{ maxWidth: "80px" }}>
						<SmallStockChart
							data={getStockPlot(stock?.stock_day_series)}
							height={40}
							lineColor={
								stock?.price_change < 0 ? "#c81a2f" : "#078950"
							}
						/>
					</div>
				</li>
			))}
		</ul>
	);
};

export default WatchListBoard;
