import React, { useState } from "react";
import Doughnut from "utils/Charts/Doughnut/Doughnut";
import AssetAllocationAccordion from "./AssetAllocationAccordion/AssetAllocationAccordion";

const AssestAllocationDoughnut = ({ datas }) => {
	const [expanded, setExpanded] = useState(false);

	const handleToggle = (panel) => (e, isExpanded) => {
		console.log("securityLabel", isExpanded);
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			<div className="col-sm-4 col-7">
				<Doughnut
					totalSegments={datas?.length}
					filledSegments={datas?.length}
					data={datas}
					className="medium"
					showData
					shade={datas?.length ? datas[0] : {}}
				/>
			</div>

			<div className="col-sm-7 col-12">
				{datas?.map((data, i) => (
					<AssetAllocationAccordion
						id={data.securityLabel}
						key={i}
						data={data}
						index={i}
						expanded={expanded}
						handleToggle={handleToggle}
					/>
				))}
			</div>
		</>
	);
};

export default AssestAllocationDoughnut;
