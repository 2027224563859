import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";
import { ReactComponent as FilterIcon } from "assets/images/icons/filter.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/closecover.svg";
import CustomDatePicker from "utils/CustomDatePicker/CustomDatePicker";
import { formValidator } from "utils/FormValidator/FormValidator";
import { checkSameDay } from "utils/helpers";
import { FIXED_INCOME_STATUS } from "api/queries/fixedIncome";
import { ALL } from "components/constants";
import { moneyMarkeyDefaultFilters } from "../../helpers";

const all = { label: ALL, value: "All" };

const MoneyMarketHistoryFilters = ({
	currentFilters,
	getPortfolio,
	setIsToday,
}) => {
	const [formErrors, setFormErrors] = useState({});
	const [isFilter, setIsFilter] = useState(false);
	const [filters, setFilters] = useState(moneyMarkeyDefaultFilters);
	const [filterOptions, setFilterOptions] = useState({});

	const mutateOptions = (datas) => {
		const newData = datas?.map((data) => ({
			...data,
			label: data?.comment,
		}));
		return [{ ...all }, ...newData];
	};

	useQuery(FIXED_INCOME_STATUS, {
		onCompleted: ({ fixed_income_status }) =>
			setFilterOptions({
				status: mutateOptions(fixed_income_status),
			}),
	});

	const handleChange = ({ target: { name, value } }) => {
		handleForm(name, value);
	};

	const handleDate = (name, value) => {
		handleForm(name, value);
		checkSameDay(new Date(), value) ? setIsToday(true) : setIsToday(false);
	};

	const handleForm = (name, value) => {
		setFilters((prev) => ({
			...prev,
			[name]: value,
		}));
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleSubmit = () => {
		setIsFilter(true);
		const { formErrors, isValid } = formValidator(filters);
		if (isValid) {
			setIsFilter(true);
			getPortfolio({
				variables: { ...getAllFilters(filters) },
			});
		} else setFormErrors(formErrors);
	};

	const getAllFilters = (allFilters) => {
		const { status } = allFilters || {};
		return {
			...allFilters,
			status: status === ALL ? "" : status,
		};
	};

	const clearFilter = () => {
		setIsFilter(false);
		setIsToday(true);
		setFilters(currentFilters);
		getPortfolio({
			variables: { ...getAllFilters(currentFilters) },
		});
	};

	return (
		<div className="my-5 d-flex flex-wrap">
			<div className="d-flex">
				<div style={{ width: "90px" }} className="ms-4">
					<SmallSelect
						value={filters?.status}
						label="Status"
						name="status"
						onChange={handleChange}
						options={filterOptions.status || []}
					/>
				</div>

				<div className="mx-4 small-date with__filters">
					<CustomDatePicker
						label="Start Date"
						name="start_date"
						placeholder="Start"
						max={new Date()}
						value={filters?.start_date}
						cls="small-form-group"
						onChange={(e) => handleDate("start_date", e)}
						error={formErrors?.start_date}
						hideErrorText
					/>
				</div>

				<div className="small-date with__filters">
					<CustomDatePicker
						label="End Date"
						name="end_date"
						placeholder="End"
						min={filters?.start_date}
						max={new Date()}
						value={filters?.end_date}
						cls="small-form-group"
						onChange={(e) => handleDate("end_date", e)}
						error={formErrors?.end_date}
						hideErrorText
					/>
				</div>
			</div>

			<div className="flex__between ms-4">
				<FilterIcon className="pointer" onClick={handleSubmit} />
				{isFilter && (
					<CloseIcon
						className="pointer ms-3"
						width={20}
						onClick={clearFilter}
					/>
				)}
			</div>
		</div>
	);
};

export default MoneyMarketHistoryFilters;
