import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Table from "utils/Table/Table";
import TableError from "utils/Table/TableError/TableError";
import StatementWrapper from "../common/StatementWrapper/StatementWrapper";
import { cashHeading, defaultCashFilters } from "../extra";
import { GET_CASH_STATEMENT, GET_CASH_ACCOUNTS } from "api/queries/statement";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "../../../constants";
import { timeDateFormat } from "utils/helpers";
import ViewMore from "utils/ViewMore/ViewMore";
import { TableLoader } from "utils/TableLoader/TableLoader";
import { toCurrency } from "utils/helpers";

const Cash = () => {
	const [portfolio, setPortfolio] = useState({});
	const [cashAccount, setCashAccount] = useState([]);
	const [cash, setCash] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");

	const [getCashStatements, { error: err, loading }] = useLazyQuery(
		GET_CASH_STATEMENT,
		{
			onCompleted: (data) => setCash(data?.cash_statement || []),
			onError: (error) => {
				setCash([]);
				setErrorMsg(error);
			},
		}
	);

	const [getCashAccount] = useLazyQuery(GET_CASH_ACCOUNTS, {
		onCompleted: (data) => {
			const port = data?.cash_accounts?.[0];
			setPortfolio(port);
			setCashAccount(data?.cash_accounts || []);
			const { start_date, end_date } = defaultCashFilters;
			port?.id &&
				getCashStatements({
					variables: {
						start_date,
						end_date,
						account_id: port?.id,
					},
				});
		},
	});

	useEffect(() => {
		getCashAccount();
		// eslint-disable-next-line
	}, []);

	const getTableData = () =>
		cash?.map((v) => ({
			id: v.id,
			orderNo: v.name,
			label: v?.label,
			balance: `${v?.currency} ${toCurrency(v?.balance)}`,
			debit: (
				<span className="text-danger">
					{`${v?.currency} ${toCurrency(v?.debitAmount)}`}
				</span>
			),
			credit: (
				<span className="text--primary">
					{`${v?.currency} ${toCurrency(v?.creditAmount)}`}
				</span>
			),
			desc: <ViewMore text={v?.description} />,
			transactDate: timeDateFormat(parseInt(v?.transactionDate)).date,
		}));

	const tableData = loading ? TableLoader(8) : getTableData();

	return (
		<>
			{err && <Toast msg={errorMsg} variant={ERROR} />}

			<StatementWrapper
				isCash
				total={cash?.length}
				filters={defaultCashFilters}
				data={loading ? [] : cash}
				refetch={getCashStatements}
				options={cashAccount}
				portfolio={portfolio}
				setPortfolio={setPortfolio}
			>
				<Table
					data={tableData}
					headings={cashHeading}
					cls="faint__bg full__height "
					rowClick={() => {}}
				/>
				{!loading && cash?.length === 0 && <TableError />}
			</StatementWrapper>
		</>
	);
};

export default Cash;
