import React from "react";
import { FormControl, Select } from "@material-ui/core";
import { titleCase } from "../../helpers";

const SmallSelect = ({
	name,
	value,
	formLabel = "Age",
	label,
	onChange,
	options,
	nocase,
}) => {
	return (
		<FormControl variant="outlined">
			{label && (
				<label className="mb-2 pb-1 fw-bold text--gray">{label}</label>
			)}
			<Select
				native
				value={value}
				onChange={onChange}
				label={formLabel}
				inputProps={{ name }}
			>
				{/* <option aria-label="None" value="" /> */}
				{options?.map((option) => (
					<option
						value={option?.value || option}
						key={option?.value || option}
					>
						{nocase
							? option?.label || option
							: titleCase(option?.label || option)}
					</option>
				))}
			</Select>
		</FormControl>
	);
};

export default SmallSelect;
