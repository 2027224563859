import React, { useState } from "react";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";
import { getDateRange } from "utils/helpers";

const options = [
	{ label: "Today", value: "ID" },
	{ label: "A Week Ago", value: "IW" },
	{ label: "A Month Ago", value: "1M" },
	{ label: "3 Month Ago", value: "3M" },
	{ label: "6 Month Ago", value: "6M" },
	{ label: "A Year Ago", value: "1Y" },
	{ label: "5 Year Ago", value: "5Y" },
	{ label: "All Report", value: "All Time" },
];

const PortfolioReportFilter = ({ loading, handleFilter }) => {
	const [value, setValue] = useState(options[0]);

	const handleChange = (e) => {
		setValue(e);
		handleFilter({
			variables: { date: getDateRange(e.value) },
		});
	};

	return (
		<div className="form-group mb-0" style={{ width: "170px" }}>
			<CustomSelect
				name="portfolio"
				placeholder="Select Portfolio"
				value={value}
				onChange={handleChange}
				options={options}
				formGroupCls="mb-0"
				disabled={loading}
			/>
		</div>
	);
};

export default PortfolioReportFilter;
