import React from "react";
import DashboardPortfolios from "../../../common/DashboardPortfolios/DashboardPortfolios";
import AssetAllocationBalance from "./AssetAllocationBalance/AssetAllocationBalance";

const AssetAllocationRightView = ({ history }) => {
	return (
		<div className="col-xl-3 col-lg-4 right__view d-lg-block d-none">
			<AssetAllocationBalance />
			<DashboardPortfolios history={history} />
		</div>
	);
};

export default AssetAllocationRightView;
