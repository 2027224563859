import React from "react";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import { SECONDARY } from "../../../../../constants";
import ResolveAccountForm from "../../../common/ResolveAccountForm/ResolveAccountForm";

const AddAccountForm = ({ check, setCheck, toggle, nextStep, setErrorMsg }) => {
	return (
		<>
			<ModalHeader
				type={SECONDARY}
				toggle={toggle}
				title="Add New Bank Account"
			/>

			<p className="heading3 text-center text-danger">
				Adding of new bank account cost NGN50.00
			</p>

			<div className="px-sm-4 px-2 py-3">
				<ResolveAccountForm
					check={check}
					showCheck
					setCheck={setCheck}
					nextStep={nextStep}
					setErrorMsg={setErrorMsg}
				/>
			</div>
		</>
	);
};

export default AddAccountForm;
