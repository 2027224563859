import * as mui from "@mui/icons-material";
import { ALL } from "components/constants";
import { timeRangeFormat } from "utils/helpers";

export const sectorIcons = {
	"All Sectors": "",
	"Agriculture/Agro Allied": mui.Agriculture,
	AGRICULTURE: mui.Agriculture,
	BANKING: mui.AccountBalance,
	Banking: mui.AccountBalance,
	"FINANCIAL SERVICES": mui.AccountBalance,
	"Computer and Office Equipment": mui.Computer,
	"Airline Services": mui.LocalAirport,
	"Airline Service": mui.LocalAirport,
	"Real Estate": mui.Cottage,
	"REAL ESTATE": mui.Cottage,
	"OIL AND GAS": mui.LocalGasStation,
	"\n Other Financial Institutions": mui.AccountBalanceWallet,
	"Other Financial Institutions": mui.AccountBalanceWallet,
	"Automobile and Tyre": mui.Commute,
	Breweries: mui.Liquor,
	"Building Materials": mui.Apartment,
	CONGLOMERATES: mui.Workspaces,
	Conglomerates: mui.Workspaces,
	"CONSTRUCTION/REAL ESTATE": mui.Construction,
	Construction: mui.Construction,
	"CONSUMER GOOD": mui.FoodBank,
	"Chemical and Paints": mui.ImagesearchRoller,
	"Commercial Services": mui.MiscellaneousServices,
	SERVICES: mui.MiscellaneousServices,
	"Engineering Technology": mui.Engineering,
	TECH: mui.Engineering,
	"Food Beverages and Tobacco": mui.EmojiFoodBeverage,
	Healthcare: mui.LocalHospital,
	"Hotels and Tourism": mui.Hotel,
	ICT: mui.OnlinePrediction,
	"INDUSTRIAL GOODS": mui.Factory,
	"Industrial Domestic Products": mui.Factory,
	Insurance: mui.VerifiedUser,
	"MANUFACTURING & ALLIED": mui.PrecisionManufacturing,
	"Mortgage Companies": mui.VerifiedUser,
	OTHERS: "",
	"N/A": "",
	"Petroleum (Marketing)": mui.LocalGasStation,
	"Petroleum(marketing)": mui.LocalGasStation,
	"Printing and Publishing": mui.Print,
	"Road Transportation": mui.DirectionsBus,
};

export const moneyMarketTableHeading = [
	"Label",
	"Principal",
	"Interest Rate",
	"Interest",
	"Expected Net Interest",
	"Product",
	"Date",
	"Status",
];

export const moneyMarkeyDefaultFilters = {
	start_date: timeRangeFormat(new Date(), true),
	end_date: timeRangeFormat(new Date()),
	status: ALL,
};
