import React from "react";
import {
	HEADLESS,
	NORMAL,
	PRIMARY,
	SECONDARY,
	HEADLESS_CLOSE_ROUNDED,
} from "../../../components/constants";
import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { ReactComponent as Close } from "assets/images/icons/closecover.svg";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";

const ModalHeader = ({ type, toggle, prevStep, title, text }) => {
	const render = () => {
		switch (type) {
			case HEADLESS:
				return (
					<div className="flex__end p-4">
						<CloseIcon className="pointer" onClick={toggle} />
					</div>
				);

			case NORMAL:
				return (
					<div className="m-4 p-3 d-flex align-items-center border-bottom">
						<BackIcon className="pointer" onClick={toggle} />
						<h3 className="w-100 text-center heading3 m-0">
							{title}
						</h3>
					</div>
				);

			case PRIMARY:
				return (
					<div className="primary__header">
						<p className="heading3 mb-0">{title}</p>
						<h3>{text}</h3>
					</div>
				);

			case SECONDARY:
				return (
					<div className="text-center pb-4 position-relative">
						{prevStep && (
							<div
								className="abs position-absolute pointer"
								style={{ left: "10px", top: "15px" }}
							>
								<BackIcon
									className="pointer"
									height="20px"
									onClick={prevStep}
								/>
							</div>
						)}
						<p className="heading4 border-bottom p-3">{title}</p>
						<div
							className="abs position-absolute pointer"
							style={{ right: "10px", top: "15px" }}
						>
							<Close
								className="pointer"
								height="20px"
								onClick={toggle}
							/>
						</div>
					</div>
				);

			case HEADLESS_CLOSE_ROUNDED:
				return (
					<div className="flex__end p-4">
						<Close className="pointer" onClick={toggle} />
					</div>
				);

			default:
				return "";
		}
	};

	return <>{render()}</>;
};

export default ModalHeader;
