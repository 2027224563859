import React from "react";
import { useQuery } from "@apollo/client";
import { GET_PAYMENT_TRANSACTIONS } from "api/queries/wallet";
import BankingNavs from "../common/BankingNavs/BankingNavs";
import BankingWrapper from "../common/BankingWrapper/BankingWrapper";
import WalletTransactions from "../common/WalletTransactions/WalletTransactions";
import WithdrawalForm from "./WithdrawalForm/WithdrawalForm";
import { WITHDRAW } from "../../../constants";

const Withdrawal = ({ history }) => {
	const getTransactionQuery = useQuery(GET_PAYMENT_TRANSACTIONS, {
		fetchPolicy: "no-cache",
		variables: { type: WITHDRAW },
	});

	return (
		<BankingWrapper history={history}>
			<BankingNavs />
			<WithdrawalForm />
			<WalletTransactions
				type={WITHDRAW}
				transactionQuery={getTransactionQuery}
			/>
		</BankingWrapper>
	);
};

export default Withdrawal;
