import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

const Scroll = ({ children, cls }) => {
	return (
		<Scrollbars autoHide autoHeight>
			<div className={`d-flex ${cls}`}>{children}</div>
		</Scrollbars>
	);
};

export default Scroll;
