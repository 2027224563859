import React, { useEffect, useState } from "react";
import { formValidator } from "utils/FormValidator/FormValidator";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";
import { fetchUserData } from "storage/sessionStorage";
import { ERROR, SECONDARY } from "../../../../constants";
import { useLazyQuery } from "@apollo/client";
import { GET_PORTFOLIOS } from "api/queries/portfolio";
import Toast from "utils/Alert/Toast/Toast";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";

const SellStockPortfolioList = ({ stock, toggle, nextStep }) => {
	const [formError, setFormError] = useState({});
	const [errorMsg, setErrorMsg] = useState("");
	const [selectedPortfolio, setSelectedPortfolio] = useState({});

	let { portfolio_accounts = [] } = fetchUserData();

	useEffect(() => {
		const account = portfolio_accounts.find(
			(v) => v.name === stock.account
		);
		account && setSelectedPortfolio(account);
		// eslint-disable-next-line
	}, []);

	const [getPortfolio, { loading }] = useLazyQuery(GET_PORTFOLIOS, {
		onCompleted: ({ portfolio = [] }) => {
			const { securityName, name } = stock;
			const currentPortfolio = portfolio?.find(
				(v) =>
					v.securityName === name || v.securityName === securityName
			);

			currentPortfolio
				? nextStep({
						...currentPortfolio,
						account: selectedPortfolio.name,
				  })
				: setErrorMsg(
						"Stock does not exist in this selected portfolio"
				  );
		},
	});

	const handleSelect = (value) => {
		setSelectedPortfolio(value);
		setFormError({ portfolio: "" });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setErrorMsg("");
		const { formErrors, isValid } = formValidator(selectedPortfolio);
		isValid
			? getPortfolio({ variables: { id: selectedPortfolio?.id } })
			: setFormError(formErrors);
	};

	return (
		<form onSubmit={handleSubmit} className="px-4">
			<ModalHeader
				type={SECONDARY}
				toggle={toggle}
				title="Current Portfolio"
			/>

			<Toast msg={errorMsg} variant={ERROR} />

			<CustomSelect
				name="portfolio"
				value={selectedPortfolio}
				label={stock.account ? "" : "Select Portfolio"}
				onChange={handleSelect}
				error={formError?.portfolio}
				options={portfolio_accounts}
				getOptionLabel={({ label, exchange }) =>
					label ? `${label} - ${exchange}` : ""
				}
				disabled={stock.account}
			/>
			<div className="col-sm-4 col-12 pt-4 pb-3 mx-auto">
				<button
					className="btn btn--lg btn--secondary"
					disabled={loading}
				>
					{loading ? <CustomSpinner /> : "Continue"}
				</button>
			</div>
		</form>
	);
};

export default SellStockPortfolioList;
