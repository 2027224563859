import { NORMAL, WALLET } from "../../components/constants";
import {
    CLEAR_ONBOARDING,
    NEXT_STEP,
    PREVIOUS_STEP,
    SHOW_OTP_FORM,
    UPDATE_ACCOUNT_INFO,
    UPDATE_INVESTMENT_INFO,
    UPDATE_SIGNUP_OPTION,
    SHOW_NIN_FULL_FORM,
} from "../actionTypes/onboardingActionTypes";

const initialState = {
    currentStep: 1,
    signupOption: NORMAL,
    emailConfirmed: false,
    showOTP: false,
    showNINFullForm: false,
    onboardData: {
        accountInformation: {
            first_name: "",
            last_name: "",
            username: "",
            email: "",
            country: "",
            phone: "",
            password: "",
            type: "",
            dob: "",
            question: "",
            answer: "",
            referral: "",
            account: true,
            settlement_type: WALLET,
            nin: "",
            token: "",
        },
        investmentInformation: {
            objective: "",
            risk_tolerance: "",
            knowledge: "",
            net_worth: "",
            source_of_wealth: "",
        },
    },
};

const onboarding = (state = initialState, action) => {
    switch (action.type) {
        case NEXT_STEP:
            return {
                ...state,
                currentStep: state.currentStep + 1,
                emailConfirmed: true,
            };

        case PREVIOUS_STEP:
            return {
                ...state,
                currentStep: state.currentStep - 1,
                emailConfirmed: false,
            };

        case UPDATE_SIGNUP_OPTION:
            return { ...initialState, signupOption: action.payload };

        case SHOW_OTP_FORM:
            return { ...state, showOTP: !state.showOTP };

        case SHOW_NIN_FULL_FORM:
            return { ...state, showNINFullForm: !state.showNINFullForm };

        case UPDATE_ACCOUNT_INFO:
            return {
                ...state,
                onboardData: {
                    ...state.onboardData,
                    accountInformation: {
                        ...state?.onboardData?.accountInformation,
                        ...action.payload,
                    },
                },
            };

        case UPDATE_INVESTMENT_INFO:
            return {
                ...state,
                onboardData: {
                    ...state.onboardData,
                    investmentInformation: {
                        ...state?.onboardData?.investmentInformation,
                        ...action.payload,
                    },
                },
            };

        case CLEAR_ONBOARDING:
            return { ...initialState };

        default:
            return state;
    }
};

export default onboarding;
