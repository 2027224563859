import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_PORTFOLIOS } from "api/queries/portfolio";
import { fetchUserData } from "storage/sessionStorage";
import PortfolioHeader from "../common/PortfolioHeader/PortfolioHeader";
import PortfolioTable from "../common/PortfolioTable/PortfolioTable";
import ValuationBalance from "../common/ValuationBalance/ValuationBalance";
import MarginTradeStatusModal from "../modals/MarginTradeStatusModal/MarginTradeStatusModal";
import MarginPortfolioHeader from "./MarginPortfolioHeader/MarginPortfolioHeader";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR, margin_req_status } from "../../../constants";

const MarginPortfolio = ({ history }) => {
	let { portfolio_accounts, margin_request } = fetchUserData() || {};
	const status = margin_request?.[0]?.status || "";
	const marginEnabled = status === margin_req_status.APPROVED;
	const margin = portfolio_accounts?.find(({ margin }) => margin === true);

	const [getPortfolio, { loading, error, data }] =
		useLazyQuery(GET_PORTFOLIOS);

	useEffect(() => {
		margin && getPortfolio({ variables: { id: margin?.id } });
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{error && <Toast msg="Error Processing Request" variant={ERROR} />}

			<PortfolioHeader header="Your Margin Portfolio" cls="primary" />
			<div className="portfolio">
				<MarginPortfolioHeader history={history} />
				<ValuationBalance isMargin portfolio={data?.portfolio} />
				<PortfolioTable
					history={history}
					loading={loading}
					portfolio={data?.portfolio}
					portfolioID={margin?.id}
				/>
			</div>

			<MarginTradeStatusModal
				toggle={() => history.goBack()}
				margin={margin || marginEnabled}
			/>
		</>
	);
};

export default MarginPortfolio;
