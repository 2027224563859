import React from "react";
import { Loader } from "utils/TableLoader/TableLoader";

const LoadingNews = ({ count }) => {
	return (
		<>
			<div className="row div__wrapper mb-4">
				<div className="col-sm-6 p-sm-0 p-2">
					<Loader height={145} />
				</div>
				<div className="col-sm-6 rel p-4 fw-bold">
					<Loader height={10} width={60} />

					<div className="mt-3">
						<Loader height={10} />
					</div>
					<div className="mb-5">
						<Loader height={10} />
					</div>

					<Loader height={10} />
				</div>
			</div>

			{count && (
				<div className="row">
					{Array(count)
						.fill()
						.map((_, i) => (
							<div
								key={i}
								className="col-lg-4 col-sm-6 col-12 mb-5 ps-sm-0 ps-2 pe-sm-4 pe-2"
							>
								<div className="row div__wrapper m-0 ">
									<div className="col-sm-12 col-5 p-0">
										<Loader height={110} />
									</div>
									<div className="col-sm-12 col-7  p-3 fw-bold">
										<div className="flex__between d-sm-flex d-none mb-3">
											<Loader height={10} width={60} />
										</div>

										<section className="fw-bold d-sm-none d-block ">
											<Loader height={10} />
										</section>
										<span className="d-block mb-4">
											<Loader height={10} />
										</span>

										<Loader height={10} />
									</div>
								</div>
							</div>
						))}
				</div>
			)}
		</>
	);
};

export default LoadingNews;
