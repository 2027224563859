import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { GET_USER_TYPES } from "api/queries/others/userTypes";
import { REQUEST_OTP } from "api/mutations/auth";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import CustomPassword from "utils/CustomInputBox/CustomPassword/CustomPassword";
import AsyncSelect from "utils/Select/AsyncSelect/AsyncSelect";
import { formValidator } from "utils/FormValidator/FormValidator";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { BANK, ERROR, WALLET } from "components/constants";
import CustomDatePicker from "utils/CustomDatePicker/CustomDatePicker";
import CountrySelect from "utils/Select/CountrySelect/CountrySelect";
import CustomPhoneInput from "utils/CustomPhoneInput/CustomPhoneInput";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import SettlementTypeDescription from "../../buttons/SettlementTypeDescription/SettlementTypeDescription";
import Disclaimer from "components/Onboarding/utils/Disclaimer/Disclaimer";
import { getError } from "utils/helpers";
import Toast from "utils/Alert/Toast/Toast";
import { slicePhone } from "utils/helpers";
import { VERIFY_USERNAME } from "api/mutations/auth";

const BioDataForm = ({
	updateAccountInfo,
	updateOption,
	onboardData,
	showOTPForm,
	foooterText,
	isBVNForm,
	history,
	nextStep,
}) => {
	const [errorMsg, setErrorMsg] = useState();
	const [formErrors, setFormErrors] = useState({});
	const { accountInformation } = onboardData || {};
	const { dob, phone, username } = accountInformation || {};
	const newDate = dob && dob !== "Invalid date" ? new Date(dob) : "";

	const [requestOTP, { loading }] = useMutation(REQUEST_OTP, {
		onCompleted: ({ request_otp }) => {
			updateAccountInfo({ token: request_otp?.token });
			showOTPForm();
		},
		onError: (error) => setErrorMsg(getError(error)),
	});

	const [verifyUsername, { loading: verifyLoading }] = useMutation(
		VERIFY_USERNAME,
		{
			onCompleted: ({ is_username_available }) => {
				if (is_username_available?.status) {
					isBVNForm
						? nextStep()
						: requestOTP({
								variables: {
									data: { phone: slicePhone(phone) },
								},
						  });
				} else setErrorMsg("Username Already Exist!.");
			},
			onError: (error) => setErrorMsg(getError(error)),
		}
	);

	const handleSelect = (name, value) => {
		updateAccountInfo({ [name]: value });
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleChange = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const {
			target: { name, value },
		} = e;
		updateAccountInfo({ [name]: value });
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handlePhone = (value) => {
		updateAccountInfo({ phone: value });
		setFormErrors((prev) => ({
			...prev,
			phone: "",
		}));
	};

	const handleAPICall = () => {
		setErrorMsg();
		verifyUsername({ variables: { username } });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const payload = { ...accountInformation };
		delete payload.question;
		delete payload.answer;
		delete payload.referral;
		delete payload.nin;
		delete payload.token;
		delete payload.middle_name;
		delete payload.referral;

		const { formErrors, isValid } = formValidator(payload);
		isValid ? handleAPICall() : setFormErrors(formErrors);
	};

	return (
		<div className="px-sm-5 px-0">
			<Toast msg={errorMsg} variant={ERROR} />

			<div className="col-12 px-4 mx-3 mb-4 flex__between">
				<p className="heading4 ms-n2">Personal Information</p>
				<p
					className="text--primary fw-bold pb-3 me-4 pointer"
					onClick={updateOption}
				>
					Signup with NIN
				</p>
			</div>
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-sm-6 col-12 px-5">
						<CustomInputBox
							name="first_name"
							label="First Name"
							placeholder="First Name"
							defaultValue={accountInformation?.first_name}
							onChange={handleChange}
							error={formErrors?.first_name}
							disabled={isBVNForm}
							required
						/>
					</div>
					<div className="col-sm-6 col-12 px-5">
						<CustomInputBox
							name="last_name"
							label="Last Name"
							placeholder="First Name"
							defaultValue={accountInformation?.last_name}
							onChange={handleChange}
							error={formErrors?.last_name}
							disabled={isBVNForm}
							required
						/>
					</div>
					<div className="col-sm-6 col-12 px-5">
						<CustomInputBox
							name="username"
							label="Username"
							placeholder="Username"
							defaultValue={accountInformation?.username}
							onChange={handleChange}
							error={formErrors?.username}
							required
						/>
					</div>
					<div className="col-sm-6 col-12 px-5">
						<CustomInputBox
							type="email"
							name="email"
							label="Email"
							placeholder="Email"
							defaultValue={accountInformation?.email}
							onChange={handleChange}
							error={formErrors?.email}
							required
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CountrySelect
							name="country"
							label="Country"
							defaultValue={accountInformation?.country}
							onChange={(e) => handleSelect("country", e)}
							error={formErrors?.country}
							required
							disabled={isBVNForm}
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CustomPhoneInput
							name="phone"
							label="Phone Number"
							value={accountInformation?.phone}
							placeholder="Phone Number"
							error={formErrors?.phone}
							onChange={handlePhone}
							code={accountInformation?.country?.iso}
							disableDropdown
							disabled={isBVNForm}
							required
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CustomPassword
							name="password"
							label="Password"
							placeholder="***********"
							defaultValue={accountInformation?.password}
							onChange={handleChange}
							error={formErrors?.password}
							required
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CustomDatePicker
							name="dob"
							label="Date of Birth"
							max={new Date()}
							placeholder="YYYY-MM-DD"
							value={newDate}
							error={formErrors?.dob}
							onChange={(e) => handleSelect("dob", e)}
							required
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<AsyncSelect
							name="type"
							label="Account Type"
							placeholder="Select Account Type"
							value={accountInformation?.type}
							onChange={(e) => handleSelect("type", e)}
							error={formErrors?.type}
							query={GET_USER_TYPES}
							queryData="user_type"
							required
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CustomInputBox
							name="question"
							label="Security Question"
							placeholder="Security Question"
							defaultValue={accountInformation?.question}
							onChange={handleChange}
							error={formErrors?.question}
							question
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CustomInputBox
							name="answer"
							label="Security Answer"
							placeholder="Security Answer"
							defaultValue={accountInformation?.answer}
							onChange={handleChange}
							error={formErrors?.answer}
							question
						/>
					</div>

					<div className="col-sm-6 col-12 px-5">
						<CustomInputBox
							name="referral"
							label="Referral Code"
							placeholder="Referral Code"
							defaultValue={accountInformation?.referral}
							onChange={handleChange}
							error={formErrors?.referral}
						/>
					</div>

					<div className="col-sm-6 col-12 pt-sm-4 pt--0 px-5">
						<div className="form-group">
							<div className="d-flex align-items-center">
								<div className="my-2">
									<CustomCheckBox
										label="Cash Account"
										checked={
											accountInformation?.settlement_type ===
											"WALLET"
										}
										onClick={() =>
											handleSelect(
												"settlement_type",
												WALLET
											)
										}
									/>
								</div>
								<SettlementTypeDescription />
							</div>

							<div className="d-flex align-items-center">
								<div className="">
									<CustomCheckBox
										label="Direct Cash Settlement Account"
										checked={
											accountInformation?.settlement_type ===
											BANK
										}
										onClick={() =>
											handleSelect(
												"settlement_type",
												"BANK"
											)
										}
									/>
								</div>
								<SettlementTypeDescription direct />
							</div>
						</div>
					</div>

					<div className="col-sm-6 col-12 pt-3 text--gray px-5">
						<div className="form-group text-end">
							<Disclaimer history={history} />
						</div>
					</div>

					<div className="col-sm-6 col-12 pt-4 px-5">
						<div className="form-group">
							<button
								className="btn btn--lg btn--primary"
								disabled={loading || verifyLoading}
							>
								{loading || verifyLoading ? (
									<Spinner />
								) : (
									"Continue"
								)}
							</button>
						</div>
					</div>

					<div className="col-12 pt-4 px-5">
						<div className="border__overhead">
							<p
								className="text--primary fw-bold pointer pb-3"
								onClick={updateOption}
							>
								{foooterText}
							</p>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default BioDataForm;
