import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import StatementWrapper from "../common/StatementWrapper/StatementWrapper";
import StatementTable from "../common/StatementTable/StatementTable";
import { GET_ORDER_STATEMENT } from "api/queries/statement";
import Toast from "utils/Alert/Toast/Toast";
import { BUY, ERROR } from "../../../constants";
import { defaultBuyFilters } from "../extra";
import { fetchUserData } from "storage/sessionStorage";

const Purchase = () => {
	const { portfolio_accounts } = fetchUserData();
	const { id, label } = portfolio_accounts?.[0] || {};
	let options = portfolio_accounts?.map(({ label, id }) => ({
		label,
		value: id,
	}));

	const [portfolio, setPortfolio] = useState({});
	const [purchase, setPurchase] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");

	const [getStatement, { error: err, loading }] = useLazyQuery(
		GET_ORDER_STATEMENT,
		{
			fetchPolicy: "no-cache",
			onCompleted: (data) => setPurchase(data?.order_statement || []),
			onError: (error) => {
				setPurchase([]);
				setErrorMsg(error);
			},
		}
	);

	useEffect(() => {
		const { start_date, end_date, type } = defaultBuyFilters;
		setPortfolio({ label, id });
		getStatement({
			variables: {
				start_date,
				end_date,
				type,
				portfolio_id: id,
			},
		});
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{err && <Toast msg={errorMsg} variant={ERROR} />}
			<StatementWrapper
				total={purchase?.length}
				type={BUY}
				filters={defaultBuyFilters}
				refetch={getStatement}
				data={loading ? [] : purchase}
				portfolio={portfolio}
				setPortfolio={setPortfolio}
				options={options}
			>
				<StatementTable loading={loading} data={purchase} />
			</StatementWrapper>
		</>
	);
};

export default Purchase;
