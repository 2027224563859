import React from "react";

const FooterLink = ({ text, link, bottom = "20px", col = "col-lg-11" }) => {
	return (
		<div className="row justify-content-center">
			<div className="position-absolute col-lg-7 col-12" style={{ bottom }}>
				<div className={`${col} col-12 mx-auto px-5`}>
					<div className="border__overhead">
						<p className="text--primary fw-bold pb-3 pointer" onClick={link}>
							{text}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterLink;
