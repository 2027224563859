import React from "react";

const FailedState = ({ errorMsg, retry, hideBtn, OtherBtn, OtherAction }) => {
	return (
		<div className="d-flex justify-content-center p-5 mt-4">
			<div className="text-center">
				<p className="heading3 text-danger"> {errorMsg}</p>
				<br />
				<div className="flex__center">
					{OtherBtn && (
						<button
							className="btn btn--sm btn--primary--bordered px-5 fw-bold me-4"
							onClick={OtherAction}
						>
							{OtherBtn}
						</button>
					)}
					{!hideBtn && (
						<button
							className="btn btn--sm btn--primary px-5  fw-bold"
							onClick={retry}
						>
							Retry
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default FailedState;
