import React from "react";
import { ReactComponent as AlertIcon } from "assets/images/icons/tri-alert.svg";

const EmailValidationMessage = ({ email }) => {
	return (
		<div className="text-center pt-3">
			<AlertIcon width="45px" />
			<p className="heading1">Your email has not been verified !!!</p>
			<p className="heading3 py-4 ">
				A link was sent to <b className="text-danger">{email}</b>.{" "}
				<br /> Click on the link to verify your account
			</p>
		</div>
	);
};

export default EmailValidationMessage;
