import React from "react";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";

const StatusTablesNav = ({ currentIndex, header, next, prev }) => (
	<div className="bg__gray flex__between py-2 px-3 rounded">
		<BackIcon width="19px" onClick={prev} className="pointer" />
		<span className="heading4">{header[currentIndex]}</span>
		<div>
			<BackIcon
				width="19px"
				className="rot-180 ms-3 pointer"
				onClick={next}
			/>
		</div>
	</div>
);

export default StatusTablesNav;
