import React from "react";
import { fetchUserData } from "storage/sessionStorage";
import AccountSummaryContent from "../common/AccountSummaryContent/AccountSummaryContent";

const MarginAccountSummary = () => {
	let { portfolio_accounts } = fetchUserData() || {};
	const margin = portfolio_accounts?.find(({ margin }) => margin === true);

	return <AccountSummaryContent id={margin?.id} margin />;
};

export default MarginAccountSummary;
