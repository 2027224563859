import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import WatchListLoader from "../../WatchList/common/WatchListLoader/WatchListLoader";
import DashboardPortfolio from "./DashboardPortfolio/DashboardPortfolio";
import { GET_PORTFOLIOS } from "api/queries/portfolio";
import { fetchUserData } from "storage/sessionStorage";

const DashboardPortfolios = () => {
	const { push } = useHistory();
	let { portfolio_accounts } = fetchUserData() || {};
	const account = portfolio_accounts?.[0] || {};

	const [getPortfolio, { loading, data }] = useLazyQuery(GET_PORTFOLIOS);
	let { portfolio } = data || {};
	portfolio = portfolio?.length > 3 ? portfolio?.slice(0, 3) : portfolio;

	useEffect(() => {
		getPortfolio({ variables: { id: account?.id } });
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="dash__port div__wrapper">
				<div className="bg__gray flex__between py-2 px-3 mb-3 rounded">
					<span className="heading4">Portfolio</span>
					<span
						className="pointer text--primary fw-bold"
						onClick={() => push("/portfolio")}
					>
						View All
					</span>
				</div>

				{loading ? (
					<WatchListLoader count={2} />
				) : (
					<ul className="bottom__border">
						{portfolio?.map((port) => (
							<DashboardPortfolio
								key={port?.securityLabel}
								port={port}
								account={account?.name || ""}
							/>
						))}
					</ul>
				)}
			</div>
		</>
	);
};

export default DashboardPortfolios;
