import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

const middleware = [thunk];

const persistConfig = {
	key: "root",
	storage,
};

export const store = createStore(
	persistReducer(persistConfig, rootReducer),
	{},
	composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);
