import React from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as Alert } from "assets/images/icons/tri-alert.svg";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { getError } from "utils/helpers";
import { REQUEST_OTP } from "api/mutations/auth";

const RemoveCardForm = ({ toggle, nextStep, setErrorMsg }) => {
	const [requestOtp, { loading }] = useMutation(REQUEST_OTP, {
		onCompleted: () => nextStep(),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleSubmit = () => {
		requestOtp({
			variables: { data: { mutation: "delete_settlement_account" } },
		});
	};

	return (
		<>
			<div className="text-center px-3 pb-3">
				<Alert className="my-5" />

				<p className="fw-bold">
					Are you sure you want to unlink this bank account?
				</p>

				<div className="flex__between mt-5 px-3">
					<button
						className="btn btn--lg btn--primary--bordered me-3"
						onClick={toggle}
						disabled={loading}
					>
						No
					</button>
					<button
						className="btn btn--lg btn--secondary ms-3"
						onClick={handleSubmit}
						disabled={loading}
					>
						{loading ? <Spinner /> : "Yes"}
					</button>
				</div>
			</div>
		</>
	);
};

export default RemoveCardForm;
