import React from "react";
import { connect } from "react-redux";
import BankInfoTable from "./BankInfoTable/BankInfoTable";
import ProfileKycAccountForm from "./ProfileKycAccountForm/ProfileKycAccountForm";

const ProfileKYCBankInfo = ({ kyc }) => {
	const { settlement } = kyc?.status || {};

	return (
		<div className="row pt-3">
			{settlement ? <BankInfoTable /> : <ProfileKycAccountForm />}
		</div>
	);
};

export default connect(({ kyc }) => ({ kyc }))(ProfileKYCBankInfo);
