import React from "react";
import { priceStatus } from "utils/helpers";
import TableError from "utils/Table/TableError/TableError";

const StockMarketBid = ({ title, bids }) => {
	return (
		<div className="col-md-4 h-100">
			<div className="dash__port div__wrapper mb-5 w-100">
				<div className="bg__gray text-center py-2 px-3 mb-3 rounded">
					<span className="heading4 text--primary">{title}</span>
				</div>
				<div className="fw-bold">
					<Bid
						price="Price (NGN)"
						quantity="Quantity"
						count="Count"
					/>
				</div>

				<div>
					{bids?.map(({ qty, count, price }, i) => (
						<div key={`${title}${i}`}>
							{i < 9 && (
								<Bid
									key={i}
									price={priceStatus(bids, price, i)}
									quantity={qty}
									count={count}
								/>
							)}
						</div>
					))}

					{bids?.length === 0 && (
						<TableError errorMsg={`No ${title} Found!!!`} />
					)}
				</div>
			</div>
		</div>
	);
};

export default StockMarketBid;

const Bid = ({ price, quantity, count }) => (
	<div className="row px-4">
		<div className="col-5">
			<p>{price}</p>
		</div>
		<div className="col-4">
			<p>{quantity}</p>
		</div>
		<div className="col-3">
			<p>{count}</p>
		</div>
	</div>
);
