import React from "react";
import { toCurrency } from "utils/helpers";
import AssestAllocationDoughnut from "../../AssetAllocation/AssetAllocationLeftView/AssestAllocationDoughnut/AssestAllocationDoughnut";

const AccountSummaryAssetAllocation = ({ history, data, totalValuation }) => {
	const getPercent = (v) =>
		parseFloat(((v * 360) / totalValuation).toFixed(2));

	const getData = () => {
		const newData = data?.portfolio?.map(
			({ quantityHeld, securityLabel, valuation }) => ({
				quantityHeld,
				valuation,
				securityLabel,
				percent: getPercent(valuation),
			})
		);

		return newData;
	};

	return (
		<div className="col-lg-5 col-12 pt-5 ps-md-5 ps-3 mt-md-5 mt-0">
			<div className="flex__between">
				<p className="heading4">Asset Allocation</p>
				<p
					className="fw-bold text--primary pointer"
					onClick={() => history.push("/portfolio/asset-allocation")}
				>
					View All
				</p>
			</div>

			<div className="border rounded px-4 py-3">
				<div className="flex__between border-bottom pb-3">
					<span>
						Total
						<span className="fw-bold ms-4">
							N{toCurrency(totalValuation)}
						</span>{" "}
					</span>
				</div>

				<div className="row my-4">
					<AssestAllocationDoughnut datas={getData()} />
				</div>
			</div>
		</div>
	);
};

export default AccountSummaryAssetAllocation;
