import React, { useState } from "react";
import { connect } from "react-redux";
import { useSubscription } from "@apollo/client";
import { useParams } from "react-router";
import Modal from "utils/Modal/Modal";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import { chartTypes, SECONDARY } from "../../../../constants";
import MarketUpdateLineChart from "../../charts/MarketUpdateLineChart/MarketUpdateLineChart";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";
import MarketUpdateAreaChart from "../../charts/MarketUpdateAreaChart/MarketUpdateAreaChart";
import MarketUpdateVolumeChart from "../../charts/MarketUpdateVolumeChart/MarketUpdateVolumeChart";
import MarketBarAreaChart from "../../charts/MarketBarAreaChart/MarketBarAreaChart";
import MarketCandleStickChart from "../../charts/MarketCandleStickChart/MarketCandleStickChart";
import StockMarketBid from "./StockMarketBid/StockMarketBid";
import StockMarketBest from "./StockMarketBest/StockMarketBest";
import { getDateRange, timeDateFormat } from "utils/helpers";
import { STOCK_DAY_SERIES, GET_STOCK } from "api/subscription/investment";

const switchers = ["ID", "1W", "1M", "3M", "6M", "1Y", "5Y", "All Time"];
const chartOptions = ["Single_line", "Candle_stick", "Area", "Volume", "Bar"];

const ShowChartModal = ({ toggle, mode }) => {
	const [currentChart, setCurrentChart] = useState(chartTypes.SINGLELINE);
	const [time, setTime] = useState(switchers[3]);
	const { name } = useParams();

	const { data, loading } = useSubscription(STOCK_DAY_SERIES, {
		variables: { name, time: timeDateFormat(getDateRange(time)).date },
	});

	const { data: stockData } = useSubscription(GET_STOCK, {
		variables: { name },
	});

	let { stock } = stockData || {};
	stock = stock ? stock[0] : [];
	const { bid_levels, offer_levels } = stock || {};

	const renderChart = () => {
		switch (currentChart) {
			case chartTypes.SINGLELINE:
			default:
				return (
					<MarketUpdateLineChart
						loading={loading}
						stocks={data?.stock_day_series}
						mode={mode}
					/>
				);

			case chartTypes.AREA:
				return (
					<MarketUpdateAreaChart
						loading={loading}
						stocks={data?.stock_day_series}
						mode={mode}
					/>
				);

			case chartTypes.VOLUME:
				return (
					<MarketUpdateVolumeChart
						loading={loading}
						stocks={data?.stock_day_series}
						mode={mode}
					/>
				);

			case chartTypes.BAR:
				return (
					<MarketBarAreaChart
						loading={loading}
						stocks={data?.stock_day_series}
						mode={mode}
					/>
				);

			case chartTypes.CANDLESTICK:
				return (
					<MarketCandleStickChart
						loading={loading}
						stocks={data?.stock_day_series}
						mode={mode}
					/>
				);
		}
	};

	return (
		<Modal show={true} size="xl">
			<div className="px-4">
				<ModalHeader
					type={SECONDARY}
					toggle={toggle}
					title="Stock Update"
				/>

				<div style={{ width: "100px" }}>
					<SmallSelect
						value={currentChart}
						name="currentChart"
						onChange={({ target }) =>
							setCurrentChart(target?.value)
						}
						options={chartOptions}
					/>
				</div>

				<div className="port__summary nm-5">
					<div className="d-flex justify-content-end mb-5">
						<div className="switchers">
							{switchers?.map((btn) => (
								<p
									onClick={() => setTime(btn)}
									className={btn === time ? "active" : ""}
								>
									{btn}
								</p>
							))}
						</div>
					</div>

					{renderChart()}
				</div>

				<div className="row mt-5 align-items-stretch">
					<StockMarketBest stock={stock} />
					<StockMarketBid title="Bids" bids={bid_levels} />
					<StockMarketBid title="Offers" bids={offer_levels} />
				</div>
			</div>
		</Modal>
	);
};

export default connect(({ mode }) => ({ mode }))(ShowChartModal);
