import React from "react";
import { getBalance, toCurrency } from "utils/helpers";

const AssetAllocationBalance = ({ mobile }) => {
	const { cashBalance, margin_details } = getBalance();

	return (
		<>
			{mobile ? (
				<div className="row content__slider pt-4">
					<div className="col-9 mb-4 ms-n4">
						<Average cashBalance={cashBalance} />
					</div>
					<div className="col-9 mb-4 ">
						<Power trading_power={margin_details?.trading_power} />
					</div>
				</div>
			) : (
				<div className="row px-3">
					<div className="col-md-12 col-9 shade mb-5 green__light rounded pe-0 ps-2 overflow-hidden">
						<Average cashBalance={cashBalance} />
					</div>
					<div className="col-md-12 col-9 shade mb-5 primary__med rounded pe-0 ps-2 overflow-hidden">
						<Power trading_power={margin_details?.trading_power} />
					</div>
				</div>
			)}
		</>
	);
};

const Average = ({ cashBalance }) => (
	<div className="bg-white ps-4 py-2">
		<p className="heading4 text--primary m-0">Available Balance </p>
		<h1 className="d-inline-block">N{toCurrency(cashBalance)}</h1>
		{/* <span className="fw-bold ps-3 text-danger">-N27,500</span> */}
	</div>
);

const Power = ({ trading_power }) => (
	<div className="bg-white ps-4 py-2">
		<p className="heading4 text--primary--med m-0">Purchasing Power </p>
		<h1 className="d-inline-block">N{toCurrency(trading_power)}</h1>
		{/* <span className="fw-bold ps-3 text--primary">-N27,500</span> */}
	</div>
);

export default AssetAllocationBalance;
