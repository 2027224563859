import { gql } from "@apollo/client";

export const DELETE_SETTLEMENT_ACCOUNT = gql`
	mutation DeleteSettlementAccount($account_number: String!) {
		delete_settlement_account(
			where: { account_number: { _eq: $account_number } }
		) {
			affected_rows
		}
	}
`;

export const SET_SETTLEMENT_ACCOUNT = gql`
	mutation MyMutation($reference: uuid) {
		update_account(
			where: {}
			_set: { settlement_account_reference: $reference }
		) {
			affected_rows
		}
	}
`;

export const WITHDRAW_FUND = gql`
	mutation WithdrawFund($account_number: String!, $amount: Float!) {
		withdraw(account_number: $account_number, amount: $amount) {
			message
			status
		}
	}
`;

export const INSERT_SETTLEMENT = gql`
	mutation AttachSettlement($account: settlement_account_insert_input!) {
		insert_settlement_account_one(object: $account) {
			account_name
			account_number
			bank_code
			bank_name
			sort_code
			reference
		}
	}
`;

export const ENABLE_MFA = gql`
	mutation MyMutation($mfa: Boolean) {
		update_user(_set: { mfa: $mfa }, where: {}) {
			affected_rows
		}
	}
`;

export const GET_PAYMENT_TRANX = gql`
	mutation ($object: payment_transaction_insert_input!) {
		insert_payment_transaction_one(object: $object) {
			reference
		}
	}
`;
