import React, { useRef, useEffect } from "react";
import { createChart } from "lightweight-charts";
import { chartOptions, barSeriesOptions } from "../data";
import { getBarData } from "../helpers";
import ChartOverlay from "utils/ChartOverlay";

const MarketBarAreaChart = ({ loading, stocks, mode }) => {
	const chartContainerRef = useRef();
	const chart = useRef();
	const series = useRef();

	useEffect(() => {
		chart.current = createChart(
			chartContainerRef.current,
			chartOptions(mode)
		);
		series.current = chart.current.addBarSeries(barSeriesOptions);
		series.current.setData(getBarData(stocks) || []);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (stocks) {
			const stockData = getBarData(stocks);
			series.current.setData([...stockData]);
			stocks?.length < 100 && chart.current.timeScale().fitContent();
		}
		// eslint-disable-next-line
	}, [stocks]);

	return (
		<div ref={chartContainerRef} className="chart__wrap">
			<ChartOverlay loading={loading} data={stocks} />
		</div>
	);
};

export default MarketBarAreaChart;
