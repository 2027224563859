import React from "react";
import { kebabCase, titleCase } from "../helpers";

const Tabs = ({
	tabActionsList,
	tabList = [],
	iconList,
	id = "myTab",
	cls,
	tabCls,
}) => {
	return (
		<ul
			className={`nav nav-tabs wrapper--overflow ${cls}`}
			id={id}
			role="tablist"
		>
			{tabList?.map((name, i) =>
				i === 0 ? (
					<Tab
						key={name}
						active="active"
						id={kebabCase(name)}
						tabName={titleCase(name)}
						tabAction={tabActionsList ? tabActionsList[i] : null}
						tabCls={tabCls}
						Icon={iconList ? iconList[i] : ""}
					/>
				) : (
					<Tab
						key={name}
						active=""
						id={kebabCase(name)}
						tabAction={tabActionsList ? tabActionsList[i] : null}
						tabName={titleCase(name)}
						tabCls={tabCls}
						Icon={iconList ? iconList[i] : ""}
					/>
				)
			)}
		</ul>
	);
};

const Tab = ({ active, id, tabAction, tabName, tabCls, Icon }) => (
	<li className="nav-item pe-sm-5 pe-4" role="presentation">
		<button
			className={`nav-link ${tabCls} ${active}`}
			id={`${id}-tab`}
			data-bs-toggle="tab"
			href={`#${id}`}
			role="tab"
			aria-controls={id}
			onClick={tabAction}
		>
			{Icon && <Icon className="me-1 mb-1" />} {tabName}
		</button>
	</li>
);

export default Tabs;
