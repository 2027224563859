import React from "react";
import { connect } from "react-redux";
import { NORMAL } from "components/constants";
import OTPVerification from "../../OTPVerification/OTPVerification";
import OnboardingHeader from "components/Onboarding/utils/OnboardingHeader/OnboardingHeader";
import StatusBar from "components/Onboarding/utils/StatusBar/StatusBar";
import BioDataForm from "../BioDataForm/BioDataForm";
import NINVerificationForm from "./NINVerificationForm/NINVerificationForm";
import {
	updateAccountInfo,
	updateSignupOption,
	showOTPForm,
	nextStep,
	showFullNINForm,
} from "redux/actions/onboardingActions";

const SignUpWithNIN = ({
	updateAccountInfo,
	updateSignupOption,
	onboarding,
	showOTPForm,
	showFullNINForm,
	nextStep,
	history,
}) => {
	const { showNINFullForm, showOTP, onboardData } = onboarding || {};
	const { accountInformation } = onboardData || {};
	let { nin } = accountInformation || {};

	const render = () => {
		if (showNINFullForm)
			return (
				<BioDataForm
					onboardData={onboardData}
					updateAccountInfo={updateAccountInfo}
					updateOption={() => updateSignupOption(NORMAL)}
					foooterText="My NIN info has changed?"
					history={history}
					nextStep={nextStep}
					isBVNForm
				/>
			);

		return (
			<NINVerificationForm
				accountInformation={onboardData?.accountInformation}
				updateAccountInfo={updateAccountInfo}
				updateSignupOption={updateSignupOption}
				showOTPForm={showOTPForm}
				history={history}
			/>
		);
	};

	return (
		<>
			{showOTP ? (
				<OTPVerification
					history={history}
					onboardData={onboardData}
					showFullNINForm={showFullNINForm}
					showOTPForm={showOTPForm}
					resendOptData={{ nin, phone: "2348068686868" }}
					updateAccountInfo={updateAccountInfo}
				/>
			) : (
				<>
					<OnboardingHeader title="Sign up to begin trading">
						<StatusBar />
					</OnboardingHeader>

					{render()}
				</>
			)}
		</>
	);
};

export default connect(({ onboarding }) => ({ onboarding }), {
	updateAccountInfo,
	updateSignupOption,
	showOTPForm,
	showFullNINForm,
	nextStep,
})(SignUpWithNIN);
