import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useMutation, useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import Table from "utils/Table/Table";
import { ReactComponent as MeriLogo } from "assets/images/icons/meri-logo.svg";
import MeriLogo2 from "assets/images/icons/meri-logo.svg";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import SearchForm from "utils/CustomInputBox/SearchForm/SearchForm";
import { GET_STOCKS } from "api/queries/stock";
import { ADD_WATCHLIST_ITEMS } from "api/mutations/watchlist";
import { GET_WATCHLIST } from "api/queries/watchlist";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import SuccessState from "utils/Modal/Response/SuccessState";
import { toPrice, getError, titleCase } from "utils/helpers";
import Scroll from "utils/Scroll/Scroll";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "components/constants";
import "./AddStockToWatchListForm.scss";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";
import { fetchUserData } from "storage/sessionStorage";

const AddStockToWatchListForm = ({ toggle, watchlist }) => {
	const { portfolio_accounts = [] } = fetchUserData();
	const [checkedStock, setCheckedStock] = useState([]);
	const [stockList, setStockList] = useState([]);
	const [stocks, setStocks] = useState([]);
	const [filteredStock, setFilteredStock] = useState([]);
	const [errorMsg, setErrorMsg] = useState();
	const [successMsg, setSuccessMsg] = useState("");
	const [exchange, setExchange] = useState(portfolio_accounts?.[0]);

	const [getStocks, { loading }] = useLazyQuery(GET_STOCKS, {
		fetchPolicy: "no-cache",
		onCompleted: (response) => {
			setStocks(response?.stock || []);
			setFilteredStock(response?.stock || []);
		},
	});

	useEffect(() => {
		const filters = {};
		exchange?.margin
			? (filters.marginable = { _eq: true })
			: (filters.exchange = { _iregex: exchange?.exchange });
		getStocks({ variables: { filters } });
		// eslint-disable-next-line
	}, []);

	const [addWatchlistItems, { loading: qLoading }] = useMutation(
		ADD_WATCHLIST_ITEMS,
		{
			refetchQueries: [{ query: GET_WATCHLIST }],
			onCompleted: () => setSuccessMsg("Items Added Successfully "),
			onError: (error) => setErrorMsg(getError(error)),
		}
	);

	const getStockList = () =>
		stockList?.map(({ label }) => ({
			stock_name: label,
			watchlist_id: watchlist?.id,
		}));

	const handleChange = (e) => {
		const filters = {};
		setExchange(e);
		e.margin
			? (filters.marginable = { _eq: true })
			: (filters.exchange = { _iregex: e.exchange });
		getStocks({ variables: { filters } });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (stockList)
			addWatchlistItems({ variables: { items: getStockList() } });
		else setErrorMsg("No Item Selected!!!");
	};

	const handleCheck = (stock) => {
		let label = stock?.name
			?.split("(")
			?.join(":")
			?.split(")")
			?.join(":")
			?.split(":");
		label = label[label.length - 2];
		const exist = checkedStock[label];
		const checked = exist ? false : true;
		setCheckedStock({ ...checkedStock, [label]: checked });

		if (exist) {
			const newStocks = [...stockList].filter(
				(stock) => stock?.label !== label
			);
			setStockList(newStocks);
		} else setStockList((prev) => [...prev, { ...stock, label }]);
	};

	const searchForStocks = (value, allStocks) => {
		const filteredStocks = _.filter(allStocks, (v) => {
			return (
				titleCase(v.label).includes(titleCase(value)) ||
				titleCase(v.name).includes(titleCase(value))
			);
		});
		setFilteredStock(value ? filteredStocks : allStocks);
	};

	const getTableData = () => {
		if (filteredStock?.length > 0)
			return filteredStock?.map((stock) => ({
				action: <CheckStock checked={checkedStock[stock?.name]} />,
				image: stock?.logo_url ? (
					<img src={stock?.logo_url} alt="" className="fliud-mg" />
				) : (
					<MeriLogo width={30} height={30} />
				),
				name: `${stock?.label} (${stock?.name})`,
				dailyPL: toPrice(stock?.price_change),
				dailyPL2: toPrice(stock?.price_change_p),
			}));
		else return [];
	};

	const loadingTable = () => {
		const ld = <Skeleton height={10} width={100} />;
		return Array(5)
			.fill()
			.map(() => ({
				a: ld,
				b: ld,
				c: ld,
				d: ld,
				e: ld,
			}));
	};

	const rowClick = (stock) => handleCheck(stock);
	const tableData = loading ? loadingTable() : getTableData();

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			{successMsg ? (
				<SuccessState btnText="Done" msg={successMsg} toggle={toggle} />
			) : (
				<form onSubmit={handleSubmit} className="pb-3 px-3">
					<div className="stock_list_holder">
						<Scroll>
							{stockList?.map(({ image }, i) => (
								<img
									key={i}
									src={image?.props?.src || MeriLogo2}
									alt=""
								/>
							))}
						</Scroll>
					</div>

					<div className="mt-4 row">
						<div className="col-7">
							<SearchForm
								allData={stocks}
								placeholder="Search for stocks"
								setResult={searchForStocks}
							/>
						</div>
						<div className="col-5">
							<CustomSelect
								name="portfolio"
								placeholder="Select Portfolio"
								value={exchange}
								onChange={(e) => handleChange(e)}
								options={portfolio_accounts}
								getOptionLabel={({ label }) => label}
								formGroupCls="mb-0"
							/>
						</div>
					</div>

					<div className="modal_stock_list">
						<Table
							data={tableData}
							headings={[]}
							indexRow={loading ? "" : "name"}
							rowClick={rowClick}
						/>
					</div>

					<div className="flex__between">
						<button
							className="btn btn--lg  btn--primary--bordered me-3"
							onClick={toggle}
							disable={qLoading}
						>
							Cancel
						</button>

						<button
							type="submit"
							className="btn btn--lg btn--primary ms-3"
							disabled={
								loading || qLoading || stockList?.length < 1
							}
						>
							{qLoading ? <Spinner /> : "Save"}
						</button>
					</div>
				</form>
			)}
		</>
	);
};

const CheckStock = ({ checked }) => {
	return <CustomCheckBox checked={checked} />;
};

export default AddStockToWatchListForm;
