import axios from "axios";
import { fetchUserData } from "storage/sessionStorage";

const baseURLs = [
	process.env.REACT_APP_MERITRADE_BASE_URL,
	process.env.REACT_APP_IDENTITY_BASE_URL,
	process.env.REACT_APP_WALLET_BASE_URL,
	process.env.REACT_APP_BLUSALT_API_BASE_URL,
];

const APIs = [];

baseURLs.forEach((url) => {
	let API = axios.create({ baseURL: url });

	API.interceptors.request.use((config) => {
		const { accessToken } = fetchUserData();

		const headers = {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${accessToken}`,
			...(config?.headers || {}),
		};

		config.headers = headers;

		return config;
	});

	API.interceptors.response.use(
		async (response) => response,
		async (error) => {
			return Promise.reject(error);
		}
	);

	APIs.push(API);
});

export default APIs[0];
export const identityAPI = APIs[1];
export const WalletAPI = APIs[2];
export const BlusaltAPI = APIs[3];
