import ProfileAccountInfo from "./ProfileAccountInfo/ProfileAccountInfo";
import ProfileInvestmentInfo from "./ProfileInvestmentInfo/ProfileInvestmentInfo";
import ProfileKYCInfo from "./ProfileKYCInfo/ProfileKYCInfo";
// import ProfileKYCBVNInfo from "./ProfileKYCInfo/ProfileKYBVNInfo/ProfileKYBVNInfo";
// import ProfileKYCNINInfo from "./ProfileKYCInfo/ProfileKYCNINInfo/ProfileKYCNINInfo";
import ProfileKYCKinInfo from "./ProfileKYCInfo/ProfileKYCKinInfo.js/ProfileKYCKinInfo";
import ProfileKYCBankInfo from "./ProfileKYCInfo/ProfileKYCBankInfo/ProfileKYCBankInfo";
import ProfileAccountUpdateInfo from "./ProfileAccountInfo/ProfileAccountUpdateInfo/ProfileAccountUpdateInfo";
import ProfileAccountUpdateDocument from "./ProfileAccountInfo/ProfileAccountUpdateDocument/ProfileAccountUpdateDocument";
import Referral from "./Referral/Referral";

const defaultRoutes = "/profile";

const routes = [
	{
		name: "Account Information",
		path: `${defaultRoutes}/account/user`,
		component: ProfileAccountInfo,
		dropdown: [
			{
				name: "Account Info",
				path: `${defaultRoutes}/account/user`,
				component: ProfileAccountUpdateInfo,
				exact: true,
			},
			{
				name: "User Documents",
				path: `${defaultRoutes}/account/user/documents`,
				component: ProfileAccountUpdateDocument,
				exact: true,
			},
			{
				name: "Referral",
				path: `${defaultRoutes}/account/user/referral`,
				component: Referral,
				exact: true,
			},
		],
	},
	{
		name: "KYC Information",
		path: `${defaultRoutes}/kyc`,
		component: ProfileKYCInfo,
		dropdown: [
			// {
			// 	name: "BVN",
			// 	path: `${defaultRoutes}/kyc`,
			// 	component: ProfileKYCBVNInfo,
			// 	exact: true,
			// },
			// {
			// 	name: "NIN",
			// 	path: `${defaultRoutes}/kyc/nin`,
			// 	component: ProfileKYCNINInfo,
			// 	exact: true,
			// },
			{
				name: "Next Of Kin",
				path: `${defaultRoutes}/kyc`,
				component: ProfileKYCKinInfo,
				exact: true,
			},
			{
				name: "Bank Info",
				path: `${defaultRoutes}/kyc/bank`,
				component: ProfileKYCBankInfo,
				exact: true,
			},
		],
	},

	{
		name: "Investment Information",
		path: `${defaultRoutes}/investment`,
		component: ProfileInvestmentInfo,
		exact: true,
	},
];

export default routes;
