import React from "react";
import DashboardPortfolios from "../../common/DashboardPortfolios/DashboardPortfolios";
import MarketUpdateTopButtons from "./MarketUpdateTopButtons/MarketUpdateTopButtons";
import MarketTrades from "./MarketTrades/MarketTrades";

const MarketUpdateRightView = ({ loading, history, stock }) => (
	<div className="col-xl-3 col-lg-4 right__view d-lg-block d-none">
		<MarketUpdateTopButtons loading={loading} stock={stock} />
		<MarketTrades
			showTitle
			loading={loading}
			currency={stock?.currency}
			trade={stock?.trades}
		/>
		<DashboardPortfolios history={history} />
	</div>
);

export default MarketUpdateRightView;
