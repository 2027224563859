import React from "react";
import Table from "utils/Table/Table";
import { TableLoader } from "utils/TableLoader/TableLoader";
import { timeDateFormat } from "utils/helpers";
import { UPCOMING_DATES } from "../../enums";
import {
	AGM_DATE_HEADING,
	CLOSURE_DATE_HEADING,
	CORPORATE_BENEFIT_HEADING,
	PAYMENT_DATE_HEADING,
} from "../../extra";

const CorporateBenefitTable = ({ type, loading, data = [], setBenefit }) => {
	const dateSwitch = (cp) => {
		let date = "";
		let headings = "";
		switch (type) {
			case UPCOMING_DATES.CLOSURE_DATE:
				date = timeDateFormat(cp?.closure_date)?.date;
				headings = CLOSURE_DATE_HEADING;
				break;
			case UPCOMING_DATES.PAYMENT_DATE:
				date = timeDateFormat(cp?.payment_date)?.date;
				headings = PAYMENT_DATE_HEADING;
				break;
			case UPCOMING_DATES.AGM_DATE:
				date = timeDateFormat(cp?.payment_date)?.date;
				headings = AGM_DATE_HEADING;
				break;
			default:
				break;
		}
		return { date, headings };
	};

	const getFullTableData = () =>
		data?.map((cp) => ({
			company: cp?.stock?.label,
			divDeclared: cp?.dividend_declared,
			bonus: cp?.bonus,
			yield: cp?.dividend_yield,
			current: cp?.final ? "Final" : "Interim",
			declaration: timeDateFormat(cp?.declaration_date)?.date,
			qualification: timeDateFormat(cp?.qualification_date)?.date,
			closure: timeDateFormat(cp?.closure_date)?.date,
			payment: timeDateFormat(cp?.payment_date)?.date,
			agm: timeDateFormat(cp?.agm_date)?.date,
			agmVenue: cp?.agm_venue,
		}));

	const getTableData = () =>
		data?.map((cp) => ({
			company: cp?.stock?.label,
			divDeclared: cp?.dividend_declared,
			bonus: cp?.bonus,
			yield: cp?.dividend_yield,
			current: cp?.final ? "Final" : "Interim",
			declaration: timeDateFormat(cp?.declaration_date)?.date,
			qualification: timeDateFormat(cp?.qualification_date)?.date,
			closure: dateSwitch(cp).date,
		}));

	const loader = type ? TableLoader(8) : TableLoader(11);
	const tableSwitch = type ? getTableData() : getFullTableData();
	const tableData = loading ? loader : tableSwitch;
	const headers = type ? dateSwitch()?.headings : CORPORATE_BENEFIT_HEADING;

	const handleRowClick = (e) => {
		const currentBenefit =
			data?.find((v) => v?.stock?.label === e?.company) || data?.[0];
		setBenefit(currentBenefit);
	};

	return (
		<Table
			data={tableData}
			headings={headers}
			cls="full__height faint__bg"
			rowClick={handleRowClick}
		/>
	);
};

export default CorporateBenefitTable;
