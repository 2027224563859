import React, { useState } from "react";
import { ReactComponent as Share } from "assets/images/icons/share.svg";
import ReferralModal from "../../modals/ReferralModal/ReferralModal";

const ReferralButton = ({ referralLink, disabled }) => {
    const [showModal, setShowModal] = useState(false);

    const toggle = () => setShowModal((prev) => !prev);

    return (
        <>
            <button
                className="btn btn--primary--bordered btn--sm  px-5"
                disabled={disabled}
                onClick={toggle}
            >
                <Share className="primary me-3" width={15} /> Share Link
            </button>

            {showModal && (
                <ReferralModal toggle={toggle} referralLink={referralLink} />
            )}
        </>
    );
};

export default ReferralButton;
