import React, { useState } from "react";
import StatusTables from "../../../../common/StatusTables/StatusTables";
import QuickTradeAllForms from "./QuickTradeAllForms/QuickTradeAllForms";
import QuickTradeBalance from "../QuickTradeBalance/QuickTradeBalance";

const QuickTradeForm = ({ toggle, order, setSuccessMsg }) => {
	const [stock, setStock] = useState({});
	const [account, setAccount] = useState(false);

	return (
		<div className="row">
			<div className="col-md-6">
				<div className="border rounded m-3 p-3">
					<h3 className="fw-bold  pb-2 ">
						{order ? `Update Order ${order.id}` : "New Order"}
					</h3>

					<QuickTradeAllForms
						toggle={toggle}
						order={order}
						setStock={setStock}
						setSuccessMsg={setSuccessMsg}
						setAccount={setAccount}
					/>
				</div>
			</div>

			<div className="col-md-6 d-md-inline-block d-none">
				<div className="mt-3 me-3">
					<QuickTradeBalance account={account} order={stock?.order} />

					<StatusTables stock={stock} cls="accord_250" />
				</div>

				{stock?.label && (
					<div className="text-center">
						<div className="stock-select-text">
							Showing Order Book for
						</div>
						<div className="stock-name">{stock?.label}</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default QuickTradeForm;
