import React from "react";
import { useQuery } from "@apollo/client";
import CustomSelect from "../CustomSelect/CustomSelect";
import { ALL_COUNTRIES } from "api/queries/others/country";

const CountrySelect = ({
	label,
	name,
	value,
	error,
	isMulti,
	placeholder = "Select Country",
	required,
	disabled,
	onChange,
	defaultValue,
}) => {
	const { loading, data } = useQuery(ALL_COUNTRIES);
	const { country = [] } = data || {};

	return (
		<CustomSelect
			name={name}
			value={value}
			label={label}
			error={error}
			isMulti={isMulti}
			isLoading={loading}
			options={country}
			placeholder={placeholder}
			onChange={onChange}
			required={required}
			disabled={disabled}
			defaultValue={defaultValue}
			getOptionLabel={({ name }) => name}
		/>
	);
};

export default CountrySelect;
