import React from "react";

const CustomCheckBox = ({ label, checked, onClick, className, cls }) => {
	return (
		<label className={`custom-check-box ${cls}`}>
			<input
				type="checkbox"
				checked={checked}
				onChange={() => {}}
				onClick={onClick}
			/>
			<span className={className}>{label}</span>
		</label>
	);
};

export default CustomCheckBox;
