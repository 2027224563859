import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import OnboardingHeader from "../../Onboarding/utils/OnboardingHeader/OnboardingHeader";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import FooterLink from "../common/FooterLink/FooterLink";
import { RESET_PASSWORD } from "api/mutations/auth";
import Toast from "utils/Alert/Toast/Toast";
import { formValidator } from "utils/FormValidator/FormValidator";
import Disclaimer from "../../Onboarding/utils/Disclaimer/Disclaimer";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { ERROR } from "../../constants";
import { getError } from "utils/helpers";

const ForgotPassword = ({ history }) => {
	const [formError, setFormError] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const [username, setUsername] = useState("");

	const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
		onCompleted: () => history.push({ pathname: "/auth/mail" }),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleChange = ({ target: { value } }) => {
		setUsername(value);
		setFormError({ username: "" });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator({ username });
		if (isValid) {
			setErrorMsg();
			resetPassword({ variables: { username } });
		} else setFormError(formErrors);
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<OnboardingHeader title="Forgot Password" />

			<div className="px-sm-5 px-0 mt-4">
				<form onSubmit={handleSubmit}>
					<div className="row justify-content-center">
						<div className="col-12 px-5 mb-4">
							<CustomInputBox
								type="username"
								name="username"
								label="Username"
								placeholder="Username"
								value={username}
								onChange={handleChange}
								error={formError?.username}
								required
							/>
						</div>

						<div className="col-sm-6 col-12 px-5 mb-4">
							<button
								type="submit"
								className="btn btn--lg btn--primary"
								disabled={loading}
							>
								{loading ? <Spinner /> : "Continue"}
							</button>
						</div>

						<div className="col-sm-6 col-12 px-5 text-end text--gray">
							<Disclaimer history={history} isLogin />
						</div>
					</div>
				</form>
			</div>

			<FooterLink
				text="Back to Login"
				link={() => history.push("/auth/login")}
			/>
		</>
	);
};

export default ForgotPassword;
