import React, { useState } from "react";
import { useSubscription } from "@apollo/client";
import { Accordion, AccordionDetails } from "@material-ui/core";
import WatchListBoard from "../WatchListBoard/WatchListBoard";
import DefaultWatchListCard from "../../../../WatchList/common/DefaultWatchListCard/DefaultWatchListCard";
import WatchListLoader from "../../../../WatchList/common/WatchListLoader/WatchListLoader";
import { GET_WATCHLIST } from "api/subscription/watchlist";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "components/constants";
import { getError } from "utils/helpers";

const AllDashWatchList = ({ history }) => {
	const [expanded, setExpanded] = useState(false);

	const { loading, error, data } = useSubscription(GET_WATCHLIST);

	const handleChange = (panel) => (_, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	return (
		<>
			{error && (
				<Toast
					msg={getError(error)}
					variant={ERROR}
					toastId="watchlist"
				/>
			)}

			<div className="accord">
				{loading ? (
					<WatchListLoader count={3} />
				) : (
					<>
						{data?.watchlist?.map(
							({ id, name, watchlist_items }, i) => (
								<Accordion
									key={id}
									expanded={expanded === `panel${i}`}
									onChange={handleChange(`panel${i}`)}
								>
									<DefaultWatchListCard
										id={id}
										name={name}
										history={history}
									/>

									<AccordionDetails>
										<WatchListBoard
											items={watchlist_items}
										/>
										<hr className="border-gray" />
									</AccordionDetails>
								</Accordion>
							)
						)}
					</>
				)}
			</div>
			<p
				className="text--primary fw-bold text-end pointer me-4 pt-2"
				onClick={() => history.push("/watchlist")}
			>
				View All
			</p>
		</>
	);
};

export default AllDashWatchList;
