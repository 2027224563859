import React, { Component } from "react";
import IdleTimer from "react-idle-timer";

class SessionHandler extends Component {
	state = {
		logOff: false,
		timeout: 10000 * 60 * 1,
		remaining: null,
		isIdle: false,
		lastActive: null,
		elapsed: null,
	};

	idleTimer = null;

	componentDidMount = () => {
		if (this.idleTimer !== null) {
			this.setState({
				remaining: this.idleTimer.getRemainingTime(),
				lastActive: this.idleTimer.getLastActiveTime(),
				elapsed: this.idleTimer.getElapsedTime(),
			});
		}
	};

	onActive = () => this.setState({ isIdle: false });

	onIdle = () => {
		this.props.handleLogout();
		this.setState({ isIdle: true, logOff: true });
	};

	changeTimeout = () => {
		this.setState({ timeout: this.refs.timeoutInput.state.value() });
	};

	reset = () => this.idleTimer.reset();

	pause = () => this.idleTimer.pause();

	resume = () => this.idleTimer.resume();

	render() {
		return (
			<>
				<IdleTimer
					ref={(ref) => {
						this.idleTimer = ref;
					}}
					onActive={this.onActive}
					onIdle={this.onIdle}
					timeout={this.state.timeout}
					startOnLoad
				/>
			</>
		);
	}
}

export default SessionHandler;
