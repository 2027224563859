import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { FINISH_REGISTRATION } from "api/mutations/onboarding";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import { getError } from "utils/helpers";
import { ReactComponent as SuccessIcon } from "assets/images/icons/success.svg";
import { ReactComponent as AlertIcon } from "assets/images/icons/tri-alert.svg";

const FinishRegistration = ({ updateRegStatus }) => {
	const [errorMsg, setErrorMsg] = useState("");

	const [finish, { loading }] = useMutation(FINISH_REGISTRATION, {
		onCompleted: () => complete(),
		onError: (err) => setErrorMsg(getError(err)),
	});

	const complete = () => {
		updateRegStatus({ completed: true });
		window.location.reload();
	};

	return (
		<div className="text-center pt-3">
			{errorMsg ? (
				<AlertIcon width="35px" />
			) : (
				<SuccessIcon width="35px" />
			)}
			<p
				className={`heading3 py-4 ${
					errorMsg ? "text-danger" : "text-success"
				}`}
			>
				{errorMsg || "Your KYC has been succesfully updated!!!"}
			</p>

			<div className="row justify-content-center">
				<div className="col-sm-6 col-12">
					<div className="text-center mb-4">
						<button
							className="btn btn--lg btn--primary mx-auto"
							onClick={finish}
						>
							{loading ? (
								<CustomSpinner />
							) : (
								`${errorMsg ? "Retry" : "Finish"}`
							)}
						</button>

						{errorMsg && (
							<p
								className="heading3 pointer mt-3 text--primary"
								onClick={complete}
							>
								Close
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FinishRegistration;
