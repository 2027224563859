import React from "react";
import { ReactComponent as SuccessIcon } from "assets/images/icons/success.svg";

const SuccessState = ({
	cls,
	msg,
	toggle,
	btnText,
	// showBtn = true,
	iconHeight,
	iconWidth = "35",
	msgCls,
}) => {
	return (
		<div className={`text-center mt-3 ${cls}`}>
			<SuccessIcon width={iconWidth} height={iconHeight} />

			<p className={`heading3 my-4 ${msgCls}`}>{msg}</p>

			<div className="form-group px-5 col-8 mx-auto">
				{btnText && (
					<button
						className="btn btn--lg btn--primary"
						onClick={toggle}
					>
						{btnText}
					</button>
				)}
			</div>
		</div>
	);
};

export default SuccessState;
