import React, { useState } from "react";
import { ReactComponent as FilterIcon } from "assets/images/icons/filter.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/closecover.svg";
import CustomDatePicker from "utils/CustomDatePicker/CustomDatePicker";
import ExportAsCSV from "utils/ExportAsCSV/ExportAsCSV";
import { checkSameDay } from "utils/helpers";
import { formValidator } from "utils/FormValidator/FormValidator";
import { BUY, SELL } from "components/constants";
import {
	cashExport,
	defaultBuyFilters,
	defaultCashFilters,
	defaultSellFilters,
	statementExport,
} from "../../extra";
import { toCurrency, timeDateFormat } from "utils/helpers";

const StatementFilters = ({
	type,
	isCash,
	data = [],
	defaultFilters,
	applyFilter,
	setIsToday,
}) => {
	const [formErrors, setFormErrors] = useState({});
	const [filters, setFilters] = useState(defaultFilters);
	const [isFilter, setIsFilter] = useState(false);

	const date = (v) => `${timeDateFormat(v)?.date} ${timeDateFormat(v)?.time}`;

	const cashData = data?.map((v) => ({
		...v,
		balance: toCurrency(v?.balance),
		creditAmount: toCurrency(v?.creditAmount),
		debitAmount: toCurrency(v?.debitAmount),
		transactionDate: date(v?.transactionDate),
		valueDate: date(v?.valueDate),
	}));

	const statementData = data?.map((v) => ({
		...v,
		price: toCurrency(v?.contractPrice),
		amount: toCurrency(v?.contractAmount),
		date: date(v?.transactionDate),
	}));

	const handleSubmit = () => {
		const { formErrors, isValid } = formValidator(filters);
		if (isValid) {
			setIsFilter(true);
			applyFilter({ data: filters });
		} else setFormErrors(formErrors);
	};

	const clearFilter = () => {
		const newFilter =
			type === BUY
				? defaultBuyFilters
				: type === SELL
				? defaultSellFilters
				: defaultCashFilters;
		setIsFilter(false);
		setIsToday(true);
		setFilters(newFilter);
		applyFilter({ data: newFilter });
	};

	const handleDate = (name, value) => {
		setFilters((prev) => ({
			...prev,
			[name]: value,
		}));
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
		checkSameDay(new Date(), value) ? setIsToday(true) : setIsToday(false);
	};

	return (
		<div className="mt-5 flex__between flex-wrap">
			<div className="d-flex align-items-end">
				<div className="mx-4 small-date with__filters">
					<CustomDatePicker
						label="Start Date"
						name="start_date"
						placeholder="Start"
						value={filters?.start_date}
						max={new Date()}
						cls="small-form-group mb-0"
						onChange={(e) => handleDate("start_date", e)}
						error={formErrors?.start_date}
						isClearable={false}
					/>
				</div>

				<div className="small-date with__filters">
					<CustomDatePicker
						label="End Date"
						name="start_date"
						placeholder="End"
						min={filters?.start_date}
						max={new Date()}
						value={filters?.end_date}
						cls="small-form-group mb-0"
						onChange={(e) => handleDate("end_date", e)}
						error={formErrors?.start_date}
						isClearable={false}
					/>
				</div>

				<div className="ms-4 d-flex align-items-end">
					<span
						onClick={handleSubmit}
						className="flex__between pointer me-2"
					>
						<FilterIcon />
						<h3 className="fw-bold mb-0 ms-2">Filter</h3>
					</span>
					{isFilter && (
						<span
							onClick={clearFilter}
							className="flex__between pointer ms-3"
						>
							<CloseIcon width={20} height={20} />
							<h3 className="fw-bold mb-0 ms-2">Clear</h3>
						</span>
					)}
				</div>

				<ExportAsCSV
					data={isCash ? cashData : statementData}
					cls="ms-4"
					headers={isCash ? cashExport : statementExport}
					filename="Statement"
				/>
			</div>
		</div>
	);
};

export default StatementFilters;
