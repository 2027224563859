import { gql } from "@apollo/client";

export const GET_NEWS = gql`
	query getNews {
		news(page: 0, size: 100) {
			body
			category
			headLine
			id
			imageUrl
			pubDate
			summary
			url
		}
	}
`;
