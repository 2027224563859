import React from "react";
import MarketUpdateScroll from "../../../DashContent/DashContentLeftView/MarketUpdates/MarketUpdateScroll/MarketUpdateScroll";
import MarketTrades from "../../MarketUpdateRightView/MarketTrades/MarketTrades";
import AboutMarket from "../AboutMarket/AboutMarket";
import MarketStatistics from "../MarketStatistics/MarketStatistics";
import RelatedNews from "../RelatedNews/RelatedNews";

const MarketUpdateMobile = () => {
	return (
		<div className="d-md-none d-block pt-5 mt-5">
			<ul className="nav nav-tabs " id="myTab" role="tablist">
				<Tab active="active" id="market" tabName="Market Trades" />
				<Tab id="info" tabName="Info" />
				<Tab id="earnings" tabName="Earnings" />
				<Tab id="news" tabName="News" />
			</ul>

			<div className="tab-content" id="myTabContent">
				<div className="tab-pane fade show active" id="market" role="tabpanel">
					<MarketTrades />
				</div>
				<div className="tab-pane fade" id="info" role="tabpanel">
					<AboutMarket />
					<MarketStatistics />
					<div className="mt-5 py-5">
						<span className="heading2 fw-bold">More like This</span>
						<div className="mt-4">
							<MarketUpdateScroll />
						</div>
					</div>
				</div>
				<div className="tab-pane fade" id="earnings" role="tabpanel"></div>
				<div className="tab-pane fade" id="news" role="tabpanel">
					<RelatedNews />
				</div>
			</div>
		</div>
	);
};

const Tab = ({ active, id, tabName }) => (
	<li className="nav-item" role="presentation">
		<button
			className={`nav-link ${active}`}
			id={`${id}-tab`}
			data-bs-toggle="tab"
			data-bs-target={`#${id}`}
			role="tab"
			aria-controls={id}
		>
			{tabName}
		</button>
	</li>
);

export default MarketUpdateMobile;
