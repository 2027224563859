import { SWITCH_MODE, UPDATE_MODE } from "../actionTypes/modeActionTypes";
import { getMode } from "storage/localStorage";
import { DARK_MODE, LIGHT_MODE } from "../../components/constants";

const inititalState = {
	meriMode: getMode(),
	username: "",
};

const modeReducer = (state = inititalState, action) => {
	switch (action.type) {
		case SWITCH_MODE:
			return {
				...state,
				meriMode: state.meriMode === DARK_MODE ? LIGHT_MODE : DARK_MODE,
			};

		case UPDATE_MODE:
			return {
				...state,
				...(action.payload || {}),
			};

		default:
			return state;
	}
};

export default modeReducer;
