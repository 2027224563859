import React, { useState } from "react";
import { AreaData2 } from "utils/Charts/helper/areachart";
import TopGainer from "../../../../../DashContent/DashContentRightView/DashboardWatchlist/TopGainer/TopGainer";
import TopLoser from "../../../../../DashContent/DashContentRightView/DashboardWatchlist/TopLoser/TopLoser";
import MarketStatusNav from "./MarketStatusNav/MarketStatusNav";

const MarketStatus = () => {
	const [showGainer, setShowGainer] = useState(true);

	const toggle = () => setShowGainer((prev) => !prev);

	return (
		<div className="dash__watchlist div__wrapper">
			<MarketStatusNav showGainer={showGainer} toggle={toggle} />

			{showGainer ? (
				<TopGainer data={AreaData2} />
			) : (
				<TopLoser data={AreaData2} />
			)}
		</div>
	);
};

export default MarketStatus;
