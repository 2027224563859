import React from "react";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";
import { fetchUserData } from "storage/sessionStorage";
import { titleCase } from "utils/helpers";

const ProfileInvestmentInfo = ({ history }) => {
	const { investment_profile } = fetchUserData();
	const data = investment_profile ? investment_profile[0] : {};

	return (
		<div className="px-sm-5 px-0 pb-5">
			<div className="d-flex align-items-center col-12 px-3 mx-3">
				<BackIcon
					height="20px"
					width="20px"
					onClick={() => history.push("/profile/account")}
					className="pointer d-lg-none d-inline-block me-4"
				/>
				<p className="heading2 fw-bold mb-0">Investment Information</p>
			</div>

			<div className="row mt-5">
				<div className="col-sm-6 col-12 px-5">
					<CustomInputBox
						name="objective"
						label="Investment Objective"
						placeholder="Investment Objective"
						value={titleCase(data?.objective_id)}
						disabled
					/>
				</div>

				<div className="col-sm-6 col-12 px-5">
					<CustomInputBox
						name="tolerance"
						label="Risk Tolerance"
						placeholder="Risk Tolerance"
						value={titleCase(data?.risk_tolerance_id)}
						disabled
					/>
				</div>

				<div className="col-sm-6 col-12 px-5">
					<CustomInputBox
						name="knowledge"
						label="Investment Knowledge"
						placeholder="Investment Knowledge"
						value={titleCase(data?.knowledge_id)}
						disabled
					/>
				</div>

				{/* <div className="col-sm-6 col-12 px-5">
					<CustomInputBox
						name="preferredAccount"
						label="Preferred Account"
						placeholder="Preferred Account"
						value={data?.preferredAccount_id}
						disabled
					/>
				</div> */}

				<div className="col-sm-6 col-12 px-5">
					<CustomInputBox
						name="estNetWorth"
						label="Estimated Net Worth"
						placeholder="Estimated Net Worth"
						value={data?.net_worth}
						disabled
					/>
				</div>

				<div className="col-sm-6 col-12 px-5">
					<CustomInputBox
						name="sourceOfWealth"
						label="Source of Wealth"
						placeholder="08066766767"
						value={titleCase(data?.source_of_wealth_id)}
						disabled
					/>
				</div>
			</div>
		</div>
	);
};

export default ProfileInvestmentInfo;
