import React from "react";
import Modal from "utils/Modal/Modal";
import { SECONDARY } from "components/constants";
import AddStockToWatchListForm from "./AddStockToWatchListForm/AddStockToWatchListForm";

const AddStockToWatchListModal = ({ toggle, watchlist }) => {
	return (
		<Modal
			show={true}
			type={SECONDARY}
			toggle={toggle}
			size="lg"
			title="Add Item to List "
		>
			<AddStockToWatchListForm toggle={toggle} watchlist={watchlist} />
		</Modal>
	);
};

export default AddStockToWatchListModal;
