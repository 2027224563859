import React, { useState } from "react";
import API from "api/rest";
import { ERROR } from "../../../../constants";
import { downloadPDF, getError } from "utils/helpers";
import Toast from "utils/Alert/Toast/Toast";
import { downloadContract } from "api/restRoutes";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";

const DownloadContract = ({ portfolio_id = "", id }) => {
	const [errorMsg, setErrorMsg] = useState("");
	const [loading, setLoading] = useState(false);

	const handleDownload = async () => {
		try {
			setLoading(true);
			setErrorMsg();

			const response = await API.get(downloadContract, {
				responseType: "blob",
				params: { portfolio_id, id },
			});
			const { status, data } = response || {};

			if (status === 200) {
				setLoading(false);
				downloadPDF(data);
			}
		} catch (error) {
			setLoading(false);
			setErrorMsg(getError(error));
		}
	};
	return (
		<>
			{errorMsg && <Toast msg={errorMsg} variant={ERROR} />}

			{loading ? (
				<CustomSpinner cls="text-succuss" />
			) : (
				<DownloadIcon onClick={handleDownload} />
			)}
		</>
	);
};

export default DownloadContract;
