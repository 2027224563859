import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as Alert } from "assets/images/icons/tri-alert.svg";
import Toast from "utils/Alert/Toast/Toast";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { ERROR } from "../../../../../constants";
import { getError } from "utils/helpers";
import { ADD_WATCHLIST_ITEM } from "api/mutations/watchlist";

const AddWatchlistConfirmation = ({ stock, watchlist, prevStep, nextStep }) => {
	const [errorMsg, setErrorMsg] = useState();

	const [addToWatchlist, { loading }] = useMutation(ADD_WATCHLIST_ITEM, {
		onCompleted: () => nextStep(),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleAPICall = () => {
		setErrorMsg();
		addToWatchlist({
			variables: { stock_name: stock, watchlist_id: watchlist },
		});
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<div className="text-center px-3 pb-3">
				<Alert className="my-5" />

				<p className="fw-bold">
					Are you sure you want to add the selected item to this list?
				</p>

				<div className="flex__between mt-5 px-3">
					<button
						className="btn btn--lg btn--primary--bordered me-3"
						onClick={prevStep}
					>
						No
					</button>
					<button
						className="btn btn--lg btn--primary ms-3"
						onClick={handleAPICall}
						disabled={loading}
					>
						{loading ? <Spinner /> : "Yes"}
					</button>
				</div>
			</div>
		</>
	);
};

export default AddWatchlistConfirmation;
