import { gql } from "@apollo/client";

export const ALL_COUNTRIES = gql`
	query GetCountries {
		country {
			id
			iso
			name
		}
	}
`;
