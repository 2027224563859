import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const routes = [
	{
		name: "Portfolio Account",
		path: "/portfolio/portfolio-account-summary",
	},
	{
		name: "Margin Account",
		path: "/portfolio/margin-account-summary",
	},
];

const AccountSummaryNavs = () => {
	const { pathname } = useLocation();

	return (
		<div>
			<ul className="nav nav-tabs no-flex" id="myTab" role="tablist">
				{routes?.map(({ name, path }) => (
					<NavLink
						className="nav-item pe-sm-5 pe-4"
						to={path}
						role="presentation"
						key={name}
					>
						<button
							className={`nav-link px-0 py-3 ${
								pathname === path ? "active" : ""
							}`}
						>
							{name}
						</button>
					</NavLink>
				))}
			</ul>
		</div>
	);
};

export default AccountSummaryNavs;
