import React from "react";
import MoneyMarketRightview from "./MoneyMarketRightview/MoneyMarketRightview";

const MoneyMarketWrapper = ({ children, history }) => {
	return (
		<div className="dash__content full__screen">
			<div className="row">
				<div className="col-lg-8 col-12 left__view">{children}</div>
				<MoneyMarketRightview history={history} />
			</div>
		</div>
	);
};

export default MoneyMarketWrapper;
