import { gql } from "@apollo/client";

export const UPDATE_ORDER = gql`
	mutation UpdateOrder(
		$account: String!
		$id: String!
		$name: String!
		$price_limit: Float
		$price_type: String!
		$quantity: Int
		$stop_price: Float
	) {
		update_order(
			account: $account
			id: $id
			name: $name
			price_limit: $price_limit
			price_type: $price_type
			quantity: $quantity
			stop_price: $stop_price
		) {
			message
			status
		}
	}
`;
