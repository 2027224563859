import { titleCase } from "utils/helpers";
import { stock_sectors } from "./enums";

// gVG6%SSy_Aa,W-Y

export const getStockSector = (status, otherCls) => {
	let cls = "";
	switch (titleCase(status)) {
		case stock_sectors.ENGINEERING:
		case stock_sectors.AUTO:
		case stock_sectors.COMPUTER:
		case stock_sectors.TECH:
			cls = "blue__light";
			break;

		case stock_sectors.CONSUMER:
		case stock_sectors.ESTATE:
		case stock_sectors.ESTATES:
			cls = "purple__light";
			break;

		case stock_sectors.BANKING:
		case stock_sectors.AGRICULTURE:
		case stock_sectors.HEALTH:
			cls = "danger__light";
			break;

		case stock_sectors.AIRLINE:
		case stock_sectors.AIRLINES:
		case stock_sectors.COMMERCIAL:
		case stock_sectors.OTHERS:
			cls = "primary__light";
			break;

		case stock_sectors.BREWERIES:
		case stock_sectors.CONSTRUCTION:
		case stock_sectors.SERVICES:
			cls = "primary__med";
			break;

		case stock_sectors.CHEMICAL:
		case stock_sectors.FINANCIAL:
		case stock_sectors.INDUSTRIAL:
			cls = "faint__primary";
			break;

		default:
			cls = "bg-warning";
			break;
	}
	return (
		<small className={`rounded px-4 py-2 ${cls} ${otherCls}`}>
			{titleCase(status)}
		</small>
	);
};
