import React, { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_MARKET_STATUS } from "api/queries/stock";
import { titleCase, toCurrency } from "utils/helpers";
import LineText from "utils/LineText/LineText";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import { getCommissions } from "components/Dashboard/common/helpers";
import {
	BUY,
	ERROR,
	SECONDARY,
	SELL,
	stockTransType,
} from "../../../../../constants";
import Toast from "utils/Alert/Toast/Toast";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { CommissionTooltip } from "components/Dashboard/common/CommissionTooltip";

const ReviewOrder = ({ isBuy, order, toggle, nextStep, prevStep }) => {
	const [errorMsg, setErrorMsg] = useState();
	const [isOpen, setIsOpen] = useState(true);
	const [cont, setCont] = useState(false);

	const type = isBuy ? BUY : SELL;
	const { price_type, price, quantity, total, data } = order || {};
	const { stop_price, term, price_limit } = order || {};
	const { commission, fees } = getCommissions({ type, amount: total });
	const estTotal = commission + fees + total;

	const [verifyMarketStatus, { error, loading }] = useLazyQuery(
		GET_MARKET_STATUS,
		{
			fetchPolicy: "no-cache",
			onCompleted: ({ market_status }) => {
				if (market_status?.status) nextStep({ estTotal });
				else {
					setIsOpen(false);
					setCont(true);
				}
			},
			onError: () => {
				setCont(true);
				setErrorMsg("Error Confirming Market Status");
			},
		}
	);

	const handleSubmit = () => {
		if (cont) nextStep({ estTotal });
		else verifyMarketStatus();
	};

	return (
		<div>
			{error && <Toast msg={errorMsg} variant={ERROR} />}

			<div className="text-center">
				<ModalHeader
					type={SECONDARY}
					toggle={toggle}
					prevStep={prevStep}
					title="Review"
				/>

				<div className="">
					<p className="heading4">{data?.label}</p>
					<span>{data?.name}</span>
				</div>

				<div className="px-sm-5 px-2 pb-4">
					<LineText
						title="Order Type"
						value={titleCase(price_type)}
					/>
					<LineText title="Quantity" value={quantity} />
					<LineText title="Price" value={`N${toCurrency(price)}`} />
					{price_type !== stockTransType.MARKET && (
						<LineText
							title="Price Limit"
							value={`N${toCurrency(price_limit)}`}
						/>
					)}
					{price_type === stockTransType.STOP_LIMIT && (
						<LineText
							title="Stop Price"
							value={`N${toCurrency(stop_price)}`}
						/>
					)}
					<LineText
						title="Total Price"
						value={`N${toCurrency(total)}`}
					/>
					{term && <LineText title="Term" value={term} />}
					<LineText
						title="Est. Commission"
						value={`N${toCurrency(commission)}`}
					/>
					<LineText
						title="Est. Fee "
						value={
							<span className="d-flex align-items-center">
								N{toCurrency(fees)}{" "}
								<CommissionTooltip type={type} />
							</span>
						}
					/>

					<div className="flex__between fw-bold">
						<span className="text-nowrap">Total</span>{" "}
						<div className="border-bottom w-100 mx-2" />
						<span className="text-nowrap">
							{`N${toCurrency(estTotal)}`}{" "}
						</span>
					</div>

					{!isOpen && (
						<div className="green__light text--primary p-3 rounded fw-bold mt-5">
							The market is currently closed. Your order will be
							executed at market price once the market reopens.
						</div>
					)}

					<div className="col-sm-6 col-12 mt-5 mx-auto">
						<button
							className={`btn btn--lg ${
								isBuy ? "btn--primary" : "btn--secondary"
							}`}
							onClick={handleSubmit}
							disabled={loading}
						>
							{loading ? <Spinner /> : "Confirm"}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewOrder;
