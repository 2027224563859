import React from "react";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";

const SmallStockChart = ({
	data,
	lineColor = "#078950",
	height = 50,
	backgroundColor = "#fff",
}) => {
	const configPrice = {
		yAxis: {
			visible: false,
			crosshair: false,
		},

		xAxis: {
			visible: false,
			crosshair: false,
		},
		scale: 50,
		tooltip: {
			enabled: false,
			visible: false,
		},
		chart: {
			height: height,
			backgroundColor,
		},
		credits: {
			enabled: false,
		},
		scrollbar: {
			enabled: false,
		},
		rangeSelector: {
			allButtonsEnabled: false,
			enabled: false,
		},
		series: [
			{
				type: "area",
				data,
				showInLegend: false,
				color: lineColor,
				lineWidth: 1,
				states: {
					hover: {
						enabled: false,
					},
				},
				fillColor: {
					fill: "#fff",
				},
			},
		],
		navigator: {
			enabled: false,
		},
	};
	return <ReactHighcharts config={configPrice}></ReactHighcharts>;
};

export default SmallStockChart;
