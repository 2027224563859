import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "assets/images/icons/logo.svg";
import { ReactComponent as Logo2 } from "assets/images/icons/Logo-white.svg";
import AppContext from "components/Dashboard/common/AppContext";
import { DARK_MODE } from "components/constants";

const OnboardingHeader = ({ title, children }) => {
	const { push } = useHistory();
	const { mode } = useContext(AppContext);
	const CurrentLogo = mode.meriMode === DARK_MODE ? Logo2 : Logo;

	return (
		<div className="text-center p-3">
			<CurrentLogo
				className="logo pointer"
				onClick={() => push("/auth/login")}
			/>
			<h1 className="heading1">{title}</h1>
			{children}
		</div>
	);
};

export default OnboardingHeader;
