import React from "react";
import DashContentLeftView from "./DashContentLeftView/DashContentLeftView";
import DashContentRightView from "./DashContentRightView/DashContentRightView";
import TopButtons from "./DashContentRightView/TopButtons/TopButtons";
import "./DashContent.scss";

const DashContent = ({ history }) => {
	return (
		<>
			<div className="dash__content">
				<div className="row">
					<DashContentLeftView history={history} />
					<DashContentRightView history={history} />
				</div>
			</div>
			<div className="bottom__button d-lg-none d-block bg-white">
				<TopButtons history={history} />
			</div>
		</>
	);
};

export default DashContent;
