import React, { useState, useEffect } from "react";
import $ from "jquery";
import "./Doughnut.scss";
import { truncate } from "utils/helpers";

const Doughnut = ({
	data,
	offset = 0,
	totalSegments = 6,
	filledSegments = 5,
	showData,
	className,
	shade = data[0],
}) => {
	const [currentShade, setCurrentShade] = useState(shade);

	offset = parseInt(offset);
	totalSegments = parseInt(totalSegments);
	filledSegments = parseInt(filledSegments);

	$(document).on("mouseenter", "path", (e) => {
		const isSegment = !!e.target.id;
		let index = e.target.id.split("filled")[1];
		index = parseInt(index);
		if (data?.length && isSegment) {
			setCurrentShade(data[index]);
		}
	});

	const values = data?.map(({ percent }) => percent);

	useEffect(() => {
		const svgComp = document.getElementById("circle");
		data?.length > 0 && draw(svgComp);
		// eslint-disable-next-line
	}, [currentShade]);

	useEffect(() => {
		console.log("shade", shade);
		setCurrentShade(shade);
		// eslint-disable-next-line
	}, [shade]);

	const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
		const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
		return {
			x: centerX + radius * Math.cos(angleInRadians),
			y: centerY + radius * Math.sin(angleInRadians),
		};
	};

	const describeArc = (x, y, radius, startAngle, endAngle) => {
		const start = polarToCartesian(x, y, radius, endAngle);
		const end = polarToCartesian(x, y, radius, startAngle);
		const arcSweep = endAngle - startAngle <= 180 ? "0" : "1";
		const d = [
			"M",
			start.x,
			start.y,
			"A",
			radius,
			radius,
			0,
			arcSweep,
			0,
			end.x,
			end.y,
		].join(" ");

		return d;
	};

	const draw = (svgComp) => {
		let prevStartAngle = 0,
			prevEndAngle = 0,
			segment = "",
			i;

		if (offset === 0 && totalSegments === 1) {
			segment = '<circle cx="160" cy="160" r="130"';
			segment += filledSegments === 1 ? ' class="filled" />' : " />";
		} else {
			for (i = 0; i < totalSegments; i++) {
				let id = `filled${i} `;
				let cls = `filled ${className}`;
				prevStartAngle = prevEndAngle + offset;
				prevEndAngle = values[i] + prevEndAngle;
				segment += `<path id=${id}`;
				segment += filledSegments >= i ? `class="${cls} " ` : "";
				segment += 'd="';
				segment += describeArc(
					160,
					160,
					130,
					prevStartAngle,
					prevEndAngle
				);
				segment += '"/>';
			}
		}

		if (showData) {
			segment += `<circle cx="160" cy="160" r="105" class="inner-circle" />`;
			segment += `<text class="circle-text" x="50%" y="45%" dy=".1em" text-anchor="middle">${truncate(
				currentShade?.securityLabel || " ",
				20
			)}</text>`;
			segment += `<text class="circle-text-bold " x="50%" y="55%" dy=".3em" text-anchor="middle">${
				currentShade?.valuation ?? 0
			}</text>`;
		}

		svgComp.innerHTML = segment;
	};

	return (
		<div className="doughnut">
			<svg
				id="circle"
				viewBox="0 0 320 320"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
			/>
		</div>
	);
};

export default Doughnut;
