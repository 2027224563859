import React from "react";

const TableCell = ({ cellValues, tag }) => {
	return React.createElement(
		tag,
		{ className: `text-nowrap ${tag === "th" ? "text-capitalize" : ""}` },
		cellValues
	);
};

export default TableCell;
