import React from "react";
import {
	AccordionSummary,
	AccordionDetails,
	Accordion,
} from "@material-ui/core";
import { ReactComponent as CaretUp } from "assets/images/icons/caret-up.svg";
import { colors, toCurrency } from "utils/helpers";

const AssetAllocationAccordion = ({
	index,
	data,
	id,
	expanded,
	handleToggle,
}) => {
	const { securityLabel, quantityHeld, valuation } = data || {};
	const open = expanded === id;

	return (
		<Accordion expanded={open} onChange={handleToggle(id)}>
			<AccordionSummary
				className="p-0"
				expandIcon={<CaretUp className="rot-180" />}
			>
				<div
					className="rounded-1 me-4"
					style={{
						width: "20px",
						height: "20px",
						background: colors[index],
					}}
				/>
				<span>{securityLabel}</span>
			</AccordionSummary>

			<AccordionDetails>
				<div className="flex__between">
					<p>Allocation</p>
					<p>{quantityHeld}</p>
				</div>
				<div className="flex__between">
					<p>Value</p>
					<p>NGN {toCurrency(valuation)}</p>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default AssetAllocationAccordion;
