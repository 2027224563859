import React from "react";

const CustomIpnutBox = ({
	type = "text",
	name,
	label,
	placeholder,
	value,
	defaultValue,
	readonly,
	error,
	icon,
	iconText,
	onChange,
	onBlur,
	onFocus,
	required,
	question,
	cls,
	disabled,
	...rest
}) => {
	const isError = error ? "error" : " ";
	const isRequired = required ? "required" : " ";
	const isDisabled = disabled ? "disabled" : " ";

	return (
		<div className={`form-group ${cls}`}>
			{label && (
				<label
					htmlFor={name}
					className={`form-group-label ${isRequired}`}
				>
					{label}
					{question && <div className="question">?</div>}
				</label>
			)}
			<div
				className={`input-wrapper d-flex align-items-center ${isDisabled} ${isError} `}
			>
				{icon && <div className="px-4">{icon}</div>}
				<input
					type={type}
					// id={name}
					name={name}
					placeholder={placeholder}
					value={value}
					defaultValue={defaultValue}
					readOnly={readonly}
					autoComplete="off"
					disabled={disabled}
					onChange={onChange}
					onBlur={onBlur}
					step="any"
					{...rest}
				/>
				{iconText && <div className="px-4">{iconText}</div>}
			</div>
			{typeof error === "string" && (
				<small className="input-text-error">{error}</small>
			)}
		</div>
	);
};

export default CustomIpnutBox;
