import { getCommissions } from "components/Dashboard/common/helpers";
import React from "react";
import { getBalance, toCurrency } from "utils/helpers";

const QuickTradeBalance = ({ account, order }) => {
	const { cashBalance, totalBalance, margin_details } = getBalance();
	const { quantity, name } = order || {};
	const totalAMount = (quantity || 1) * (name?.price || 1);
	const { commission, fees } = getCommissions({
		type: order?.type,
		amount: totalAMount,
	});
	const total = (quantity || 1) * (name?.price || 1) + commission + fees;

	return (
		<div className="border rounded p-3">
			{account?.margin && (
				<p className="flex__between border-bottom pb-3">
					Margin Purchasing Power{" "}
					<b className="ms-auto">{`NGN ${toCurrency(
						margin_details?.trading_power
					)}`}</b>
				</p>
			)}
			<p className="flex__between border-bottom pb-3">
				Total Balance{" "}
				<b className="ms-auto">{`NGN ${toCurrency(totalBalance)}`}</b>
			</p>

			<p className="flex__between border-bottom pb-3">
				Available Balance{" "}
				<b className="ms-auto">{`NGN ${toCurrency(cashBalance)}`}</b>
			</p>

			{name && (
				<p className="flex__between border-bottom pb-3">
					Stock Price{" "}
					<b className="ms-auto">{`NGN ${toCurrency(
						name?.price
					)}`}</b>
				</p>
			)}

			{quantity && (
				<p className="flex__between border-bottom pb-3">
					Quantity <b className="ms-auto">{quantity}</b>
				</p>
			)}

			{name && (
				<p className="flex__between border-bottom pb-3">
					Estimated Commission{" "}
					<b className="ms-auto">N{toCurrency(commission)}</b>
				</p>
			)}

			{name && (
				<p className="flex__between border-bottom pb-3">
					Estimated Fee <b className="ms-auto">N{toCurrency(fees)}</b>
				</p>
			)}

			{name && (
				<p className="flex__between mb-0">
					Estimated Total{" "}
					<b className="ms-auto">{`NGN ${toCurrency(total)}`}</b>
				</p>
			)}
		</div>
	);
};

export default QuickTradeBalance;
