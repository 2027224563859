import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSubscription } from "@apollo/client";
import { createChart } from "lightweight-charts";
import { areaOptions, mainChartOptions } from "../data";
import { STOCK_DAY_SERIES } from "api/subscription/investment";
import { getDateRange, timeDateFormat } from "utils/helpers";
import { getData } from "../helpers";
import AppContext from "components/Dashboard/common/AppContext";
import ChartOverlay from "utils/ChartOverlay";

const switchers = ["ID", "1W", "1M", "3M", "6M", "1Y", "5Y", "All Time"];

const MarketUpdateChart = ({ stockName, hideSwitch }) => {
	const [time, setTime] = useState(switchers[3]);

	const { mode } = useContext(AppContext);

	const history = useHistory();
	let { name } = useParams();
	name = name || stockName;

	const chartContainerRef = useRef();
	const chart = useRef();
	const series = useRef();

	const { data, loading } = useSubscription(STOCK_DAY_SERIES, {
		variables: { name, time: timeDateFormat(getDateRange(time)).date },
	});
	const { stock_day_series } = data || {};

	useEffect(() => {
		!name && history.goBack();
		chart.current = createChart(
			chartContainerRef.current,
			mainChartOptions(mode)
		);
		series.current = chart.current.addAreaSeries(areaOptions);
		series.current.setData(getData(stock_day_series) || []);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (stock_day_series) {
			const stockData = getData(stock_day_series);
			series.current.setData([...stockData]);
			stock_day_series?.length < 100 &&
				chart.current.timeScale().fitContent();
		}
		// eslint-disable-next-line
	}, [stock_day_series, name]);

	return (
		<div className="port__summary">
			<div ref={chartContainerRef} className="chart__wrap">
				<ChartOverlay loading={loading} data={stock_day_series} />
			</div>

			{!hideSwitch && (
				<div className="flex__between ">
					<div className="switchers">
						{switchers?.map((btn) => (
							<p
								onClick={() => setTime(btn)}
								className={btn === time ? "active" : ""}
							>
								{btn}
							</p>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default MarketUpdateChart;
