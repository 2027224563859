import React, { useState } from "react";
import { useSubscription } from "@apollo/client";
import { GET_STOCKS } from "api/subscription/investment";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";
import SearchForm from "utils/CustomInputBox/SearchForm/AsyncSearchForm";
import InvestmentCategories from "../InvestmentCategories/InvestmentCategories";
import InvestmentNav from "../InvestmentNav/InvestmentNav";
import InvestmentTable from "../InvestmentTable/InvestmentTable";
import { fetchUserData } from "storage/sessionStorage";
import Toast from "utils/Alert/Toast/Toast";
import { EQUITY, MARGIN, ERROR } from "../../../../constants";

const InvestmentWrapper = ({ history, type, noTable, children }) => {
	let { portfolio_accounts } = fetchUserData();
	const account = portfolio_accounts?.length ? portfolio_accounts?.[0] : {};
	const { exchange: exch, name, margin } = account;
	const [sector, setSector] = useState("");
	const [exchange, setExchange] = useState(
		`${margin ? MARGIN : exch}|${name}`
	);
	const exchangeName = exchange?.split("|");

	const getPayload = () => {
		let marginable = exchangeName?.[0] === MARGIN;
		const filters = {
			type: { _iregex: type },
			sector: { _iregex: sector },
		};
		marginable && (filters.marginable = { _eq: marginable });
		!marginable && (filters.exchange = { _iregex: exchangeName?.[0] });
		return { filters };
	};

	const { loading, error, data } = useSubscription(GET_STOCKS, {
		fetchPolicy: "no-cache",
		variables: getPayload(),
	});

	return (
		<>
			{error && !noTable && (
				<Toast msg="Error Processing Request" variant={ERROR} />
			)}

			<div className="dash__content full__screen">
				<div
					className="row align-items-start justify-content-between"
					style={{ maxHeight: "55px" }}
				>
					<div className="col-md-9 col-12">
						<span className="d-flex align-items-center form-group">
							<BackIcon
								height="20px"
								width="20px"
								onClick={() => history.goBack()}
								className="pointer"
							/>
							<span className="heading2 ms-4">
								Select an option
							</span>
						</span>
					</div>
					<div className="col-md-3 d-md-block d-none">
						<SearchForm history={history} />
					</div>
				</div>

				{type === EQUITY && (
					<InvestmentCategories setSector={setSector} />
				)}

				<InvestmentNav
					history={history}
					tabName={type}
					setExchange={setExchange}
					exchange={exchange}
					accounts={portfolio_accounts}
				/>

				{noTable ? (
					<>{children}</>
				) : (
					<>
						<InvestmentTable
							loading={loading}
							datas={data?.stock}
							history={history}
							account={exchangeName?.[1]}
						/>
						{!loading && data?.stock?.length === 0 && (
							<p className="heading3 text-danger text-center my-5">
								No Record Found!!!
							</p>
						)}
					</>
				)}
			</div>
		</>
	);
};

export default InvestmentWrapper;
