import React, { useContext } from "react";
import { connect } from "react-redux";
import { Modal as BootstrapModal, ModalBody } from "reactstrap";
import ModalHeader from "./ModalHeader/ModalHeader";
import { DARK_MODE } from "components/constants";
import AppContext from "components/Dashboard/common/AppContext";
import "./Modal.scss";

const Modal = ({
	show,
	type,
	toggle,
	children,
	title,
	text,
	cls,
	size = "md",
	onEnter,
}) => {
	const { mode } = useContext(AppContext);
	const theme = mode.meriMode === DARK_MODE ? "meri__night" : "";

	return (
		<BootstrapModal
			className={cls}
			isOpen={show}
			size={size}
			onEnter={onEnter}
			modalClassName={theme}
		>
			<ModalHeader
				type={type}
				toggle={toggle}
				title={title}
				text={text}
			/>
			<ModalBody>{children}</ModalBody>
		</BootstrapModal>
	);
};

export default connect(({ mode }) => ({ mode }))(Modal);
