import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { ReactComponent as Alert } from "assets/images/icons/tri-alert.svg";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { ENABLE_MFA } from "api/mutations/account";
import { REQUEST_OTP } from "api/mutations/auth";
import { getError } from "utils/helpers";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "../../../../../constants";

const EnableMFAForm = ({ mfa, toggle, nextStep }) => {
	const [errorMsg, setErrorMsg] = useState();

	const [enableMFA, { loading }] = useMutation(ENABLE_MFA, {
		onCompleted: () => nextStep(),
		onError: (error) => {
			setErrorMsg(getError(error));
		},
	});

	const [requestOtp, { loading: qloading }] = useMutation(REQUEST_OTP, {
		onCompleted: () => nextStep(),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleSubmit = () => {
		mfa
			? enableMFA({ variables: { mfa } })
			: requestOtp({
					variables: { data: { mutation: "update_user" } },
			  });
	};

	return (
		<div className="text-center px-3 pb-3">
			<Toast msg={errorMsg} variant={ERROR} />

			<Alert className="my-5" />

			<p className="fw-bold">
				{`Are you sure you want to ${
					mfa ? "enable" : "disable"
				} 2FA for this account?`}
			</p>

			<div className="flex__between mt-5 px-3">
				<button
					className="btn btn--lg btn--primary--bordered me-3"
					onClick={toggle}
					disabled={loading || qloading}
				>
					No
				</button>
				<button
					className="btn btn--lg btn--primary ms-3"
					onClick={handleSubmit}
					disabled={loading || qloading}
				>
					{loading || qloading ? <Spinner /> : "Yes"}
				</button>
			</div>
		</div>
	);
};

export default EnableMFAForm;
