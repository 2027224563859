import React from "react";
import DashboardPortfolios from "../../common/DashboardPortfolios/DashboardPortfolios";
import TopButtons from "../../DashContent/DashContentRightView/TopButtons/TopButtons";

const WatchListRightView = ({ history }) => {
	return (
		<div className="col-xl-3 col-lg-4 right__view d-lg-block d-none">
			<TopButtons />
			<DashboardPortfolios history={history} />
		</div>
	);
};

export default WatchListRightView;
