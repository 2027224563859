import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import FooterLink from "components/Auth/common/FooterLink/FooterLink";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import Disclaimer from "components/Onboarding/utils/Disclaimer/Disclaimer";
import { NORMAL } from "components/constants";
import { REQUEST_OTP } from "api/mutations/auth";
import { getError } from "utils/helpers";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "components/constants";

const NINVerificationForm = ({
	history,
	updateAccountInfo,
	updateSignupOption,
	accountInformation,
	showOTPForm,
}) => {
	const [terms, setTerms] = useState(false);
	const [formErrors, setFormErrors] = useState({});
	const [errorMsg, setErrorMsg] = useState();

	const { nin } = accountInformation || {};

	const [requestOTP, { loading }] = useMutation(REQUEST_OTP, {
		onCompleted: ({ request_otp }) => {
			updateAccountInfo({ ...request_otp });
			showOTPForm();
		},
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleNIN = ({ target: { value } }) => {
		value = value.replace(/[^0-9]/g, "");
		updateAccountInfo({ nin: value });
		setFormErrors();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator({ nin });
		isValid
			? requestOTP({
					variables: { data: { nin, phone: "2348068686868" } },
			  })
			: setFormErrors(formErrors);
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<div className="px-sm-5 px-0">
				<div className="col-12 px-4 mx-3 mb-4">
					<p className="heading4 ms-n2 mb-4">Enter your NIN</p>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="row">
						<div className="col-12 px-5">
							<CustomInputBox
								name="nin"
								label="NIN"
								placeholder="Enter your NIN here"
								value={nin}
								onChange={handleNIN}
								error={formErrors?.nin}
								maxLength={11}
								required
							/>
						</div>
						<div className="col-lg-7 col-12 mt-n3 px-5">
							<div className="my-2">
								<CustomCheckBox
									label={
										<>
											<small>
												I agree with the
												<a
													href="https://meristemng.com/docs/MSBL_TandC.pdf"
													target="_blank"
													rel="noopener noreferrer"
													className="px-1"
												>
													<strong className="text--primary">
														Terms of Service
													</strong>
												</a>
												and
												<a
													href="https://meristemng.com/docs/Privacy_Notice.pdf"
													target="_blank"
													rel="noopener noreferrer"
													className="px-1"
												>
													<strong className="text--primary">
														Privacy Policy
													</strong>
												</a>
											</small>
										</>
									}
									checked={terms}
									onClick={() => {
										setTerms((prev) => !prev);
									}}
								/>
							</div>
						</div>

						<div className="col-lg-6 col-12 pt-4 px-5">
							<button
								className="btn btn--lg btn--primary"
								disabled={
									nin?.length !== 11 || !terms || loading
								}
							>
								{loading ? <Spinner /> : "Continue"}
							</button>
						</div>
						<div className="col-lg-6 px-5 col-12 pt-3 text--gray">
							<div className="text-end">
								<Disclaimer history={history} />
							</div>
						</div>
					</div>
				</form>

				<FooterLink
					text="Can’t find your NIN?"
					link={() => updateSignupOption(NORMAL)}
					col="col-sm-11"
				/>
			</div>
		</>
	);
};

export default NINVerificationForm;
