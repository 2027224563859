import React, { useState } from "react";
import { useSelector } from "react-redux";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";
import WithdrawalFormModal from "../../modal/WithdrawalFormModal/WithdrawalFormModal";
import { fetchUserData } from "storage/sessionStorage";
import IneligibleWithdrawalModal from "../../modal/IneligibleWithdrawalModal/IneligibleWithdrawalModal";
import { getBalance } from "utils/helpers";
import { formatFloatInput } from "utils/helpers";
import { toCurrency } from "utils/helpers";

const WithdrawalForm = () => {
	const { completedUnchanged } = useSelector(({ kyc }) => kyc?.status || {});

	const [formErrors, setFormErrors] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [kycPending, setkycPending] = useState(false);

	const { cashBalance } = getBalance();

	let { settlement_account, account, admin_config } = fetchUserData();
	account = account.length ? account[0] : {};
	settlement_account = settlement_account?.filter(
		({ reference }) => reference === account?.settlement_account_reference
	);
	const maxWithdrawalAmount = admin_config?.find(
		(config) => config?.name === "maximum_automated_withdrawal"
	);
	const minAmount = `Minimum Withdrawal Amount is N${toCurrency(500)}`;
	const maxAmount = `Maximum Withdrawal Amount is N${toCurrency(
		maxWithdrawalAmount?.number_value
	)}`;

	const [formattedAmount, setFormattedAmount] = useState();
	const [request, setRequest] = useState({
		amount: "",
		account: settlement_account ? settlement_account[0] : "",
	});

	const handleChange = ({ target: { name, value } }) => {
		value = formatFloatInput(value);
		const formatted = parseFloat(value.replace(/,/g, ""));
		handleSelect(name, formatted);
		setFormattedAmount(value);
		formatted > cashBalance &&
			handleError("amount", `Maximum amount of your available exceeded`);
	};

	const handleSelect = (name, value) => {
		setRequest((prev) => ({
			...prev,
			[name]: value,
		}));
		handleError(name);
	};

	const handleError = (name, value = "") => {
		setFormErrors((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator(request);
		if (isValid) {
			if (completedUnchanged)
				request.amount >= 500 &&
				request.amount <= maxWithdrawalAmount.number_value
					? setShowModal(true)
					: setFormErrors((prev) => ({
							...prev,
							amount:
								request.amount < 500 ? minAmount : maxAmount,
					  }));
			else setkycPending(true);
		} else setFormErrors(formErrors);
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className="pt-3">
				<CustomSelect
					name="account"
					label="Select Withdrawal Account"
					placeholder="Type here"
					value={[request?.account]}
					onChange={(e) => handleSelect("account", e)}
					error={formErrors?.account}
					options={settlement_account}
					getOptionLabel={({ account_number, bank_name }) =>
						`${account_number} - ${bank_name}`
					}
					required
				/>
			</div>

			<div className="pt-3">
				<CustomInputBox
					name="amount"
					label="Enter Amount"
					placeholder="Type here"
					value={formattedAmount}
					onChange={handleChange}
					error={formErrors?.amount}
					max={maxWithdrawalAmount?.number_value}
					required
				/>
			</div>

			<Info message={minAmount} />
			{maxWithdrawalAmount && <Info message={maxAmount} />}
			<Info
				message="It may take up to 24hrs for the Bank to process your
					transaction"
			/>

			<div className="col-sm-4 col-12 mt-5">
				<button
					className="btn btn--lg btn--primary"
					disabled={request.amount > cashBalance}
				>
					Continue
				</button>
			</div>

			{showModal && (
				<WithdrawalFormModal
					request={request}
					toggle={() => setShowModal(false)}
				/>
			)}

			{kycPending && (
				<IneligibleWithdrawalModal
					toggle={() => setkycPending(false)}
				/>
			)}
		</form>
	);
};

const Info = ({ message }) => (
	<div className="d-flex text--gray mb-4 mt-4">
		<span className="small-circle mb-1 me-2 smallest bg--primary">i</span>
		<span className="fw-bold ">{message}</span>
	</div>
);

export default WithdrawalForm;
