import { HEADLESS_CLOSE_ROUNDED } from "components/constants";
import React from "react";
import Modal from "utils/Modal/Modal";
import ReferralContent from "./ReferralContent/ReferralContent";

const ReferralModal = ({ toggle, referralLink }) => {
    return (
        <Modal show={true} type={HEADLESS_CLOSE_ROUNDED} toggle={toggle}>
            <ReferralContent referralLink={referralLink} />
        </Modal>
    );
};

export default ReferralModal;
