import React from "react";
import { connect } from "react-redux";
import { Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "assets/images/icons/logo2.svg";
import { ReactComponent as Logo2 } from "assets/images/icons/Logo-white.svg";
import NavDropDropdown from "../NavDropDropdown/NavDropDropdown";
import OthersMenu from "./OthersMenu/OthersMenu";
import { DARK_MODE } from "components/constants";
import Routes from "../../routes";

const HeaderMenu = ({ history, mode }) => {
	const CurrentLogo = mode.meriMode === DARK_MODE ? Logo2 : Logo;

	const getMenuButtons = () => (
		<div className="menu__button meri__night">
			{Routes.map(
				({ name, path, dropdown, hide = false, hideOnDesktop }) => (
					<div key={name} className="d-inline-block ">
						{!hideOnDesktop && (
							<>
								{dropdown ? (
									<NavDropDropdown
										title={name}
										dropdown={dropdown}
										history={history}
									/>
								) : (
									!hide && (
										<Link className="menu__link" to={path}>
											{name}
										</Link>
									)
								)}
							</>
						)}
					</div>
				)
			)}
			<OthersMenu history={history} />
		</div>
	);

	return (
		<Toolbar>
			<CurrentLogo
				className="mt-n4 pointer"
				onClick={() => history.push("/dashboard")}
			/>
			<div>{getMenuButtons()}</div>
		</Toolbar>
	);
};

export default connect(({ mode }) => ({ mode }))(HeaderMenu);
