import React from "react";
import DashboardPortfolios from "../../../../common/DashboardPortfolios/DashboardPortfolios";
import MarketStatus from "./MarketStatus/MarketStatus";

const MoneyMarketRightview = ({ history }) => {
	return (
		<div className="col-xl-3 col-lg-4 right__view d-lg-block d-none">
			<MarketStatus />
			<DashboardPortfolios history={history} />
		</div>
	);
};

export default MoneyMarketRightview;
