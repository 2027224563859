import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Route, Switch, withRouter } from "react-router";
import { BootIntercom } from "../Dashboard/Popups/extra";
import Routes from "./routes";
import "./Auth.scss";

const Authentication = () => {
	useEffect(() => {
		BootIntercom();
	}, []);

	const getRoutes = () => (
		<Switch>
			{Routes.map((route, i) => (
				<Route
					key={i}
					path={route.path}
					exact={route.exact}
					component={route.component}
				/>
			))}
			<Route exact path="*" component={() => <Redirect to="/auth/login" />} />
		</Switch>
	);

	return (
		<div className="row onboarding auth">
			<div className="col-lg-7 col-12 h-100">{getRoutes()}</div>

			<div className="col-lg-5 d-lg-block d-none auth__right__view" />
		</div>
	);
};

export default withRouter(Authentication);
