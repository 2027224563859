import React, { useState } from "react";
import { portfolioMockData } from "../../portfolioMockdata";
import Table from "utils/Table/Table";
import PortfolioCard from "./PortfolioCard/PortfolioCard";
import { PLUS } from "../../../../constants";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";
import InvestmentTableActions from "../../../Investment/common/InvestmentTable/InvestmentTableActions/InvestmentTableActions";
import { TableLoader } from "utils/TableLoader/TableLoader";
import { dailyOptions, portfolioTableHeading, totalOptions } from "../../enums";
import { toCurrency } from "utils/helpers";

const PortfolioTable = ({ history, loading, portfolio = [], account }) => {
	const [expanded, setExpanded] = useState(false);
	const [dailyOption, setDailyOption] = useState(dailyOptions[0]);
	const [totalOption, setTotalOption] = useState(totalOptions[0]);

	const handleToggle = (panel) => (e, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const getTableData = () => {
		const getColor = (gain) => (gain >= 0 ? "text-success" : "text-danger");
		return portfolio?.map((port) => ({
			name: port?.securityLabel,
			id: port?.securityName,
			lastPrice: toCurrency(port?.marketPrice),
			quantity: port?.quantityHeld,
			pricePaid: toCurrency(port?.costBasis),
			currentValue: toCurrency(port?.marketValue),
			totalCost: toCurrency(
				port?.quantityHeld * port?.costBasis * port?.priceValueMultiple
			),
			dailyPL: (
				<span
					className={getColor(
						port?.stock?.price_change * port?.quantityHeld
					)}
				>
					{toCurrency(port?.stock?.price_change * port?.quantityHeld)}
				</span>
			),
			dailyPL2: (
				<span className={getColor(port?.stock?.price_change_p)}>
					{toCurrency(port?.stock?.price_change_p)}
				</span>
			),
			totalPL: (
				<span className={getColor(port?.gain)}>
					{toCurrency(port?.gain)}
				</span>
			),
			totalPL2: (
				<span className={getColor(port?.percentGain)}>
					{port?.percentGain}
				</span>
			),
			action: (
				<InvestmentTableActions
					stock={{ ...port, account }}
					loading={loading}
					hideWatchlist
				/>
			),
		}));
	};

	const tableData = loading ? TableLoader(12) : getTableData();

	const rowClick = ({ id }, { target }) => {
		const selectEvent = target?.id.includes("react-select");
		const val = portfolio?.find(({ name }) => name === id);
		!loading &&
			!selectEvent &&
			history.push({
				pathname: `/market/${id}`,
				state: { currency: val?.currency, label: val?.name },
			});
	};

	return (
		<>
			<div className="d-md-block d-none">
				<Table
					data={tableData}
					headings={portfolioTableHeading}
					cls="full__height faint__bg"
					rowClick={rowClick}
				/>

				{!loading && portfolio?.length === 0 && (
					<p className="heading3 text-danger text-center my-5">
						No Record Found!!!
					</p>
				)}
			</div>
			<div className="d-md-none d-block">
				<div className="d-flex align-items-center w-100 fw-bold bg__gray p-3">
					<span className="w-100">Name</span>
					<div className="borderless" style={{ minWidth: "85px" }}>
						<SmallSelect
							nocase
							value={dailyOptions}
							onChange={({ target }) =>
								setDailyOption(target?.value)
							}
							options={dailyOptions}
						/>
					</div>
					<div className="borderless" style={{ minWidth: "85px" }}>
						<SmallSelect
							nocase
							value={totalOptions}
							onChange={({ target }) =>
								setTotalOption(target?.value)
							}
							options={totalOptions}
						/>
					</div>
				</div>

				{portfolioMockData?.map((data, i) => (
					<PortfolioCard
						id={`port${i}`}
						key={`port${i}`}
						data={data}
						expanded={expanded}
						type={PLUS}
						dailyOption={dailyOption}
						totalOption={totalOption}
						handleToggle={handleToggle}
					/>
				))}
			</div>
		</>
	);
};

export default PortfolioTable;
