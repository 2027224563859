import React, { useState, useEffect } from "react";
import "./RangeSelect.scss";

const allRange = [0, 4, 2, 1.33, 1];

const RangeSelect = ({ price, bal, handleClick, reset, isSell }) => {
	const [range, setRange] = useState(0);
	const [temp, setTemp] = useState(0);

	useEffect(() => {
		handleSelect(0);
	}, [reset]);

	const getQuantity = (i) => {
		if (allRange[i] === 0 || price === 0) return 1;

		const percentageValue = bal / allRange[i];

		if (isSell) {
			return percentageValue <= 0 ? 1 : parseInt(percentageValue);
		}
		if (percentageValue < price) return 0;

		return parseInt(percentageValue / price);
	};

	const handleSelect = (i) => {
		setRange(i);
		setTemp(i);
	};

	const handleMouseAction = (i) => {
		const qty = getQuantity(i);
		setRange(i);
		handleClick("quantity", qty);
	};

	return (
		<>
			<div className="flex__between mb-3">
				{Array(5)
					.fill()
					.map((_, i) => (
						<>
							<div
								className={`range__dot ${
									range >= i ? "primary" : " "
								}`}
								onClick={() => handleSelect(i)}
								onMouseOver={() => handleMouseAction(i)}
								onMouseOut={() => handleMouseAction(temp)}
							>
								<div className="small__dot" />
							</div>

							{i < 4 && <div className="dot-line" />}
						</>
					))}
			</div>
			<div className="flex__between fw-bold text--gray ">
				<p>0%</p>
				<p className="ms-4">25%</p>
				<p className="ms-2">50%</p>
				<p className="ms-4">75%</p>
				<p>100%</p>
			</div>
		</>
	);
};

export default RangeSelect;
