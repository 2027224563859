import React, { useState } from "react";
import { ReactComponent as Coin } from "assets/images/icons/coin.svg";
import { ReactComponent as ForwardIcon } from "assets/images/icons/forward.svg";
import { getBalance, toCurrency } from "utils/helpers";

const BalanceBox = ({ history }) => {
	const [balance, setBalance] = useState(true);

	const { cashBalance, totalBalance } = getBalance();

	const dot = balance ? "bg-white" : "border";
	const dot2 = !balance ? "bg-white" : "border";

	const toggleBal = () => setBalance((prev) => !prev);

	const showBal = () =>
		balance ? (
			<>
				<strong>Total Valuation</strong>
				<p className="heading2 m-0">N{toCurrency(totalBalance || 0)}</p>
			</>
		) : (
			<>
				{" "}
				<strong>Total Cash Balance</strong>
				<p className="heading2 m-0">N{toCurrency(cashBalance || 0)}</p>
			</>
		);

	return (
		<div className="balance__box">
			<div className="flex__between">
				<Coin className="coin" />
				<div className="text-start">{showBal()}</div>
				<div className="d-flex d-lg-flex d-none ">
					<button
						className="btn btn--sm btn--primary-grad me-4 px-5"
						onClick={() => history.push("/banking")}
					>
						Fund Wallet
					</button>
					<button
						className="btn btn--sm btn--primary-grad px-5"
						onClick={() => history.push("/banking/withdrawal")}
					>
						Withrawal
					</button>
				</div>
				{/* <div className="d-flex">
					<div className="icons rounded-circle bg-white flex__center me-3 ">
						<BrailleDot />
					</div> */}

				<ForwardIcon className="icons" onClick={toggleBal} />
			</div>

			<div className="flex__center mt-2">
				<div className={`small-circle dot me-2 ${dot}`} />
				<div className={`small-circle dot ${dot2}`} />
			</div>
		</div>
	);
};

export default BalanceBox;
