import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Iframe from "react-iframe";
import { getMode } from "storage/localStorage";
import { updateMode } from "redux/actions/modeActions";
import Auth from "../Auth/Auth";
import { DARK_MODE } from "../constants";
import Dashboard from "../Dashboard/Dashboard";
import Onboarding from "../Onboarding/Onboarding";
import PrivateRoutes from "../Routes/PrivateRoutes";
import PublicRoutes from "../Routes/PublicRoutes";
import AppContext from "components/Dashboard/common/AppContext";

const App = ({ mode, updateMode }) => {
	const [checkoutData, setCheckoutData] = useState({});

	const { url, handleRefetch } = checkoutData;

	const theme = mode.meriMode === DARK_MODE ? "meri__night" : "";

	useEffect(() => {
		updateMode({ meriMode: getMode() });
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("message", receiveMessage, false);
		}
		// eslint-disable-next-line
	});

	// Check Checkout Event
	const receiveMessage = async (event) => {
		const { data: response } = event || {};

		// if (origin === process.env.NEXT_PUBLIC_CHECKOUT_BASE_URL) {
		const { name } = response || {};

		if (name === "close") setCheckoutData({});
		else if (name === "done") {
			handleRefetch && handleRefetch();
			setCheckoutData({});
		}
	};

	return (
		<AppContext.Provider value={{ mode, setCheckoutData }}>
			<div className={theme}>
				<BrowserRouter>
					<Switch>
						<Route
							exact
							path="/onboarding"
							component={Onboarding}
						/>

						<PublicRoutes path="/auth" component={Auth} />

						<PrivateRoutes path="/" component={Dashboard} />

						<Route
							exact
							path="*"
							component={() => <h1>Page Not Found</h1>}
						/>
					</Switch>
				</BrowserRouter>
				{url && <Iframe url={url} id="payment" />}
			</div>
		</AppContext.Provider>
	);
};

export default connect(({ mode }) => ({ mode }), { updateMode })(App);
