import React from "react";

const TableError = ({
	btn,
	btnRoute,
	errorMsg = "No Record Found!!!",
	realBtn,
}) => {
	return (
		<>
			<p className="heading3 text-danger text-center my-5">{errorMsg}</p>
			{btn && (
				<button
					className="btn btn--sm btn--primary px-5 mx-auto"
					onClick={btnRoute}
				>
					{btn}
				</button>
			)}
			<div className="flex__center">{realBtn && realBtn}</div>
		</>
	);
};

export default TableError;
