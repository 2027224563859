import React, { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import { SEARCH_STOCK } from "api/queries/stock";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
import CustomInputBox from "../CustomInputBox";

const AsyncSearchForm = ({
	cls,
	history,
	placeholder = "search",
	getResult,
}) => {
	const [search, setSearch] = useState("");
	const [stocks, setStocks] = useState();

	const [searchStock] = useLazyQuery(SEARCH_STOCK, {
		onCompleted: ({ stock }) => setStocks(stock),
	});

	const debouncedSearch = React.useMemo(
		() => debounce((name) => searchStock({ variables: { name } }), 1000),
		// eslint-disable-next-line
		[]
	);

	const handleChange = ({ target: { value } }) => {
		setSearch(value);
		if (value) debouncedSearch(`%${value}%`);
		else {
			debouncedSearch.cancel();
			setStocks();
		}
	};

	const handleClick = (stock) => {
		const { name, currency } = stock || {};
		setStocks();
		getResult
			? getResult(stock)
			: history.push({
					pathname: `/market/${name}`,
					state: { currency, label: name },
			  });
	};

	return (
		<div className="search__form">
			<CustomInputBox
				cls={cls}
				name="search"
				type="search"
				value={search}
				placeholder={placeholder}
				icon={<SearchIcon />}
				onChange={handleChange}
			/>

			{stocks && (
				<div className="search__result">
					{stocks?.length === 0 ? (
						<div className="text-center py-3 fw-bold">
							No Result Found!
						</div>
					) : (
						<>
							{stocks?.map((stock, i) => (
								<div key={i}>
									<p
										className="py-1 pointer"
										onClick={() => handleClick(stock)}
									>
										{stock?.label}({stock?.name})
									</p>
									{i !== stocks?.length - 1 && (
										<hr className="border-gray" />
									)}
								</div>
							))}
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default AsyncSearchForm;
