import { DARK_MODE } from "../../components/constants";
import { SWITCH_MODE, UPDATE_MODE } from "../actionTypes/modeActionTypes";

const setTheme = (mode) => {
	const status = mode === DARK_MODE;
	localStorage.setItem("meriMode", mode);
	if (status) {
		document.body.classList.add("meri__night");
		document.body.style.background = "#011E11 ";
		document.body.style.color = "#ccc";
	} else {
		document.body.classList.remove("meri__night");
		document.body.style.background = "#ffffff ";
		document.body.style.color = "#071b2b";
	}
	return mode;
};

const switchMode = (mode) => (dispatch) => {
	setTheme(mode);
	dispatch({ type: SWITCH_MODE });
};

const updateMode = (mode) => (dispatch) => {
	const payload = setTheme(mode);
	dispatch({ type: UPDATE_MODE, payload });
};

export { switchMode, updateMode };
