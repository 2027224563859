import React from "react";
import { getBest } from "../../../../common/helpers";

const StockMarketBest = ({ stock }) => {
	const bestOffer = getBest(stock?.offer_levels);
	const bestBid = getBest(stock?.bid_levels);
	const trade = stock?.trades || [];
	const lastTrade = trade ? trade[trade?.length - 1] : {};

	return (
		<div className="col-md-4">
			<div className="dash__port div__wrapper mb-5 w-100">
				<List name="Best Bid Quantity" value={bestBid?.qty} />
				<List name="Bid Size" value={bestBid?.count} />
				<List name="Best Bid" value={bestBid?.price} />
				<List name="Best Depth" value={bestOffer?.qty} />
				<List name="Best Offer Quantity" value={bestOffer?.qty} />
				<List name="Best Offer" value={bestOffer?.price} />
				<List name="Offer Size" value={bestOffer?.count} />
				<List name="Average 10 Days Volume" value={0} />
				<List name="Last Trade Quantity" value={lastTrade?.volume} />
			</div>
		</div>
	);
};

const List = ({ name, value }) => (
	<div className="row px-4">
		<div className="col-10">
			<p>{name}</p>
		</div>
		<div className="col-2">
			<p>{value}</p>
		</div>
		<hr className="border-gray" />
	</div>
);

export default StockMarketBest;
