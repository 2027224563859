import React, { useState } from "react";
import AddMarketToWatchlistModal from "../../modal/AddMarketToWatchlistModal/AddMarketToWatchlistModal";
import { ReactComponent as Love } from "assets/images/icons/love.svg";

const AddMarketToWatchlistButton = ({
	useIcon,
	useText,
	stock,
	loading,
	handleClose,
}) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = (e) => {
		e.stopPropagation();
		setShowModal((prev) => !prev);
		handleClose && handleClose();
	};

	return (
		<>
			{useIcon ? (
				<Love className="pointer" onClick={loading ? "" : toggle} />
			) : useText ? (
				<span className="pointer" onClick={loading ? "" : toggle}>
					Add to watchlist
				</span>
			) : (
				<button
					className="btn btn--primary--bordered btn--lg "
					onClick={toggle}
					disabled={loading}
				>
					Add to Watchlist
				</button>
			)}

			{showModal && (
				<AddMarketToWatchlistModal stock={stock} toggle={toggle} />
			)}
		</>
	);
};

export default AddMarketToWatchlistButton;
