import { gql } from "@apollo/client";

export const GET_BANKS = gql`
	query getBanks {
		bank {
			code
			name
			sort_code
		}
	}
`;

export const RESOLVE_BANK = gql`
	query resolveBank($account_number: String!, $bank_code: String!) {
		resolve_bank(account_number: $account_number, bank_code: $bank_code) {
			account_name
			account_number
		}
	}
`;
