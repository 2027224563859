import React from "react";
import { fetchUserData } from "storage/sessionStorage";
import RemoveCardButton from "../../button/RemoveCardButton/RemoveCardButton";
import SetSettlementButton from "../../button/SetSettlementButton/SetSettlementButton";

const Card = ({ data }) => {
	const { BankIcon, account_number } = data || {};
	const { account_name, bank_name, reference } = data || {};
	let { account } = fetchUserData();
	account = account.length ? account[0] : {};
	const active = account.settlement_account_reference === reference;

	return (
		<section className="pb-5">
			<div className="cards">
				<div className="flex__between">
					<div className="">
						{BankIcon && <BankIcon />}{" "}
						<span className="heading3 text-white ms-3">
							{bank_name}
						</span>
					</div>
					<div className="text-end">
						<span className="fw-bold">{account_name}</span>
						<p className="heading3 text-white m-0">
							{account_number}
						</p>
					</div>
				</div>
			</div>
			<div className="flex__between mt-3">
				<SetSettlementButton active={active} reference={reference} />
				{!active && (
					<RemoveCardButton account_number={account_number} />
				)}
			</div>
		</section>
	);
};

export default Card;
