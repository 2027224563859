import CorporateBenefit from "./CorporateBenefit/CorporateBenefit";
import MarketOverview from "./MarketOverview/MarketOverview";
import PortfolioReport from "./PortfolioReport/PortfolioReport";
import ResearchNews from "./ResearchNews/ResearchNews";

const defaultRoutes = "/research";

const routes = [
	{
		name: "Corporate Benefit",
		path: defaultRoutes,
		component: CorporateBenefit,
		exact: true,
	},
	{
		name: "Market Overview",
		path: `${defaultRoutes}/market-overview`,
		component: MarketOverview,
		exact: true,
	},
	{
		name: "Portfolio Report",
		path: `${defaultRoutes}/portfolio`,
		component: PortfolioReport,
		exact: true,
	},
	{
		name: "News",
		path: `${defaultRoutes}/news`,
		component: ResearchNews,
		exact: true,
	},
];

export default routes;
