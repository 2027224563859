import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { ATTACH_BVN } from "api/mutations/onboarding";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import { ERROR } from "../../../../constants";

const AttachBvn = ({ updateRegStatus }) => {
	const [formError, setFormError] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const [bvn, setBvn] = useState("");

	const [attachBvn, { loading }] = useMutation(ATTACH_BVN, {
		onCompleted: ({ attach_bvn }) =>
			updateRegStatus({ bvn: attach_bvn?.status }),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleChange = ({ target: { value } }) => {
		value = value ? value?.replace(/[^0-9]/g, "") : "";
		setBvn(value);
		setFormError({ bvn: "" });
	};

	const handleAPICall = () => {
		setErrorMsg();
		attachBvn({ variables: { data: { bvn } } });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator({ bvn });
		isValid ? handleAPICall() : setFormError(formErrors);
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<form onSubmit={handleSubmit} className="px-5 pt-5">
				<CustomInputBox
					name="bvn"
					label="Enter your BVN"
					placeholder="type here"
					value={bvn}
					onChange={handleChange}
					error={formError?.bvn}
					maxLength={11}
					required
				/>

				<div className="row justify-content-center">
					<div className="col-sm-6 col-12">
						<div className="text-center pt-5">
							<button
								className="btn btn--lg btn--primary mt-5 mx-auto"
								disabled={bvn?.length !== 11 || loading}
							>
								{loading ? <Spinner /> : "Continue"}
							</button>

							<p
								className="heading3 pointer mt-3 text--primary"
								onClick={() => {
									updateRegStatus({ completed: true });
								}}
							>
								Skip
							</p>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};

export default AttachBvn;
