import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Table from "utils/Table/Table";
import { ReactComponent as AddIcon } from "assets/images/icons/add.svg";
import MoneyMarketHistoryFilters from "./MoneyMarketHistoryFilters/MoneyMarketHistoryFilters";
import MoneyMarketRightview from "../common/MoneyMarketWrapper/MoneyMarketWrapper";
import { GET_FIXED_INCOME } from "api/queries/fixedIncome";
import Toast from "utils/Alert/Toast/Toast";
import { timeDateFormat, toCurrency } from "utils/helpers";
import ViewMore from "utils/ViewMore/ViewMore";
import { ERROR } from "components/constants";
import { TableLoader } from "utils/TableLoader/TableLoader";
import { moneyMarketTableHeading, moneyMarkeyDefaultFilters } from "../helpers";
import { ALL } from "components/constants";

const MoneyMarketHistory = ({ history }) => {
	const [portfolio, setPortfolio] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");
	const [isToday, setIsToday] = useState(true);

	const [getPortfolio, { error: err, loading }] = useLazyQuery(
		GET_FIXED_INCOME,
		{
			fetchPolicy: "no-cache",
			onCompleted: ({ fixed_portfolio }) => setPortfolio(fixed_portfolio),
			onError: (error) => {
				setPortfolio([]);
				setErrorMsg(error);
			},
		}
	);

	useEffect(() => {
		const { status } = moneyMarkeyDefaultFilters;
		getPortfolio({
			variables: {
				...moneyMarkeyDefaultFilters,
				status: status === ALL ? "" : status,
			},
		});
		// eslint-disable-next-line
	}, []);

	const getTableData = () =>
		portfolio?.map((port) => ({
			label: <ViewMore title="Label" text={port?.label} />,
			princial: toCurrency(port?.faceValue),
			InterestRate: port?.currentRate,
			interets: toCurrency(port?.accruedInterest),
			expectedInterest: toCurrency(port?.expectedNetInterest),
			product: port?.instrumentTypeLabel,
			date: timeDateFormat(port?.startDate).date,
			status: port?.status,
		}));

	const tableData = loading ? TableLoader(6) : getTableData();

	return (
		<>
			{err && <Toast msg={errorMsg} variant={ERROR} />}

			<MoneyMarketRightview history={history}>
				<div className="flex__between">
					<span className="heading2">
						Money Market History{" "}
						<span>({isToday ? "Today" : "Date Range"})</span>
						<span> ({portfolio?.length || 0})</span>
					</span>
					<button
						className="btn btn--sm btn--primary px-4 d-md-block d-none"
						onClick={() => history.push("/investment/money-market")}
					>
						View Money Market
					</button>
					<AddIcon
						className="pointer d-md-none d-block"
						onClick={() => history.push("/investment/money-market")}
					/>
				</div>

				<MoneyMarketHistoryFilters
					setIsToday={setIsToday}
					getPortfolio={getPortfolio}
					currentFilters={moneyMarkeyDefaultFilters}
				/>

				<Table
					headings={moneyMarketTableHeading}
					data={tableData}
					cls="full__height faint__bg"
					rowClick={() => {}}
				/>
				{!loading && portfolio.length === 0 && (
					<>
						<p className="heading3 text-danger text-center my-5">
							No history found!. Click on the button below to
							create one
						</p>
						<button
							className="btn btn--sm btn--primary px-5 mx-auto"
							onClick={() =>
								history.push("/investment/money-market")
							}
						>
							Create Money Market
						</button>
					</>
				)}
			</MoneyMarketRightview>
		</>
	);
};

export default MoneyMarketHistory;
