import React from "react";
import Modal from "utils/Modal/Modal";
import MFAValidationForm from "./MFAValidationForm/MFAValidationForm";

const MFAValidationModal = ({ url, token, toggle, updateUsername }) => {
	return (
		<Modal show={true}>
			<MFAValidationForm
				url={url}
				token={token}
				toggle={toggle}
				updateUsername={updateUsername}
			/>
		</Modal>
	);
};

export default MFAValidationModal;
