import React, { useState, useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { ReactComponent as ToggleIcon } from "assets/images/icons/toggler.svg";
import { ReactComponent as Logo } from "assets/images/icons/logo2.svg";
import { ReactComponent as Caret } from "assets/images/icons/caret.svg";
// import { fetchUserData } from "storage/sessionStorage";
import OthersMenu from "../HeaderMenu/OthersMenu/OthersMenu";
import Routes from "../../routes";
import {
	Toolbar,
	IconButton,
	Drawer,
	List,
	ListItem,
	MenuItem,
	Collapse,
} from "@material-ui/core";

const Sidebar = ({
	drawerOpen,
	handleDrawerOpen,
	handleDrawerClose,
	history,
}) => {
	const [submenu, setSubmenu] = useState({});
	const { pathname: currentPath } = useLocation();

	// const { accounts } = fetchUserData();
	// const account = accounts ? accounts[0] : {};

	useEffect(() => {
		Routes.forEach(({ name, dropdown }) => {
			dropdown && handleInitialDropdown(name);
		});
	}, []);

	const activeRoute = (path, dropdown) => {
		const matchFunction = (pathVariable) =>
			matchPath(currentPath, {
				path: pathVariable,
				exact: false,
				strict: false,
			});
		let match = matchFunction(path);
		let isActive = dropdown
			? dropdown.find(
					(x) => x.path === currentPath || matchFunction(x.path)
			  )
			: false;
		return path === currentPath || isActive || match ? "active" : "";
	};

	const handleDropdown = (name) => {
		setSubmenu((prev) => ({
			...prev,
			[name]: !submenu[name],
		}));
	};

	const handleInitialDropdown = (name) => {
		setSubmenu((prev) => ({
			...prev,
			[name]: false,
		}));
	};

	const redirect = (path) => {
		handleDrawerClose();
		history.push(path);
		setSubmenu({});
	};

	const getDrawerChoices = () => (
		<List component="nav">
			{Routes.map(({ name, path, dropdown, hide }) => (
				<>
					{dropdown
						? getSubMenu(dropdown, name, path)
						: !hide && (
								<MenuItem
									className={activeRoute(path)}
									onClick={() => redirect(path)}
								>
									<span className="menu__title">{name}</span>
								</MenuItem>
						  )}
				</>
			))}
		</List>
	);

	const getSubMenu = (dropdown, title, path) => (
		<>
			<MenuItem
				button
				className={activeRoute(path)}
				onClick={() => handleDropdown(title)}
			>
				<span className="menu__title me-3">{title}</span>
				<Caret />
			</MenuItem>
			{dropdown.map(({ name, path, hide }) => {
				// if (name === "Margin Trade" && account?.margin === false) return "";
				return (
					!hide && (
						<Collapse
							in={submenu[title] || false}
							timeout="auto"
							unmountOnExit
						>
							<List component="div" disablePadding>
								<ListItem onClick={() => redirect(path)}>
									<span className="menu__title">{name}</span>
								</ListItem>
							</List>
						</Collapse>
					)
				);
			})}
		</>
	);

	return (
		<Toolbar>
			<div className="flex__between  w-100">
				<IconButton
					{...{
						edge: "start",
						color: "inherit",
						"aria-label": "menu",
						"aria-haspopup": "true",
						onClick: handleDrawerOpen,
					}}
				>
					<ToggleIcon />
				</IconButton>
				<OthersMenu history={history} />
			</div>

			<Drawer
				{...{
					anchor: "left",
					open: drawerOpen,
					onClose: handleDrawerClose,
				}}
			>
				<div>
					<Logo
						className="logo"
						onClick={() => redirect("/dashboard")}
					/>
					{getDrawerChoices()}
				</div>
			</Drawer>
		</Toolbar>
	);
};

export default Sidebar;
