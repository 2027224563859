import React from "react";
import { useQuery } from "@apollo/client";
import OnboardingHeader from "components/Onboarding/utils/OnboardingHeader/OnboardingHeader";
import StatusBar from "components/Onboarding/utils/StatusBar/StatusBar";
import InvestmentInformationForm from "./InvestmentInformationForm/InvestmentInformationForm";
import { GET_INVESTMENT_ENUMS } from "api/queries/others/investmentEnums";

const InvestmentInformation = ({ history }) => {
	const { data } = useQuery(GET_INVESTMENT_ENUMS);

	return (
		<>
			<OnboardingHeader title="Sign up to begin trading">
				<StatusBar />
			</OnboardingHeader>

			<InvestmentInformationForm history={history} enums={data || {}} />
		</>
	);
};

export default InvestmentInformation;
