import { gql } from "@apollo/client";

export const BOOK_FIXED_INCOME = gql`
	mutation AttachSettlement(
		$amount: Float!
		$instrument: Int!
		$roll_over_rule: String!
		$tenure: String!
	) {
		book_fixed_portfolio(
			amount: $amount
			instrument: $instrument
			roll_over_rule: $roll_over_rule
			tenure: $tenure
		) {
			message
			status
		}
	}
`;
