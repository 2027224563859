import React from "react";
import { TableLoader } from "utils/TableLoader/TableLoader";
import DownloadContract from "../DownloadContract/DownloadContract";
import { timeDateFormat, toCurrency } from "utils/helpers";
import Table from "utils/Table/Table";
import TableError from "utils/Table/TableError/TableError";
import ViewMore from "utils/ViewMore/ViewMore";
import { buyHeadings } from "../../extra";

const StatementTable = ({ loading, data }) => {
	const getTableData = () =>
		data?.map((v) => ({
			contractNo: v?.id,
			orderNo: v?.name,
			orderName: v.tradeOrderName,
			symbol: v?.symbol,
			label: <ViewMore text={v?.label} />,
			quantity: v?.quantity,
			currency: v.contractCurrency,
			amount: toCurrency(v?.contractAmount),
			price: toCurrency(v?.contractPrice),
			fee: toCurrency(v?.contractFee),
			settleDate: timeDateFormat(parseInt(v?.settlementDate)).date,
			date: timeDateFormat(parseInt(v?.transactionDate)).date,
			download: <DownloadContract id={v?.id} />,
		}));

	const tableData = loading ? TableLoader(8) : getTableData();

	return (
		<>
			<Table
				data={tableData}
				headings={buyHeadings}
				cls="faint__bg full__height "
				rowClick={() => {}}
			/>

			{!loading && data.length === 0 && <TableError />}
		</>
	);
};

export default StatementTable;
