import React from "react";
import { connect } from "react-redux";

const StatusBar = ({ onboarding }) => {
	const { currentStep, emailConfirmed } = onboarding || {};

	const cls = currentStep === 2 ? "bg--primary" : "normal";
	const mark = emailConfirmed ? "mark" : "";

	return (
		<div className="status__bar">
			<div className="border"></div>
			<div className="flex__between circle">
				<div className="">
					<div className={`small-circle one ${mark}`}></div>
					<small className="fw-bold"> Portal Information</small>
				</div>

				<div className="">
					<div className={`small-circle mx-auto mb-2 ${cls}`}>2</div>
					<small className="fw-bold">Investment Information</small>
				</div>
			</div>
		</div>
	);
};

export default connect(({ onboarding }) => ({ onboarding }))(StatusBar);
