import React, { useState, useMemo, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import NotificationDetails from "./NotificationDetails/NotificationDetails";
import { ReactComponent as MeriLogo } from "assets/images/icons/meri-logo.svg";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
import { GET_NOTIFICATIONS } from "api/queries/notification";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import Toast from "utils/Alert/Toast/Toast";
import { pureString, timeDateFormat, truncate } from "utils/helpers";
import { ERROR } from "../../constants";
import "./Notifications.scss";

const Notifications = ({ history }) => {
	const [allNotif, setAllNotif] = useState([]);
	const [current, setCurrent] = useState({});
	const [subject, setSubject] = useState("");

	const [search, { loading, error }] = useLazyQuery(GET_NOTIFICATIONS, {
		onCompleted: ({ notification }) => {
			setAllNotif(notification);
			if (!current?.subject) {
				notification?.length > 0 && setCurrent(notification[0]);
			}
		},
	});

	useEffect(() => {
		search({ variables: { subject } });
		// eslint-disable-next-line
	}, []);

	const viewDetails = (currentData) => {
		history.push({
			pathname: "/notifications/details",
			state: { currentData },
		});
	};

	const debouncedSearch = useMemo(
		() => debounce((subject) => search({ variables: { subject } }), 1000),
		// eslint-disable-next-line
		[]
	);

	const handleChange = ({ target: { value } }) => {
		setSubject(value);
		debouncedSearch(value);
	};

	return (
		<>
			{error && <Toast msg="Error Processing Request" variant={ERROR} />}

			<div className="notifications">
				<div className="row h-100">
					<div className="col-lg-4 notif__sidebar h-100">
						<p className="heading2 ">Notifications</p>

						<div className="search__form">
							<CustomInputBox
								name="subject"
								type="search"
								value={subject}
								placeholder="search notification"
								icon={<SearchIcon />}
								onChange={handleChange}
							/>
						</div>

						<h5 className="border-bottom fw-bold pb-3 flex__between">
							<span>({allNotif?.length || 0}) Inbox</span>
							{loading && <CustomSpinner cls="text-success" />}
						</h5>

						{allNotif?.length === 0 ? (
							<div className="pt-5 mt-5 text-center heading3 text-danger">
								No Message Found
							</div>
						) : (
							<>
								<div className="d-lg-block d-none">
									{allNotif?.map((val, i) => (
										<Notification
											key={i}
											data={val}
											setCurrent={setCurrent}
										/>
									))}
								</div>
								<div className="d-lg-none d-block">
									{allNotif?.map((val, i) => (
										<Notification
											key={i}
											data={val}
											setCurrent={viewDetails}
										/>
									))}
								</div>
							</>
						)}
					</div>

					<div className="col-lg-8 px-5 py-4 notif__content d-lg-block d-none">
						{loading && (
							<div className="flex__center">
								<CustomSpinner cls="text-success" />
							</div>
						)}

						{current?.subject ? (
							<NotificationDetails currentData={current} />
						) : (
							<div className="pt-5 mt-5 text-center heading3 text-danger">
								No Message Found
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const Notification = ({ data, setCurrent }) => {
	const { subject, body, created_at } = data || {};
	return (
		<div className="d-flex notif__list" onClick={() => setCurrent(data)}>
			<MeriLogo width="40px" height="40px" />
			<div className="ms-3 w-100">
				<div className="flex__between flex-wrap">
					<p className="heading4 m-0">{truncate(subject, 20)}</p>
					<span>{timeDateFormat(created_at).timeAgo}</span>
				</div>
				<span>{truncate(pureString(body), 60)}</span>
			</div>
		</div>
	);
};

export default Notifications;
