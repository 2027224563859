import { gql } from "@apollo/client";

export const GET_INVESTMENT_ENUMS = gql`
	query GetInvestmentEnums {
		investment_knowledge {
			comment
			value
		}
		investment_objective {
			comment
			value
		}
		risk_tolerance {
			comment
			value
		}
		settlement_type {
			comment
			value
		}
		source_of_wealth {
			comment
			value
		}
	}
`;
