import React from "react";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import { fetchUserData } from "storage/sessionStorage";
import { SECONDARY } from "../../../../constants";
import OTPInput from "utils/OTPInput/OTPInput";

const ConfirmFundOTP = ({
	loading,
	otp,
	setOtp,
	toggle,
	nextStep,
	prevStep,
}) => {
	let { phone, email } = fetchUserData();
	phone = phone ? `*******${phone.slice(phone?.length - 4)}` : "";
	email = email ? ` or  *******${email.slice(email?.length - 13)}` : "";

	return (
		<>
			<ModalHeader
				type={SECONDARY}
				toggle={toggle}
				prevStep={prevStep}
				title="OTP verification"
			/>

			<h5 className="fw-bold text-center mb-4">{`Enter the OTP sent to ${phone}  ${email}`}</h5>

			<div className="flex__center py-3">
				<OTPInput
					name="otp"
					value={otp}
					onChange={(value) => setOtp(value)}
				/>
			</div>

			<div className="col-6 mb-4 pt-5 mx-auto">
				<button
					className="btn btn--lg btn--primary "
					type="button"
					onClick={() => nextStep(otp)}
					disabled={loading || otp?.length !== 6}
				>
					{loading ? <Spinner /> : "Verify"}
				</button>
			</div>
		</>
	);
};

export default ConfirmFundOTP;
