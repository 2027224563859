import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import ConfirmFundOTP from "components/Dashboard/Banking/modal/ConfirmFundOTP/ConfirmFundOTP";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import { ERROR } from "../../../constants";
import { CHANGE_PASSWORD } from "api/mutations/auth";

const PasswordResetFormModal = ({ request, toggle }) => {
	const [errorMsg, setErrorMsg] = useState();
	const [currentStep, setCurrentStep] = useState(1);
	const [otp, setOtp] = useState();

	const nextStep = () => setCurrentStep((prev) => prev + 1);

	const handleToggle = () => {
		setCurrentStep(1);
		toggle();
	};

	const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
		context: { headers: { otp } },
		onCompleted: () => nextStep(),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleSubmit = () => {
		setErrorMsg();
		const { current_password, password } = request || {};
		changePassword({ variables: { current_password, password } });
	};

	const render = () => {
		switch (currentStep) {
			case 1:
			default:
				return (
					<ConfirmFundOTP
						otp={otp}
						setOtp={setOtp}
						loading={loading}
						nextStep={handleSubmit}
						toggle={handleToggle}
					/>
				);

			case 2:
				return (
					<SuccessState
						btnText="Done"
						msg="Password Change Successfully"
						toggle={handleToggle}
					/>
				);
		}
	};
	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />
			<Modal show={true}>{render()}</Modal>
		</>
	);
};

export default PasswordResetFormModal;
