import React from "react";
import { useQuery } from "@apollo/client";
import { GET_PAYMENT_TRANSACTIONS } from "api/queries/wallet";
import BankingNavs from "../common/BankingNavs/BankingNavs";
import BankingWrapper from "../common/BankingWrapper/BankingWrapper";
import FundWalletForm from "./FundWalletForm/FundWalletForm";
import WalletTransactions from "../common/WalletTransactions/WalletTransactions";
import { FUND } from "../../../constants";

const FundWallet = ({ history, refetch }) => {
	const getTransactionQuery = useQuery(GET_PAYMENT_TRANSACTIONS, {
		fetchPolicy: "no-cache",
		variables: { type: FUND, completed: { _eq: true } },
	});

	return (
		<BankingWrapper
			history={history}
			btn={
				<button
					className="btn btn--sm btn--primary--bordered mb-5 ms-auto px-4"
					onClick={() => history.push("/banking/cards")}
				>
					Manage Account
				</button>
			}
		>
			<BankingNavs />
			<FundWalletForm
				handleRefetch={() => {
					getTransactionQuery.refetch();
					refetch?.();
				}}
			/>
			<WalletTransactions
				type={FUND}
				transactionQuery={getTransactionQuery}
			/>
		</BankingWrapper>
	);
};

export default FundWallet;
