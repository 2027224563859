import { gql } from "@apollo/client";

export const REQUEST_OTP = gql`
	mutation RequestOTPMutation($data: RequestOTPInput!) {
		request_otp(data: $data) {
			phone
			token
			email
		}
	}
`;

export const VERIFY_IDENTITY_OTP = gql`
	mutation VerifyIdentityOTP($token: String!, $otp: String!) {
		verify_identity_otp(data: { token: $token, otp: $otp }) {
			dob
			email
			first_name
			last_name
			middle_name
			phone
		}
	}
`;

export const CREATE_USER = gql`
	mutation CreateUser($data: UserCreateInput!) {
		create_user(data: $data) {
			email
			first_name
			last_name
			middle_name
		}
	}
`;

export const LOGIN_USER = gql`
	mutation LoginUser($data: LoginInput!) {
		login(data: $data) {
			token
			status
		}
	}
`;

export const MFA_LOGIN = gql`
	mutation MfaLogin($data: VerifyIdentityInput!) {
		mfa_login(data: $data) {
			token
		}
	}
`;

export const RESET_PASSWORD = gql`
	mutation resetPassword($username: String!) {
		reset_password(username: $username) {
			message
			status
		}
	}
`;

export const CONFIRM_PASSWORD_RESET = gql`
	mutation confirmPasswordReset($password: String!, $token: String!) {
		confirm_password_reset(password: $password, token: $token) {
			message
			status
		}
	}
`;

export const CHANGE_PASSWORD = gql`
	mutation changePassword($current_password: String!, $password: String!) {
		change_password(
			current_password: $current_password
			password: $password
		) {
			message
			status
		}
	}
`;

export const VERIFY_EMAIL = gql`
	mutation verifyEmail($token: String!) {
		verify_email(token: $token) {
			message
			status
		}
	}
`;

export const VERIFY_USERNAME = gql`
	mutation VerifyUserName($username: String!) {
		is_username_available(data: { username: $username }) {
			status
		}
	}
`;
