import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { useQuery, NetworkStatus, useApolloClient } from "@apollo/client";
import { Scrollbars } from "react-custom-scrollbars-2";
import ScrollToTop from "utils/Scroll/ScrollToTop";
import { ReactComponent as Logo } from "assets/images/icons/logo.svg";
import CompleteOnboarding from "./Popups/CompleteOnboarding/CompleteOnboarding";
import MeriLoader from "utils/Loader/MeriLoader/MeriLoader";
import FailedState from "utils/StateStatus/FailedState";
import { GET_USER_INFO, GET_PORTFOLIO_ACCOUNT } from "api/queries/dashboard";
import { resetApp } from "redux/actions/appActions";
import { BootIntercom } from "./Popups/extra";
import SessionHandler from "components/utils/SessionHandler";
import * as session from "storage/sessionStorage";
import * as actions from "redux/actions/kycActions";
import { getError } from "utils/helpers";
import Header from "./Header/Header";
import routes from "./routes";
import AppContext from "./common/AppContext";
import { switchMode } from "redux/actions/modeActions";
import { updateMode } from "redux/actions/modeActions";

const { updateRegStatus, setNINStep, updateNinInfo } = actions;
const { deleteUserData, fetchUserData, storeUserData } = session;

const Dashboard = ({
	history,
	resetApp,
	updateRegStatus,
	setNINStep,
	updateNinInfo,
	switchMode,
	updateMode,
}) => {
	const [errorMsg, setErrorMsg] = useState();
	const [dataReady, setDataReady] = useState(false);

	const client = useApolloClient();
	const { mode } = useContext(AppContext);

	useQuery(GET_PORTFOLIO_ACCOUNT, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			const portfolio_accounts = data?.portfolio_accounts
				? data?.portfolio_accounts
				: [];
			storeUserData({ portfolio_accounts });
		},
		onError: () => storeUserData({ portfolio_accounts: [] }),
	});

	const { loading, error, networkStatus, refetch } = useQuery(GET_USER_INFO, {
		fetchPolicy: "no-cache",
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			const status = data?.registration_status[0] || {};
			const userProfile = data?.user[0] || {};
			const { first_name, middle_name, last_name, email, phone, dob } =
				userProfile;
			const marginRequest = data?.margin_request?.[0]?.status || "";

			BootIntercom({ name: `${first_name} ${last_name}`, email });

			let kin = data?.next_of_kin[0] || {};
			kin = kin?.name ? true : false;

			let nin = userProfile?.kyc_information?.state_of_origin
				? true
				: false;

			if (status?.identity) {
				setNINStep(3);
				updateNinInfo({
					first_name,
					middle_name,
					last_name,
					email,
					phone,
					dob,
				});
			}

			const settlement =
				data?.settlement_account?.length > 0 ? true : false;
			const { accessToken } = fetchUserData();
			storeUserData({
				...userProfile,
				...data,
				accessToken,
				status: {
					...status,
					kin,
					nin,
					marginRequest,
					completedUnchanged: status.completed,
				},
			});
			updateRegStatus({
				...status,
				kin,
				nin,
				settlement,
				marginRequest,
				completedUnchanged: status.completed,
			});
			setDataReady(true);
		},
		onError: (error) => {
			setDataReady(true);
			setErrorMsg(getError(error));
		},
	});

	const handleLogout = async () => {
		switchMode(mode.meriMode);
		deleteUserData();
		client.clearStore();
		resetApp();
		BootIntercom();
		mode.username && updateMode({ username: mode.username });
		history.replace("/auth/login");
	};

	const getRoutes = () => (
		<Switch>
			{routes.map(({ path, exact, component, dropdown }, i) =>
				dropdown && dropdown?.length > 0 ? (
					dropdown?.map(({ path, exact, component }, i) => {
						const Component = component;
						return (
							<Route
								key={i}
								path={path}
								exact={exact}
								render={(props) => (
									<Component {...props} refetch={refetch} />
								)}
							/>
						);
					})
				) : (
					<Route
						key={i}
						path={path}
						exact={exact}
						component={component}
					/>
				)
			)}

			<Route
				exact
				path="*"
				component={() => <Redirect to="/dashboard" />}
			/>
		</Switch>
	);

	const render = () => {
		if (loading || !dataReady || networkStatus === NetworkStatus.refetch)
			return <MeriLoader />;
		if (error)
			return (
				<div className="text-center">
					<Logo className="mt-5" />
					<FailedState
						errorMsg={errorMsg}
						retry={refetch}
						OtherBtn="Logout"
						OtherAction={handleLogout}
					/>
				</div>
			);
		return (
			<>
				<ScrollToTop />
				<Header history={history} />
				<Scrollbars
					autoHide
					autoHeight
					autoHeightMin={window.innerHeight}
				>
					{getRoutes()}

					<CompleteOnboarding refetch={refetch} />
				</Scrollbars>
				{/* <div className="bottom__button d-lg-none d-block bg-white">
					<TopButtons />
				</div> */}
			</>
		);
	};

	return (
		<React.Fragment>
			{render()}
			<SessionHandler handleLogout={handleLogout} />
		</React.Fragment>
	);
};

export default withRouter(
	connect(null, {
		updateRegStatus,
		setNINStep,
		updateNinInfo,
		resetApp,
		switchMode,
		updateMode,
	})(Dashboard)
);
