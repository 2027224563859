import Skeleton from "react-loading-skeleton";
import { timeRangeFormat } from "utils/helpers";

export const buyHeadings = [
	"Contract No",
	"Order No",
	"Order Name",
	"Symbol",
	"Label",
	"Quality",
	"Contract Currency",
	"Contract Amount",
	"Contract Price",
	"Contract Fee",
	"Settlement Date",
	"Transaction Date",
	"Download",
];

export const cashHeading = [
	"Contract No",
	"Order No",
	"Label",
	"Balance",
	"Debit Amount",
	"Credit Amount",
	"Description",
	"Transaction Date",
];

export const cashExport = [
	{ label: "Contract No", key: "name" },
	{ label: "Description", key: "description" },
	{ label: "Currency", key: "currency" },
	{ label: "Debit Amount", key: "debitAmount" },
	{ label: "Credit Amount", key: "creditAmount" },
	{ label: "Balance", key: "balance" },
	{ label: "Transaction Date", key: "transactionDate" },
	{ label: "Value Date", key: "valueDate" },
];

export const statementExport = [
	{ label: "Contract No", key: "name" },
	{ label: "Order No", key: "id" },
	{ label: "Transaction Date", key: "transactionDate" },
	{ label: "Price", key: "contractPrice" },
	{ label: "Quality", key: "quantity" },
	{ label: "Symbol", key: "symbol" },
	{ label: "Contract Amount", key: "contractAmount" },
];

export const statementLoadingTable = () => {
	const ld = <Skeleton height={10} width={100} />;
	return Array(5)
		.fill()
		.map(() => ({
			a: ld,
			b: ld,
			c: ld,
			d: ld,
			e: ld,
			f: ld,
			g: ld,
		}));
};

const defaultFilters = {
	start_date: timeRangeFormat(new Date(), true),
	end_date: timeRangeFormat(new Date()),
};

export const defaultCashFilters = { ...defaultFilters };

export const defaultBuyFilters = {
	...defaultFilters,
	type: "BUY",
};

export const defaultSellFilters = {
	...defaultFilters,
	type: "SELL",
};
