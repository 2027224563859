import React from "react";
import { connect } from "react-redux";
import Modal from "utils/Modal/Modal";
import { PRIMARY } from "../../../constants";
import AttachBvn from "./AttachBvn/AttachBvn";
import AttachSettlement from "./AttachSettlement/AttachSettlement";
import AttachNin from "./AttachNin/AttachNin";
import AttachNextOfKin from "./AttachNextOfKin/AttachNextOfKin";
import { titleCase } from "utils/helpers";
import FinishRegistration from "./FinishRegistration/FinishRegistration";
import { fetchUserData } from "storage/sessionStorage";
import EmailValidationMessage from "./EmailValidationMessage/EmailValidationMessage";
import {
	updateRegStatus,
	updateNinInfo,
	updateKinInfo,
	updateBankInfo,
	nextStep,
	previousStep,
} from "redux/actions/kycActions";
import "./CompleteOnboarding.scss";

const CompleteOnboarding = ({
	kyc,
	updateRegStatus,
	updateKinInfo,
	updateNinInfo,
	updateBankInfo,
	nextStep,
	previousStep,
	refetch,
}) => {
	const { ninStep, status, kycData } = kyc || {};
	const { completed, nin, bvn, settlement, liveness_check } = status || {};
	const { bankVerified, documents, kin } = status || {};

	const { email, first_name, email_verified } = fetchUserData();

	// const regComplete = completed || (bvn && documents && identity && kin);
	// const { phone } = fetchUserData();
	// const isNig = phone.substring(0, 3) === "234";

	const render = () => {
		if (!email_verified) return <EmailValidationMessage email={email} />;

		if (!nin)
			return (
				<AttachNin
					ninStep={ninStep}
					nextStep={nextStep}
					kycData={kycData}
					previousStep={previousStep}
					updateNinInfo={updateNinInfo}
					updateRegStatus={updateRegStatus}
				/>
			);
		else if (!bvn) return <AttachBvn updateRegStatus={updateRegStatus} />;
		else if (!kin)
			return (
				<AttachNextOfKin
					kycData={kycData}
					updateKinInfo={updateKinInfo}
					updateRegStatus={updateRegStatus}
				/>
			);
		else if (!settlement)
			return (
				<AttachSettlement
					bankVerified={bankVerified}
					documents={documents}
					bankInfo={kycData?.bankInfo}
					updateBankInfo={updateBankInfo}
					updateRegStatus={updateRegStatus}
					liveness_check={liveness_check}
				/>
			);
		else
			return (
				<FinishRegistration
					updateRegStatus={updateRegStatus}
					refetch={refetch}
				/>
			);
	};

	return (
		<>
			{!completed && (
				<Modal
					show={true}
					type={PRIMARY}
					title={`Welcome ${titleCase(first_name)}`}
					text="Just one final step before you begin trading!"
					size="lg"
				>
					{render()}
				</Modal>
			)}
		</>
	);
};

export default connect(({ kyc }) => ({ kyc }), {
	nextStep,
	previousStep,
	updateNinInfo,
	updateKinInfo,
	updateBankInfo,
	updateRegStatus,
})(CompleteOnboarding);
