import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import {
	DELETE_WATCHLIST,
	DELETE_WATCHLIST_ITEM,
} from "api/mutations/watchlist";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import { ERROR } from "../../../../constants";
import Spinner from "utils/CustomSpinner/CustomSpinner";

const DeleteWatchListModal = ({ id, toggle, isItem }) => {
	const [errorMsg, setErrorMsg] = useState();
	const [successMsg, setSuccessMsg] = useState();

	const [deleteWatchlist, { loading }] = useMutation(DELETE_WATCHLIST, {
		onCompleted: () => setSuccessMsg("Watchlist  Deleted"),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const [deleteWatchlistItem, { qloading }] = useMutation(
		DELETE_WATCHLIST_ITEM,
		{
			onCompleted: () => setSuccessMsg("Watchlist Item Deleted"),
			onError: (error) => setErrorMsg(getError(error)),
		}
	);

	const handleAPICall = () => {
		setErrorMsg();
		isItem
			? deleteWatchlistItem({ variables: { id } })
			: deleteWatchlist({ variables: { id } });
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<Modal show={true}>
				<div className="text-center pb-4">
					<p className="heading4 border-bottom">
						{isItem
							? "Remove Item from your Watch List"
							: "Delete Watchlist"}
					</p>

					{successMsg ? (
						<SuccessState
							btnText="Done"
							msg={successMsg}
							toggle={toggle}
						/>
					) : (
						<>
							<div className="pt-4 pb-5">
								<span>
									{isItem
										? "Are you sure you want to remove this stock from this list?"
										: "Are you sure you want to delete this Watchlist"}
								</span>
							</div>

							<div className="flex__between px-3">
								<button
									className="btn btn--lg btn--primary--bordered me-3"
									onClick={toggle}
								>
									No
								</button>
								<button
									className="btn btn--lg btn--secondary ms-3"
									onClick={handleAPICall}
									disabled={loading || qloading}
								>
									{loading || qloading ? <Spinner /> : "Yes"}
								</button>
							</div>
						</>
					)}
				</div>
			</Modal>
		</>
	);
};

export default DeleteWatchListModal;
