import React from "react";
import { ReactComponent as MeriLogo } from "assets/images/icons/meri-logo.svg";
import MarketUpdateChart from "../../../MarketUpdates/charts/MarketUpdateChart/MarketUpdateChart";
import Tabs from "utils/Tabs/Tabs";
import CorporateBenefitTable from "../CorporateBenefitTable/CorporateBenefitTable";
import Scroll from "utils/Scroll/Scroll";
import { kebabCase, toCurrency } from "utils/helpers";
import { UPCOMING_DATES } from "../../enums";

const tabList = ["closure_date", "payment_date", "agm_date"];

const CorporateBenefitDate = ({ loading, data, benefit = {}, setBenefit }) => {
	const { dividend_declared, dividend_yield, stock } = benefit || data?.[0];

	return (
		<div>
			<div className="my-4">
				<Scroll>
					<div className=" d-flex align-items-center me-5">
						<div className="d-flex align-items-center">
							{stock?.logo_url ? (
								<img
									src={stock?.logo_url}
									alt=""
									className="fliud-mg"
								/>
							) : (
								<MeriLogo width={40} height={40} />
							)}
							<div className="">
								<p className="fw-bold mb-1">{stock?.label}</p>
								<small className="">{stock?.name}</small>
							</div>
						</div>
					</div>
					<div className="dividend dec">
						<span>Dividend Declared</span>
						<h1>{toCurrency(dividend_declared)}</h1>
					</div>
					<div className="dividend yield">
						<span>Dividend Yield</span>
						<h1>{dividend_yield}%</h1>
					</div>
				</Scroll>
			</div>

			<MarketUpdateChart stockName={stock?.name} hideSwitch />

			<Tabs tabList={tabList} cls="no-flex mt-5" />

			<div className="tab-content">
				<div
					className="tab-pane fade show active"
					id={kebabCase(tabList[0])}
				>
					<CorporateBenefitTable
						type={UPCOMING_DATES.CLOSURE_DATE}
						loading={loading}
						data={data}
						setBenefit={setBenefit}
					/>
				</div>

				<div className="tab-pane fade" id={kebabCase(tabList[1])}>
					<CorporateBenefitTable
						type={UPCOMING_DATES.PAYMENT_DATE}
						loading={loading}
						data={data}
						setBenefit={setBenefit}
					/>
				</div>
				<div className="tab-pane fade" id={kebabCase(tabList[2])}>
					<CorporateBenefitTable
						type={UPCOMING_DATES.AGM_DATE}
						loading={loading}
						data={data}
						setBenefit={setBenefit}
					/>
				</div>
			</div>
		</div>
	);
};

export default CorporateBenefitDate;
