import React from "react";
import { useSubscription } from "@apollo/client";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { GET_STOCK } from "api/subscription/investment";
import MarketUpdatesLeftView from "./MarketUpdateLeftView/MarketUpdateLeftView";
import MarketUpdatesRightView from "./MarketUpdateRightView/MarketUpdateRightView";
import MarketUpdatesTopButtons from "./MarketUpdateRightView/MarketUpdateTopButtons/MarketUpdateTopButtons";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "../../constants";

const MarketUpdates = ({ history, location }) => {
	const { name } = useParams();
	const params = queryString.parse(location.search);

	const { loading, error, data } = useSubscription(GET_STOCK, {
		variables: { name },
	});

	let { stock } = data || {};
	stock = stock?.length ? stock[0] : location?.state?.data;

	return (
		<>
			{error && <Toast msg="Error Processing Request" variant={ERROR} />}

			<div className="dash__content port">
				<div className="row">
					<MarketUpdatesLeftView
						history={history}
						stock={stock}
						loading={loading}
					/>
					<MarketUpdatesRightView
						history={history}
						loading={loading}
						stock={{ ...stock, account: params.account }}
					/>
				</div>
			</div>
			<div className="bottom__button d-lg-none d-block">
				<MarketUpdatesTopButtons />
			</div>
		</>
	);
};

export default MarketUpdates;
