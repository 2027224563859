import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { SUBMIT_ORDER, SUBMIT_ORDER_2 } from "api/mutations/stock";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import { ReactComponent as Coin } from "assets/images/icons/coin.svg";
import { ERROR, SECONDARY, stockTransType } from "../../../../constants";
import { getError, toCurrency } from "utils/helpers";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import Toast from "utils/Alert/Toast/Toast";

const SellStockConfirmation = ({ order = {}, toggle, nextStep, prevStep }) => {
	const [errorMsg, setErrorMsg] = useState();
	const { price_limit, account, price_type, quantity, type, term } = order;
	const { securityName, name, marketPrice, stop_price } = order;
	const total = marketPrice * quantity;

	const QUERY = term ? SUBMIT_ORDER : SUBMIT_ORDER_2;

	const [buyStock, { error, loading }] = useMutation(QUERY, {
		onCompleted: ({ submit_order }) => nextStep({}, submit_order?.message),
		onError: (err) => setErrorMsg(getError(err)),
	});

	const handleSubmit = () => {
		const variables = {
			account,
			name: securityName || name,
			price_type,
			quantity: parseInt(quantity),
			type,
		};
		price_type !== stockTransType.MARKET &&
			(variables.price_limit = parseFloat(price_limit));
		price_type === stockTransType.STOP_LIMIT &&
			(variables.stop_price = parseFloat(stop_price));
		term && (variables.term = term);
		buyStock({ variables });
	};

	// const handleSubmit = () => {
	// 	buyStock({
	// 		variables: {
	// 			account,
	// 			name: securityName || name,
	// 			price_type,
	// 			quantity,
	// 			type,
	// 			price_limit: price_limit ? parseFloat(price_limit) : "",
	// 		},
	// 	});
	// };

	return (
		<div>
			{error && <Toast msg={errorMsg} variant={ERROR} />}

			<div className="text-center pb-4 ">
				<ModalHeader
					type={SECONDARY}
					toggle={toggle}
					prevStep={prevStep}
					title="Confirm"
				/>

				<div className="px-sm-5 px-2">
					<div className="row flex__between green__light p-4 rounded">
						<div className="col-4">
							<Coin className="coin" />
						</div>

						<div className="col-8 text-start">
							<span className="heading4 text--primary">
								Amount
							</span>
							<p className="heading2 m-0">{`N${toCurrency(
								total
							)}`}</p>
						</div>
					</div>

					<h4 className="fw-bold mt-5">
						An amount of{" "}
						<span className="text--primary">{`N${toCurrency(
							total
						)}`}</span>{" "}
						will be credited to your Cash Balance. Do you want to
						confirm this transaction?
					</h4>

					<div className="flex__between pt-5">
						<button
							className="btn btn--lg btn--secondary--bordered me-3"
							onClick={prevStep}
						>
							No
						</button>
						<button
							className="btn btn--lg btn--secondary ms-3"
							onClick={handleSubmit}
							disabled={loading}
						>
							{loading ? <CustomSpinner /> : "Confirm"}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SellStockConfirmation;
