import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { formValidator } from "utils/FormValidator/FormValidator";
import { RESOLVE_BANK } from "api/queries/others/bank";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import BankSelect from "utils/Select/BankSelect/BankSelect";
import { getError } from "utils/helpers";
import { ATTACH_SETTLEMENT } from "api/mutations/onboarding";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "../../../../../constants";

const ProfileKycAccountForm = () => {
	const [resolveError, setResolveError] = useState();
	const [formErrors, setFormErrors] = useState();
	const [errorMsg, setErrorMsg] = useState("");
	const [account, setAccount] = useState({});
	const [request, setRequest] = useState({
		bank: "",
		account_number: "",
	});

	useEffect(() => {
		const { account_number, bank } = request;
		if (account_number?.length === 10 && bank) {
			setResolveError();
			resolveBank({
				variables: { account_number, bank_code: bank?.code },
			});
		}
		// eslint-disable-next-line
	}, [request]);

	const [resolveBank, { loading }] = useLazyQuery(RESOLVE_BANK, {
		fetchPolicy: "no-cache",
		onCompleted: ({ resolve_bank }) => setAccount(resolve_bank),
		onError: () => {
			setAccount();
			setResolveError("Error Validating Account");
		},
	});

	const [attachSettlement, { loading: qLoading }] = useMutation(
		ATTACH_SETTLEMENT,
		{
			onCompleted: () => window.location.reload(),
			onError: (error) => {
				setErrorMsg(getError(error));
			},
		}
	);

	const checkValidity = () => {
		const { account_number, bank } = request;
		return account_number?.length !== 10 || !bank;
	};

	const handleChange = ({ target: { name, value } }) => {
		value = value.replace(/[^0-9]/g, "");
		handleSelect(name, value);
	};

	const handleSelect = (name, value) => {
		setRequest((prev) => ({
			...prev,
			[name]: value,
		}));
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleSubmit = () => {
		const { formErrors: errs, isValid } = formValidator(request);
		const { account_name, account_number } = account || {};

		isValid
			? attachSettlement({
					variables: {
						account: {
							account_name,
							account_number,
							active: true,
							bank_code: request?.bank?.code,
							bank_name: request?.bank?.name,
						},
					},
			  })
			: setFormErrors(errs);
	};

	const renderResolve = () => {
		if (loading)
			return (
				<div className="text-end fw-bold text--gray mt-n4 pb-4">
					<span>Please Wait...</span>
					<CustomSpinner cls="spinner-border-sm ms-2" />
				</div>
			);
		else if (resolveError)
			return (
				<div className="text-end text-danger fw-bold mt-n4 pb-4">
					<span>{resolveError}</span>
				</div>
			);
		else if (account)
			return (
				<div className="text-end text--gray text-uppercase fw-bold mt-n4 pb-4">
					<span>{account?.account_name}</span>
				</div>
			);
		else return "";
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<CustomInputBox
				name="account_number"
				label="Enter your Account Number"
				placeholder="Enter account number"
				value={request?.account_number}
				onChange={handleChange}
				error={formErrors?.account_number}
				maxLength={10}
				disabled={loading}
				required
			/>

			<BankSelect
				name="bank"
				label="Select your Bank"
				placeholder="Select Bank"
				error={formErrors?.bank}
				value={request?.bank}
				disabled={loading}
				onChange={(e) => handleSelect("bank", e)}
				required
			/>

			{renderResolve()}

			<div className="col-md-6 col-lg-4 col-12">
				<button
					type="button"
					className="btn btn--lg btn--primary"
					disabled={qLoading || checkValidity()}
					onClick={handleSubmit}
				>
					{qLoading ? <CustomSpinner /> : "Submit"}
				</button>
			</div>
		</>
	);
};

export default ProfileKycAccountForm;
