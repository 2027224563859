import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";
import { ReactComponent as FilterIcon } from "assets/images/icons/filter.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/closecover.svg";
import QuickTradeButton from "../../../MarketUpdates/button/QuickTradeButton/QuickTradeButton";
import CustomDatePicker from "utils/CustomDatePicker/CustomDatePicker";
import { formValidator } from "utils/FormValidator/FormValidator";
import { checkSameDay } from "utils/helpers";
import { ORDERS_FILTERS } from "api/queries/orders";
import { ALL } from "components/constants";
import { fetchUserData } from "storage/sessionStorage";
import ExportAsCSV from "utils/ExportAsCSV/ExportAsCSV";
import { orderExport } from "../../extra";

const all = { label: ALL, value: "All" };

const HistoryFilters = ({
	data,
	hideBtn,
	currentFilters,
	getOrders,
	setIsToday,
}) => {
	const [formErrors, setFormErrors] = useState({});
	const [isFilter, setIsFilter] = useState(false);
	const [filters, setFilters] = useState(currentFilters);
	const [filterOptions, setFilterOptions] = useState({});

	const { portfolio_accounts } = fetchUserData();
	let accounts = portfolio_accounts.map((v) => ({
		...v,
		value: v.id,
		label: v.label,
	}));

	const mutateOptions = (datas) => {
		const newData = datas?.map((data) => ({
			...data,
			label: data?.comment,
		}));
		return [{ ...all }, ...newData];
	};

	useQuery(ORDERS_FILTERS, {
		onCompleted: ({ order_status, order_type }) =>
			setFilterOptions({
				order_status: mutateOptions(order_status),
				order_type: mutateOptions(order_type),
			}),
	});

	const handleChange = ({ target: { name, value } }) => {
		handleForm(name, value);
	};

	const handleDate = (name, value) => {
		handleForm(name, value);
		checkSameDay(new Date(), value) ? setIsToday(true) : setIsToday(false);
	};

	const handleForm = (name, value) => {
		setFilters((prev) => ({
			...prev,
			[name]: value,
		}));
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleSubmit = () => {
		if (filters) {
			setIsFilter(true);
			const { formErrors, isValid } = formValidator(filters);
			if (isValid) {
				setIsFilter(true);
				getOrders({
					variables: { arg: { ...getAllFilters(filters) } },
				});
			} else setFormErrors(formErrors);
		}
	};

	const getAllFilters = (allFilters) => {
		const { type, status, portfolio_id } = allFilters || {};
		return {
			...allFilters,
			type: type === ALL ? "" : type,
			status: status === ALL ? "" : status,
			portfolio_id: portfolio_id === ALL ? "" : portfolio_id,
		};
	};

	const clearFilter = () => {
		setIsFilter(false);
		setIsToday(true);
		setFilters(currentFilters);
		getOrders({
			variables: { arg: { ...getAllFilters(currentFilters) } },
		});
	};

	return (
		<div className="my-5 flex__between flex-wrap">
			{/* <Scroll> */}
			<div className="d-flex">
				<div style={{ width: "190px" }} className="me-4">
					<SmallSelect
						value={filters?.portfolio_id}
						label="Portfolio"
						name="portfolio_id"
						onChange={handleChange}
						options={accounts || []}
					/>
				</div>
				<div style={{ width: "90px" }}>
					<SmallSelect
						value={filters?.type}
						label="Order Type"
						name="type"
						onChange={handleChange}
						options={filterOptions?.order_type || []}
					/>
				</div>
				<div style={{ width: "90px" }} className="ms-4">
					<SmallSelect
						value={filters?.status}
						label="Status"
						name="status"
						onChange={handleChange}
						options={filterOptions.order_status || []}
					/>
				</div>

				<div className="mx-4 small-date with__filters">
					<CustomDatePicker
						label="Start Date"
						name="start_date"
						placeholder="Start"
						max={new Date()}
						value={filters?.start_date}
						cls="small-form-group"
						onChange={(e) => handleDate("start_date", e)}
						error={formErrors?.start_date}
						hideErrorText
					/>
				</div>

				<div className="small-date with__filters">
					<CustomDatePicker
						label="End Date"
						name="end_date"
						placeholder="End"
						min={filters?.start_date}
						max={new Date()}
						value={filters?.end_date}
						cls="small-form-group"
						onChange={(e) => handleDate("end_date", e)}
						error={formErrors?.end_date}
						hideErrorText
					/>
				</div>
			</div>
			{/* </Scroll> */}

			<div className="flex__between ms-auto">
				<span
					onClick={handleSubmit}
					className="flex__between pointer me-2"
				>
					<FilterIcon />
					<h3 className="fw-bold mb-0 ms-2">Filter</h3>
				</span>
				{isFilter && (
					<span
						onClick={clearFilter}
						className="flex__between pointer ms-3"
					>
						<CloseIcon width={20} />
						<h3 className="fw-bold mb-0 ms-2">Clear</h3>
					</span>
				)}
				{data?.length > 0 && (
					<ExportAsCSV
						data={data}
						cls="mx-5"
						headers={orderExport}
						filename="order-history"
					/>
				)}
				{!hideBtn && (
					<QuickTradeButton
						btnName="New Order"
						btnVariant="btn--sm px-5 ms-3"
					/>
				)}
			</div>
		</div>
	);
};

export default HistoryFilters;
