import { gql } from "@apollo/client";

export const CREATE_WATCHLIST = gql`
	mutation CreateWatchlist($name: String!) {
		insert_watchlist_one(object: { name: $name }) {
			id
			name
		}
	}
`;

export const UPDATE_WATCHLIST = gql`
	mutation UpdateWatchlist($id: uuid!, $name: String!) {
		update_watchlist_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
			id
			name
		}
	}
`;

export const ADD_WATCHLIST_ITEM = gql`
	mutation AddWatchlistItem($stock_name: String, $watchlist_id: uuid) {
		insert_watchlist_item_one(
			object: { stock_name: $stock_name, watchlist_id: $watchlist_id }
		) {
			stock_name
		}
	}
`;

export const ADD_WATCHLIST_ITEMS = gql`
	mutation AddWatchlistItem($items: [watchlist_item_insert_input!]!) {
		insert_watchlist_item(objects: $items) {
			affected_rows
		}
	}
`;

export const DELETE_WATCHLIST = gql`
	mutation DeleteWatchlist($id: uuid!) {
		delete_watchlist_by_pk(id: $id) {
			name
		}
	}
`;

export const DELETE_WATCHLIST_ITEM = gql`
	mutation DeleteWatchlist($id: uuid!, $stock: String) {
		delete_watchlist_item(
			where: { stock_name: { _eq: $stock }, watchlist_id: { _eq: $id } }
		) {
			returning {
				watchlist_id
				stock_name
			}
		}
	}
`;
