import React from "react";
import TableCell from "./TableCell";

const TableRow = ({ rowValues, header, id, rowClick }) => {
	const tag = header ? "th" : "td";
	return (
		<tr
			id={id}
			onClick={(e) => rowClick(rowValues, e)}
			className={rowClick ? "pointer" : " "}
		>
			{Object.values(rowValues).map((cellValues, i) => (
				<TableCell key={i} cellValues={cellValues} tag={tag} />
			))}
		</tr>
	);
};

export default TableRow;
