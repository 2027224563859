import { combineReducers } from "redux";
import onboarding from "./onboardingReducer";
import kyc from "./kycReducer";
import mode from "./modeReducer";
import { RESET_APP } from "../actionTypes/app";

const allReducer = combineReducers({
	onboarding,
	kyc,
	mode,
});

const rootReducer = (state, action) => {
	if (action.type === RESET_APP) {
		state = undefined;
	}

	return allReducer(state, action);
};

export default rootReducer;
