import React from "react";
import { Route } from "react-router";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";
import TabsLink from "utils/Tabs/TabsLink";
import Routes from "../ProfileRoutes";

const ProfileKYCInfo = ({ history }) => {
	const routes = Routes.find((route) => route.name === "KYC Information");

	return (
		<div className="px-sm-5 px-0 pb-5">
			<div className="d-flex align-items-center col-12 px-3 mx-3">
				<BackIcon
					height="20px"
					width="20px"
					onClick={() => history.push("/profile/account")}
					className="pointer d-lg-none d-inline-block me-4"
				/>
				<p className="heading2 fw-bold mb-0">KYC Information</p>
			</div>

			<div className="row ">
				<div className="mt-n4 col-12 px-5 mt-4">
					<TabsLink routes={routes} />
					{routes?.dropdown?.map(({ exact, path, component }) => (
						<Route
							key={path}
							exact={exact}
							path={path}
							component={component}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProfileKYCInfo;
