import React, { useState } from "react";
import Cancel from "assets/images/icons/close.png";
import { ReactComponent as EditIcon } from "assets/images/icons/pen.svg";
import CancelOrderModal from "../../modals/CancelOrderModal/CancelOrderModal";
import { EXECUTING } from "components/constants";
import QuickTradeModal from "components/Dashboard/MarketUpdates/modal/QuickTradeModal/QuickTradeModal";

const actionStatus = {
	EDIT: "EDIT",
	CANCELLED: "CANCELLED",
};

const OrderActionButton = ({ order, refetch }) => {
	const [showModal, setShowModal] = useState(false);
	const [action, setAction] = useState();

	const newId = order?.id ? order?.id.toString() : order.id;

	const toggleModal = (value) => {
		setShowModal(true);
		setAction(value);
	};

	const closeModal = () => {
		setShowModal(false);
		setAction();
	};

	const renderByStatus = () => {
		if (action === actionStatus.EDIT)
			return (
				<QuickTradeModal
					order={{ ...order, id: newId }}
					toggle={closeModal}
					refetch={refetch}
				/>
			);
		if (action === actionStatus.CANCELLED)
			return (
				<CancelOrderModal
					id={newId}
					toggle={closeModal}
					refetch={refetch}
				/>
			);
	};

	return (
		<>
			{order?.orderStatus === EXECUTING && (
				<>
					<EditIcon
						className="me-5"
						onClick={() => toggleModal(actionStatus.EDIT)}
					/>
					<img
						src={Cancel}
						alt="cancel"
						width={15}
						onClick={() => toggleModal(actionStatus.CANCELLED)}
						className="pointer"
					/>
				</>
			)}

			{showModal && <>{renderByStatus()}</>}
		</>
	);
};

export default OrderActionButton;
