import React, { useState } from "react";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import AddWatchlistConfirmation from "./AddWatchlistConfirmation/AddWatchlistConfirmation";
import UsersWatchlist from "./UsersWatchlist/UsersWatchlist";

const AddMarketToWatchlistModal = ({ toggle, stock }) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [watchlist, setWatchlist] = useState();

	const nextStep = (name) => {
		setWatchlist(name);
		setCurrentStep((prev) => prev + 1);
	};

	const prevStep = () => setCurrentStep((prev) => prev - 1);

	const render = () => {
		switch (currentStep) {
			case 1:
			default:
				return <UsersWatchlist nextStep={nextStep} toggle={toggle} />;
			case 2:
				return (
					<AddWatchlistConfirmation
						nextStep={nextStep}
						prevStep={prevStep}
						stock={stock}
						watchlist={watchlist}
					/>
				);
			case 3:
				return (
					<SuccessState
						msg="Item successfully added to your watchlist"
						btnText="Done"
						toggle={toggle}
					/>
				);
		}
	};

	return <Modal show={true}>{render()}</Modal>;
};

export default AddMarketToWatchlistModal;
