import React from "react";
import Skeleton from "react-loading-skeleton";
import FreequentlyTradedActions from "../FrequentlyTradedActions/FreequentlyTradedActions";
import { PLUS, MINUS } from "../../../../../constants";
import { getStatus, toCurrency, truncate } from "utils/helpers";

const FrequentlyTradedScroll = ({ stock, handleClick }) => {
	const { price, price_change, price_change_p, label, name } = stock || {};
	const gains = getStatus(
		"",
		price_change >= 0 ? PLUS : MINUS,
		`${toCurrency(price_change)} (${toCurrency(price_change_p)}%)`
	);

	return (
		<div
			className="frequent__slide border rounded pointer"
			onClick={handleClick}
		>
			<p>
				<span className="heading4">{truncate(label, 25)}</span>
				<span className="d-block">{name}</span>
			</p>

			<div className="flex__between ">
				<div className="m-0">
					<h3 className="fw-bold d-inline-block">
						{toCurrency(price)}
					</h3>
					<small className="fw-bold ps-2">{gains}</small>
				</div>
				<FreequentlyTradedActions />
			</div>
		</div>
	);
};

export const FrequentlyTradedLoader = () => {
	return (
		<>
			{Array(4)
				.fill()
				.map((_, i) => (
					<div key={i} className="frequent__slide border rounded ">
						<Skeleton height={15} />
						<Skeleton height={15} className="my-3" />
						<Skeleton height={15} />
					</div>
				))}
		</>
	);
};

export default FrequentlyTradedScroll;
