import PasswordReset from "./PasswordReset/PasswordReset";
import PushNotifications from "./PushNotifications/PushNotifications";
import TwoFactorAuth from "./TwoFactorAuth/TwoFactorAuth";

const defaultPath = "/settings";

const routes = [
	{
		name: "Password Reset",
		path: defaultPath,
		component: PasswordReset,
		exact: true,
	},
	{
		name: "Push Notifications",
		path: `${defaultPath}/push-notification`,
		component: PushNotifications,
		exact: true,
	},
	{
		name: "Two Factor Authentication",
		path: `${defaultPath}/2fa`,
		component: TwoFactorAuth,
		exact: true,
	},
];

export default routes;
