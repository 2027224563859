import React, { useState } from "react";
import debounce from "lodash.debounce";
import { ReactComponent as SearchIcon } from "assets/images/icons/search.svg";
import CustomInputBox from "../CustomInputBox";

const SearchForm = ({ allData, placeholder = "search", setResult }) => {
	const [search, setSearch] = useState("");

	const debouncedSearch = React.useMemo(
		() => debounce((name, allData) => setResult(name, allData), 1000),
		// eslint-disable-next-line
		[]
	);

	const handleChange = ({ target: { value } }) => {
		setSearch(value);
		if (value) debouncedSearch(value, allData);
		else {
			debouncedSearch("", allData);
			setSearch();
		}
	};

	return (
		<div className="search__form">
			<CustomInputBox
				name="search"
				type="search"
				value={search}
				placeholder={placeholder}
				icon={<SearchIcon />}
				onChange={handleChange}
			/>
		</div>
	);
};

export default SearchForm;
