import { gql } from "@apollo/client";

export const GET_PAYMENT_TRANSACTIONS = gql`
	query Payment_Transactions(
		$type: String
		$completed: Boolean_comparison_exp = {}
	) {
		payment_transaction(
			order_by: { created_at: desc }
			where: { completed: $completed, type: { _eq: $type } }
		) {
			amount
			completed
			reference
			type
			user_id
			channel
			destination_account_name
			destination_account_number
			destination_bank_name
			user {
				account {
					account_name
					account_number
					bank_name
				}
			}
			created_at
		}
	}
`;
