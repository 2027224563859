import React from "react";
import Table from "utils/Table/Table";
import { ReactComponent as MeriLogo } from "assets/images/icons/meri-logo.svg";
import { TableLoader } from "utils/TableLoader/TableLoader";
import { toPrice } from "utils/helpers";
import InvestmentTableActions from "./InvestmentTableActions/InvestmentTableActions";

const tableHeading = [
	"Logo",
	"Name",
	"Symbol",
	"Currency",
	"Price",
	"Daily P/L (N)",
	"Daily P/L (%)",
	"",
];

const InvestmentTable = ({ loading, datas, history, account }) => {
	const getTableData = () =>
		datas?.map((data) => ({
			image: data?.logo_url ? (
				<img src={data?.logo_url} alt="" className="fliud-mg" />
			) : (
				<MeriLogo width={30} height={30} />
			),
			name: data?.label,
			id: data?.name,
			currency: data?.currency,
			valuation: data?.price,
			dailyPL: toPrice(data?.price_change),
			dailyPL2: toPrice(data?.price_change_p),
			action: (
				<InvestmentTableActions
					stock={{ ...data, account }}
					loading={loading}
					exchange
				/>
			),
		}));

	const rowClick = ({ id }, { target }) => {
		const selectEvent = target?.id.includes("react-select");
		const data = datas?.find(({ name }) => name === id);
		!loading &&
			!selectEvent &&
			history.push({
				pathname: `/market/${id}`,
				state: { data },
				search: `?account=${account}`,
			});
	};

	const tableData = loading ? TableLoader(8) : getTableData();

	return (
		<Table
			data={tableData}
			headings={tableHeading}
			indexRow={loading ? "" : "name"}
			rowClick={rowClick}
		/>
	);
};

export default InvestmentTable;
