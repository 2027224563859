import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Flash } from "assets/images/icons/flash.svg";
import { toPriceStat } from "utils/helpers";

const TopStockWrapper = ({ item, loser }) => {
	const history = useHistory();

	const { name, currency, price_change, price_change_p } = item || {};
	return (
		<li
			className="flex__between px-md-4 px-2 py-3 pointer"
			onClick={() =>
				history.push({
					pathname: `/market/${name}`,
					state: { currency, label: name },
				})
			}
		>
			<div className="d-flex">
				<div className="med-circle border p-3 bg__gray me-4">
					<Flash />
				</div>
				<div className="">
					<p className="m-0 fw-bold">{name}</p>
					<small
						className={`${loser ? "text-danger" : "text--primary"}`}
					>
						{toPriceStat(price_change, price_change_p)}
					</small>
				</div>
			</div>
		</li>
	);
};

export default TopStockWrapper;
