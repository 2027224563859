import React, { useState, useEffect, useRef, useContext } from "react";
import { connect } from "react-redux";
import { createChart } from "lightweight-charts";
import { useLazyQuery } from "@apollo/client";
import { areaOptions, chartOptions } from "./data";
import { PORTFOLIO_SUMMARY } from "api/queries/dashboard";
import { getDateRange, timeDateFormat } from "utils/helpers";
import AppContext from "components/Dashboard/common/AppContext";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";
import {
	portfolioOptions,
	portfolioOptionsWithNoMargin,
} from "components/Dashboard/common/helpers";
import ChartOverlay from "utils/ChartOverlay";

const switchers = ["ID", "1W", "1M", "3M", "6M", "1Y", "5Y", "All Time"];

const PortfolioSummaryChart = ({ id, showTitle, margin, handlePortfolio }) => {
	const [response, setResponse] = useState([]);
	const currentPortfolio =
		!showTitle && !margin
			? portfolioOptionsWithNoMargin()
			: portfolioOptions();
	const { mode } = useContext(AppContext);
	const [selected, setSelected] = useState(switchers[2]);
	const [portfolio_id, setPortfolioId] = useState(
		id || currentPortfolio?.[0]?.value
	);

	const chartContainerRef = useRef();
	const chart = useRef();
	const series = useRef();

	const [getSummary, { loading }] = useLazyQuery(PORTFOLIO_SUMMARY, {
		onCompleted: ({ portfolio_summary_daily }) => {
			const data = portfolio_summary_daily.map(({ time, value }) => ({
				time,
				value,
			}));
			setResponse(data || []);
			series.current.setData(data || []);
			data?.length < 50 && chart.current.timeScale().fitContent();
		},
	});

	useEffect(() => {
		chart.current = createChart(
			chartContainerRef.current,
			chartOptions(mode)
		);
		series.current = chart.current.addAreaSeries(areaOptions);
		apiCall(selected);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		apiCall(selected);
		// eslint-disable-next-line
	}, [selected, portfolio_id]);

	const apiCall = (btn) => {
		const time = timeDateFormat(getDateRange(btn)).date;
		const filters = { time: { _gte: time } };
		portfolio_id !== "all" &&
			(filters.portfolio_id = { _iregex: portfolio_id });
		getSummary({ variables: { filters, time } });
	};

	return (
		<div className="port__summary">
			{showTitle && <p className="heading4">Porfolio Summary</p>}

			<div className="flex__between flex-wrap my-4">
				{!margin && (
					<div
						className="d-inline-block  mb-3"
						style={{ minWidth: "200px" }}
					>
						<SmallSelect
							nocase
							value={portfolio_id}
							onChange={({ target: { value } }) => {
								setPortfolioId(value);
								handlePortfolio?.(value);
							}}
							options={currentPortfolio}
						/>
					</div>
				)}

				<div className="switchers ms-auto mb-3">
					{switchers?.map((btn) => (
						<p
							key={btn}
							onClick={() => setSelected(btn)}
							className={btn === selected ? "active" : ""}
						>
							{btn}
						</p>
					))}
				</div>
			</div>

			<div ref={chartContainerRef} className="chart__wrap">
				<ChartOverlay loading={loading} data={response} />
			</div>
		</div>
	);
};

export default connect(({ mode }) => ({ mode }))(PortfolioSummaryChart);
