import { gql } from "@apollo/client";

export const STOCK_DAY_SERIES_WITHOUT_TIME = gql`
	subscription GetStockDaySeries2($type: String, $exchange: String) {
		stock_day_series(
			order_by: { t: asc }
			distinct_on: t
			limit: 1000000
			where: {
				stock: {
					type: { _regex: $type }
					exchange: { _regex: $exchange }
				}
			}
		) {
			t
			price
			close
			high
			low
			open
			price_change
			volume
			stock {
				currency
				exchange
				label
				name
				price
				previous_close
				price_change
				price_change_p
				sector
				type
				trades
				bid_levels
				offer_levels
				updated_at
			}
		}
	}
`;

export const STOCK_BOND = gql`
	subscription GetStocks(
		$type: String
		$exchange: String
		$sector: String
		$marginable: Boolean
	) {
		stock(
			limit: 1000000
			where: {
				type: { _iregex: $type }
				exchange: { _iregex: $exchange }
				sector: { _iregex: $sector }
				marginable: { _eq: $marginable }
			}
		) {
			type
			bid_levels
			currency
			exchange
			issued_shares
			label
			last_trade_time
			logo_url
			marginable
			name
			offer_levels
			previous_close
			price
			price_change
			price_change_p
			sector
			todays_change
			todays_high
			todays_low
			trades
			year_high
			year_low
		}
	}
`;

export const GET_STOCKS = gql`
	subscription GetStocks($filters: stock_bool_exp = {}) {
		stock(limit: 1000000, where: $filters) {
			type
			bid_levels
			currency
			exchange
			issued_shares
			label
			last_trade_time
			logo_url
			marginable
			name
			offer_levels
			previous_close
			price
			price_change
			price_change_p
			sector
			todays_change
			todays_high
			todays_low
			trades
			year_high
			year_low
		}
	}
`;

export const GET_STOCK = gql`
	subscription GetStock($name: String) {
		stock(limit: 1000000, where: { name: { _eq: $name } }) {
			offer_levels
			bid_levels
			currency
			exchange
			sector
			issued_shares
			label
			last_trade_time
			logo_url
			marginable
			name
			news {
				content
				created_at
				title
			}
			previous_close
			price
			price_change
			price_change_p
			todays_change
			todays_high
			todays_low
			trades
			type
			year_high
			year_low
			company_profile {
				about
				ceo
				headquarters
				no_of_employees
				year_founded
			}
		}
	}
`;

export const STOCK_DAY_SERIES = gql`
	subscription GetStockDaySeries($name: String, $time: timestamptz) {
		stock_day_series(
			order_by: { t: asc }
			distinct_on: t
			limit: 1000000
			where: { stock: { name: { _eq: $name } }, t: { _gte: $time } }
		) {
			t
			price
			close
			high
			low
			open
			price_change
			volume
			stock {
				currency
				exchange
				label
				name
				price
				previous_close
				price_change
				price_change_p
				sector
				type
				trades
				bid_levels
				offer_levels
				updated_at
			}
		}
	}
`;

export const STOCK_LIVES_SERIES = gql`
	subscription GetStockLiveSeries($name: String) {
		stock_live_series(
			limit: 1000000
			where: { stock: { name: { _eq: $name } } }
		) {
			t
			price
			stock {
				currency
				exchange
				label
				name
				price
				previous_close
				price_change
				price_change_p
				sector
				type
				trades
				updated_at
			}
		}
	}
`;
