import React from "react";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";
import SearchForm from "utils/CustomInputBox/SearchForm/AsyncSearchForm";
import { marketOptions, stockMarketOptions } from "../../extra";

const MarketOverviewFilters = ({ filters, setFilters, setCurrentStock }) => {
	const { type, exchange } = filters;

	const handleSelect = ({ target: { name, value } }) => {
		setFilters((prev) => ({
			...prev,
			[name]: value === "all" ? "" : value,
		}));
	};

	return (
		<div
			className="row d-flex justify-content-between align-items-start flex-wrap mb-5"
			style={{ maxHeight: "50px" }}
		>
			<div className="col-sm-8 my-4">
				<div className="d-flex " style={{ maxWidth: "150px" }}>
					<SmallSelect
						nocase
						name="type"
						onChange={handleSelect}
						options={marketOptions}
						value={type ? type : "all"}
					/>

					<div className="mx-3" />

					{type !== "BOND" && (
						<SmallSelect
							nocase
							name="exchange"
							onChange={handleSelect}
							options={stockMarketOptions}
							value={exchange ? exchange : "all"}
						/>
					)}
				</div>
			</div>
			<div className="col-sm-4">
				<SearchForm
					cls="mb-0"
					placeholder="Search e.g 7Up"
					getResult={setCurrentStock}
				/>
			</div>
		</div>
	);
};

export default MarketOverviewFilters;
