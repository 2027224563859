import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { ORDER_TERMS } from "api/queries/stock";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import ReviewOrder from "../common/ReviewOrder/ReviewOrder";
import BuyStockConfirmation from "./BuyStockConfirmation";
import BuyStockPortfolioList from "./BuyStockPortfolioList";
import BuyStockOptions from "./BuyStockOptions";
import { BUY } from "../../../../constants";

const BuyStock = ({ stock, toggle }) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [order, setOrder] = useState({ ...stock, type: BUY });
	const [successMsg, setSuccessMsg] = useState("");

	const { data } = useQuery(ORDER_TERMS);

	const nextStep = (data = {}, msg) => {
		setOrder((prev) => ({ ...prev, ...data }));
		setSuccessMsg(msg);
		setCurrentStep((prev) => prev + 1);
	};

	useEffect(() => {
		if (stock.account) {
			setCurrentStep(2);
		}
	}, [stock.account]);

	const prevStep = () => setCurrentStep((prev) => prev - 1);

	const render = () => {
		switch (currentStep) {
			case 1:
			default:
				return (
					<BuyStockPortfolioList
						order={order}
						nextStep={nextStep}
						toggle={toggle}
					/>
				);

			case 2:
				return (
					<BuyStockOptions
						order={order}
						nextStep={nextStep}
						toggle={toggle}
						orderTerms={data?.order_term || []}
					/>
				);
			case 3:
				return (
					<ReviewOrder
						isBuy
						order={order}
						nextStep={nextStep}
						prevStep={prevStep}
						toggle={toggle}
					/>
				);
			case 4:
				return (
					<BuyStockConfirmation
						order={order}
						nextStep={nextStep}
						prevStep={prevStep}
						toggle={toggle}
					/>
				);
			case 5:
				return (
					<SuccessState
						msg={successMsg}
						btnText="Done"
						toggle={toggle}
					/>
				);
		}
	};

	return <Modal show={true}>{render()}</Modal>;
};

export default BuyStock;
