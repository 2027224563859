import React, { useEffect, useState } from "react";
import ReferralButton from "../buttons/ReferralButton/ReferralButton";
import { ReactComponent as Copy } from "assets/images/icons/copy.svg";
import { copyToClipboard } from "utils/helpers";
import Toast from "utils/Alert/Toast/Toast";
import { SUCCESS } from "components/constants";
import { fetchUserData } from "storage/sessionStorage";

const Referral = () => {
	const [showMsg, setShowMsg] = useState("");

	const { referral_code } = fetchUserData();

	const referralLink = `${window.origin}/onboarding?referral=${referral_code}`;

	const copy = () => {
		copyToClipboard(referralLink);
		setShowMsg("Link Copied Successfully!!!");
	};

	const copyCode = () => {
		copyToClipboard(referral_code);
		setShowMsg("Referral Code Copied Successfully!!!");
	};

	useEffect(() => setShowMsg(""), [showMsg]);

	return (
		<>
			<Toast msg={showMsg} variant={SUCCESS} />

			<h4 className="py-3">
				Refer a friend and earn up to{" "}
				<b className="text--primary">N1000</b> on there first 20 stocks
			</h4>
			<h4 className="my-5 fw-bold">
				Referral Code:{" "}
				<b className="text--primary ms-5">{referral_code}</b>
				<Copy
					className="primary pointer mx-3"
					width={15}
					onClick={copyCode}
				/>
			</h4>

			<h4 className="my-5 fw-bold">
				Completed Invitees: <b className="text--primary ms-5">0</b>
			</h4>

			<div className="d-flex">
				<button
					className="btn btn--primary--bordered btn--sm me-5 px-5"
					disabled={!referral_code}
					onClick={copy}
				>
					<Copy className="primary me-3" width={15} /> Copy Link
				</button>

				<ReferralButton
					referralLink={referralLink}
					disabled={!referral_code}
				/>
			</div>
		</>
	);
};

export default Referral;
