import React from "react";
import { toCurrency, toPrice } from "utils/helpers";
import { getBest } from "../../helpers";

const MarketCaps = ({ stock }) => {
	const { bid_levels, offer_levels, trades } = stock || {};
	const bestOffer = getBest(offer_levels);
	const bestBid = getBest(bid_levels);
	const lastTraded = trades?.length ? trades[0] : 0;

	return (
		<div className="div__wrapper pt-4 h-100 ">
			<List
				name="Last Traded price"
				value={lastTraded?.tradePrice || 0}
			/>
			<List name="Last Price" value={stock?.price || 0} />
			<List name="Quantity Traded" value={lastTraded?.tradeSize || 0} />
			<List
				name="Price Change"
				value={toPrice(toCurrency(stock?.price_change || 0))}
			/>
			<List name="Today's Volume" value={stock?.volume || 0} />
			<List name="High Price" value={toCurrency(stock?.high || 0)} />
			<List
				name="Best Bid Price"
				value={toCurrency(bestBid?.price || 0)}
			/>
			<List name="Best Bid Quantity" value={bestBid?.qty || 0} />
			<List
				name="Best Offer Price"
				value={toCurrency(bestOffer?.price || 0)}
			/>
			<List name="Best Offer Quantity" value={bestOffer?.qty || 0} />
		</div>
	);
};

const List = ({ name, value }) => (
	<div className="px-4">
		<div className="flex__between">
			<span>{name}</span>
			<span>{value}</span>
		</div>
		<hr className="border-gray" />
	</div>
);

export default MarketCaps;
