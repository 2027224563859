import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
	query GetNotifications($subject: String) {
		notification(
			order_by: { created_at: asc }
			where: { subject: { _iregex: $subject } }
		) {
			body
			created_at
			subject
		}
	}
`;

export const GET_NOTIFICATIONS_SETTING = gql`
	query GetNotifications {
		notification_preference {
			transaction
			withdrawal
			enabled
		}
	}
`;
