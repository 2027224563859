import { gql } from "@apollo/client";

export const GET_INSTRUMENTS = gql`
	query getInstruments {
		instrument_type {
			currency
			id
			label
			rate
		}
	}
`;

export const FIXED_INCOME_TENURE = gql`
	query fixedIncomeTenure {
		fixed_income_tenure {
			comment
			value
		}
	}
`;

export const FIXED_INCOME = gql`
	query fixedIncome {
		fixed_income_rollover_rule {
			comment
			value
		}
		fixed_income_tenure {
			comment
			value
		}
		instrument_type {
			currency
			id
			label
			rate
		}
	}
`;

export const FIXED_INCOME_STATUS = gql`
	query fixedIncomeStatus {
		fixed_income_status {
			comment
			value
		}
	}
`;

export const GET_FIXED_INCOME = gql`
	query getFixedIncome(
		$end_date: String
		$start_date: String
		$status: String
	) {
		fixed_portfolio(
			end_date: $end_date
			start_date: $start_date
			status: $status
		) {
			accruedInterest
			accruedNetInterest
			expectedNetInterest
			autoRollover
			currency
			currentRate
			customerLabel
			status
			name
			startDate
			label
			currentRate
			faceValue
			portfolioName
			portfolioLabel
			instrumentTypeLabel
		}
	}
`;
