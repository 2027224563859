import { gql } from "@apollo/client";

export const GET_CORPORATE_BENEFIT = gql`
	query CorporateBenefit {
		corporate_benefit(limit: 200) {
			bonus
			closure_date
			declaration_date
			dividend_declared
			dividend_yield
			final
			id
			qualification_date
			stock_id
			stock {
				label
				name
				logo_url
			}
		}
	}
`;
