import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import ReportWrapper from "./ReportWrapper/ReportWrapper";
import PortfolioReportFilter from "./PortfolioReportFilter/PortfolioReportFilter";
import { getDateRange } from "utils/helpers";
import { _1D, ERROR } from "../../../constants";
import { GET_PORTFOLIO_REPORTS } from "api/queries/portfolio";
import Toast from "utils/Alert/Toast/Toast";
import TableError from "utils/Table/TableError/TableError";
import "./PortfolioReport.scss";
import MeriLoader from "utils/Loader/MeriLoader/MeriLoader";

const PortfolioReport = () => {
	const [reports, setReports] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");
	const defaultDate = getDateRange(_1D);

	const [getReport, { error: err, loading }] = useLazyQuery(
		GET_PORTFOLIO_REPORTS,
		{
			fetchPolicy: "no-cache",
			onCompleted: ({ portfolio_report }) => setReports(portfolio_report),
			onError: (error) => {
				setReports([]);
				setErrorMsg(error);
			},
		}
	);

	useEffect(() => {
		getReport({
			variables: { date: defaultDate },
		});
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{err && <Toast msg={errorMsg} variant={ERROR} />}

			<div className="flex__between">
				<p className="heading2 m-0">Portfolio Report</p>
				<PortfolioReportFilter
					loading={loading}
					handleFilter={getReport}
				/>
			</div>

			<div className="porfolio-report">
				{loading && <MeriLoader hideLogo />}

				<div className="row mt-4">
					{!loading &&
						reports.map((report, i) => (
							<ReportWrapper key={i} report={report} />
						))}
				</div>

				{!loading && reports?.length === 0 && <TableError />}
			</div>
		</>
	);
};

export default PortfolioReport;
