import { MINUS, PLUS } from "../../constants";

export const portfolioMockData = [
	{
		name: "Eternal Oil & Gas",
		code: "ETRNA",
		lastPrice: 7.5,
		quantity: 54,
		pricePaid: 6.7,
		currentValue: "148,900.63",
		totalCost: "197,600",
		dailyPL: "+N166.31",
		dailyPL2: "26.31",
		totalPL: "+N46.44",
		totalPL2: "37.44",
		type: MINUS,
	},
	{
		name: "Transcorp",
		code: "TRNSCRP",
		lastPrice: 7.5,
		quantity: 54,
		pricePaid: 6.7,
		currentValue: "148,900.63",
		totalCost: "197,600",
		dailyPL: "+N166.31",
		dailyPL2: "66.31",
		totalPL: "+N46.44",
		totalPL2: "4.44",
		type: PLUS,
	},
	{
		name: "Wema Bank",
		code: "WEMA",
		lastPrice: 7.5,
		quantity: 54,
		pricePaid: 6.7,
		currentValue: "148,900.63",
		totalCost: "197,600",
		dailyPL: "+N166.31",
		dailyPL2: "16.31",
		totalPL: "+N46.44",
		totalPL2: "6.44",
		type: PLUS,
	},
	{
		name: "United Bank of Africa",
		code: "UBA",
		lastPrice: 7.5,
		quantity: 54,
		pricePaid: 6.7,
		currentValue: "148,900.63",
		totalCost: "197,600",
		dailyPL: "+N166.31",
		dailyPL2: "9.31",
		totalPL: "+N46.44",
		totalPL2: "6",
		type: MINUS,
	},
	{
		name: "Blusalt Financial Services",
		code: "BFS",
		lastPrice: 7.5,
		quantity: 54,
		pricePaid: 6.7,
		currentValue: "148,900.63",
		totalCost: "197,600",
		dailyPL: "+N166.31",
		dailyPL2: "1.31",
		totalPL: "+N46.44",
		totalPL2: "2.44",
		type: PLUS,
	},
];
