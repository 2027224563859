import React from "react";
import { useHistory } from "react-router-dom";
import { fetchUserData } from "storage/sessionStorage";
import { getBalance } from "utils/helpers";
import { getStatus, toCurrency } from "utils/helpers";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";
import { PLUS, MINUS } from "../../../../constants";

const ValuationBalance = ({
	isMargin,
	portfolio,
	selectedAccount,
	handleAccount,
}) => {
	const history = useHistory();
	const { cashBalance, margin_details: md } = getBalance();
	let { account, portfolio_accounts } = fetchUserData();
	account = account.find(({ margin }) => margin === false);
	const { currency } = account || {};

	let options = portfolio_accounts?.map(({ label, id }) => ({
		label,
		value: id,
	}));

	const calcTotal = (name, otherName) => {
		return portfolio?.reduce((acc, arr, i) => {
			return acc + arr?.[name] * (arr?.[otherName] ?? 1);
		}, 0);
	};

	const total = calcTotal("valuation")?.toFixed(2) || 0;
	const totalGain = calcTotal("gain")?.toFixed(2) || 0;
	const totalPercentGain = calcTotal("percentGain")?.toFixed(2) || 0;

	const gains = getStatus(
		"",
		totalGain >= 0 ? PLUS : MINUS,
		`${totalGain} (${totalPercentGain}%)`
	);

	const setAccount = (value) => {
		const acc = portfolio_accounts?.find(({ id }) => id === value);
		acc?.margin ? history.push("/margin-trade") : handleAccount(acc);
	};

	return (
		<div className="flex__between mb-5 content__slider">
			{!isMargin && (
				<div className="mb-4" style={{ minWidth: "180px" }}>
					<p className="heading4 ">Select Portfolio</p>
					<SmallSelect
						nocase
						value={selectedAccount}
						onChange={({ target: { value } }) => setAccount(value)}
						options={options}
					/>
				</div>
			)}

			<div className="me-5">
				<p className="heading4 m-0">Portfolio Value</p>
				<span className="heading2 small">
					{currency} {toCurrency(total)}
				</span>
				<span className="fw-bold ps-3">{gains}</span>
			</div>

			{!isMargin && (
				<div className="text-sm-end text-start">
					<p className="heading4 m-0">Available Balance </p>
					<span className="heading2 small">
						{currency} {toCurrency(cashBalance || 0)}
					</span>
				</div>
			)}

			{isMargin && (
				<>
					<div className="text-start me-5">
						<p className="heading4 m-0">Purchasing Power </p>
						<span className="heading2 small">
							{currency} {toCurrency(md?.trading_power)}
						</span>
					</div>

					<div className="text-start me-5">
						<p className="heading4 m-0">Current Equity Value</p>
						<span className="heading2 small">
							{currency} {toCurrency(md?.equity_value)}
						</span>
					</div>

					<div className="text-start me-5">
						<p className="heading4 m-0">
							Current Maintenance Value
						</p>
						<span className="heading2 small">
							{currency} {toCurrency(md?.min_maintain_value)}
						</span>
					</div>

					<div className="text-start">
						<p className="heading4 m-0">Collateral Requirement</p>
						<span className="heading2 small">
							{currency} {toCurrency(md?.collateral_requirement)}
						</span>
					</div>
				</>
			)}
		</div>
	);
};

export default ValuationBalance;
