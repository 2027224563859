import React from "react";
import NINDetailsWrapper from "./NINDetailsWrapper/NINDetailsWrapper";
import AttachNinForm from "./AttachNinForm/AttachNinForm";
import AttachNinOtpVerification from "./AttachNinOtpVerification/AttachNinOtpVerification";

const AttachNin = ({
	ninStep,
	kycData,
	nextStep,
	previousStep,
	updateNinInfo,
	updateRegStatus,
}) => {
	const { ninInfo } = kycData || {};

	const render = () => {
		switch (ninStep) {
			case 3:
				return (
					<NINDetailsWrapper
						kycData={kycData}
						updateNinInfo={updateNinInfo}
						updateRegStatus={updateRegStatus}
					/>
				);

			case 2:
				return (
					<AttachNinOtpVerification
						ninInfo={ninInfo}
						nextStep={nextStep}
						previousStep={previousStep}
						updateNinInfo={updateNinInfo}
					/>
				);

			case 1:
			default:
				return (
					<AttachNinForm
						nin={ninInfo?.nin}
						nextStep={nextStep}
						updateNinInfo={updateNinInfo}
						updateRegStatus={updateRegStatus}
					/>
				);
		}
	};

	return <>{render()}</>;
};

export default AttachNin;
