import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";
import OTPVerificationSuccess from "../../../../Onboarding/OTPVerification/OTPVerificationSuccess/OTPVerificationSuccess";
import { BOOK_FIXED_INCOME } from "api/mutations/fixedIncome";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import { ERROR } from "../../../../constants";

const MoneyMarketForm = ({ showForm, selected, tenure, rule }) => {
	const [formErrors, setFormErrors] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const [successMsg, setSuccessMsg] = useState();
	const [request, setRequest] = useState({
		amount: "",
		roll_over_rule: "",
		tenure: "",
	});
	let history = useHistory();

	const [bookFixedIncome, { loading, error }] = useMutation(
		BOOK_FIXED_INCOME,
		{
			onCompleted: ({ book_fixed_portfolio }) =>
				setSuccessMsg(book_fixed_portfolio?.message),
			onError: (err) => setErrorMsg(getError(err)),
		}
	);

	const handleChange = ({ target: { name, value } }) => {
		value = value.replace(/[^0-9]/g, "");
		handleSelect(name, value);
	};

	const handleSelect = (name, value) => {
		setRequest((prev) => ({
			...prev,
			[name]: value,
		}));
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleAPICall = () => {
		setErrorMsg();
		bookFixedIncome({
			variables: {
				...request,
				roll_over_rule: request.roll_over_rule?.value,
				tenure: request.tenure?.value,
				instrument: selected?.id,
			},
		});
	};

	const toggleSuccess = () => {
		setSuccessMsg();
		history.push("/investment/money-market-history");
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator(request);
		isValid ? handleAPICall() : setFormErrors(formErrors);
	};

	return (
		<>
			{error && <Toast msg={errorMsg} variant={ERROR} />}

			{showForm && (
				<form onSubmit={handleSubmit} className="py-5 row">
					<div className="col-md-6 col-12 pe-md-5 pe-2">
						<CustomSelect
							name="roll_over_rule"
							label="Roll Over Rule"
							placeholder="Select Rule"
							value={request?.roll_over_rule}
							onChange={(e) => handleSelect("roll_over_rule", e)}
							error={formErrors?.roll_over_rule}
							options={rule || []}
							getOptionLabel={({ comment }) => comment}
							required
						/>
					</div>

					<div className="col-md-6 col-12 ps-md-5 ps-2">
						<CustomInputBox
							name="amount"
							label="Investment Amount"
							placeholder="Type here"
							value={request?.amount}
							onChange={handleChange}
							error={formErrors?.amount}
							required
						/>
					</div>

					<div className="col-md-6 col-12 pe-md-5 pe-2">
						<CustomSelect
							name="tenure"
							label="Tenure"
							placeholder="Type here"
							value={request?.tenure}
							onChange={(e) => handleSelect("tenure", e)}
							error={formErrors?.tenure}
							options={tenure || []}
							getOptionLabel={({ comment }) => comment}
							required
						/>
					</div>

					<div className="col-md-6 col-12 ps-md-5 ps-2">
						<CustomInputBox
							name="rate"
							label="Rate"
							value={selected?.rate}
							disabled
						/>
					</div>

					<div className="col-md-6 col-12 pe-md-5 pe-2">
						<CustomInputBox
							name="currency"
							label="Currency"
							value={selected?.currency}
							disabled
						/>
					</div>

					<div className="col-sm-4 col-12 pt-4 ps-md-5 ps-2">
						<button
							className="btn btn--lg btn--primary"
							disabled={loading}
						>
							{loading ? <CustomSpinner /> : "Continue"}
						</button>
					</div>

					{successMsg && (
						<OTPVerificationSuccess
							msg={successMsg}
							btnText="Done"
							toggle={toggleSuccess}
						/>
					)}
				</form>
			)}
		</>
	);
};

export default MoneyMarketForm;
