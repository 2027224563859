import { useState } from "react";
import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import { fetchUserData } from "storage/sessionStorage";
import { BUY } from "components/constants";
import { titleCase } from "utils/helpers";

export const CommissionTooltip = ({ type = BUY }) => {
	const [show, setShow] = useState(false);
	const { order_fee = [] } = fetchUserData();
	const fee = order_fee?.find((v) => titleCase(v.type) === titleCase(type));

	return (
		<div className="rel">
			<InfoIcon
				className="white"
				width={30}
				height={30}
				onMouseOver={() => setShow(true)}
				onMouseOut={() => setShow(false)}
			/>

			{show && (
				<ul className="tooltipContainer text--gray">
					<h3 className="heading4 mb-3 flex__start">FEES</h3>
					<li>
						Brokage Commission
						<span>
							{fee?.commission_percentage ?? 0}
							{fee?.commission_percentage > 0 && "%"}
						</span>
					</li>
					<li>
						CSCS Transaction Fee
						<span>
							{fee?.cscs_percentage ?? 0}
							{fee?.cscs_percentage > 0 && "%"}
						</span>
					</li>
					<li>
						CSCS Trade Alert
						<span>N{fee?.cscs_alert ?? 0}</span>
					</li>
					<li>
						NSE Fee
						<span>
							{fee?.exchange_percentage ?? 0}
							{fee?.exchange_percentage > 0 && "%"}
						</span>
					</li>
					<li>
						SEC Fee
						<span>
							{fee?.sec_percentage ?? 0}
							{fee?.sec_percentage > 0 && "%"}
						</span>
					</li>
					<li>
						Stamp Duties
						<span>
							{fee?.stamp_duty_percentage}
							{fee?.stamp_duty_percentage > 0 && "%"}
						</span>
					</li>
				</ul>
			)}
		</div>
	);
};
