import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { fetchUserData } from "storage/sessionStorage";

const PublicRoutes = ({ component: Component, ...rest }) => {
	const { accessToken } = fetchUserData();

	return (
		<Route
			{...rest}
			render={(props) =>
				accessToken ? (
					<Redirect to="/dashboard" />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default withRouter(PublicRoutes);
