export const ordersTableHeadings = [
	"Order No",
	"Date",
	"Order Type",
	"Total (NGN)",
	"Symbol",
	"Price Type",
	"Terms",
	"Order Status",
	"Quantity Requested",
	"Quantity Filled",
	"Quantity Pending",
	"",
];

export const orderExport = [
	{ label: "Order No", key: "id" },
	{ label: "Date", key: "statusDate" },
	{ label: "Order Type", key: "orderType" },
	{ label: "Quantity", key: "orderTotal" },
	{ label: "Symbol", key: "label" },
	{ label: "Price Type", key: "priceType" },
	{ label: "Order Status", key: "orderStatus" },
	{ label: "Quantity Filled", key: "quantityFilled" },
];
