import React, { useEffect, useRef } from "react";
import { createChart } from "lightweight-charts";
import ChartOverlay from "utils/ChartOverlay";
import { volumeSeriesOptions, histogramOption } from "../data";
import { getVolumeData } from "../helpers";

const MarketUpdateVolumeChart = ({ loading, stocks, mode }) => {
	const chartContainerRef = useRef();
	const chart = useRef();
	const series = useRef();

	useEffect(() => {
		chart.current = createChart(
			chartContainerRef.current,
			histogramOption(mode)
		);
		series.current = chart.current.addHistogramSeries(volumeSeriesOptions);
		series.current.setData(getVolumeData(stocks) || []);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (stocks) {
			const stockData = getVolumeData(stocks);
			series.current.setData([...stockData]);
			stocks?.length < 100 && chart.current.timeScale().fitContent();
		}
		// eslint-disable-next-line
	}, [stocks]);

	return (
		<div ref={chartContainerRef} className="chart__wrap">
			<ChartOverlay loading={loading} data={stocks} />
		</div>
	);
};

export default MarketUpdateVolumeChart;
