import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const TabsLink = ({ routes }) => {
	const { pathname } = useLocation();

	return (
		<div>
			<ul className="nav nav-tabs no-flex" id="myTab" role="tablist">
				{routes?.dropdown?.map(
					({ name, path, hide }) =>
						!hide && (
							<NavLink
								key={name}
								className="nav-item pe-sm-5 pe-4"
								to={path}
								role="presentation"
							>
								<button
									className={`nav-link px-0 py-3 ${
										pathname === path ? "active" : ""
									}`}
								>
									{name}
								</button>
							</NavLink>
						)
				)}
			</ul>
		</div>
	);
};

export default TabsLink;
