import React from "react";
import { useHistory } from "react-router-dom";
import Bids from "components/Dashboard/common/StatusTables/Bids/Bids";
import MarketCaps from "components/Dashboard/common/StatusTables/MarketCaps/MarketCaps";
import Offers from "components/Dashboard/common/StatusTables/Offers/Offers";
import MarketTrades from "components/Dashboard/MarketUpdates/MarketUpdateRightView/MarketTrades/MarketTrades";

const MarketOverviewSelectedStock = ({ stock, loading }) => {
	const { label, name, currency } = stock || {};
	const { bid_levels, offer_levels, trades } = stock || {};

	const history = useHistory();

	const tradeStock = () => {
		history.push({
			pathname: `/market/${name}`,
			state: { currency, label: name },
		});
	};

	return (
		<div className="row pb-5 mb-5">
			<div className="col-12 my-5">
				<div className="flex__between">
					<div className="stock-name">{label}</div>
					<button
						className="btn btn--sm btn--primary px-3"
						disabled={!label}
						onClick={tradeStock}
					>
						Trade This Stock
					</button>
				</div>
			</div>

			<Wrapper title="Market Trades">
				<MarketTrades loading={loading} currency="NGN" trade={trades} />
			</Wrapper>

			<Wrapper title="Bids">
				<Bids bids={bid_levels} loading={loading} />
			</Wrapper>

			<Wrapper title="Offers">
				<Offers offers={offer_levels} loading={loading} />
			</Wrapper>

			<Wrapper title="Market Caps">
				<MarketCaps stock={stock} />
			</Wrapper>
		</div>
	);
};

const Wrapper = ({ title, children }) => (
	<div className="col-lg-3 col-12 ">
		<div className="h-100">
			<div className="bg__gray flex__between py-2 px-3 rounded">
				<span className="heading4">{title}</span>
			</div>
			{children}
		</div>
	</div>
);

export default MarketOverviewSelectedStock;
