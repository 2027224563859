import React from "react";
import { connect } from "react-redux";
import { fetchUserData } from "storage/sessionStorage";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import { updateRegStatus, updateKinInfo } from "redux/actions/kycActions";
import AttachNextOfKin from "components/Dashboard/Popups/CompleteOnboarding/AttachNextOfKin/AttachNextOfKin";

const ProfileKYCKinInfo = ({ kyc, updateRegStatus, updateKinInfo }) => {
	const { kycData, status } = kyc || {};
	const { next_of_kin } = fetchUserData();
	const nextOfKinInfo = next_of_kin?.[0] || {};

	return (
		<>
			{status?.kin ? (
				<div className="row pt-3">
					<div className="col-md-6 col-12 pe-5">
						<CustomInputBox
							name="name"
							label="Next of Kin"
							placeholder="Next of Kin"
							value={nextOfKinInfo?.name}
							disabled
						/>
					</div>

					<div className="col-md-6 col-12 ps-md-5 ps-2 pe-md-0 pe-5">
						<CustomInputBox
							name="email"
							label="Next of Kin Email Address"
							placeholder="Next of Kin Email"
							value={nextOfKinInfo?.email}
							disabled
						/>
					</div>

					<div className="col-md-6 col-12 pe-5">
						<CustomInputBox
							name="phone"
							label="Next of Kin Phone Number"
							placeholder="Next of Kin Phone Number"
							value={nextOfKinInfo?.phone}
							disabled
						/>
					</div>

					<div className="col-md-6 col-12 ps-md-5 ps-2 pe-md-0 pe-5">
						<CustomInputBox
							name="relationship"
							label="Next of Kin Relationship"
							placeholder="Next of Kin Relationship"
							value={nextOfKinInfo?.relationship}
							disabled
						/>
					</div>

					<div className="col-12">
						<CustomInputBox
							name="address"
							label="Next of Kin Address"
							placeholder="Next of Kin Phone Number"
							value={nextOfKinInfo?.address}
							disabled
						/>
					</div>
				</div>
			) : (
				<AttachNextOfKin
					kycData={kycData}
					updateKinInfo={updateKinInfo}
					updateRegStatus={updateRegStatus}
				/>
			)}
		</>
	);
};

export default connect(({ kyc }) => ({ kyc }), {
	updateKinInfo,
	updateRegStatus,
})(ProfileKYCKinInfo);
