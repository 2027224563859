import React, { useState } from "react";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import Modal from "utils/Modal/Modal";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import { SECONDARY } from "../../../../../constants";

const defaultOptions = {
	upload: false,
	scan: false,
};

const UploadOptionSelect = ({ toggle, onSubmit }) => {
	const [filter, setFilter] = useState({
		upload: true,
		scan: false,
	});

	const isScan = filter["scan"] ? true : false;

	const handleCheck = (name) => {
		setFilter(() => ({
			...defaultOptions,
			[name]: true,
		}));
	};

	return (
		<Modal show={true} size="sm">
			<ModalHeader
				type={SECONDARY}
				toggle={toggle}
				title="Select an option"
			/>

			<div className="px-3">
				<CustomCheckBox
					label="Upload File"
					checked={filter?.upload}
					onClick={() => handleCheck("upload")}
				/>

				<div className="py-5">
					<CustomCheckBox
						label="Scan with OCR"
						checked={filter?.scan}
						onClick={() => handleCheck("scan")}
					/>
				</div>

				<button
					className="btn btn--lg btn--primary mb-4"
					onClick={() => onSubmit(isScan)}
				>
					Submit
				</button>
			</div>
		</Modal>
	);
};

export default UploadOptionSelect;
