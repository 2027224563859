import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";
import { EQUITY, MARKET, NSE, NGX, MARGIN } from "../../../../constants";
import Routes from "../../../routes";

const InvestmentNav = ({
	history,
	tabName,
	exchange,
	setExchange,
	accounts,
}) => {
	const { pathname } = useLocation();

	let options = accounts?.map(({ label, name, exchange, margin }) => ({
		label: `${label} - ${
			margin ? MARGIN : exchange === NSE ? NGX : exchange
		}`,
		value: `${margin ? MARGIN : exchange}|${name}`,
	}));

	const InvestmentRoutes = Routes.find(
		(route) => route.name === "Investment"
	)?.dropdown;

	const render = () => {
		switch (tabName) {
			case MARKET:
				return (
					<button
						style={{ width: "130px" }}
						className="btn btn--xm btn--primary mb-2"
						onClick={() =>
							history.push("/investment/money-market-history")
						}
					>
						View History
					</button>
				);
			case EQUITY:
			default:
				return (
					<div
						className="d-inline-block"
						style={{ minWidth: "180px" }}
					>
						<SmallSelect
							nocase
							value={exchange}
							onChange={({ target: { value } }) =>
								setExchange(value)
							}
							options={options}
						/>
					</div>
				);
		}
	};

	return (
		<div className="flex__between nav-tabs content__slider mb-0 mt-5">
			<ul
				className="nav  w-100 no-flex m-0"
				id="myTab"
				role="tablist"
				style={{ minWidth: "320px" }}
			>
				{InvestmentRoutes?.map(
					({ name, path, hide }) =>
						!hide && (
							<NavLink
								className="nav-item pe-sm-5 pe-4"
								to={path}
								key={name}
								role="presentation"
							>
								<button
									className={`nav-link px-0 py-3 ${
										pathname === path ? "active" : ""
									}`}
								>
									{name}
								</button>
							</NavLink>
						)
				)}
			</ul>
			{render()}
		</div>
	);
};

export default InvestmentNav;
