import React from "react";
import AddMarketToWatchlistButton from "../../../../MarketUpdates/button/AddMarketToWatchlistButton/AddMarketToWatchlistButton";
import SubmitOrderButton from "../../../../MarketUpdates/button/SubmitOrderButton/SubmitOrderButton";

const InvestmentTableActions = ({ stock, loading, hideWatchlist }) => {
	return (
		<div>
			{!hideWatchlist && (
				<AddMarketToWatchlistButton stock={stock?.name} useIcon />
			)}
			<SubmitOrderButton useText loading={loading} stock={stock} />
			<SubmitOrderButton useText loading={loading} stock={stock} isSell />
		</div>
	);
};

export default InvestmentTableActions;
