import React from "react";
import AssetAllocationLeftView from "./AssetAllocationLeftView/AssetAllocationLeftView";
import AssetAllocationRightView from "./AssetAllocationRightView/AssetAllocationRightView";
import "./AssetAllocation.scss";

const AssetAllocation = () => {
	return (
		<div className="dash__content assest__allocation">
			<div className="row">
				<AssetAllocationLeftView />
				<AssetAllocationRightView />
			</div>
		</div>
	);
};

export default AssetAllocation;
