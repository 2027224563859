import React from "react";
import SearchForm from "utils/CustomInputBox/SearchForm/AsyncSearchForm";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";
import GetNotifiedButton from "../../button/GetNotifiedButton/GetNotifiedButton";
import { getValueType } from "utils/helpers";

const MarketUpdateSearch = ({ history, sector }) => {
	const hideSector =
		sector === null || sector === "N/A" || sector === undefined;

	return (
		<div className="row mt-2" style={{ maxHeight: "50px" }}>
			<div className="col-md-7 col-12 d-flex align-items-start">
				<div className="form-group pt-2">
					<BackIcon
						height="20px"
						width="20px"
						onClick={() => history.goBack()}
						className="pointer"
					/>

					{!hideSector && (
						<span className="mx-4">
							{getValueType(sector?.toUpperCase())}
						</span>
					)}

					<GetNotifiedButton />
				</div>
			</div>

			<div className="col-md-5  d-md-block d-none">
				<SearchForm history={history} />
			</div>
		</div>
	);
};

export default MarketUpdateSearch;
