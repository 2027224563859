import React from "react";
import { CSVLink } from "react-csv";
import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";

const ExportAsCSV = ({ filename, cls, headers, data, hideText }) => {
	return (
		<CSVLink
			data={data}
			headers={headers}
			filename={`${filename}.csv`}
			target="_blank"
		>
			<span className={`flex__between pointer ms-3 ${cls}`}>
				<DownloadIcon />
				{!hideText && <h3 className="fw-bold mb-0 ms-2">Download</h3>}
			</span>
		</CSVLink>
	);
};

export default ExportAsCSV;
