import React from "react";
import SearchForm from "utils/CustomInputBox/SearchForm/AsyncSearchForm";

const AccountSummaryHeader = ({ history }) => {
	return (
		<div className="row mt-2" style={{ maxHeight: "55px" }}>
			<div className="col-md-9 col-12">
				<div className="heading2">Account Summary </div>
			</div>

			<div className="col-md-3  d-md-block d-none">
				<SearchForm history={history} />
			</div>
		</div>
	);
};

export default AccountSummaryHeader;
