import React from "react";
import _ from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import TableHead from "./TableHead";
import TableBody from "./TableBody";

const Table = ({
	headings = [],
	data = [],
	indexRow,
	cls,
	rowClick,
	maxHeight = 550,
}) => {
	const sortedData = _.sortBy(data, (val) => (indexRow ? val[indexRow] : ""));

	const containsAnyLetter = (str) => {
		return /[a-zA-Z]/.test(str);
	};

	const groupedData = _.groupBy(sortedData, (val) => {
		if (indexRow) {
			return containsAnyLetter(val[indexRow]?.charAt(0))
				? val[indexRow]?.charAt(0)
				: "";
		}
		return "";
	});

	const indexedData = _.map(groupedData, (_, key) => (
		<span onClick={() => scroll(key)}>{key}</span>
	));

	let tableData = _.flatMapDeep(
		_.map(groupedData, (value, key) =>
			value.map((val) => ({ value: val, id: key }))
		)
	);

	tableData = indexRow ? tableData : data;

	const scroll = (id) => {
		const section = document.querySelector(`#${id}`);
		section.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	return (
		<div className={`table__wrapper ${cls}`}>
			<Scrollbars autoHide autoHeight autoHeightMax={maxHeight}>
				<table className="table ">
					<TableHead headings={headings} />

					<TableBody data={tableData} rowClick={rowClick} />
				</table>
			</Scrollbars>

			{indexRow && (
				<div className="table__index">
					<Scrollbars autoHide autoHeight autoHeightMax={maxHeight}>
						{indexedData?.map((val, i) => (
							<p
								key={i}
								className="text-capitalize text--gray pointer"
							>
								{val}
							</p>
						))}
					</Scrollbars>
				</div>
			)}
		</div>
	);
};

export default Table;
