const accessDenied = "Authentication hook unauthorized this request";
const errorMessages = [
	"nin not found",
	"order could not be placed",
	"invalid username or password",
	"insufficient funds",
];

export const ErrorFormatter = (error = {}) => {
	const { extensions, message: errorMessage } = error;
	let { code } = extensions || {};

	let message = "";
	if (code != null) {
		code = code.toUpperCase();
	}

	if (errorMessage === accessDenied)
		return { message: "Invalid OTP provided" };

	if (errorMessages.includes(errorMessage.toLowerCase()))
		return { message: errorMessage };

	if (code === "BAD_USER_INPUT" || code === "INVALID_INPUT") {
		message =
			"The provided input is invalid. Please check your input and try again.";
	} else if (code === "UNAUTHORIZED") {
		message =
			"You are not authorized to perform this action. Please make sure you have the necessary permissions.";
	} else if (code === "FORBIDDEN") {
		message =
			"Access to the requested resource is forbidden. You do not have sufficient privileges to access it.";
	} else if (code === "NOT_FOUND") {
		message =
			"The requested resource could not be found. Please verify the provided information and try again.";
	} else if (code === "INTERNAL_SERVER_ERROR") {
		message =
			"Oops! Something went wrong on our end. We apologize for the inconvenience. Please try again later.";
	} else if (code === "GRAPHQL_VALIDATION_FAILED") {
		message =
			"The provided data is not valid. Please review your request and try again";
	} else if (code === "GRAPHQL_PARSE_FAILED") {
		message =
			"The data could not be parsed due to syntax errors. Please check your request and try again.";
	} else if (code === "CONFLICT") {
		message =
			"There is a conflict with the current state of the resource. Please resolve the conflict and try again.";
	} else if (code === "LIMIT_EXCEEDED") {
		message =
			"The requested operation exceeds the allowed limit. Please reduce your request frequency or contact support for assistance.";
	} else if (code === "INVALID_OPERATION") {
		message =
			"The requested operation is not supported. Please ensure you are using a valid operation and try again.";
	} else if (code === "UNAVAILABLE") {
		message =
			"Sorry, the requested resource is currently unavailable. Please try again later.";
	} else if (code === "TIMEOUT") {
		message =
			"Oops! The request took too long to complete. Please try again or contact support for assistance.";
	} else if (
		code === "INVALID_TOKEN" ||
		code === "UNAUTHORIZED_ACCESS" ||
		code === "INVALID_AUTHENTICATION"
	) {
		message =
			"Your authentication access is invalid or expired. Please log in again to obtain a valid access.";
	} else if (code === "DUPLICATE_ENTRY") {
		message =
			"Oops! The requested operation would result in a duplicate entry. Please provide unique data and try again.";
	} else if (code === "NETWORK_ERROR") {
		message =
			"There was an error while processing your request due to a network issue. Please check your internet connection and try again";
	} else if (code === "CONFLICT") {
		message =
			"Oops! There is a conflict with the current state of the resource. Please resolve the conflict and try again.";
	} else if (code === "INVALID_QUERY_PARAMETERS") {
		message =
			"The provided data are invalid. Please review the data and ensure all parameters are correct.";
	} else if (code === "THROTTLING_ERROR") {
		message =
			"Slow down! The requested operation has been throttled due to excessive requests. Please try again later.";
	} else if (code === "EXTERNAL_SERVICE_ERROR") {
		message =
			"Oops! An error occurred while interacting with an external service. Please try again or contact support for assistance.";
	} else if (code === "INVALID_PERMISSIONS") {
		message =
			"Sorry, you do not have sufficient permissions to perform the requested operation. Please contact the administrator for assistance.";
	} else if (code === "RESOURCE_NOT_FOUND") {
		message =
			"The requested resource could not be found. Please check the identifier or query parameters and try again.";
	} else if (code === "SERVER_ERROR") {
		message =
			"Oops! An internal server error occurred. Please try again later or contact support for assistance.";
	} else if (code === "UNSUPPORTED_MEDIA_TYPE") {
		message =
			"The provided media type is not supported. Please use a supported media type for the request.";
	} else if (code === "LIMIT_EXCEEDED") {
		message =
			"The limit for the requested operation has been exceeded. Please reduce the size or quantity of the requested data.";
	} else if (code === "INVALID_FILE_FORMAT") {
		message =
			"The uploaded file has an invalid format. Please ensure you are uploading a file in the correct format and try again.";
	} else if (code === "CONSTRAINT-VIOLATION") {
		message = "Duplicate Record Exists";
	} else if (code === "ACCESS-DENIED") {
		message = "You do not have permission to access this resource.";
	} else if (code === "VALIDATION-FAILED") {
		message =
			"Oops! Something went wrong with your request. If you need any help, feel free to reach out to our support team. We're here to assist you!";
	} else if (code === "UNEXPECTED") {
		message =
			"An internal server error occurred. Please try again later or contact support for assistance.";
	} else if (code === "DATA-EXCEPTION") {
		message =
			"An unexpected error occurred while processing your request. Please try again later.";
	} else {
		message =
			"Oops! Something went wrong with your request. If you need any help, feel free to reach out to our support team. We're here to assist you!";
	}

	return { message };
};
