import {
	BANKING,
	BUSINESS,
	CONSUMER,
	ECONOMY,
	ENGINEERING,
	PENDING,
	PLUS,
	RUNNING,
	TECH,
	TERMINATED,
	HEALTH,
	EDUCATION,
	POLITICS,
} from "../../components/constants";
import moment from "moment";
import { ReactComponent as Up } from "assets/images/icons/up.svg";
import { fetchUserData } from "storage/sessionStorage";
import { ErrorFormatter } from "./ErrorFormatter";

export const getError2 = (error) => {
	const errorMessage =
		error && error.message
			? titleCase(error.message?.replace(/[^a-z0-9+]+/gi, " "))
			: "Error Processing Request. Try again.";

	return errorMessage;
};

export const getError = (err = {}) => {
	const graphQLErrors = err?.graphQLErrors?.[0];
	const errorMsg = err?.response?.data || err?.data || err;

	const { error, message } =
		(graphQLErrors ? ErrorFormatter(graphQLErrors) : errorMsg) || {};

	let errorMessage = "";

	if (err) {
		if (message)
			errorMessage = titleCase(message?.replace(/[^a-z0-9+]+/gi, " "));
		else if (error?.message)
			errorMessage = titleCase(
				error?.message?.replace(/[^a-z0-9+]+/gi, " ")
			);
		else errorMessage = "Error Processing Request. Try again.";
	} else errorMessage = "Error Processing Request. Try again.";

	return errorMessage;
};

export const truncate = (str, len = 50) => {
	if (str?.length > len) {
		return str?.substring(0, len) + "...";
	} else {
		return str;
	}
};

export const titleCase = (string) => {
	if (string === null || string === undefined) {
		return "";
	} else {
		let text = string.toLowerCase().split("_");
		for (var i = 0; i < text.length; i++) {
			text[i] = text[i][0]?.toUpperCase() + text[i]?.slice(1);
		}
		return text.join(" ");
	}
};

export const textOnly = (string) => {
	if (string === "" || string === null || string === undefined) {
		return "";
	} else {
		let text = string.replace(/[^a-zA-Z]/g, " ");
		return titleCase(text);
	}
};

export const pureString = (text) => {
	const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
	return text.replace(regex, "");
};

export const preText = (text) => {
	const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
	return text.replace(regex, "<br />");
};

export const kebabCase = (str) =>
	str
		.match(
			/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
		)
		.join("-")
		.toLowerCase();

export const toCurrency = (n) => {
	if (n === null || n === undefined || n === "NaN") return 0;
	return Number(n)
		.toFixed(2)
		.replace(/./g, function (c, i, a) {
			return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
		});
};

export const getValueType = (status, otherCls) => {
	let cls = "";
	switch (status) {
		case CONSUMER:
		case ENGINEERING:
			cls = "blue__light";
			break;

		case TECH:
		case EDUCATION:
			cls = "purple__light";
			break;

		case BANKING:
		case TERMINATED:
		case HEALTH:
			cls = "danger__light";
			break;

		case BUSINESS:
		case ECONOMY:
		case RUNNING:
			cls = "primary__light";
			break;

		case PENDING:
		case POLITICS:
			cls = "bg-light";
			break;

		default:
			break;
	}
	return (
		<small className={`rounded px-4 py-2 ${cls} ${otherCls}`}>
			{titleCase(status.trim())}
		</small>
	);
};

export const manageText = (type) =>
	type === PLUS ? "text--primary" : "text-danger";

export const colors = [
	"#50556f",
	"#7a64cc",
	"#ff8e78",
	"#c23c3b",
	"#47b2f5",
	"#deff73",
	"#8a8e7c",
	"#4e4af1",
	"#d59608",
	"#6f6d73",
	"#2dcc7a",
	"#08fb7e",
	"#046790",
	"#c410f8fc",
	"#deff73",
];

export const timeDateFormat = (obj, format = "YYYY-MM-DD") => {
	if (!obj || obj === undefined) return "";

	const timeAgo = moment(obj).fromNow();

	const newObj = new Date(obj);

	const time = moment(newObj).format("hh:mm:ss a");
	const timeOnly = moment(newObj).format("hh:mm:ss");
	const date = moment(newObj).format(format);

	return { time, date, timeOnly, timeAgo };
};

export const timeRangeFormat = (obj, init) => {
	if (!obj) return null;

	const newDate = new Date(obj);
	init ? newDate.setHours(0, 0, 0, 0) : newDate.setHours(23, 59, 59, 0);
	return newDate;
};

export const dateToString = (obj) => {
	if (!obj) return null;

	const newDate = new Date(obj);
	const date = moment(newDate).format("LL");
	return date;
};

export const checkSameDay = (first, second) =>
	first?.getFullYear() === second?.getFullYear() &&
	first?.getMonth() === second?.getMonth() &&
	first?.getDate() === second?.getDate();

export const toPrice = (price_change) => {
	if (price_change < 0) {
		return (
			<span className="text-danger">
				{" "}
				-{toCurrency(Math.abs(price_change))}
			</span>
		);
	} else
		return (
			<span className="text--primary">+{toCurrency(price_change)}</span>
		);
};

export const toPriceStat = (price_change, price_change_p, show) => {
	if (price_change < 0) {
		return (
			<span className="text-nowrap">
				-N{toCurrency(Math.abs(price_change))}(
				{toCurrency(price_change_p)}%)
				{show ? (
					<Up
						className="danger mx-3"
						style={{ transform: "scaleY(-1)" }}
					/>
				) : (
					" "
				)}
			</span>
		);
	} else
		return (
			<>
				+N{toCurrency(price_change)}({toCurrency(price_change_p)}%)
				{show ? <Up className="primary mx-3" /> : " "}
			</>
		);
};

export const profitStatus = (val, show) => {
	return val < 0 ? (
		<div className="text-danger">
			-NGN{toCurrency(Math.abs(val))}
			{show && (
				<Up
					className="danger mx-3"
					style={{ transform: "scaleY(-1)" }}
				/>
			)}
		</div>
	) : (
		<div className="text--priamry">
			+NGN{toCurrency(val)}
			{show && <Up className="primary mx-3" />}
		</div>
	);
};

export const getStatus = (str, type, extraStr) => {
	return type === PLUS ? (
		<>
			<span className="text--primary">{extraStr}</span>
			<Up className="primary mx-3" />
			<span className="text--primary">{str}</span>
		</>
	) : (
		<>
			<span className="text-danger">{extraStr}</span>
			<Up className="danger mx-3" style={{ transform: "scaleY(-1)" }} />
			<span className="text-danger">{str}</span>
		</>
	);
};

export const priceStatus = (trade, price, i) => {
	const current = trade[i];
	const next = trade[i + 1];
	if (next) {
		const currentprice = current?.tradePrice || current?.price;
		const nextPrice = next?.tradePrice || next?.price;
		if (currentprice >= nextPrice)
			return <span className="text--primary">{toCurrency(price)}</span>;
		else return <span className="text-danger">{toCurrency(price)}</span>;
	} else return <span className="text--primary">{toCurrency(price)}</span>;
};

export const getCurrency = (currency) => {
	switch (currency) {
		case "NGN":
			return "N";

		case "USD":
			return "$";

		default:
			return "";
	}
};

export const getDateRange = (dateRange) => {
	switch (dateRange) {
		case "1D":
			return moment().subtract(1, "days").format();
		case "1W":
			// return moment().startOf("week").format();
			return moment().subtract(7, "days").format();
		case "1M":
			return moment().subtract(1, "months").format();
		case "3M":
			return moment().subtract(3, "months").format();
		case "6M":
			return moment().subtract(6, "months").format();
		case "1Y":
			return moment().subtract(1, "years").format();
		case "5Y":
			return moment().subtract(5, "years").format();
		case "All Time":
			return moment().subtract(60, "years").format();
		default:
			return moment(new Date()).format();
	}
};

export const getPhone = (phone) => {
	if (phone) return phone.charAt(0) === "0" ? `+234${phone.slice(1)}` : phone;
	return "";
};

export const slicePhone = (phone) => {
	if (phone.slice(0, 3) === "234") {
		phone = `0${phone.slice(3, phone.length)}`;
		return phone;
	}
	return phone;
};

export const switchers = ["ID", "1W", "1M", "3M", "6M", "1Y", "5Y", "All Time"];

export const calcTotal = (arrData, name) => {
	return arrData?.reduce((acc, arr) => {
		return acc + arr[name];
	}, 0);
};

export const getStockPlot = (data) => {
	return data?.reduce(
		(acc, arr) => [...acc, [moment(arr?.t).format("x"), arr.price]],
		[]
	);
};

export const downloadPDF = (data, fileName = "file.pdf") => {
	const url = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", fileName);
	document.body.appendChild(link);
	link.click();
};

export const getBalance = () => {
	let { portfolio_accounts, account, cash_balance } = fetchUserData();
	const margin = portfolio_accounts?.find((acc) => acc?.margin === true);
	const cashBalance = cash_balance?.balance;
	const totalValuation = calcTotal(portfolio_accounts, "valuation");
	const totalBalance = cashBalance + totalValuation;

	return {
		cashBalance,
		totalValuation,
		totalBalance,
		margin,
		account,
		margin_details: margin?.margin_details || {},
	};
};

export const copyToClipboard = (value) => {
	const tempInput = document.createElement("input");
	tempInput.value = value;
	document.body.appendChild(tempInput);
	tempInput.select();
	tempInput.focus();
	document.execCommand("copy");
	document.body.removeChild(tempInput);
	return true;
};

export const b64toBlob = ({ b64Data, fileName, contentType, sliceSize }) => {
	contentType = contentType || "";
	sliceSize = sliceSize || 512;

	var byteCharacters = atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	return new File(byteArrays, fileName, { type: contentType });
};

export const clearMessage = ({ setErrorMsg, time = 5000 }) => {
	setTimeout(() => {
		setErrorMsg?.("");
	}, time);
};

export const getUploadProgress = (progressEvent, cb) => {
	const { loaded, total } = progressEvent;
	let percent = Math.floor((loaded * 100) / total);
	cb?.(percent);
	return percent;
};

export const separateNumWithCommas = (n) => {
	return String(n)
		.replace(/\D/g, "")
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatFloatInput = (value) => {
	let _value = value;

	if (_value === "") return "";

	const [firstDigit, secondDigit] = _value;

	if (firstDigit === ".") {
		_value = "0" + _value;
	}

	if (firstDigit === "0") {
		if (secondDigit === "0") {
			return _value.substring(1);
		}

		if (secondDigit?.match(/[0-9]/)) {
			_value = _value.substring(1);
		}
	}

	const decimalPos = _value.indexOf(".");

	if (decimalPos >= 0) {
		const int = separateNumWithCommas(_value.substring(0, decimalPos));

		const frac = _value
			.substring(decimalPos + 1)
			.replace(/[^0-9]/g, "")
			.substring(0, 2);

		_value = [int, ".", frac].join("");
	} else {
		_value = separateNumWithCommas(_value);
	}

	return _value;
};
