import React from "react";
import LatestNews from "./LatestNews/LatestNews";
import Welcome from "./Welcome/Welcome";
import MarketUpdates from "./MarketUpdates/MarketUpdates";
import ScrollingAllocation from "./ScrollingAllocation/ScrollingAllocation";
import BalanceBox from "./BalanceBox/BalanceBox";
import DashboardWatchlist from "../DashContentRightView/DashboardWatchlist/DashboardWatchlist";
import PortfolioSummaryChart from "./PortfolioSummaryChart/PortfolioSummaryChart";
import DashboardPortfolios from "../../common/DashboardPortfolios/DashboardPortfolios";

const DashContentLeftView = ({ history }) => {
	return (
		<div className="col-lg-8 col-12 left__view">
			<Welcome history={history} />
			<BalanceBox history={history} />
			<div className="content__slider d-lg-none d--block">
				<DashboardWatchlist history={history} />
				<DashboardPortfolios history={history} />
			</div>

			<ScrollingAllocation history={history} />

			<PortfolioSummaryChart showTitle />

			<MarketUpdates history={history} />
			<LatestNews history={history} />
		</div>
	);
};

export default DashContentLeftView;
