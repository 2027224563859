import { DARK_MODE } from "../../../constants";

const priceScaleLeft = {
	position: "left",
	borderColor: "#efefef",
	scaleMargins: {
		top: 0.3,
		bottom: 0.25,
	},
	borderVisible: true,
};

const grid = {
	vertLines: {
		visible: false,
	},
	horzLines: {
		visible: false,
	},
};

const layout = ({ meriMode }) => {
	const backgroundColor = meriMode === DARK_MODE ? "#011e11" : "#fff";
	const textColor = meriMode === DARK_MODE ? "#ccc" : "#011e11";
	return { backgroundColor, textColor };
};

const layoutWithBG = {
	backgroundColor: "rgba(246, 246, 246, 0.35)",
	textColor: "#d1d4dc",
};

export const areaOptions = {
	topColor: "rgba(109, 200, 159, 0.19)",
	bottomColor: "rgba(38,198,218, 0.04)",
	lineColor: "#078950",
	lineWidth: 2,
};

export const lineOptions = ({ meriMode }) => {
	const color = meriMode === DARK_MODE ? "#ccc" : "#078950";
	return {
		color,
		lineWidth: 2,
		lineStyle: 0,
	};
};

export const mainChartOptions = (mode) => ({
	priceScale: { ...priceScaleLeft },
	// timeScale: { ...timeScaleNone },
	timeScale: {
		borderColor: "#efefef",
	},
	layout: { ...layout(mode) },
	grid: { ...grid },
});

export const chartOptions = (mode) => ({
	priceScale: { ...priceScaleLeft },
	timeScale: {
		borderColor: "#efefef",
	},
	layout: { ...layout(mode) },
	grid: { ...grid },
});

export const smallChartOptions = {
	priceScale: {
		borderVisible: false,
	},
	timeScale: { borderVisible: false },
	layout: { ...layout },
	grid: { ...grid },
};

export const histogramOption = (mode) => ({
	...chartOptions(mode),
});

export const lineWithBackgroundOption = {
	...chartOptions,
	layout: { ...layoutWithBG },
};

export const volumeSeriesOptions = {
	color: "#26a69a",
	lineWidth: 2,
	priceFormat: {
		type: "volume",
	},
	overlay: true,
	scaleMargins: {
		top: 0.8,
		bottom: 0,
	},
};

export const barSeriesOptions = {
	thinBars: false,
	upColor: "rgba(0, 150, 136, 0.8)",
	downColor: "rgba(255, 82, 82, 0.8)",
	openVisible: true,
};

export const candleStockOptions = {};

export const areaData = [
	{ time: "2018-10-19", value: 54.9 },
	{ time: "2018-10-22", value: 54.98 },
	{ time: "2018-10-23", value: 57.21 },
	{ time: "2018-10-24", value: 57.42 },
	{ time: "2018-10-25", value: 56.43 },
	{ time: "2018-10-26", value: 55.51 },
	{ time: "2018-10-29", value: 56.48 },
	{ time: "2018-10-30", value: 58.18 },
	{ time: "2018-10-31", value: 57.09 },
	{ time: "2018-11-01", value: 56.05 },
	{ time: "2018-11-02", value: 56.63 },
	{ time: "2018-11-05", value: 57.21 },
	{ time: "2018-11-06", value: 57.21 },
	{ time: "2018-11-07", value: 57.65 },
	{ time: "2018-11-08", value: 58.27 },
	{ time: "2018-11-09", value: 58.46 },
	{ time: "2018-11-12", value: 58.72 },
	{ time: "2018-11-13", value: 58.66 },
	{ time: "2018-11-14", value: 58.94 },
	{ time: "2018-11-15", value: 59.08 },
	{ time: "2018-11-16", value: 60.21 },
	{ time: "2018-11-19", value: 60.62 },
	{ time: "2018-11-20", value: 59.46 },
	{ time: "2018-11-21", value: 59.16 },
	{ time: "2018-11-23", value: 58.64 },
	{ time: "2018-11-26", value: 59.17 },
	{ time: "2018-11-27", value: 60.65 },
	{ time: "2018-11-28", value: 60.06 },
	{ time: "2018-11-29", value: 59.45 },
	{ time: "2018-11-30", value: 60.3 },
	{ time: "2018-12-03", value: 58.16 },
	{ time: "2018-12-04", value: 58.09 },
	{ time: "2018-12-06", value: 58.08 },
	{ time: "2018-12-07", value: 57.68 },
	{ time: "2018-12-10", value: 58.27 },
	{ time: "2018-12-11", value: 58.85 },
	{ time: "2018-12-12", value: 57.25 },
	{ time: "2018-12-13", value: 57.09 },
	{ time: "2018-12-14", value: 57.08 },
	{ time: "2018-12-17", value: 55.95 },
	{ time: "2018-12-18", value: 55.65 },
	{ time: "2018-12-19", value: 55.86 },
	{ time: "2018-12-20", value: 55.07 },
	{ time: "2018-12-21", value: 54.92 },
	{ time: "2018-12-24", value: 53.05 },
	{ time: "2018-12-26", value: 54.44 },
	{ time: "2018-12-27", value: 55.15 },
	{ time: "2018-12-28", value: 55.27 },
	{ time: "2018-12-31", value: 56.22 },
	{ time: "2019-01-02", value: 56.02 },
	{ time: "2019-01-03", value: 56.22 },
	{ time: "2019-01-04", value: 56.36 },
	{ time: "2019-01-07", value: 56.72 },
	{ time: "2019-01-08", value: 58.38 },
	{ time: "2019-01-09", value: 57.05 },
	{ time: "2019-01-10", value: 57.6 },
	{ time: "2019-01-11", value: 58.02 },
	{ time: "2019-01-14", value: 58.03 },
	{ time: "2019-01-15", value: 58.1 },
	{ time: "2019-01-16", value: 57.08 },
	{ time: "2019-01-17", value: 56.83 },
	{ time: "2019-01-18", value: 57.09 },
	{ time: "2019-01-22", value: 56.99 },
	{ time: "2019-01-23", value: 57.76 },
	{ time: "2019-01-24", value: 57.07 },
	{ time: "2019-01-25", value: 56.4 },
	{ time: "2019-01-28", value: 55.07 },
	{ time: "2019-01-29", value: 53.28 },
	{ time: "2019-01-30", value: 54.0 },
	{ time: "2019-01-31", value: 55.06 },
	{ time: "2019-02-01", value: 54.55 },
	{ time: "2019-02-04", value: 54.04 },
	{ time: "2019-02-05", value: 54.14 },
	{ time: "2019-02-06", value: 53.79 },
	{ time: "2019-02-07", value: 53.57 },
	{ time: "2019-02-08", value: 53.95 },
	{ time: "2019-02-11", value: 54.05 },
	{ time: "2019-02-12", value: 54.42 },
	{ time: "2019-02-13", value: 54.48 },
	{ time: "2019-02-14", value: 54.03 },
	{ time: "2019-02-15", value: 55.16 },
	{ time: "2019-02-19", value: 55.44 },
	{ time: "2019-02-20", value: 55.76 },
	{ time: "2019-02-21", value: 56.15 },
	{ time: "2019-02-22", value: 56.92 },
	{ time: "2019-02-25", value: 56.78 },
	{ time: "2019-02-26", value: 56.64 },
	{ time: "2019-02-27", value: 56.72 },
	{ time: "2019-02-28", value: 56.92 },
	{ time: "2019-03-01", value: 56.96 },
	{ time: "2019-03-04", value: 56.24 },
	{ time: "2019-03-05", value: 56.08 },
	{ time: "2019-03-06", value: 55.68 },
	{ time: "2019-03-07", value: 56.3 },
	{ time: "2019-03-08", value: 56.53 },
	{ time: "2019-03-11", value: 57.58 },
	{ time: "2019-03-12", value: 57.43 },
	{ time: "2019-03-13", value: 57.66 },
	{ time: "2019-03-14", value: 57.95 },
	{ time: "2019-03-15", value: 58.39 },
	{ time: "2019-03-18", value: 58.07 },
	{ time: "2019-03-19", value: 57.5 },
	{ time: "2019-03-20", value: 57.67 },
	{ time: "2019-03-21", value: 58.29 },
	{ time: "2019-03-22", value: 59.76 },
	{ time: "2019-03-25", value: 60.08 },
	{ time: "2019-03-26", value: 60.63 },
	{ time: "2019-03-27", value: 60.88 },
	{ time: "2019-03-28", value: 59.08 },
	{ time: "2019-03-29", value: 59.13 },
	{ time: "2019-04-01", value: 59.09 },
	{ time: "2019-04-02", value: 58.53 },
	{ time: "2019-04-03", value: 58.87 },
	{ time: "2019-04-04", value: 58.99 },
	{ time: "2019-04-05", value: 59.09 },
	{ time: "2019-04-08", value: 59.13 },
	{ time: "2019-04-09", value: 58.4 },
	{ time: "2019-04-10", value: 58.61 },
	{ time: "2019-04-11", value: 58.56 },
	{ time: "2019-04-12", value: 58.74 },
	{ time: "2019-04-15", value: 58.71 },
	{ time: "2019-04-16", value: 58.79 },
	{ time: "2019-04-17", value: 57.78 },
	{ time: "2019-04-18", value: 58.04 },
	{ time: "2019-04-22", value: 58.37 },
	{ time: "2019-04-23", value: 57.15 },
	{ time: "2019-04-24", value: 57.08 },
	{ time: "2019-04-25", value: 55.85 },
	{ time: "2019-04-26", value: 56.58 },
	{ time: "2019-04-29", value: 56.84 },
	{ time: "2019-04-30", value: 57.19 },
	{ time: "2019-05-01", value: 56.52 },
	{ time: "2019-05-02", value: 56.99 },
	{ time: "2019-05-03", value: 57.24 },
	{ time: "2019-05-06", value: 56.91 },
	{ time: "2019-05-07", value: 56.63 },
	{ time: "2019-05-08", value: 56.38 },
	{ time: "2019-05-09", value: 56.48 },
	{ time: "2019-05-10", value: 56.91 },
	{ time: "2019-05-13", value: 56.75 },
	{ time: "2019-05-14", value: 56.55 },
	{ time: "2019-05-15", value: 56.81 },
	{ time: "2019-05-16", value: 57.38 },
	{ time: "2019-05-17", value: 58.09 },
	{ time: "2019-05-20", value: 59.01 },
	{ time: "2019-05-21", value: 59.5 },
	{ time: "2019-05-22", value: 59.25 },
	{ time: "2019-05-23", value: 58.87 },
	{ time: "2019-05-24", value: 59.32 },
	{ time: "2019-05-28", value: 59.57 },
];

export const volumeData = [
	{ time: "2018-10-19", value: 19.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-10-22", value: 52.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-10-23", value: 29.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-10-24", value: 37.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-10-25", value: 25.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-10-26", value: 24.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-10-29", value: 22.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-10-30", value: 25.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-10-31", value: 24.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-11-01", value: 22.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-11-02", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-05", value: 12.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-06", value: 12.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-07", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-08", value: 12.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-09", value: 17.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-12", value: 13.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-13", value: 13.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-11-14", value: 18.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-15", value: 17.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-16", value: 19.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-19", value: 18.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-20", value: 22.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-11-21", value: 18.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-11-23", value: 7.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-11-26", value: 12.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-27", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-11-28", value: 18.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-11-29", value: 14.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-11-30", value: 31.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-12-03", value: 27.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-04", value: 21.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-06", value: 26.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-07", value: 16.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-10", value: 16.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-12-11", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-12-12", value: 18.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-13", value: 15.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-14", value: 13.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-17", value: 18.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-18", value: 18.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-19", value: 20.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-12-20", value: 26.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-21", value: 55.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-24", value: 15.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2018-12-26", value: 17.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-12-27", value: 17.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-12-28", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2018-12-31", value: 15.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-02", value: 13.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-01-03", value: 19.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-04", value: 17.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-07", value: 16.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-08", value: 21.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-09", value: 18.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-01-10", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-11", value: 15.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-14", value: 11.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-15", value: 10.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-16", value: 13.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-01-17", value: 15.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-01-18", value: 16.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-22", value: 18.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-01-23", value: 15.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-24", value: 12.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-01-25", value: 17.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-01-28", value: 25.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-01-29", value: 33.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-01-30", value: 27.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-01-31", value: 28.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-01", value: 13.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-02-04", value: 15.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-02-05", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-06", value: 11.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-02-07", value: 12.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-02-08", value: 12.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-11", value: 9.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-12", value: 11.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-13", value: 11.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-14", value: 11.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-02-15", value: 15.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-19", value: 12.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-20", value: 11.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-21", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-22", value: 12.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-25", value: 15.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-02-26", value: 12.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-02-27", value: 8.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-02-28", value: 15.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-01", value: 10.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-04", value: 13.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-03-05", value: 15.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-03-06", value: 13.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-03-07", value: 12.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-08", value: 10.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-11", value: 13.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-12", value: 12.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-03-13", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-14", value: 12.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-15", value: 23.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-18", value: 12.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-03-19", value: 13.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-03-20", value: 11.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-21", value: 10.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-22", value: 25.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-25", value: 15.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-26", value: 12.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-27", value: 13.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-03-28", value: 20.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-03-29", value: 17.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-01", value: 16.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-04-02", value: 11.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-04-03", value: 15.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-04", value: 8.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-05", value: 11.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-08", value: 11.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-09", value: 10.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-04-10", value: 8.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-11", value: 9.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-04-12", value: 10.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-15", value: 7.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-04-16", value: 10.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-17", value: 15.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-04-18", value: 13.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-22", value: 11.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-23", value: 23.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-04-24", value: 13.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-04-25", value: 17.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-04-26", value: 16.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-29", value: 13.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-04-30", value: 15.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-01", value: 11.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-05-02", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-03", value: 9.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-06", value: 10.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-05-07", value: 12.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-05-08", value: 12.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-05-09", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-10", value: 10.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-13", value: 13.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-05-14", value: 12.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-05-15", value: 10.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-16", value: 11.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-17", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-20", value: 20.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-21", value: 14.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-22", value: 11.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-05-23", value: 11.0, color: "rgba(255,82,82, 0.8)" },
	{ time: "2019-05-24", value: 8.0, color: "rgba(0, 150, 136, 0.8)" },
	{ time: "2019-05-28", value: 3.0, color: "rgba(0, 150, 136, 0.8)" },
];

export const barData = [
	{
		time: "2018-12-19",
		open: 141.77,
		high: 170.39,
		low: 120.25,
		close: 145.72,
	},
	{
		time: "2018-12-20",
		open: 145.72,
		high: 147.99,
		low: 100.11,
		close: 108.19,
	},
	{
		time: "2018-12-21",
		open: 108.19,
		high: 118.43,
		low: 74.22,
		close: 75.16,
	},
	{ time: "2018-12-22", open: 75.16, high: 82.84, low: 36.16, close: 45.72 },
	{ time: "2018-12-23", open: 45.12, high: 53.9, low: 45.12, close: 48.09 },
	{ time: "2018-12-24", open: 60.71, high: 60.71, low: 53.39, close: 59.29 },
	{ time: "2018-12-25", open: 68.26, high: 68.26, low: 59.04, close: 60.5 },
	{ time: "2018-12-26", open: 67.71, high: 105.85, low: 66.67, close: 91.04 },
	{ time: "2018-12-27", open: 91.04, high: 121.4, low: 82.7, close: 111.4 },
	{
		time: "2019-01-28",
		open: 111.51,
		high: 142.83,
		low: 103.34,
		close: 131.25,
	},
	{
		time: "2019-01-29",
		open: 131.33,
		high: 151.17,
		low: 77.68,
		close: 96.43,
	},
	{ time: "2019-01-30", open: 106.33, high: 110.2, low: 90.39, close: 98.1 },
	{
		time: "2019-01-31",
		open: 109.87,
		high: 114.69,
		low: 85.66,
		close: 111.26,
	},
	{
		time: "2019-02-01",
		open: 108.19,
		high: 118.43,
		low: 74.22,
		close: 75.16,
	},
	{
		time: "2019-02-02",
		open: 109.87,
		high: 114.69,
		low: 85.66,
		close: 111.26,
	},
	{
		time: "2019-02-03",
		open: 109.87,
		high: 114.69,
		low: 85.66,
		close: 111.26,
	},
	{ time: "2019-02-04", open: 91.04, high: 121.4, low: 82.7, close: 111.4 },
	{
		time: "2019-02-05",
		open: 145.72,
		high: 147.99,
		low: 100.11,
		close: 108.19,
	},
	{
		time: "2019-02-06",
		open: 109.87,
		high: 114.69,
		low: 85.66,
		close: 111.26,
	},
	{
		time: "2019-02-07",
		open: 131.33,
		high: 151.17,
		low: 77.68,
		close: 96.43,
	},
	{
		time: "2019-02-08",
		open: 109.87,
		high: 114.69,
		low: 85.66,
		close: 111.26,
	},
	{ time: "2019-02-09", open: 68.26, high: 68.26, low: 59.04, close: 60.5 },
	{
		time: "2019-02-10",
		open: 109.87,
		high: 114.69,
		low: 85.66,
		close: 111.26,
	},
	{
		time: "2019-02-11",
		open: 131.33,
		high: 151.17,
		low: 77.68,
		close: 96.43,
	},
];
