import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import OnboardingHeader from "components/Onboarding/utils/OnboardingHeader/OnboardingHeader";
import OTPInput from "utils/OTPInput/OTPInput";
import OTPVerificationSuccess from "./OTPVerificationSuccess/OTPVerificationSuccess";
import Disclaimer from "components/Onboarding/utils/Disclaimer/Disclaimer";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "components/constants";
import { VERIFY_IDENTITY_OTP } from "api/mutations/auth";
import { getError, getPhone } from "utils/helpers";
import { REQUEST_OTP } from "api/mutations/auth";
import { SUCCESS } from "components/constants";

const OTPVerification = ({
	history,
	showOTPForm,
	nextStep,
	onboardData,
	updateAccountInfo,
	showFullNINForm,
	resendOptData = {},
}) => {
	const [otp, setOTP] = useState();
	const [showModal, setShowModal] = useState(false);
	const [message, setMessage] = useState();

	const { accountInformation } = onboardData || {};
	let { phone } = accountInformation || {};
	phone = phone?.length ? phone.slice(phone?.length - 4) : "";

	const [validateOTP, { loading }] = useMutation(VERIFY_IDENTITY_OTP, {
		onCompleted: ({ verify_identity_otp }) => {
			showFullNINForm &&
				updateAccountInfo({
					...verify_identity_otp,
					phone: getPhone(verify_identity_otp?.phone),
					country: { id: 156, iso: "NG", name: "Nigeria" },
				});
			toggle();
		},
		onError: (error) => setMsgStatus(getError(error)),
	});

	const [requestOTP, { loading: qLoading }] = useMutation(REQUEST_OTP, {
		onCompleted: ({ request_otp }) => {
			updateAccountInfo({ ...request_otp });
			setMsgStatus("OTP Resend Successfully", SUCCESS);
		},
		onError: (error) => setMsgStatus(getError(error)),
	});

	const toggle = () => setShowModal((prev) => !prev);

	const setMsgStatus = (msg, variant = ERROR) => setMessage({ msg, variant });

	const confirmVerification = () => {
		toggle();
		showFullNINForm ? showFullNINForm() : nextStep();
		showOTPForm();
	};

	const handleSubmit = () => {
		setMessage({});
		validateOTP({
			variables: { otp, token: accountInformation?.token },
		});
	};

	const handleResendOtp = () => {
		setMessage({});
		requestOTP({
			variables: {
				data: { ...resendOptData },
			},
		});
	};

	return (
		<div className="row px-5">
			<Toast {...message} />

			<OnboardingHeader title="Verify Phone Number" />

			<div className="col-12 py-4 px-sm-5 px-0">
				<p className="heading3 fw-bold mb-4">
					Enter OTP sent to the number ending with {`*******${phone}`}
				</p>

				<OTPInput
					name="otp"
					value={otp}
					onChange={(value) => setOTP(value)}
				/>

				<p className="mt-4 d-flex align-items-center">
					Didn't get OTP?.{" "}
					{qLoading ? (
						<span className="ms-3">
							<CustomSpinner />
						</span>
					) : (
						<b
							className="pointer text--primary ms-3"
							onClick={handleResendOtp}
						>
							Resend OTP
						</b>
					)}
				</p>
			</div>

			<div className="col-lg-6 col-12 pt-2 px-sm-5 px-0">
				<div className="flex__between">
					<button
						className="btn btn--lg btn--primary--bordered me-2"
						onClick={showOTPForm}
					>
						Back
					</button>
					<button
						className="btn btn--lg btn--primary ms-2"
						disabled={!otp || otp?.length < 6 || loading}
						onClick={handleSubmit}
					>
						{loading ? <CustomSpinner /> : "Verify"}
					</button>
				</div>
			</div>

			<div className="col-lg-6 col-12 pt-4 px-sm-5 px-0 text--gray">
				<div className="text-end">
					<Disclaimer history={history} />
				</div>
			</div>

			{showModal && (
				<OTPVerificationSuccess
					msg="Phone Number Verification Successful"
					btnText="Continue"
					toggle={confirmVerification}
				/>
			)}
		</div>
	);
};

export default OTPVerification;
