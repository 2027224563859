import React from "react";
import { useQuery } from "@apollo/client";
import CustomSelect from "../CustomSelect/CustomSelect";
import { GET_BANKS } from "api/queries/others/bank";

const BankSelect = ({
	label,
	name,
	value,
	error,
	isMulti,
	placeholder = "Select Bank",
	required,
	onChange,
	disabled,
	defaultValue,
}) => {
	const { loading, data } = useQuery(GET_BANKS);
	const { bank = [] } = data || {};

	return (
		<CustomSelect
			name={name}
			value={value}
			label={label}
			error={error}
			isMulti={isMulti}
			isLoading={loading}
			options={bank}
			disabled={disabled}
			placeholder={placeholder}
			onChange={onChange}
			required={required}
			defaultValue={defaultValue}
			getOptionLabel={({ name }) => name}
		/>
	);
};

export default BankSelect;
