import React, { useState, useRef } from "react";
import FileUpload from "utils/FileUpload/FileUpload";
import LivenessCheck from "../../LivenessCheck/LivenessCheck";
import UploadOptionSelect from "../UploadOptionSelect/UploadOptionSelect";

const AttachDocuments = ({
	request,
	formErrors,
	handleForm,
	setError,
	percent = 0,
	liveness_check,
	updateRegStatus,
}) => {
	const [showOCR, setShowOCR] = useState(false);
	const [showOptionSelect, setShowOptionSelect] = useState(false);
	const [currentRef, setCurrentRef] = useState();

	const fileRef = {
		identity: useRef(),
		utility: useRef(),
		signature: useRef(),
	};

	const handleClick = (isScan) => {
		isScan ? toggleOCR() : currentRef.current.click();
		setShowOptionSelect(false);
	};

	const handleOptionSelect = (name) => {
		setCurrentRef(fileRef[name]);
		setShowOptionSelect(true);
	};

	const toggleOCR = () => setShowOCR((prev) => !prev);

	const handleScan = (file) => {
		handleForm("passport", file);
		toggleOCR();
	};

	const handleUpload = (name, files) => {
		const file = files[0];
		if (
			file &&
			["image/png", "image/jpeg", "application/pdf"].includes(file.type)
		) {
			if (file.size > 1048576) {
				handleForm(name, "");
				setError(name, "File too Large, Max. of 1MB");
			} else handleForm(name, file);
		} else setError(name, "Please upload a valid image (png/jpg/pdf only)");
	};

	return (
		<>
			{showOCR && (
				<LivenessCheck
					onSubmit={handleScan}
					toggleOCR={toggleOCR}
					liveness_check={liveness_check}
					updateRegStatus={updateRegStatus}
				/>
			)}

			{showOptionSelect && (
				<UploadOptionSelect
					toggle={() => setShowOptionSelect(false)}
					onSubmit={handleClick}
				/>
			)}

			<div className="pb-3 fw-bold text--gray">
				<small>
					<small>
						**All files max. size of 1MB in png, jpg format only
					</small>
				</small>
			</div>

			<FileUpload
				name="passport"
				id="passport"
				label="Take Passport Photo"
				file={request?.passport}
				isSnap
				onSnapClick={() => toggleOCR(true)}
				error={formErrors?.passport}
				percent={percent}
			/>

			<FileUpload
				name="identity"
				id="identity"
				refFile={fileRef?.identity}
				click={() => handleOptionSelect("identity")}
				label="Upload National Passport/Driver’s License"
				file={request?.identity}
				onChange={(e) => handleUpload("identity", e)}
				error={formErrors?.identity}
				percent={percent}
			/>

			<FileUpload
				name="utility"
				id="utility"
				refFile={fileRef?.utility}
				label="Upload Scanned Utility Bill"
				file={request?.utility}
				click={() => handleOptionSelect("utility")}
				onChange={(e) => handleUpload("utility", e)}
				error={formErrors?.utility}
				percent={percent}
			/>

			<FileUpload
				name="signature"
				id="signature"
				refFile={fileRef?.signature}
				label="Upload signature "
				file={request?.signature}
				click={() => handleOptionSelect("signature")}
				onChange={(e) => handleUpload("signature", e)}
				error={formErrors?.signature}
				percent={percent}
			/>
		</>
	);
};

export default AttachDocuments;
