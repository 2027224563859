import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { GET_NOTIFICATIONS_SETTING } from "api/queries/notification";
import TableError from "utils/Table/TableError/TableError";
import { UPDATE_NOTIFICATIONS_SETTINGS } from "api/mutations/notification";
import { fetchUserData } from "storage/sessionStorage";
import { getError } from "utils/helpers";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR, SUCCESS } from "components/constants";
import { pushNotifFormDefaults } from "../enums";
import { INSERT_NOTIFICATIONS_SETTINGS } from "api/mutations/notification";

const PushNotifications = () => {
	const [request, setRequest] = useState(pushNotifFormDefaults);
	const [responseStatus, setResponseStatus] = useState({});
	const [isNew, setIsNew] = useState(true);
	const { username } = fetchUserData();

	const { error, loading: isLoadingNotification } = useQuery(
		GET_NOTIFICATIONS_SETTING,
		{
			onCompleted: (data) => {
				const newData = data?.notification_preference || [];
				setRequest((prev) => ({ ...prev, ...(newData?.[0] || {}) }));
				setIsNew(!newData.length);
			},
		}
	);

	const URL = isNew
		? INSERT_NOTIFICATIONS_SETTINGS
		: UPDATE_NOTIFICATIONS_SETTINGS;

	const [updateSettings, { loading }] = useMutation(URL, {
		onCompleted: (data) => {
			const newData =
				data?.insert_notification_preference_one ||
				data?.update_notification_preference?.returning;
			setRequest((prev) => ({ ...prev, ...(newData?.[0] || {}) }));
			setResponseStatus({
				status: SUCCESS,
				message: "Notification Preferences Updated Successfully",
			});
			setIsNew(false);
		},
		onError: (error) =>
			setResponseStatus({
				status: ERROR,
				message: getError(error),
			}),
	});

	const HandleSelect = (name, value) => {
		setRequest((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const HandleSubmit = (e) => {
		e.preventDefault();
		setResponseStatus({});
		const variables = {
			object: {
				transaction: request.transaction,
				withdrawal: request.withdrawal,
				enabled: request.enabled,
			},
		};
		!isNew && (variables.username = username);
		updateSettings({ variables });
	};

	return (
		<>
			<Toast
				msg={responseStatus?.message}
				variant={responseStatus?.status}
			/>

			{error ? (
				<TableError errorMsg="Error Fetching Notifications Settings" />
			) : (
				<form onSubmit={HandleSubmit}>
					<label className="my-4">
						Enable Push Notification For:
					</label>
					<CustomCheckBox
						checked={request?.transaction}
						cls="col-6"
						label="Transaction Notification"
						name="transaction"
						onClick={() =>
							HandleSelect("transaction", !request?.transaction)
						}
					/>

					<div className="my-4">
						<CustomCheckBox
							checked={request?.withdrawal}
							cls="col-6"
							label="Withdrawal Notification"
							name="withdrawal"
							onClick={() =>
								HandleSelect("withdrawal", !request?.withdrawal)
							}
						/>
					</div>

					<div className="my-4">
						<CustomCheckBox
							checked={request?.enabled}
							cls="col-6"
							label="Other Notification"
							name="enabled"
							onClick={() =>
								HandleSelect("enabled", !request?.enabled)
							}
						/>
					</div>

					<div className="col-md-5 col-12 mt-5">
						<button
							type="submit"
							className="btn btn--lg btn--primary"
							disabled={loading || isLoadingNotification}
						>
							{loading ? <Spinner /> : "Save"}
						</button>
					</div>
				</form>
			)}
		</>
	);
};

export default PushNotifications;
