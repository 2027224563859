import { gql } from "@apollo/client";

export const GET_ORDERS = gql`
	query GetOrders($arg: QueryInput!) {
		order(arg: $arg) {
			id
			statusDate
			orderType
			orderTotal
			label
			priceType
			orderTermName
			orderTermLabel
			orderStatus
			quantityFilled
			quantityPending
			quantityRequested
			portfolioLabel
			securityLabel
			securityName
			instrumentType
		}
	}
`;

export const ORDERS_FILTERS = gql`
	query MyQuery {
		order_status {
			comment
			value
		}
		order_type {
			comment
			value
		}
	}
`;
