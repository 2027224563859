import { matchPath } from "react-router";

export const getActiveRoute = (path, pathname, dropDowns) => {
	const matchFunction = (path) =>
		matchPath(pathname, {
			path,
			exact: false,
			strict: false,
		});
	let match = matchFunction(path);
	let isActive = dropDowns
		? dropDowns.find((x) => x.path === pathname || matchFunction(x.path))
		: false;
	return path === pathname || isActive || match ? "active" : "";
};
