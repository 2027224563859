import React from "react";
import Skeleton from "react-loading-skeleton";
import ShowChartButton from "../../button/ShowChartButton/ShowChartButton";
import MarketUpdateChart from "../../charts/MarketUpdateChart/MarketUpdateChart";
import { ReactComponent as MeriLogo } from "assets/images/icons/meri-logo.svg";
import { toPriceStat } from "utils/helpers";

const MarketUpdateLeftViewHeader = ({ loading, stock, toggle, mode }) => {
	const { label, currency, price_change, price_change_p } = stock || {};
	const isLoser = price_change < 0 ? true : false;

	return (
		<>
			{loading ? (
				<LoadingState />
			) : (
				<>
					<div className="d-flex align-items-center mt-n4">
						{stock?.logo_url ? (
							<img
								src={stock?.logo_url}
								alt=""
								className="fliud-mg"
							/>
						) : (
							<MeriLogo width={30} height={30} />
						)}
						<span className="heading2 fw-bold ">{label}</span>
					</div>

					<div className="flex__between flex-wrap mt-n4">
						<span>
							<span className="heading2">
								{currency}
								{stock?.price}
							</span>{" "}
							<small
								className={`${
									isLoser ? "text-danger" : "text--primary"
								}`}
							>
								{toPriceStat(
									price_change,
									price_change_p,
									true
								)}
							</small>
						</span>

						<ShowChartButton />
					</div>
				</>
			)}

			<MarketUpdateChart toggle={toggle} mode={mode} />
		</>
	);
};

const LoadingState = () => (
	<div className="w-100">
		<Skeleton height={30} />
		<div className="flex__between mt-3">
			<div className="w-100 me-5">
				<Skeleton height={30} />
			</div>
			<Skeleton height={30} width={50} />
		</div>
	</div>
);

export default MarketUpdateLeftViewHeader;
