import React from "react";
import Market from "assets/images/images/market.png";
import { dateToString } from "utils/helpers";

const ReportWrapper = ({ report }) => {
	const { title, created_at, file_url } = report || {};

	const download = () => window.open(file_url, "_blank");

	return (
		<div className="col-sm-6 col-12 portfolio__wrap">
			<div className="div__wrapper p-3">
				<div className="d-flex align-items-center p-4">
					<img
						src={Market}
						alt="Portfolio Report"
						height="45px"
						width="45px"
					/>
					<div className="ms-4">
						<p className="heading4 m-0">{title}</p>
						<span>{dateToString(created_at)}</span>
					</div>
					<button
						className="btn btn--xm px-4 primary__light ms-auto"
						onClick={download}
					>
						View
					</button>
				</div>
			</div>
		</div>
	);
};

export default ReportWrapper;
