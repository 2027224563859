import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import AccountReview from "./AccountReview/AccountReview";
import ConfirmFundOTP from "../ConfirmFundOTP/ConfirmFundOTP";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import { WITHDRAW_FUND } from "api/mutations/account";
import { ERROR } from "../../../../constants";

const WithdrawalFormModal = ({ request, toggle }) => {
	const [errorMsg, setErrorMsg] = useState();
	const [currentStep, setcurrentStep] = useState(1);
	const [otp, setOtp] = useState();

	const nextStep = () => setcurrentStep((prev) => prev + 1);

	const handleToggle = () => {
		setcurrentStep(1);
		toggle();
	};

	const [withdraw, { loading }] = useMutation(WITHDRAW_FUND, {
		context: { headers: { otp } },
		onCompleted: () => nextStep(),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleSubmit = () => {
		setErrorMsg();
		const { amount, account } = request || {};
		withdraw({
			variables: {
				account_number: account?.account_number,
				amount: parseFloat(amount),
			},
		});
	};

	const refresh = () => {
		handleToggle();
		window.location.reload(true);
	};

	const render = () => {
		switch (currentStep) {
			case 1:
			default:
				return (
					<AccountReview
						request={request}
						toggle={handleToggle}
						nextStep={nextStep}
					/>
				);

			case 2:
				return (
					<ConfirmFundOTP
						otp={otp}
						setOtp={setOtp}
						loading={loading}
						nextStep={handleSubmit}
						toggle={handleToggle}
					/>
				);

			case 3:
				return (
					<SuccessState
						btnText="Done"
						msg="Successful"
						toggle={refresh}
					/>
				);
		}
	};
	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />
			<Modal show={true}>{render()}</Modal>
		</>
	);
};

export default WithdrawalFormModal;
