export const portfolioTableHeading = [
	"Name",
	"Symbol",
	"Last Price (N)",
	"Quantity",
	"Price Paid (N)",
	"Current Value (N)",
	"Total Cost (N)",
	"Daily P/L (N)",
	"Daily P/L (%)",
	"Total P/L (N)",
	"Total P/L (%)",
	"",
];

export const dailyOptions = ["Daily P/L (N)", "Daily P/L (%)"];
export const totalOptions = ["Total P/L (N)", "Total P/L (%)"];
