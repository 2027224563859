import React from "react";

const snapshot = {
	ANSI: 43238.88,
	Deals: 0,
	Volume: 63737,
	Value: 36777,
	"Equity CAP": 3773737,
	"Bond CAP": 3773737,
	"ETF CAP": 3773737,
};

const SnapShot = () => {
	const data = Object.entries(snapshot);
	return (
		<>
			<div className="mt-4 pb-3 px-5 accord">
				<ul className="">
					<li className="flex__between fw-bold mb-4">
						<span>Market</span>
						<span>Valuation</span>
					</li>

					{data?.map((shot) => (
						<li className="flex__between py-3">
							<span>{shot[0]}</span>
							<span>{shot[1]}</span>
						</li>
					))}
				</ul>
			</div>
		</>
	);
};

export default SnapShot;
