import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { REQUEST_OTP } from "api/mutations/auth";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import { ERROR } from "../../../../../constants";

const AttachNinForm = ({
	nin,
	nextStep,
	updateNinInfo,
	updateRegStatus,
	isProfile,
}) => {
	const [formError, setFormError] = useState({});
	const [errorMsg, setErrorMsg] = useState();

	const [requestOtp, { loading }] = useMutation(REQUEST_OTP, {
		onCompleted: ({ request_otp }) => {
			updateNinInfo(request_otp);
			nextStep();
		},
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleChange = ({ target: { value } }) => {
		value = value.replace(/[^0-9]/g, "");
		updateNinInfo({ nin: value });
		setFormError({ nin: "" });
	};

	const handleAPICall = () => {
		setErrorMsg();
		requestOtp({ variables: { data: { nin } } });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator({ nin });
		isValid ? handleAPICall() : setFormError(formErrors);
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<form onSubmit={handleSubmit} className="px-5 pt-5">
				<CustomInputBox
					name="nin"
					label="Enter your Nin"
					placeholder="type here"
					value={nin}
					onChange={handleChange}
					error={formError?.nin}
					maxLength={11}
					required
				/>

				{isProfile ? (
					<button
						className="btn btn--lg btn--primary"
						disabled={nin?.length !== 11 || loading}
					>
						{loading ? <Spinner /> : "Continue"}
					</button>
				) : (
					<div className="row justify-content-center">
						<div className="col-sm-6 col-12">
							<div className="text-center pt-5">
								<button
									className="btn btn--lg btn--primary mt-5 mx-auto"
									disabled={nin?.length !== 11 || loading}
								>
									{loading ? <Spinner /> : "Continue"}
								</button>

								<p
									className="heading3 pointer mt-3 text--primary"
									onClick={() =>
										updateRegStatus({ completed: true })
									}
								>
									Skip
								</p>
							</div>
						</div>
					</div>
				)}
			</form>
		</>
	);
};

export default AttachNinForm;
