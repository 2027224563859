import { gql } from "@apollo/client";

export const GET_USER_TYPES = gql`
	query GetUserTypes {
		user_type {
			comment
			value
		}
	}
`;
