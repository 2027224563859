import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ORDERS } from "api/queries/orders";
import Toast from "utils/Alert/Toast/Toast";
import Table from "utils/Table/Table";
import { ALL, ERROR } from "components/constants";
import HistoryFilters from "./HistoryFilters/HistoryFilters";
import OrderHistoryMobile from "./OrderHistoryMobile/OrderHistoryMobile";
import { ordersTableHeadings } from "../extra";
import { TableLoader } from "utils/TableLoader/TableLoader";
import { timeDateFormat, timeRangeFormat } from "utils/helpers";
import TableError from "utils/Table/TableError/TableError";
import QuickTradeButton from "../../MarketUpdates/button/QuickTradeButton/QuickTradeButton";
import OrderActionButton from "../buttons/OrderActionButton/OrderActionButton";
import { fetchUserData } from "storage/sessionStorage";

const defaultFilters = {
	start_date: timeRangeFormat(new Date(), true),
	end_date: timeRangeFormat(new Date()),
	status: ALL,
	type: ALL,
};

const OrderHistory = ({ history }) => {
	const [orders, setOrders] = useState([]);
	const [errorMsg, setErrorMsg] = useState("");
	const [isToday, setIsToday] = useState(true);
	const [expanded, setExpanded] = useState(false);

	const { portfolio_accounts } = fetchUserData();
	const portfolio_id = portfolio_accounts?.[0]?.id;

	const [getOrders, { error: err, loading }] = useLazyQuery(GET_ORDERS, {
		fetchPolicy: "no-cache",
		onCompleted: (data) => setOrders(data?.order || []),
		onError: (error) => {
			setOrders([]);
			setErrorMsg(error);
		},
	});

	useEffect(() => {
		handleAPICall();
		// eslint-disable-next-line
	}, []);

	const handleAPICall = () => {
		const { type, status } = defaultFilters;
		getOrders({
			variables: {
				arg: {
					...defaultFilters,
					type: type === ALL ? "" : type,
					status: status === ALL ? "" : status,
					portfolio_id,
				},
			},
		});
	};

	const handleToggle = (panel) => (_, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const getTableData = () =>
		orders?.map((order) => ({
			orderNo: order?.id,
			date: order?.statusDate
				? timeDateFormat(order?.statusDate)?.date
				: "Not Available",
			type: order?.orderType,
			total: order?.orderTotal,
			label: order?.securityName,
			priceType: order?.priceType,
			orderTermName: order?.orderTermLabel,
			orderStatus: order.orderStatus,
			quantity: order?.quantityRequested,
			quantityFilled: order?.quantityFilled,
			quantityPending: order?.quantityPending,
			action: <OrderActionButton order={order} refetch={handleAPICall} />,
		}));

	const tableData = loading ? TableLoader(9) : getTableData();

	return (
		<>
			{err && <Toast msg={errorMsg} variant={ERROR} />}

			<div className="dash__content full__screen">
				<p className="heading2">
					Order History{" "}
					<span>({isToday ? "Today" : "Date Range"})</span>
					<span> ({orders?.length || 0})</span>
				</p>

				<HistoryFilters
					data={orders}
					history={history}
					getOrders={getOrders}
					setIsToday={setIsToday}
					currentFilters={{ ...defaultFilters, portfolio_id }}
				/>

				<div className="d-md-block d-none">
					<Table
						headings={ordersTableHeadings}
						data={tableData}
						cls="faint__bg"
						rowClick={() => {}}
					/>
				</div>

				<div className="d-md-none d-block">
					<div className="flex__between w-100 fw-bold bg__gray p-3">
						<span>Order No.</span>
						<span>Date</span>
						<span>Market Status</span>
					</div>

					{orders?.map((order, i) => (
						<OrderHistoryMobile
							id={`order${i}`}
							key={`order${i}`}
							data={order}
							expanded={expanded}
							handleToggle={handleToggle}
						/>
					))}
				</div>

				{!loading && orders?.length === 0 && (
					<TableError
						realBtn={
							<QuickTradeButton
								btnName="New Order"
								btnVariant="btn--sm px-5"
							/>
						}
						errorMsg="No Order Found!. Click on the button below to create order"
					/>
				)}
			</div>
		</>
	);
};

export default OrderHistory;
