import React from "react";
import WalletBalance from "../WalletBalance/WalletBalance";
import { ReactComponent as ManageAcct } from "assets/images/icons/manage-acct.svg";

const BankingWrapper = ({ btn, children, history, hideHeader }) => {
	return (
		<div className="dash__content assest__allocation">
			<div className="row">
				<div className="col-lg-8 col-12 left__view">
					{!hideHeader && (
						<div className="d-md-none d-block">
							<div className="flex__between">
								<span className="heading2">Banking</span>
								<ManageAcct
									className="pointer"
									onClick={() =>
										history.push("/banking/cards")
									}
								/>
							</div>

							<WalletBalance mobile />
						</div>
					)}

					{children}
				</div>
				<div className="col-xl-3 col-lg-4 right__view d-lg-block d-none">
					{btn ? btn : <div style={{ height: "60px" }} />}

					<div className="bg--primary px-4 pt-5 rounded">
						<WalletBalance />
					</div>
				</div>
			</div>
		</div>
	);
};

export default BankingWrapper;
