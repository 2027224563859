import React from "react";
import CustomSpinner from "./CustomSpinner/CustomSpinner";

const ChartOverlay = ({ loading, data = [] }) => {
	return (
		<>
			{(loading || data?.length === 0) && (
				<div className="loader">
					{loading && <CustomSpinner cls="text-success" />}
					{data?.length === 0 && !loading && (
						<h5 className="text--primary fw-bold">
							No record found for the date range selected
						</h5>
					)}
				</div>
			)}
		</>
	);
};

export default ChartOverlay;
