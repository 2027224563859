import React from "react";
import {
	TwitterIcon,
	FacebookIcon,
	WhatsappIcon,
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from "react-share";
import "./ReferralContent.scss";

const ReferralContent = ({ referralLink }) => {
	const quote = `I just won my first 1 Million Dollar by trading won Meritrade. 
	Go to ${referralLink} to get started.
		`;

	return (
		<div>
			<h4 className="text-center mt-3">
				<b>Referral Link:</b>
				<a
					className="text-primary ms-2 fw-bold"
					target="_blank"
					href={referralLink}
					rel="noreferrer"
				>
					{referralLink}
				</a>
			</h4>

			<div className="social__icons">
				<FacebookShareButton
					url={referralLink}
					quote={quote}
					hashtag={"#Meritrade"}
				>
					<FacebookIcon />
				</FacebookShareButton>

				<TwitterShareButton
					url={referralLink}
					title={quote}
					hashtags={["Meritrade"]}
				>
					<TwitterIcon />
				</TwitterShareButton>

				<WhatsappShareButton url={referralLink} title={quote}>
					<WhatsappIcon />
				</WhatsappShareButton>
			</div>
		</div>
	);
};

export default ReferralContent;
