import React from "react";

const MarketTabs = () => {
	return (
		<ul className="nav nav-tabs no-flex" id="myTab" role="tablist">
			<Tab active="active" id="markets" tabName="Market" />
			<Tab id="limit" tabName="Limit" />
			<Tab id="stop_limit" tabName="Stop Limit" />
		</ul>
	);
};

const Tab = ({ active, id, tabName }) => (
	<li className="nav-item" role="presentation">
		<button
			className={`nav-link ${active}`}
			id={`${id}-tab`}
			data-bs-toggle="tab"
			data-bs-target={`#${id}`}
			role="tab"
			aria-controls={id}
		>
			{tabName}
		</button>
	</li>
);

export default MarketTabs;
