export const storeUserData = (userData) => {
	try {
		const existingData = fetchUserData();
		const data = JSON.stringify({ ...existingData, ...userData });
		sessionStorage.setItem("userData", data);
	} catch (error) {
		throw new Error("Session storage permission is needed");
	}
};

export const fetchUserData = () => {
	try {
		let userData = sessionStorage.getItem("userData");
		userData = userData ? JSON.parse(userData) : {};
		return userData;
	} catch (error) {
		throw new Error("Session storage permission is needed");
	}
};

export const deleteUserData = () => {
	try {
		sessionStorage.removeItem("userData");
	} catch (error) {
		throw new Error("Session storage permission is needed");
	}
};
