import React from "react";
import { AccordionSummary } from "@material-ui/core";
import { ReactComponent as Flash } from "assets/images/icons/flash.svg";
import { ReactComponent as CaretUp } from "assets/images/icons/caret-up.svg";
import WatchListActions from "../WatchListActions/WatchListActions";

const DefaultWatchListCard = ({ init, id, name }) => {
	const watchlist = { id, name };

	return (
		<>
			<AccordionSummary expandIcon={<CaretUp className="rot-180" />}>
				<div className="flex__between  w-100 ">
					<div className="d-flex align-items-center">
						<div className="med-circle border p-3 bg__gray me-4">
							<Flash />
						</div>
						<p className="m-0 fw-bold pointer">
							{init ? "Your Default Listff" : name}
						</p>
					</div>

					<WatchListActions watchlist={watchlist} />
				</div>
			</AccordionSummary>
			<hr className="border-gray m-0" />
		</>
	);
};

export default DefaultWatchListCard;
