import React, { useState } from "react";
import BuyStock from "../../modal/BuyStock/BuyStock";
import SellStock from "../../modal/SellStock/SellStock";

const SubmitOrderButton = ({ useText, loading, stock, isSell }) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = (e) => {
		e.stopPropagation();
		setShowModal((prev) => !prev);
	};

	return (
		<>
			{isSell ? (
				<SellButton
					useText={useText}
					loading={loading}
					toggle={toggle}
				/>
			) : (
				<BuyButton
					useText={useText}
					loading={loading}
					toggle={toggle}
				/>
			)}

			{showModal && isSell && <SellStock stock={stock} toggle={toggle} />}

			{showModal && !isSell && <BuyStock stock={stock} toggle={toggle} />}
		</>
	);
};

const SellButton = ({ useText, toggle, loading }) => (
	<>
		{useText ? (
			<span className="text-danger pointer fw-bold " onClick={toggle}>
				Sell
			</span>
		) : (
			<button
				className="btn btn--secondary btn--sm mx-3 px-5"
				disabled={loading}
				onClick={toggle}
			>
				Sell
			</button>
		)}
	</>
);

const BuyButton = ({ useText, toggle, loading }) => (
	<>
		{useText ? (
			<span
				className="text--primary pointer fw-bold mx-5"
				onClick={toggle}
			>
				Buy
			</span>
		) : (
			<button
				className="btn btn--primary btn--sm px-5"
				disabled={loading}
				onClick={toggle}
			>
				Buy
			</button>
		)}
	</>
);

export default SubmitOrderButton;
