import { DARK_MODE, LIGHT_MODE } from "../components/constants";

export const getMode = () => {
	try {
		const mode = localStorage.getItem("meriMode");
		if (!mode) return LIGHT_MODE;
		else return mode === DARK_MODE ? DARK_MODE : LIGHT_MODE;
	} catch (err) {
		return LIGHT_MODE;
	}
};
