import React from "react";
import Disclaimer from "../../../Onboarding/utils/Disclaimer/Disclaimer";
import OnboardingHeader from "../../../Onboarding/utils/OnboardingHeader/OnboardingHeader";

const EmailSent = ({ history }) => {
	return (
		<>
			<OnboardingHeader title="Verify Your Email" />

			<div className="px-sm-5 px-0 mt-4">
				<div className="row justify-content-center">
					<div className="col-12 px-5 mb-4">
						<p className="text--gray fw-bold pt-5">
							A link has been sent to your mail
						</p>
					</div>

					<div className="col-sm-6 col-12 px-5 mb-4">
						<a
							href="https://mail.google.com"
							target="_blank"
							rel="noreferrer"
						>
							<button
								type="button"
								className="btn btn--lg btn--primary"
							>
								Go to email
							</button>
						</a>
					</div>

					<div className="col-sm-6 col-12 px-5 text-end text--gray">
						<Disclaimer history={history} />
					</div>
				</div>
			</div>
		</>
	);
};

export default EmailSent;
