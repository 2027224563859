import {
	NEXT_STEP,
	GO_BACK,
	SET_STEP,
	PREVIOUS_STEP,
	UPDATE_REG_STATUS,
	UPDATE_BANK_INFO,
	UPDATE_NIN_INFO,
	UPDATE_KIN_INFO,
} from "../actionTypes/kycActionTypes";

const nextStep = () => (dispatch) => dispatch({ type: NEXT_STEP });

const previousStep = () => (dispatch) => dispatch({ type: PREVIOUS_STEP });

const goBack = () => (dispatch) => dispatch({ type: GO_BACK });

const setNINStep = (step) => (dispatch) =>
	dispatch({ type: SET_STEP, payload: step });

const updateRegStatus = (status) => (dispatch) =>
	dispatch({ type: UPDATE_REG_STATUS, payload: status });

const updateKinInfo = (data) => (dispatch) =>
	dispatch({ type: UPDATE_KIN_INFO, payload: data });

const updateNinInfo = (data) => (dispatch) => {
	dispatch({
		type: UPDATE_NIN_INFO,
		payload: data,
	});
};

const updateBankInfo = (data) => (dispatch) => {
	dispatch({
		type: UPDATE_BANK_INFO,
		payload: data,
	});
};

export {
	nextStep,
	goBack,
	setNINStep,
	previousStep,
	updateRegStatus,
	updateBankInfo,
	updateNinInfo,
	updateKinInfo,
};
