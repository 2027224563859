import React from "react";
import { Loader } from "utils/TableLoader/TableLoader";

const WatchListLoader = ({ count = 5 }) => {
	return (
		<>
			{Array(count)
				.fill()
				.map((_, i) => (
					<div className="flex__between w-100 mb-3" key={i}>
						<div className="d-flex align-items-center flex-grow-1">
							<Loader
								className="me-4"
								circle={true}
								height={30}
								width={30}
							/>

							<div className="w-100">
								<Loader className="mt-4" height={10} />
							</div>
						</div>
						<Loader className="mt-4 ms-4" height={10} width={70} />
					</div>
				))}
		</>
	);
};

export default WatchListLoader;
