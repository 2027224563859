import React from "react";
import Table from "utils/Table/Table";
import { priceStatus } from "utils/helpers";
import { TableLoader } from "utils/TableLoader/TableLoader";
import TableError from "utils/Table/TableError/TableError";

const offersHeadings = ["Price (NGN)", "Quantity", "Count"];

const Offers = ({ loading, offers }) => {
	const getOffers = () =>
		offers?.map((offer) => ({
			price: priceStatus(offers, offer?.price),
			quantity: offer?.qty,
			count: offer?.count,
		}));

	const render = () => {
		const offerData = loading ? TableLoader(3) : getOffers();

		if (!loading && (!offers || offers?.length === 0))
			return <TableError errorMsg="New Offer Found" />;

		return (
			<Table
				cls="table_head_no_fill"
				data={offerData}
				headings={offersHeadings}
			/>
		);
	};

	return <div className="div__wrapper h-100">{render()}</div>;
};

export default Offers;
