import { gql } from "@apollo/client";

export const GET_PORTFOLIOS = gql`
	query MyQuery($id: String) {
		portfolio(id: $id) {
			costBasis
			gain
			marketPrice
			marketValue
			percentGain
			quantityHeld
			securityExchange
			priceValueMultiple
			securityLabel
			securityName
			securitySector
			valuation
			valueDate
			stock {
				price_change
				price_change_p
				previous_close
				price
			}
		}
	}
`;

export const GET_PORTFOLIO_REPORTS = gql`
	query PortfolioReports($date: timestamptz) {
		portfolio_report(where: { created_at: { _gte: $date } }) {
			created_at
			file_url
			id
			title
			updated_at
		}
	}
`;

export const GET_PORTFOLIO_REPORT = gql`
	query PortfolioReport($id: String) {
		portfolio_report_by_pk(id: $id) {
			created_at
			file_url
			id
			title
			updated_at
		}
	}
`;

export const FREQUENTLY_TRADED = gql`
	query FrequetlyTraded {
		frequently_traded {
			name
			stock {
				price
				price_change
				price_change_p
				logo_url
				label
				name
			}
		}
	}
`;
