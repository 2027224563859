import React, { useState } from "react";
import { ReactComponent as Avatar } from "assets/images/icons/user.svg";

const LivenessUploadUpload = ({ image = "", setImage }) => {
	const [error, setError] = useState("");

	const handleUpload = (files) => {
		const file = files[0];
		if (
			file &&
			["image/png", "image/jpeg", "image/jpg"].includes(file.type)
		) {
			if (file.size > 1048576) {
				setImage("");
				setError("File too Large, Max. of 1MB");
			} else {
				setError("");
				setImage(file);
			}
		} else setError("Please upload a valid image (png/jpg/jpeg only)");
	};

	return (
		<div className="text-center file__upload mb-0 mx-5">
			{image ? (
				<img
					src={URL.createObjectURL(image)}
					width={130}
					height={130}
					className="rounded-circle"
					alt=""
				/>
			) : (
				<Avatar width={130} height={130} />
			)}

			<input
				type="file"
				id="image__upload"
				name="image__upload"
				className="file-field"
				accept="image/jpg, image/jpeg, image/png"
				onChange={({ target: { files } }) => handleUpload(files)}
			/>

			<label htmlFor="image__upload" className="btn text--primary mt-4">
				Upload profile image
			</label>

			{error && (
				<small className="text-danger fw-bold d-block">{error}</small>
			)}
		</div>
	);
};

export default LivenessUploadUpload;
