import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import AddAccountForm from "./AddAccountForm/AddAccountForm";
import ConfirmFundOTP from "../ConfirmFundOTP/ConfirmFundOTP";
import {
	INSERT_SETTLEMENT,
	SET_SETTLEMENT_ACCOUNT,
} from "api/mutations/account";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "../../../../constants";
import { getError } from "utils/helpers";

const AddAccountModal = ({ toggle }) => {
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [currentStep, setCurrentStep] = useState(1);
	const [account, setAccount] = useState({});
	const [otp, setOtp] = useState();
	const [check, setCheck] = useState(false);

	const nextStep = () => setCurrentStep((prev) => prev + 1);

	const prevStep = () => setCurrentStep((prev) => prev - 1);

	const [attachSettlement] = useMutation(INSERT_SETTLEMENT, {
		context: { headers: { otp } },
		onCompleted: ({ insert_settlement_account_one }) => {
			const { reference } = insert_settlement_account_one || {};
			if (check) updateAccount({ variables: { reference } });
			else {
				setLoading(false);
				nextStep();
			}
		},
		onError: (error) => {
			setLoading(false);
			setErrorMsg(getError(error));
		},
	});

	const [updateAccount] = useMutation(SET_SETTLEMENT_ACCOUNT, {
		onCompleted: () => {
			setLoading(false);
			nextStep();
		},
		onError: (error) => {
			setErrorMsg(getError(error));
		},
	});

	const handleToggle = () => {
		setCurrentStep(1);
		toggle();
	};

	const refresh = () => {
		handleToggle();
		window.location.reload(true);
	};

	const sendOtp = (data) => {
		setAccount(data);
		nextStep();
	};

	const handleSubmit = () => {
		setLoading(true);
		setErrorMsg("");
		attachSettlement({ variables: { account } });
	};

	const render = () => {
		switch (currentStep) {
			case 1:
			default:
				return (
					<AddAccountForm
						check={check}
						setCheck={setCheck}
						toggle={handleToggle}
						setErrorMsg={setErrorMsg}
						nextStep={sendOtp}
					/>
				);

			case 2:
				return (
					<ConfirmFundOTP
						otp={otp}
						setOtp={setOtp}
						loading={loading}
						nextStep={handleSubmit}
						prevStep={prevStep}
						toggle={handleToggle}
					/>
				);

			case 3:
				return (
					<SuccessState
						btnText="Done"
						msg="Account Added Successful"
						toggle={refresh}
					/>
				);
		}
	};
	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />
			<Modal show={true}>{render()}</Modal>
		</>
	);
};

export default AddAccountModal;
