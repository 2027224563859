import React from "react";
import { ReactComponent as CloseIcon } from "assets/images/icons/closecover.svg";
import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";
import { ReactComponent as SuccessIcon } from "assets/images/icons/success.svg";

const DownloadMarginDoc = ({ toggle, nextStep }) => {
	return (
		<div className="p-4 fw-bold text--gray">
			<div className="flex__between border-bottom pb-2">
				<CloseIcon
					className="pointer ms-auto"
					width={18}
					onClick={toggle}
				/>
			</div>

			<div className="text-center mt-5">
				<SuccessIcon width="35" />

				<p className="heading3 my-4">Download Successful</p>

				<button
					className="btn btn--sm btn--primary px-5  mx-auto"
					onClick={nextStep}
				>
					<DownloadIcon className="me-3 white rot-180" />
					<span>Submit Margin Request Form</span>
				</button>
			</div>
		</div>
	);
};

export default DownloadMarginDoc;
