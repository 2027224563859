import React, { useState } from "react";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import AddWatchListModal from "../../modals/AddWatchListModal/AddWatchListModal";

const EditWatchlistButton = ({ useText, watchlist }) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	return (
		<>
			{useText ? (
				<span onClick={toggle}>Rename List</span>
			) : (
				<EditIcon
					height="20px"
					width="20px"
					className="me-md-4 me-0 pointer"
					onClick={toggle}
				/>
			)}

			{showModal && (
				<AddWatchListModal
					watchlist={watchlist}
					isUpdate
					toggle={toggle}
				/>
			)}
		</>
	);
};

export default EditWatchlistButton;
