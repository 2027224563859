import {
	CLEAR_ONBOARDING,
	NEXT_STEP,
	PREVIOUS_STEP,
	SHOW_OTP_FORM,
	SHOW_NIN_FULL_FORM,
	UPDATE_ACCOUNT_INFO,
	UPDATE_INVESTMENT_INFO,
	UPDATE_SIGNUP_OPTION,
} from "../actionTypes/onboardingActionTypes";

const nextStep = () => (dispatch) => dispatch({ type: NEXT_STEP });

const previousStep = () => (dispatch) => dispatch({ type: PREVIOUS_STEP });

const updateSignupOption = (option) => (dispatch) =>
	dispatch({ type: UPDATE_SIGNUP_OPTION, payload: option });

const showOTPForm = () => (dispatch) => dispatch({ type: SHOW_OTP_FORM });

const showFullNINForm = () => (dispatch) =>
	dispatch({ type: SHOW_NIN_FULL_FORM });

const updateAccountInfo = (accountInfo) => (dispatch) => {
	dispatch({
		type: UPDATE_ACCOUNT_INFO,
		payload: accountInfo,
	});
};

const updateInvestmentInfo = (investmentData) => (dispatch) => {
	dispatch({
		type: UPDATE_INVESTMENT_INFO,
		payload: investmentData,
	});
};

const clearOnboarding = () => (dispatch) =>
	dispatch({ type: CLEAR_ONBOARDING });

export {
	nextStep,
	previousStep,
	updateAccountInfo,
	updateInvestmentInfo,
	clearOnboarding,
	updateSignupOption,
	showOTPForm,
	showFullNINForm,
};
