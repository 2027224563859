import React from "react";
import { ReactComponent as AddIcon } from "assets/images/icons/add.svg";
import SearchForm from "utils/CustomInputBox/SearchForm/AsyncSearchForm";

const MarginPortfolioHeader = ({ history }) => (
	<div
		className="row mb-4 align-items-start justify-content-between"
		style={{ maxHeight: "55px" }}
	>
		<div className="col-md-4 col-6 d-md-inline-block d-none">
			<SearchForm history={history} />
		</div>
		<div className="col-md-4 col-12">
			<div className="form-group d-flex align-items-center justify-content-end">
				<AddIcon
					className="pointer"
					onClick={() => history.push("/investment/stocks")}
				/>

				<button
					className="btn btn--sm btn--primary px-4 ms-4"
					onClick={() => history.push("/portfolio")}
				>
					Go to Portfolio
				</button>
			</div>
		</div>
	</div>
);

export default MarginPortfolioHeader;
