import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { FIXED_INCOME } from "api/queries/fixedIncome";
import Scroll from "utils/Scroll/Scroll";
import { ReactComponent as Naira } from "assets/images/icons/scroll3.svg";
import MoneyMarketForm from "./MoneyMarketForm/MoneyMarketForm";
import { getError } from "utils/helpers";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR, MARKET } from "../../../constants";
import InvestmentWrapper from "../common/InvestmentWrapper/InvestmentWrapper";

const MoneyMarket = ({ history }) => {
	const [showForm, setShowForm] = useState(false);
	const [selected, setSelected] = useState();

	const toggleForm = (instrument) => {
		setShowForm(true);
		setSelected(instrument);
	};

	const { loading, error, data } = useQuery(FIXED_INCOME);
	const { fixed_income_rollover_rule, fixed_income_tenure, instrument_type } =
		data || {};

	if (loading) return <FixedLoading />;

	return (
		<InvestmentWrapper noTable history={history} type={MARKET}>
			{error && <Toast msg={getError(error)} variant={ERROR} />}

			<p className="heading4 mt-4 pt-2">
				Select an Instrument category below to begin
			</p>

			<div className="content__slider left__view">
				<Scroll>
					{instrument_type?.map((instrument) => (
						<ScrollItem
							key={instrument?.label}
							rate={instrument?.rate}
							label={instrument?.label}
							shadow={instrument?.id === selected?.id}
							toggleForm={() => toggleForm(instrument)}
						/>
					))}
				</Scroll>
			</div>

			<MoneyMarketForm
				showForm={showForm}
				selected={selected}
				rule={fixed_income_rollover_rule}
				tenure={fixed_income_tenure}
			/>
		</InvestmentWrapper>
	);
};

const ScrollItem = ({ label, rate, shadow, toggleForm }) => {
	const shade = shadow ? "shadow" : " ";
	return (
		<div className={`${shade} alloc  pointer `} onClick={toggleForm}>
			<Naira height="50px" width="50px" style={{ minWidth: "50px" }} />
			<div className="ms-4">
				<span className="fw-bold text--gray text-wrap">{label}</span>
				<p className="heading4 m-0">{rate}</p>
			</div>
		</div>
	);
};

const FixedLoading = () => {
	return (
		<div className="alloc d-flex  align-items-center">
			<Skeleton height={50} width={50} />
			<div className="ms-4">
				<Skeleton height={20} />
				<Skeleton height={20} />
			</div>
		</div>
	);
};
export default MoneyMarket;
