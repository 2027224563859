import React from "react";
import { ReactComponent as AddIcon } from "assets/images/icons/add.svg";

const AddCardButton = ({ useIcon, useText, toggle }) => {
	return (
		<>
			{useIcon ? (
				<AddIcon
					onClick={toggle}
					className="pointer d-md-none d-inline-block"
				/>
			) : useText ? (
				<div className="pointer fw-bold text--gray" onClick={toggle}>
					<AddIcon width="12" className="me-3 mt-n1" />
					<span>Add new withdrawal account</span>
				</div>
			) : (
				<div className="mb-4 pb-2">
					<button
						className="btn btn--sm btn--primary--bordered ms-auto px-4"
						onClick={toggle}
					>
						Add New Account
					</button>
				</div>
			)}
		</>
	);
};

export default AddCardButton;
