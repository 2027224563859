import React, { useState } from "react";
import { connect } from "react-redux";
import { fetchUserData } from "storage/sessionStorage";
import Toast from "utils/Alert/Toast/Toast";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import AttachDocuments from "../../../Popups/CompleteOnboarding/AttachSettlement/AttachDocuments/AttachDocuments";
import API from "api/rest";
import { updateRegStatus } from "redux/actions/kycActions";
import { formValidator } from "utils/FormValidator/FormValidator";
import { ERROR } from "../../../../constants";
import { getError, getUploadProgress } from "utils/helpers";

const ProfileAccountUpdateDocument = ({ updateRegStatus, kyc }) => {
	const [formErrors, setFormErrors] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const [loading, setLoading] = useState(false);
	const [docPercent, setPercent] = useState(0);
	const [request, setRequest] = useState({
		passport: "",
		utility: "",
		identity: "",
		signature: "",
	});

	const { status } = fetchUserData();
	const percent = status?.documents ? 100 : docPercent;

	const handleDocumentUpload = async () => {
		const formData = new FormData();
		formData.append("passport", request.passport);
		formData.append("utility", request.utility);
		formData.append("identity", request.identity);
		formData.append("signature", request.signature);

		setLoading(true);
		setErrorMsg();

		await API.post("/files/upload-documents", formData, {
			onUploadProgress: (progressEvent) =>
				getUploadProgress(progressEvent, setPercent),
		})
			.then(() => {
				setLoading(false);
				window.location.reload();
			})
			.catch((error) => {
				setLoading(false);
				setErrorMsg(getError(error));
			});
	};

	const handleForm = (name, file) => {
		setRequest((prev) => ({
			...prev,
			[name]: file,
		}));
		setError(name, "");
	};

	const setError = (name, value) => {
		setFormErrors((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator(request);
		isValid ? handleDocumentUpload() : setFormErrors(formErrors);
	};

	return (
		<div>
			{errorMsg && <Toast msg={errorMsg} variant={ERROR} />}

			<form onSubmit={handleSubmit}>
				<AttachDocuments
					percent={percent}
					request={request}
					formErrors={formErrors}
					handleForm={handleForm}
					setError={setError}
					liveness_check={kyc?.status?.liveness_check}
					updateRegStatus={updateRegStatus}
				/>

				{!status.documents && (
					<div className="col-6 pt-4">
						<button
							type="submit"
							className="btn btn--lg btn--primary"
							disabled={loading}
						>
							{loading ? <CustomSpinner /> : "Upload"}
						</button>
					</div>
				)}
			</form>
		</div>
	);
};

export default connect(({ kyc }) => ({ kyc }), {
	updateRegStatus,
})(ProfileAccountUpdateDocument);
