import React from "react";
import { ReactComponent as MeriLogo } from "assets/images/icons/meri-logo.svg";
import { ReactComponent as Facebook } from "assets/images/icons/facebook.svg";
import { ReactComponent as Twitter } from "assets/images/icons/twitter.svg";
import { ReactComponent as LinkedIn } from "assets/images/icons/linkedin.svg";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";
import { preText, timeDateFormat } from "utils/helpers";
import { useLocation } from "react-router";

const NotificationDetails = ({ currentData, history }) => {
	const { state } = useLocation();
	const data = currentData || state?.currentData;
	const { subject, body, created_at } = data || {};
	const { date, time, timeAgo } = timeDateFormat(created_at) || {};

	!data && history.push("/notifications");

	return (
		<div className="dash__content notification__details">
			<span className="d-lg-none d-flex align-items-center form-group mt-3">
				<BackIcon
					height="20px"
					width="20px"
					onClick={() => history.push("/notifications")}
					className="pointer"
				/>
				<span className="heading4 ms-4">Back</span>
			</span>

			<div className="flex__between w-100 notif__list pt-0 border-0">
				<div className="d-flex">
					<MeriLogo width="40px" height="40px" />
					<div className="ms-3">
						<p className="heading4 m-0">{subject}</p>
						<div className="">
							{created_at && (
								<span>{`${timeAgo} ... ${date} ${time}`}</span>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="notification__content mt-3">
				<div dangerouslySetInnerHTML={{ __html: preText(body) }} />

				<div className="pb-3">
					<div className="flex__center mb-3 mt-5 pt-5">
						<Twitter />
						<Facebook className="mx-5" />
						<LinkedIn />
					</div>
					<div className="w-100 text-center">
						<p>www.meristem.com</p>
						<p>Copyright © {new Date().getFullYear()}</p>
						<p> Meristem Securities Limited</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotificationDetails;
