import { gql } from "@apollo/client";

export const SUBMIT_ORDER = gql`
	mutation SubmitOrder(
		$account: String!
		$name: String!
		$term: String
		$price_limit: Float
		$price_type: String!
		$stop_price: Float
		$quantity: Int!
		$type: String!
	) {
		submit_order(
			account: $account
			name: $name
			price_type: $price_type
			price_limit: $price_limit
			quantity: $quantity
			type: $type
			term: $term
			stop_price: $stop_price
		) {
			message
			status
		}
	}
`;

export const SUBMIT_ORDER_2 = gql`
	mutation SubmitOrder(
		$account: String!
		$name: String!
		$price_limit: Float
		$price_type: String!
		$stop_price: Float
		$quantity: Int!
		$type: String!
	) {
		submit_order(
			account: $account
			name: $name
			price_type: $price_type
			price_limit: $price_limit
			quantity: $quantity
			type: $type
			stop_price: $stop_price
		) {
			message
			status
		}
	}
`;

export const SUBMIT_ORDER_ALL = gql`
	mutation SubmitOrder(
		$account: String!
		$name: String!
		$price_limit: Float
		$price_type: String!
		$quantity: Int!
		$type: String!
		$term: String
	) {
		submit_order(
			account: $account
			name: $name
			price_type: $price_type
			quantity: $quantity
			type: $type
			price_limit: $price_limit
			term: $term
		) {
			message
			status
		}
	}
`;

export const CANCEL_ORDER = gql`
	mutation CancelOrder($id: String!) {
		cancel_order(id: $id) {
			message
			status
		}
	}
`;
