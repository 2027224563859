import React from "react";

const AboutMarket = ({ profile }) => {
	return (
		<div className="mt-5 pt-md-5 pt-0">
			<p className="heading2 fw-bold">About</p>
			<span>{profile?.about}</span>

			<div className="row   mt-4">
				<div className="col-md-3 col-4">
					<strong className="text--primary">CEO</strong>
					<p>{profile?.ceo}</p>
				</div>
				<div className="col-md-3 col-4">
					<strong className="text--primary">Employees</strong>
					<p>{profile?.no_of_employees}</p>
				</div>
				<div className="col-md-3 col-4">
					<strong className="text--primary">Headquaters</strong>
					<p>{profile?.headquarters}</p>
				</div>
				<div className="col-md-3 col-4">
					<strong className="text--primary">Founded</strong>
					<p>{profile?.year_founded}</p>
				</div>
			</div>
		</div>
	);
};

export default AboutMarket;
