import React from "react";
import { connect } from "react-redux";
import MarketUpdateSearch from "./MarketUpdateSearch/MarketUpdateSearch";
import AboutMarket from "./AboutMarket/AboutMarket";
import MarketStatistics from "./MarketStatistics/MarketStatistics";
import MarketUpdateMobile from "./MarketUpdateMobile/MarketUpdateMobile";
import MarketUpdateLeftViewHeader from "./MarketUpdateLeftViewHeader/MarketUpdateLeftViewHeader";

const MarketUpdateLeftView = ({ loading, history, stock, mode }) => {
	const toggle = () => console.log();

	return (
		<div className="col-lg-8 col-12 left__view">
			<MarketUpdateSearch sector={stock?.sector} history={history} />

			<MarketUpdateLeftViewHeader
				stock={stock}
				loading={loading}
				toggle={toggle}
				mode={mode}
			/>

			<div className="d-md-block d-none">
				{stock?.company_profile && (
					<AboutMarket profile={stock?.company_profile} />
				)}
				<MarketStatistics stock={stock} />
			</div>

			<MarketUpdateMobile />
		</div>
	);
};

export default connect(({ mode }) => ({ mode }))(MarketUpdateLeftView);
