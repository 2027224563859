import React from "react";
import Table from "utils/Table/Table";
import { priceStatus } from "utils/helpers";
import { TableLoader } from "utils/TableLoader/TableLoader";
import TableError from "utils/Table/TableError/TableError";

const bidsHeadings = ["Price (NGN)", "Quantity", "Count"];

const Bids = ({ bids, loading }) => {
	const getBids = () =>
		bids?.map((bid) => ({
			price: priceStatus(bids, bid?.price),
			quantity: bid?.qty,
			count: bid?.count,
		}));

	const render = () => {
		const bidData = loading ? TableLoader(3) : getBids();

		if (!loading && (!bids || bids?.length === 0))
			return <TableError errorMsg="New Bid Found" />;

		return (
			<Table
				cls="table_head_no_fill"
				data={bidData}
				headings={bidsHeadings}
			/>
		);
	};

	return <div className="div__wrapper h-100">{render()}</div>;
};

export default Bids;
