import React, { useState } from "react";
import { ReactComponent as AddIcon } from "assets/images/icons/add.svg";
import AddWatchListModal from "../../modals/AddWatchListModal/AddWatchListModal";

const AddWatchlistButton = ({ useText }) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	return (
		<>
			{useText ? (
				<span onClick={toggle}>Add to List</span>
			) : (
				<AddIcon
					height="19px"
					width="19px"
					className="me-md-4 me-0 pointer"
					onClick={toggle}
				/>
			)}

			{showModal && <AddWatchListModal toggle={toggle} />}
		</>
	);
};

export default AddWatchlistButton;
