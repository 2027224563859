import React from "react";
import { useQuery } from "@apollo/client";
import Table from "utils/Table/Table";
import { GET_ORDER_STATEMENT } from "api/queries/statement";
import { TableLoader } from "utils/TableLoader/TableLoader";
import TableError from "utils/Table/TableError/TableError";
import { toCurrency, titleCase } from "utils/helpers";

const tableHeading = [
	"Item Name",
	"Type",
	"Quantity",
	"Purchase value (N)",
	"Daily P/L (N)",
	"Daily P/L (%)",
];

const RecentTransactions = ({ id }) => {
	const { loading, data } = useQuery(GET_ORDER_STATEMENT, {
		variables: { portfolio_id: id },
	});
	const orders = data?.order_statement
		? data?.order_statement.slice(0, 6)
		: [];

	const getTableData = () =>
		orders?.map((order) => ({
			name: order?.label,
			type: titleCase(order?.orderType),
			quantity: order?.quantity,
			purchaseValue: toCurrency(order?.contractAmount),
			dailyPL: toCurrency(order?.stock?.price_change),
			dailyPL2: toCurrency(order?.stock?.price_change_p),
		}));

	const tableData = loading ? TableLoader(6) : getTableData();

	return (
		<div className="col-lg-7 col-12 pt-5 mt-md-5 mt-0">
			<p className="heading4">Recent Transactions</p>

			<Table data={tableData} headings={tableHeading} />

			{!loading && orders.length === 0 && <TableError />}
		</div>
	);
};

export default RecentTransactions;
