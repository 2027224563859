export const CORPORATE_BENEFIT = [
	"Company",
	"Dividend Declared",
	"Bonus",
	"Dividend Yield",
	"Interim/Final",
	"Declaration Date",
	"Qualification Date",
];

export const CORPORATE_BENEFIT_HEADING = [
	...CORPORATE_BENEFIT,
	"Closure Date",
	"Payment Date",
	"AGM Date",
	"AGM Venue",
];

export const PAYMENT_DATE_HEADING = [...CORPORATE_BENEFIT, "Payment Date"];
export const CLOSURE_DATE_HEADING = [...CORPORATE_BENEFIT, "Closure Date"];
export const AGM_DATE_HEADING = [...CORPORATE_BENEFIT, "AGM Date"];

export const marketOptions = [
	{ label: "All", value: "all" },
	{ label: "Stocks", value: "EQUITY" },
	{ label: "Bonds", value: "BOND" },
];

export const stockMarketOptions = [
	{ label: "All", value: "all" },
	{ label: "NGX", value: "NSE" },
	{ label: "NASD", value: "NASD" },
	{ label: "MARGIN", value: "MARGIN" },
];

export const benefitExport = [
	{ label: "Company", key: "stock.label" },
	{ label: "Dividend Declared", key: "dividend_declared" },
	{ label: "Bonus", key: "bonus" },
	{ label: "Dividend Yield", key: "dividend_yield" },
	{ label: "Interim/Final", key: "final" },
	{ label: "Declaration Date", key: "declaration_date" },
	{ label: "Qualification Date", key: "qualification_date" },
	{ label: "Closure Date", key: "closure_date" },
	{ label: "AGM Date", key: "agm_date" },
	{ label: "AGM Venue", key: "agm_venue" },
];
