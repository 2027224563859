import {
	NEXT_STEP,
	GO_BACK,
	SET_STEP,
	PREVIOUS_STEP,
	UPDATE_REG_STATUS,
	UPDATE_BANK_INFO,
	UPDATE_NIN_INFO,
	UPDATE_KIN_INFO,
} from "../actionTypes/kycActionTypes";

const initialState = {
	ninStep: 1,
	status: {
		completed: false,
		completedUnchanged: false,
		identity: false,
		bvn: false,
		documents: false,
		settlement: false,
		bankVerified: false,
		kin: false,
		liveness_check: false,
		marginRequest: "",
	},
	kycData: {
		ninInfo: {
			nin: "",
			first_name: "",
			middle_name: "",
			last_name: "",
			email: "",
			phone: "",
			dob: "",
			mothers_maiden_name: "",
			nationality: "",
			state_of_origin: "",
			lga_of_origin: "",
			residence_street: "",
			residence_city: "",
			residence_state: "",
			postal_code: "",
			profession: "",
			employer_name: "",
			employer_address: "",
			religion: "",
			marital_status: "",
			token: "",
		},
		nextOfKinInfo: {
			email: "",
			name: "",
			phone: "",
			relationship: "",
			address: "",
		},
		bankInfo: {
			active: true,
			account_name: "",
			account_number: "",
			bank: "",
			bank_code: "",
			bank_name: "",
			sort_code: "",
		},
	},
};

const onboarding = (state = initialState, action) => {
	switch (action.type) {
		case NEXT_STEP:
			return { ...state, ninStep: state.ninStep + 1 };

		case PREVIOUS_STEP:
			return { ...state, ninStep: state.ninStep - 1 };

		case GO_BACK:
			return { ...state, ninStep: 1 };

		case SET_STEP:
			return { ...state, ninStep: action.payload };

		case UPDATE_REG_STATUS:
			return { ...state, status: { ...state.status, ...action.payload } };

		case UPDATE_BANK_INFO:
			return {
				...state,
				kycData: {
					...state.kycData,
					bankInfo: {
						...state.kycData.bankInfo,
						...action.payload,
					},
				},
			};

		case UPDATE_NIN_INFO:
			return {
				...state,
				kycData: {
					...state.kycData,
					ninInfo: {
						...state.kycData.ninInfo,
						...action.payload,
					},
				},
			};

		case UPDATE_KIN_INFO:
			return {
				...state,
				kycData: {
					...state.kycData,
					nextOfKinInfo: {
						...state.kycData.nextOfKinInfo,
						...action.payload,
					},
				},
			};

		default:
			return state;
	}
};

export default onboarding;
