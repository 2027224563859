import React, { useState } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";

const BankDetails = ({ bankInfo, handleChange }) => {
	const [isOpen, setIsOpen] = useState(true);

	const cls = isOpen ? "open" : "";

	const toggle = () => setIsOpen(!isOpen);
	return (
		<div className="mb-3">
			<div className={` bvn__tab ${cls}`} onClick={toggle}>
				Bank Information
			</div>

			<Collapse isOpen={isOpen}>
				<Card>
					<CardBody>
						<ul className="row m-0 bvn__details">
							<li className="col-6">
								<CustomInputBox
									value={bankInfo?.account_name}
									disabled
								/>
							</li>
							<li className="col-6">
								<CustomInputBox
									name="sort_code"
									value={bankInfo?.sort_code}
									placeholder="Sort Code"
									onChange={handleChange}
								/>
							</li>
						</ul>
					</CardBody>
				</Card>
			</Collapse>
		</div>
	);
};

export default BankDetails;
