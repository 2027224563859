import React, { useState } from "react";
import EnableMFAModal from "../../modals/EnableMFAModal/EnableMFAModal";
import DisableMFAModal from "../../modals/DisableMFAModal/DisableMFAModal";

const EnableMFAButton = ({ mfa }) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	return (
		<>
			<button
				className="btn btn--sm btn--primary px-4 ml-auto"
				onClick={toggle}
			>
				{mfa ? "Disable" : "Enable"}
			</button>

			{showModal &&
				(mfa ? (
					<DisableMFAModal toggle={toggle} />
				) : (
					<EnableMFAModal toggle={toggle} />
				))}
		</>
	);
};

export default EnableMFAButton;
