import React from "react";
import { useQuery } from "@apollo/client";
import { toCurrency, getBalance } from "utils/helpers";
import { GET_ORDERS } from "api/queries/orders";
import { EXECUTING } from "components/constants";

const WalletBalance = ({ mobile }) => {
	const { data } = useQuery(GET_ORDERS, {
		fetchPolicy: "no-cache",
		variables: { arg: { status: EXECUTING } },
	});

	const { cashBalance } = getBalance();
	const balance = data?.order?.reduce((acc, v) => acc + v.orderTotal, 0);
	const total = (balance ?? 0) + (cashBalance ?? 0);

	return (
		<>
			{mobile ? (
				<div className="row content__slider pt-4">
					<div className="col-9 mb-4">
						<Total val={total} />
					</div>
					<div className="col-9 mb-4 ">
						<Available val={cashBalance} />
					</div>
				</div>
			) : (
				<div className="row px-3">
					<div className="col-md-12 col-9 mb-5 bg--primary--light rounded pe-0 ps-2 overflow-hidden">
						<Total val={total} />
					</div>
					<div className="col-md-12 col-9 mb-5 primary__med rounded pe-0 ps-2 overflow-hidden">
						<Available val={cashBalance} />
					</div>
				</div>
			)}
		</>
	);
};

const Total = ({ val }) => (
	<div className="bg-white ps-md-4 ps-0 py-2">
		<p className="heading4 text--primary m-0">Total Balance </p>
		<h1 className="d-inline-block">N{toCurrency(val) || 0.0}</h1>
	</div>
);

const Available = ({ val }) => (
	<div className="bg-white ps-md-4 ps-0 py-2">
		<p className="heading4 text--primary--med m-0">Available Balance </p>
		<h1 className="d-inline-block">N{toCurrency(val) || 0.0}</h1>
	</div>
);

export default WalletBalance;
