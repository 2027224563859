import React from "react";
import {
	AccordionSummary,
	AccordionDetails,
	Accordion,
} from "@material-ui/core";
import { ReactComponent as CaretUp } from "assets/images/icons/caret-up.svg";
import { manageText } from "utils/helpers";
import { DailyPL, TotalPL } from "../../../../../constants";

const PortfolioCard = ({
	id,
	data,
	dailyOption,
	totalOption,
	expanded,
	handleToggle,
}) => {
	const { name, code, dailyPL, dailyPL2, totalPL, totalPL2, type } =
		data || {};
	const { lastPrice, quantity, pricePaid, currentValue, totalCost } =
		data || {};
	const daily = dailyOption === DailyPL ? dailyPL : dailyPL2;
	const total = totalOption === TotalPL ? totalPL : totalPL2;
	const open = expanded === id;

	return (
		<Accordion expanded={open} onChange={handleToggle(id)}>
			<AccordionSummary
				className={`p-0 ${open ? "" : "border-bottom"}`}
				expandIcon={<CaretUp className="rot-180" />}
			>
				<div className="d-flex align-items-center w-100 ">
					<div className="w-100">
						<span className="fw-bold d-block">{name}</span>
						<span>{code}</span>
					</div>
					<span
						style={{ minWidth: "70px" }}
						className={manageText(type)}
					>
						{daily}
					</span>
					<span
						style={{ minWidth: "70px" }}
						className={manageText(type)}
					>
						{total}
					</span>
				</div>
			</AccordionSummary>

			<AccordionDetails className="border mb-4 rounded ">
				<div className="flex__between px-3 mb-3">
					<small>
						Last Price <strong className="ps-3">{lastPrice}</strong>
					</small>
					<small>
						Quantity <strong className="ps-3">{quantity}</strong>
					</small>
				</div>

				<div className="flex__between px-3 mb-3">
					<small>
						Price Paid <strong className="ps-3">{pricePaid}</strong>
					</small>
					<small>
						Current Value{" "}
						<strong className="ps-3">{currentValue}</strong>
					</small>
				</div>
				<div className="px-3 mb-3">
					<small>
						Total Cost <strong className="ps-3">{totalCost}</strong>
					</small>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default PortfolioCard;
