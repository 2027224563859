import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import OTPVerificationSuccess from "../../Onboarding/OTPVerification/OTPVerificationSuccess/OTPVerificationSuccess";
import { ReactComponent as Logo } from "assets/images/icons/logo.svg";
import FailedState from "utils/StateStatus/FailedState";
import { VERIFY_EMAIL } from "api/mutations/auth";
import MeriLoader from "utils/Loader/MeriLoader/MeriLoader";

const VerifiyEmail = ({ history }) => {
	const [showModal, setShowModal] = useState(false);
	const [errorMsg, setErrorMsg] = useState();

	const [verify, { loading, error }] = useMutation(VERIFY_EMAIL, {
		onCompleted: () => setShowModal(true),
		onError: () => setErrorMsg("Error Verifying Your Account! Try Again"),
	});

	const { token } = useParams();

	useEffect(() => {
		handleAPICall();
		// eslint-disable-next-line
	}, []);

	const handleAPICall = () => verify({ variables: { token } });

	const render = () => {
		if (loading) return <MeriLoader />;
		if (error)
			return (
				<div className="text-center">
					<Logo className="mt-5" />
					<FailedState errorMsg={errorMsg} retry={handleAPICall} />;
				</div>
			);
		return "";
	};

	return (
		<>
			{render()}

			{showModal && (
				<OTPVerificationSuccess
					msg="Email Successfully Verified"
					btnText="Login"
					toggle={() => history.replace("/auth/login")}
				/>
			)}
		</>
	);
};

export default VerifiyEmail;
