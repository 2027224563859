import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import CustomPassword from "utils/CustomInputBox/CustomPassword/CustomPassword";
import { formValidator } from "utils/FormValidator/FormValidator";
import Toast from "utils/Alert/Toast/Toast";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import { passwordResetFormDefaults } from "../enums";
import { ERROR } from "../../../constants";
import { getError } from "utils/helpers";
import { REQUEST_OTP } from "api/mutations/auth";
import PasswordResetFormModal from "../modalss/PasswordResetFormModal";

const PasswordReset = () => {
	const [formErrors, setFormErrors] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [errorMsg, setErrorMsg] = useState();
	const [request, setRequest] = useState(passwordResetFormDefaults);

	const toggleModal = () => setShowModal((prev) => !prev);

	const [requestOtp, { loading }] = useMutation(REQUEST_OTP, {
		onCompleted: () => toggleModal(),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleClose = () => {
		setRequest(passwordResetFormDefaults);
		toggleModal();
	};

	const handleChange = ({ target: { name, value } }) => {
		setRequest((prev) => ({
			...prev,
			[name]: value,
		}));

		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setErrorMsg();
		const { formErrors, isValid } = formValidator(request);
		isValid
			? requestOtp({
					variables: { data: { mutation: "change_password" } },
			  })
			: setFormErrors(formErrors);
	};

	return (
		<form onSubmit={handleSubmit}>
			<Toast msg={errorMsg} variant={ERROR} />

			<CustomPassword
				error={formErrors?.current_password}
				label="Current Password"
				name="current_password"
				onChange={handleChange}
				placeholder="Enter current password"
				value={request?.current_password}
				required
			/>

			<CustomPassword
				error={formErrors?.password}
				label="New Password"
				name="password"
				onChange={handleChange}
				placeholder="Enter New Password"
				value={request?.password}
				required
			/>

			<CustomPassword
				error={formErrors?.confirm_password}
				label="Confirm Password"
				name="confirm_password"
				onChange={handleChange}
				placeholder="Confirm New Password"
				value={request?.confirm_password}
				required
			/>

			<div className="col-md-5 col-12 pt-4">
				<button className="btn btn--lg btn--primary" disabled={loading}>
					{loading ? <CustomSpinner /> : "Reset"}
				</button>
			</div>

			{showModal && (
				<PasswordResetFormModal
					toggle={handleClose}
					request={request}
				/>
			)}
		</form>
	);
};

export default PasswordReset;
