import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ProfileTabs from "./ProfileTabs/ProfileTabs";
import ProfileRoutes from "./ProfileRoutes";
import "./Profile.scss";

const Profile = () => {
	const getRoutes = () => (
		<Switch>
			{ProfileRoutes.map(({ path, exact, component }, i) => (
				<Route
					key={i}
					path={path}
					exact={exact}
					component={component}
				/>
			))}
			<Route
				exact
				path="*"
				component={() => <Redirect to="/profile/account/user" />}
			/>
		</Switch>
	);
	return (
		<div className="profile">
			<div className="row d-md-flex d-none h-100">
				<ProfileTabs />
				<div className="col-lg-7 col-md-9 col-12 pt-5 ">
					{getRoutes()}
				</div>
			</div>
			<div className="row d-md-none d-block">
				<div className="col-lg-7 col-md-9 col-12 pt-5">
					{getRoutes()}
				</div>
			</div>
		</div>
	);
};

export default Profile;
