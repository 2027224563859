import React, { useState, useEffect } from "react";
import { AppBar } from "@material-ui/core";
import Sidebar from "./Sidebar/Sidebar";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import "./Header.scss";

const Header = ({ history }) => {
	const [state, setState] = useState({
		mobileView: false,
		drawerOpen: false,
	});
	const { mobileView, drawerOpen } = state;

	useEffect(() => {
		const setResponsiveness = () => {
			return window.innerWidth < 1060
				? setState((prevState) => ({ ...prevState, mobileView: true }))
				: setState((prevState) => ({
						...prevState,
						mobileView: false,
				  }));
		};
		setResponsiveness();
		window.addEventListener("resize", () => setResponsiveness());
		return () => {
			window.removeEventListener("resize", () => setResponsiveness());
		};
	}, []);

	const handleDrawerOpen = () =>
		setState((prev) => ({ ...prev, drawerOpen: true }));

	const handleDrawerClose = () =>
		setState((prev) => ({ ...prev, drawerOpen: false }));

	return (
		<header>
			<AppBar>
				{mobileView ? (
					<Sidebar
						drawerOpen={drawerOpen}
						handleDrawerOpen={handleDrawerOpen}
						handleDrawerClose={handleDrawerClose}
						history={history}
					/>
				) : (
					<HeaderMenu history={history} />
				)}
			</AppBar>
		</header>
	);
};

export default Header;
