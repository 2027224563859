import React, { useState } from "react";
import { ReactComponent as EyeIcon } from "assets/images/icons/eye.svg";
import ShowChartModal from "../../modal/ShowChartModal/ShowChartModal";

const ShowChartButton = ({ stock, handleClose }) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = (e) => {
		e.stopPropagation();
		setShowModal((prev) => !prev);
		handleClose && handleClose();
	};

	return (
		<>
			<div className="pointer ms-auto" onClick={toggle}>
				<EyeIcon />
				<span className="heading4 ms-2 text--primary">Show chart</span>
			</div>

			{showModal && <ShowChartModal stock={stock} toggle={toggle} />}
		</>
	);
};

export default ShowChartButton;
