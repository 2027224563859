import { ApolloClient, HttpLink, from, split } from "@apollo/client";
import ApolloLinkTimeout from "apollo-link-timeout";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { fetchUserData } from "storage/sessionStorage";
import { cache } from "./cache";

const wsLink = new WebSocketLink({
	uri: process.env.REACT_APP_MERITRADE_WSS_BASE_URL,
	options: {
		reconnect: true,
		connectionParams: {
			headers: {
				Authorization: `Bearer ${fetchUserData()?.accessToken}`,
			},
		},
	},
});

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_MERITRADE_HTTP_BASE_URL,
});

const timeoutLink = new ApolloLinkTimeout(20000);
const timeoutHttpLink = timeoutLink.concat(httpLink);

const authLink = setContext((_, { headers }) => {
	const { accessToken } = fetchUserData();
	if (accessToken) {
		return {
			headers: {
				...headers,
				authorization: `Bearer ${accessToken}`,
			},
		};
	} else {
		return {
			headers: {
				...headers,
			},
		};
	}
});

const errorLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		const { accessToken } = fetchUserData();
		if (graphQLErrors) {
			for (let err of graphQLErrors) {
				switch (err.extensions.code) {
					case "UNAUTHENTICATED":
						// Modify the operation context with a new token
						const oldHeaders = operation.getContext().headers;
						operation.setContext({
							headers: {
								...oldHeaders,
								authorization: accessToken,
							},
						});
						// Retry the request, returning the new observable
						return forward(operation);

					default:
						return "";
				}
			}
		}

		// To retry on network errors, we recommend the RetryLink
		// instead of the onError link. This just logs the error.
		if (networkError) {
			// console.log(`[Network error]: ${networkError}`);
		}
	}
);

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === "OperationDefinition" &&
			definition.operation === "subscription"
		);
	},
	wsLink,
	from([errorLink, authLink, timeoutHttpLink])
);

export const client = new ApolloClient({
	link: splitLink,
	cache,
});
