import React, { useState } from "react";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import EnableMFAForm from "./EnableMFAForm/EnableMFAForm";

const EnableMFAModal = ({ toggle }) => {
	const [currentStep, setcurrentStep] = useState(1);

	const nextStep = () => setcurrentStep((prev) => prev + 1);

	const handleToggle = () => {
		setcurrentStep(1);
		toggle();
	};

	const refresh = () => {
		handleToggle();
		window.location.reload(true);
	};

	const render = () => {
		switch (currentStep) {
			case 1:
			default:
				return (
					<EnableMFAForm
						mfa={true}
						toggle={handleToggle}
						nextStep={nextStep}
					/>
				);

			case 2:
				return (
					<SuccessState
						btnText="Done"
						msg="Two Factor Authentication Enabled"
						toggle={refresh}
					/>
				);
		}
	};
	return <Modal show={true}>{render()}</Modal>;
};

export default EnableMFAModal;
