import React, { useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { ReactComponent as Caret } from "assets/images/icons/caret.svg";
// import { fetchUserData } from "storage/sessionStorage";

const NavDropDropdown = ({ dropdown, title, history }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	// const { accounts } = fetchUserData();
	// const account = accounts ? accounts[0] : {};

	const handleClick = (e) => setAnchorEl(e.currentTarget);

	const handleClose = () => setAnchorEl(null);

	const handlePush = (path) => {
		handleClose();
		path && history.push(path);
	};

	return (
		<>
			<span
				className="menu__link"
				aria-controls={title}
				onClick={handleClick}
			>
				{title}
				<Caret className="ms-3 dark" />
			</span>
			<Menu
				id={title}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				style={{ marginTop: "30px" }}
				disableScrollLock={true}
			>
				{dropdown.map(({ name, path, hide }) => {
					return (
						!hide && (
							<MenuItem
								key={name}
								onClick={() => handlePush(path)}
							>
								{name}
							</MenuItem>
						)
					);
				})}
			</Menu>
		</>
	);
};

export default NavDropDropdown;
