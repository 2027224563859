import React from "react";
import { fetchUserData } from "storage/sessionStorage";
import AsyncSearchForm from "utils/CustomInputBox/SearchForm/AsyncSearchForm";
import { titleCase } from "utils/helpers";

const Welcome = ({ history }) => {
	const { first_name } = fetchUserData();

	return (
		<div className="row mt-2 " style={{ maxHeight: "50px" }}>
			<div className="col-md-7 col-12">
				<div className="heading2">Welcome {titleCase(first_name)}</div>
			</div>

			<div className="col-md-5  d-md-block d-none">
				<AsyncSearchForm history={history} />
			</div>
		</div>
	);
};

export default Welcome;
