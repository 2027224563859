import React from "react";
import LatestNewsResearch from "./LatestNewsResearch/LatestNewsResearch";

const ResearchNews = ({ history }) => {
	return (
		<div>
			<p className="heading2 ">All News</p>

			<LatestNewsResearch isResearch history={history} />
		</div>
	);
};

export default ResearchNews;
