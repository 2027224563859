import React, { useState } from "react";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { ATTACH_KIN } from "api/mutations/onboarding";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import { ERROR } from "../../../../constants";
import { useMutation } from "@apollo/client";

const AttachNextOfKin = ({ kycData, updateKinInfo, updateRegStatus }) => {
	const [formErrors, setFormErrors] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const { nextOfKinInfo } = kycData || {};

	const [attachKin, { loading }] = useMutation(ATTACH_KIN, {
		onCompleted: () => updateRegStatus({ kin: true }),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleKin = ({ target: { name, value } }) => {
		name === "nextOfKinPhoneNumber" &&
			(value = value.replace(/[^0-9]/g, ""));
		handleSelect(name, value, true);
	};

	const handleSelect = (name, value) => {
		updateKinInfo({ [name]: value });
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator(nextOfKinInfo);
		isValid
			? attachKin({ variables: { kin: nextOfKinInfo } })
			: setFormErrors(formErrors);
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<form onSubmit={handleSubmit} className="p-4">
				<div className="row">
					<div className="col-md-6 col-12">
						<CustomInputBox
							name="name"
							label="Next of Kin"
							placeholder="Next of Kin"
							value={nextOfKinInfo?.name}
							onChange={handleKin}
							error={formErrors?.name}
							required
						/>
					</div>

					<div className="col-md-6 col-12">
						<CustomInputBox
							name="email"
							label="Next of Kin Email Address"
							placeholder="Next of Kin Email"
							value={nextOfKinInfo?.email}
							onChange={handleKin}
							error={formErrors?.email}
							required
						/>
					</div>

					<div className="col-md-6 col-12">
						<CustomInputBox
							name="phone"
							type="number"
							label="Next of Kin Phone Number"
							placeholder="Next of Kin Phone Number"
							value={nextOfKinInfo?.phone}
							onChange={handleKin}
							error={formErrors?.phone}
							required
						/>
					</div>

					<div className="col-md-6 col-12">
						<CustomInputBox
							name="relationship"
							label="Next of Kin Relationship"
							placeholder="Next of Kin Relationship"
							value={nextOfKinInfo?.relationship}
							onChange={handleKin}
							error={formErrors?.relationship}
							required
						/>
					</div>

					<div className="col-12">
						<CustomInputBox
							name="address"
							label="Next of Kin Address"
							placeholder="Next of Kin Phone Number"
							value={nextOfKinInfo?.address}
							onChange={handleKin}
							error={formErrors?.address}
							required
						/>
					</div>

					<div className="col-md-6 col-12">
						<button
							className="btn btn--lg btn--primary mt-3 "
							disabled={loading}
						>
							{loading ? <Spinner /> : "Continue"}
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default AttachNextOfKin;
