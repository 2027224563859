import React, { useEffect, useState } from "react";
import { formValidator } from "utils/FormValidator/FormValidator";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";
import { fetchUserData } from "storage/sessionStorage";
import { SECONDARY } from "../../../../constants";

const BuyStockPortfolioList = ({ toggle, nextStep }) => {
	const [formError, setFormError] = useState({});
	const [portfolio, setPortfolio] = useState(" ");

	let { portfolio_accounts = [] } = fetchUserData();

	useEffect(() => {
		portfolio_accounts?.length === 1 && setPortfolio(portfolio_accounts[0]);
		// eslint-disable-next-line
	}, []);

	const handleSelect = (value) => {
		setPortfolio(value);
		setFormError({ portfolio: "" });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator(portfolio);
		isValid
			? nextStep({ account: portfolio?.name })
			: setFormError(formErrors);
	};

	return (
		<form onSubmit={handleSubmit} className="px-4">
			<ModalHeader
				type={SECONDARY}
				toggle={toggle}
				title="Select Portfolio"
			/>
			<CustomSelect
				name="portfolio"
				label="Select Portfolio"
				value={portfolio}
				onChange={handleSelect}
				error={formError?.portfolio}
				options={portfolio_accounts}
				getOptionLabel={({ label, exchange }) =>
					label ? `${label} - ${exchange}` : ""
				}
				required
			/>
			<div className="col-sm-4 col-12 pt-4 pb-3 mx-auto">
				<button className="btn btn--lg btn--primary">Continue</button>
			</div>
		</form>
	);
};

export default BuyStockPortfolioList;
