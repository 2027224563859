import React from "react";
import EnableMFAButton from "../buttons/EnableMFAButton/EnableMFAButton";
import { fetchUserData } from "storage/sessionStorage";

const TwoFactorAuth = () => {
	const { mfa } = fetchUserData();

	return (
		<div className="pt-4">
			<h2 className="fw-bold">Two Factor Authentication</h2>

			<div className="flex__between mt-4">
				<span>
					{mfa
						? "Disable Two Factor Authentication for this account"
						: "Set up Two Factor Authentication to secure your account"}
				</span>
				<EnableMFAButton mfa={mfa} />
			</div>
		</div>
	);
};

export default TwoFactorAuth;
