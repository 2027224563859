import React from "react";
import Switch from "react-switch";
import { ReactComponent as DarkIcon } from "assets/images/icons/dark.svg";
import { ReactComponent as LightIcon } from "assets/images/icons/light.svg";

const CustomToggle = ({ checked, handleToggle }) => {
	return (
		<Switch
			className="mx-3 mt-2"
			checked={checked}
			checkedIcon={false}
			checkedHandleIcon={
				<DarkIcon width="14px" className="primary icon--dark-toggle" />
			}
			handleDiameter={18}
			height={18}
			uncheckedIcon={false}
			uncheckedHandleIcon={
				<LightIcon
					width="14px"
					className="primary-light icon--dark-toggle"
				/>
			}
			offColor="#0E804D"
			onColor="#078950"
			onChange={handleToggle}
			width={39}
		/>
	);
};

export default CustomToggle;
