import React from "react";

const PortfolioHeader = ({ header = "Your Portfolio", exchange, cls }) => {
	return (
		<div className={`portfolio__header ${cls}`}>
			<h3 className="portfolio__header__text">
				{header}
				{exchange ? ` (${exchange})` : ""}
			</h3>{" "}
		</div>
	);
};

export default PortfolioHeader;
