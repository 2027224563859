import React, { useState } from "react";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import SetSettlementAccountModal from "../../modal/SetSettlementAccountModal/SetSettlementAccountModal";

const SetSettlementButton = ({ active, reference }) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	return (
		<>
			<div className="d-flex align-items-center pointer" onClick={toggle}>
				<div style={{ width: "22px" }}>
					<CustomCheckBox checked={active} />
				</div>
				<b>Use this account as my Settlement Account</b>
			</div>

			{showModal && (
				<SetSettlementAccountModal
					reference={reference}
					toggle={toggle}
				/>
			)}
		</>
	);
};

export default SetSettlementButton;
