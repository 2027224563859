import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import Axios from "axios";
import { useSelector } from "react-redux";
import { initializeCheckout } from "api/restRoutes";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import { GET_PAYMENT_TRANX } from "api/mutations/account";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "../../../../constants";
import { getError } from "utils/helpers";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import AppContext from "components/Dashboard/common/AppContext";
import { clearMessage } from "utils/helpers";
import { formatFloatInput } from "utils/helpers";

const FundWalletForm = ({ handleRefetch }) => {
	const { setCheckoutData } = useContext(AppContext);
	const { completedUnchanged } = useSelector(({ kyc }) => kyc?.status || {});

	const [formErrors, setFormErrors] = useState({});
	const [errorMsg, setErrorMsg] = useState("");
	const [amount, setAmount] = useState();
	const [formattedAmount, setFormattedAmount] = useState();
	const [loading, setLoading] = useState(false);

	const [getRef, { loading: paymentLoading }] = useMutation(
		GET_PAYMENT_TRANX,
		{
			onCompleted: ({ insert_payment_transaction_one }) =>
				handleCheckout(insert_payment_transaction_one?.reference),
			onError: (error) => setErrorMsg(getError(error)),
		}
	);

	useEffect(() => {
		clearMessage(setErrorMsg);
	}, [errorMsg]);

	const handleCheckout = async (reference) => {
		setLoading(true);
		setErrorMsg();

		const Authorization = process.env.REACT_APP_BLUSALT_LIVE_KEY;
		const payload = {
			walletId: "master",
			amount,
			currency: "NGN",
			transaction_reference: reference,
			metadata: {
				reference,
				colors: { secColor: "#078950", primaryColor: "#078950" },
				logoLink: process.env.REACT_APP_LOGO_LINK,
			},
		};

		await Axios.post(
			`${process.env.REACT_APP_WALLET_BASE_URL}${initializeCheckout}`,
			payload,
			{
				headers: { Authorization },
			}
		)
			.then((result) => {
				const { status, message, data } = result?.data || {};
				status
					? setCheckoutData({
							url: data?.authorization_url,
							handleRefetch,
					  })
					: setError(message);
				setLoading(false);
			})
			.catch((error) => {
				setError(getError(error));
			});
	};

	const setError = (message) => {
		setLoading(false);
		setErrorMsg(message);
	};

	const handleChange = ({ target: { name, value } }) => {
		value = formatFloatInput(value);
		setFormattedAmount(value);
		setAmount(parseFloat(value.replace(/,/g, "")));
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleSubmit = (e) => {
		setErrorMsg();
		e.preventDefault();

		const { formErrors, isValid } = formValidator({ amount });
		if (isValid) {
			if (amount >= 1000) {
				completedUnchanged
					? getRef({ variables: { object: { amount } } })
					: setErrorMsg(
							"You have to complete your KYC before wallet funding"
					  );
			} else
				setFormErrors((prev) => ({
					...prev,
					amount: "Minimum of #1000",
				}));
		} else setFormErrors(formErrors);
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<form onSubmit={handleSubmit}>
				<div className="pt-4">
					<CustomInputBox
						name="amount"
						label="Enter Amount"
						placeholder="Type here"
						value={formattedAmount}
						onChange={handleChange}
						error={formErrors?.amount}
						required
					/>
				</div>

				<div className="col-sm-4 col-12 mt-5">
					<button
						className="btn btn--lg btn--primary"
						disabled={paymentLoading || loading}
					>
						{paymentLoading || loading ? (
							<CustomSpinner />
						) : (
							"Continue"
						)}
					</button>
				</div>
			</form>
		</>
	);
};

export default FundWalletForm;
