import { gql } from "@apollo/client";

export const GET_WATCHLIST = gql`
	subscription GetWatchlist {
		watchlist {
			id
			name
			watchlist_items {
				stock {
					currency
					exchange
					label
					name
					price
					price
					price_change
					price_change_p
					sector
					type
					stock_day_series {
						price
						t
					}
				}
				watchlist_id
			}
		}
	}
`;

export const TOP_LOSER = gql`
	subscription GetTopStock {
		top_losers: stock(
			order_by: { price_change: asc }
			limit: 10
			where: { type: { _eq: "EQUITY" } }
		) {
			name
			price
			currency
			price_change
			price_change_p
			previous_close
		}
	}
`;

export const TOP_GAINER = gql`
	subscription GetTopGainer {
		top_gainers: stock(
			order_by: { price_change: desc }
			limit: 10
			where: { type: { _eq: "EQUITY" } }
		) {
			name
			price
			trades
			currency
			price_change
			price_change_p
			previous_close
		}
	}
`;
