import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import Routes from "../ResearchRoutes";

const ResearchNavs = ({ history }) => {
	const { pathname } = useLocation();

	return (
		<div className="col-md-3 col-12 profileTabs pe-0">
			<button
				className="btn btn--primary btn--sm px-5 mx-auto"
				onClick={() => history.push("/investment/stocks")}
			>
				Trade Now
			</button>

			<ul className="nav nav-tabs mt-5" id="myTab" role="tablist">
				{Routes?.map(({ name, path }) => (
					<NavLink
						key={name}
						className="nav-item"
						to={path}
						role="presentation"
					>
						<button
							className={`nav-link  ${
								pathname === path ? "active" : ""
							}`}
						>
							{name}
						</button>
					</NavLink>
				))}
			</ul>
		</div>
	);
};

export default ResearchNavs;
