import React from "react";
import NewsIcon from "assets/images/images/news.png";
import { textOnly, truncate } from "utils/helpers";
import { getStockSector } from "components/Dashboard/DashContent/utils";
import { ReactComponent as Redirect } from "assets/images/icons/redirect.svg";

const NewsSingle = ({ news }) => {
	const { imageUrl, url, category, summary, headLine } = news || {};
	const cat = category?.length ? category[0] : "";

	return (
		<div className="row div__wrapper">
			<div className="col-sm-6 p-sm-0 p-2 new__image__wrapper">
				<img src={imageUrl || NewsIcon} alt="" className="img-fluid " />
			</div>
			<div className="col-sm-6 rel px-4 py-2 fw-bold">
				<div className="flex__between">{getStockSector(cat)}</div>

				<p className="heading4 mt-3">
					{truncate(textOnly(headLine, 30))}
				</p>
				<span className="text--gray pb-5">
					{truncate(summary, 150)}
				</span>

				{url && (
					<a
						href={url}
						target="_blank"
						rel="noreferrer"
						className="flex__between news__footer dash"
					>
						<Redirect className="ms-auto" />
					</a>
				)}
			</div>
		</div>
	);
};

export default NewsSingle;
