import React, { useState } from "react";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import Modal from "utils/Modal/Modal";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import { SECONDARY } from "../../../../constants";

const FilterModal = ({ toggle }) => {
	const [filter, setFilter] = useState({
		status: false,
		date: false,
	});

	const disabled = !filter.status && !filter.date;

	const handleCheck = (name) => {
		setFilter((prev) => ({
			...prev,
			[name]: !prev[name],
		}));
	};

	return (
		<Modal show={true} size="sm">
			<ModalHeader type={SECONDARY} toggle={toggle} title="Filter" />

			<div className="px-3">
				<CustomCheckBox
					label="By Status"
					checked={filter?.status}
					onClick={() => handleCheck("status")}
				/>

				<div className="py-5">
					<CustomCheckBox
						label="By Date"
						checked={filter?.date}
						onClick={() => handleCheck("date")}
					/>
				</div>

				<button
					disabled={disabled}
					className="btn btn--lg btn--primary mb-4"
					onClick={toggle}
				>
					Apply
				</button>
			</div>
		</Modal>
	);
};

export default FilterModal;
