import SmallStockChart from "utils/Charts/StockChart/SmallStockChart/SmallStockChart";
import { getStockPlot, toPrice } from "utils/helpers";
import InvestmentTableActions from "../../Investment/common/InvestmentTable/InvestmentTableActions/InvestmentTableActions";
import DeleteWatchlistItemButton from "../buttons/DeleteWatchlistItemButton";

export const bigTable = (watchlist_items, loading) => {
	const newData = watchlist_items?.filter((v) => v?.stock?.label);
	return newData?.map(({ stock, watchlist_id }) => ({
		name: stock?.label,
		code: stock?.name,
		v1: toPrice(stock?.price_change),
		v2: toPrice(stock?.price_change_p),
		chart: (
			<div
				className="overflow-hidden"
				style={{ width: "100px", height: "40px" }}
			>
				<SmallStockChart
					data={getStockPlot(stock?.stock_day_series)}
					lineColor={stock?.price_change < 0 ? "#c81a2f" : "#078950"}
					backgroundColor="rgba(111, 207, 151, 0)"
				/>
			</div>
		),
		action: (
			<InvestmentTableActions
				stock={stock}
				loading={loading}
				hideWatchlist
			/>
		),
		delete: (
			<DeleteWatchlistItemButton id={watchlist_id} stock={stock} isItem />
		),
	}));
};

export const mobileTable = (watchlist_items) => {
	const newData = watchlist_items?.filter((v) => v?.stock?.label);
	return newData?.map(({ stock, watchlist_id }) => ({
		name: (
			<>
				<p className="m-0">{stock?.label}</p>
				<span className="">{toPrice(stock?.price_change)}</span>
			</>
		),
		chart: (
			<div className="overflow-hidden" style={{ maxWidth: "80px" }}>
				<SmallStockChart
					data={getStockPlot(stock?.stock_day_series)}
					lineColor={stock?.price_change < 0 ? "#c81a2f" : "#078950"}
				/>
			</div>
		),
		delete: (
			<DeleteWatchlistItemButton id={watchlist_id} stock={stock} isItem />
		),
	}));
};
