import React, { useState, useEffect } from "react";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";
import RangeSelect from "utils/RangeSelect/RangeSelect";
import { stockTransType } from "../../../../constants";

const SellStockPriceForm = ({
	order,
	price_type,
	toggle,
	nextStep,
	orderTerms,
}) => {
	const [formErrors, setFormErrors] = useState({});
	const [total, setTotal] = useState(0);
	const [resetRange, setResetRange] = useState(false);
	const [request, setRequest] = useState({
		price: order?.marketPrice || 0,
		quantity: 1,
		term: "",
		stop_price: order?.marketPrice || 0,
		price_limit: order?.marketPrice || 0,
	});

	const isMarket = price_type === stockTransType.MARKET;

	useEffect(() => {
		setTotal(
			(isMarket ? request?.price : request?.price_limit) *
				request?.quantity
		);
	}, [isMarket, request]);

	const handleError = (name, value = "") => {
		setFormErrors((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSelect = (name, value) => {
		setRequest((prev) => ({
			...prev,
			[name]: value,
		}));
		handleError(name);
	};

	const handleChange = ({ target: { name, value } }) => {
		name === "quantity"
			? validateQuantity(value)
			: handleSelect(name, value);
		setResetRange((prev) => !prev);
	};

	const validateQuantity = (value) => {
		if (value > order.quantityHeld)
			handleError(
				"quantity",
				"You have exceeded your maximum stock value"
			);
		else handleSelect("quantity", value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const payload = { ...request };
		const { quantity, term, price_limit, stop_price } = request || {};
		delete payload.term;
		if (isMarket) {
			delete payload.stop_price;
			delete payload.price_limit;
		}
		price_type === stockTransType.LIMIT && delete payload.stop_price;
		const { formErrors, isValid } = formValidator(payload);
		const newData = { quantity, total, price_type };
		term && (newData.term = term?.value);
		price_limit && (newData.price_limit = price_limit);
		stop_price && (newData.stop_price = stop_price);

		isValid ? nextStep(newData) : setFormErrors(formErrors);
	};

	return (
		<form onSubmit={handleSubmit} className="px-4 py-3">
			<CustomInputBox
				name="price"
				label="Price"
				placeholder="Price"
				value={request?.price}
				iconText="NGN"
				disabled
				readonly
			/>

			{price_type === stockTransType.STOP_LIMIT && (
				<CustomInputBox
					name="stop_price"
					type="number"
					label="Stop Price"
					placeholder="Stop Price"
					value={request?.stop_price}
					onChange={handleChange}
					error={formErrors?.stop_price}
					min={0}
					required
				/>
			)}

			{!isMarket && (
				<CustomInputBox
					name="price_limit"
					type="number"
					label="Price Limit"
					placeholder="Price Limit"
					value={request?.price_limit}
					onChange={handleChange}
					error={formErrors?.price_limit}
					min={0}
					required
				/>
			)}

			<CustomInputBox
				name="quantity"
				type="number"
				label="Quantity"
				placeholder="Quantity"
				value={request?.quantity}
				onChange={handleChange}
				error={formErrors?.quantity}
				min={1}
				max={order?.quantityHeld}
				required
			/>

			<CustomSelect
				name="term"
				placeholder="Order Term"
				label="Order Term"
				value={[request?.term]}
				onChange={(e) => handleSelect("term", e)}
				error={formErrors?.term}
				options={orderTerms}
				getOptionLabel={({ comment }) => comment}
			/>

			<div className="mb-4 pt-4">
				<RangeSelect
					isSell
					reset={resetRange}
					price={request?.price}
					bal={order?.quantityHeld || 1}
					handleClick={handleSelect}
				/>
			</div>

			<CustomInputBox
				name="total"
				placeholder="Total"
				value={total}
				iconText="NGN"
				required
				disabled
			/>

			<div className="flex__between">
				{!order?.portfolioID && (
					<button
						className="btn btn--lg btn--secondary--bordered me-3"
						onClick={toggle}
					>
						Cancel
					</button>
				)}

				<button
					type="submit"
					className="btn btn--lg btn--secondary ms-3"
					disabled={
						!request.quantity ||
						request.quantity === 0 ||
						request.price === 0 ||
						request.quantity > order?.quantityHeld ||
						formErrors?.quantity
					}
				>
					Sell
				</button>
			</div>
		</form>
	);
};

export default SellStockPriceForm;
