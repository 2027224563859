export const settingsTabList = [
	"password_reset",
	"push_notifications",
	"two_factor_authentication",
];

export const passwordResetFormDefaults = {
	current_password: "",
	password: "",
	confirm_password: "",
};

export const pushNotifFormDefaults = {
	transaction: false,
	withdrawal: false,
	enabled: false,
};

export const pushNotifOptions = [
	{ label: "Wallet Transactions", value: "Wallet Transactions" },
	{ label: "Accounts Summary", value: "Accounts Summary" },
	{ label: "Withdrawals", value: "Withdrawals" },
	{ label: "Fund Wallets", value: "Fund Wallets" },
	{ label: "Investment Transactions", value: "Investment Transactions" },
];
