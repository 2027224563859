import React from "react";
import { Link } from "react-router-dom";
import Modal from "utils/Modal/Modal";
import { ReactComponent as TriAlert } from "assets/images/icons/tri-alert.svg";
import { HEADLESS_CLOSE_ROUNDED } from "../../../../constants";

const IneligibleWithdrawalModal = ({ toggle }) => {
	return (
		<Modal show={true} type={HEADLESS_CLOSE_ROUNDED} toggle={toggle}>
			<div className="text-center py-4 px-4">
				<TriAlert className="mx-auto " height="45px" />
				<h4 className="text-center my-5 fw-bold">
					You cannot withdraw at this time.
					<p className="pt-2"> Complete your KYC to withdraw.</p>
				</h4>

				<div className="col-5 mx-auto">
					<Link to="/profile/kyc">
						<button className="btn btn--lg btn--primary">
							Go to KYC
						</button>
					</Link>
				</div>
			</div>
		</Modal>
	);
};

export default IneligibleWithdrawalModal;
