import React from "react";

const MarketStatistics = ({ stock }) => {
	const { currency: cur, trades } = stock || {};
	const avgVolume =
		trades?.reduce((arr, val) => arr + val.volume, 0) / trades?.length;

	return (
		<div className="mt-5 pt-5">
			<span className="heading2 fw-bold">Market Statistics</span>
			<div className="row  mt-4">
				<div className="col-md-3 col-4">
					<strong className="text--primary">Market Cap</strong>
					<p>78.18B</p>
				</div>
				<div className="col-md-9 col-4">
					<strong className="text--primary">Average Volume</strong>
					<p>{avgVolume}</p>
				</div>
				<div className="col-md-3 col-4">
					<strong className="text--primary">Today's High</strong>
					<p>
						{cur} {stock?.todays_high}
					</p>
				</div>
				<div className="col-md-3 col-4">
					<strong className="text--primary">Today's Low</strong>
					<p>
						{cur} {stock?.todays_low}
					</p>
				</div>
				<div className="col-md-3 col-4">
					<strong className="text--primary">Open Price</strong>
					<p>
						{cur} {stock?.price}
					</p>
				</div>
				<div className="col-md-3 col-4">
					<strong className="text--primary">Today's Volume</strong>
					<p>15.91</p>
				</div>
				<div className="col-md-3 col-4">
					<strong className="text--primary">Yearly High</strong>
					<p>
						{cur} {stock?.year_high}
					</p>
				</div>
				<div className="col-md-3 col-4">
					<strong className="text--primary">Yearly Low</strong>
					<p>
						{cur} {stock?.year_low}
					</p>
				</div>
			</div>
		</div>
	);
};

export default MarketStatistics;
