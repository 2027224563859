import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_WATCHLIST, UPDATE_WATCHLIST } from "api/mutations/watchlist";
import { GET_WATCHLIST } from "api/queries/watchlist";
import Toast from "utils/Alert/Toast/Toast";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { formValidator } from "utils/FormValidator/FormValidator";
import { getError } from "utils/helpers";
import SuccessState from "utils/Modal/Response/SuccessState";
import { ERROR } from "../../../../../constants";

const AddWatchListForm = ({ watchlist, back, toggle, isUpdate }) => {
	const [name, setName] = useState();
	const [formError, setFormError] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const [successMsg, setSuccessMsg] = useState("");

	useEffect(() => setName(watchlist?.name), [watchlist]);

	const [createWatchlist, { loading }] = useMutation(CREATE_WATCHLIST, {
		refetchQueries: [{ query: GET_WATCHLIST }],
		onCompleted: () => {
			back ? back() : setSuccessMsg("Watchlist Successfully Created");
		},
		onError: (error) => setErrorMsg(getError(error)),
	});

	const [updateWatchlist, { qloading }] = useMutation(UPDATE_WATCHLIST, {
		onCompleted: () => setSuccessMsg("Watchlist Successfully Updated"),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleChange = ({ target: { value } }) => {
		setName(value);
		setFormError();
	};

	const handleAPICall = () => {
		setErrorMsg();
		isUpdate
			? updateWatchlist({ variables: { id: watchlist?.id, name } })
			: createWatchlist({ variables: { name } });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator({ name });
		isValid ? handleAPICall() : setFormError(formErrors);
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			{successMsg ? (
				<SuccessState btnText="Done" msg={successMsg} toggle={toggle} />
			) : (
				<form onSubmit={handleSubmit} className="pb-3 px-3">
					<div className=" py-2">
						<CustomInputBox
							name="name"
							value={name}
							placeholder={isUpdate ? "Your Default List" : ""}
							error={formError?.name}
							onChange={handleChange}
						/>
					</div>

					<div className="flex__between">
						<button
							className="btn btn--lg  btn--primary--bordered me-3"
							onClick={back || toggle}
						>
							Cancel
						</button>

						<button
							type="submit"
							className="btn btn--lg btn--primary ms-3"
							disabled={loading || qloading}
						>
							{loading || qloading ? <Spinner /> : "Save"}
						</button>
					</div>
				</form>
			)}
		</>
	);
};

export default AddWatchListForm;
