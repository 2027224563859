import { gql } from "@apollo/client";

export const GET_ORDER_STATEMENT = gql`
	query OrderStatement(
		$end_date: String
		$start_date: String
		$status: String
		$type: String
		$portfolio_id: String
	) {
		order_statement(
			end_date: $end_date
			start_date: $start_date
			status: $status
			type: $type
			portfolio_id: $portfolio_id
		) {
			contractAmount
			contractCurrency
			id
			label
			transactionDate
			tradeOrderName
			symbol
			settlementDate
			quantity
			orderType
			name
			contractPrice
			contractFee
		}
	}
`;

export const GET_CASH_STATEMENT = gql`
	query CashStatement(
		$end_date: String
		$start_date: String
		$status: String
		$account_id: String
	) {
		cash_statement(
			account_id: $account_id
			end_date: $end_date
			start_date: $start_date
			status: $status
		) {
			balance
			creditAmount
			currency
			debitAmount
			description
			id
			label
			name
			transactionDate
			valueDate
		}
	}
`;

export const GET_CASH_ACCOUNTS = gql`
	query CashAccount {
		cash_accounts {
			id
			label
			name
			balance
			currency
		}
	}
`;
