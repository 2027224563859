import React from "react";
import { fetchUserData } from "storage/sessionStorage";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import { titleCase } from "utils/helpers";

const ProfileAccountUpdateInfo = () => {
	const user = fetchUserData();

	return (
		<div className="row mt-5">
			<div className="col-sm-6 col-12 pe-5">
				<CustomInputBox
					name="firstName"
					label="First Name"
					placeholder="First Name"
					value={titleCase(user?.first_name)}
					disabled
				/>
			</div>
			<div className="col-sm-6 col-12 ps-md-5 ps-2 pe-md-0 pe-5">
				<CustomInputBox
					name="lastName"
					label="Last Name"
					placeholder="Last Name"
					value={titleCase(user?.last_name)}
					disabled
				/>
			</div>
			<div className="col-sm-6 col-12 pe-5">
				<CustomInputBox
					name="middleName"
					label="Middle Name"
					placeholder="Middle Name"
					value={titleCase(user?.middle_name)}
					disabled
				/>
			</div>
			<div className="col-sm-6 col-12 ps-md-5 ps-2 pe-md-0 pe-5">
				<CustomInputBox
					name="email"
					label="Email"
					placeholder="Email"
					value={user?.email}
					disabled
				/>
			</div>
			<div className="col-sm-6 col-12 pe-5">
				<CustomInputBox
					name="username"
					label="Username"
					placeholder="Username"
					value={user?.username}
					disabled
				/>
			</div>
			<div className="col-sm-6 col-12 ps-md-5 ps-2 pe-md-0 pe-5">
				<CustomInputBox
					name="phoneNumber"
					label="Phone Number"
					placeholder="Phone Number"
					value={user?.phone}
					disabled
				/>
			</div>

			<div className="col-sm-6 col-12 pe-5">
				<CustomInputBox
					name="accountType"
					label="Account Type"
					placeholder="Account Type"
					value={titleCase(user?.type_id)}
					disabled
				/>
			</div>
		</div>
	);
};

export default ProfileAccountUpdateInfo;
