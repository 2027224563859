import React, { useState } from "react";
import { profitStatus, toCurrency } from "utils/helpers";
import Scroll from "utils/Scroll/Scroll";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";
import { portfolioOption } from "components/Dashboard/common/helpers";

const AssetAllocationValues = ({
	totalGain,
	totalValuation,
	getPortfolio,
	setPortfolio,
}) => {
	const { account, options } = portfolioOption();
	const [value, setValues] = useState(account?.id || "");

	const handleChange = (id) => {
		setPortfolio([]);
		setValues(id);
		getPortfolio({ variables: { id } });
	};

	return (
		<div className="content__slider my-4">
			<Scroll cls="justify-content-between align-items-end">
				<div className="me-5">
					<p className="heading4 m-0">Total Valuation</p>
					<span className="heading2">
						NGN{toCurrency(totalValuation)}
					</span>
				</div>

				<div>
					<p className="heading4 m-0">Total Average P\L </p>
					<span className="heading2">
						{profitStatus(totalGain, true)}
					</span>
				</div>

				<div className="mb-4" style={{ minWidth: "170px" }}>
					<SmallSelect
						nocase
						value={value}
						onChange={({ target: { value } }) =>
							handleChange(value)
						}
						options={options}
					/>
				</div>
			</Scroll>
		</div>
	);
};

export default AssetAllocationValues;
