import { useQuery } from "@apollo/client";
import React from "react";
import { GET_NEWS } from "api/queries/news";
import { getError } from "utils/helpers";
import { ERROR } from "../../../../constants";
import Toast from "utils/Alert/Toast/Toast";
import LoadingNews from "./LoadingNews/LoadingNews";
import NewsSingle from "./NewsSingle/NewsSingle";
import Scroll from "utils/Scroll/Scroll";

const LatestNews = () => {
	const { loading, error, data } = useQuery(GET_NEWS);
	const allNews = data?.news || [];

	return (
		<div className="latest__news dash">
			{error && <Toast msg={getError(error)} variant={ERROR} />}

			<p className="heading4">Latest News</p>

			{loading ? (
				<LoadingNews />
			) : (
				<>
					{allNews?.length ? (
						<Scroll cls="p-2">
							{allNews?.map((news, i) => (
								<NewsSingle key={i} news={news} />
							))}
						</Scroll>
					) : (
						<h3 className="text-danger text-center fw-bold py-5">
							No news available at the moment
						</h3>
					)}
				</>
			)}
		</div>
	);
};

export default LatestNews;
