import React from "react";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";
import AddWatchlistButton from "../../../../WatchList/buttons/AddWatchlistButton/AddWatchlistButton";

const header = ["Watchlist", "Top Gainers", "Top Losers", "Snapshot"];

const DashboardWatchlistNav = ({ count, slideRight, slideLeft }) => {
	return (
		<div className="bg__gray flex__between py-2 px-3 rounded">
			<BackIcon width="19px" onClick={slideLeft} className="pointer" />
			<span className="heading4">{header[count]}</span>
			<div>
				{count === 0 && <AddWatchlistButton />}
				<BackIcon
					width="19px"
					className="rot-180 ms-3 pointer"
					onClick={slideRight}
				/>
			</div>
		</div>
	);
};

export default DashboardWatchlistNav;
