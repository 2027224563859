import React, { useState } from "react";
import { useQuery, NetworkStatus } from "@apollo/client";
import { ReactComponent as Flash } from "assets/images/icons/flash.svg";
import { ReactComponent as Add } from "assets/images/icons/add.svg";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import { SECONDARY } from "../../../../../constants";
import AddWatchListForm from "../../../../WatchList/modals/AddWatchListModal/AddWatchListForm/AddWatchListForm";
import { GET_WATCHLIST } from "api/queries/watchlist";
import Spinner from "utils/CustomSpinner/CustomSpinner";

const UsersWatchlist = ({ toggle, nextStep }) => {
	const [showForm, setShowForm] = useState(false);
	const { loading, error, data, networkStatus, refetch } = useQuery(
		GET_WATCHLIST,
		{
			notifyOnNetworkStatusChange: true,
		}
	);

	const toggleForm = () => setShowForm((prev) => !prev);

	const render = () => {
		if (loading || networkStatus === NetworkStatus.refetch)
			return (
				<div className="fw-bold text--gray pb-4">
					<span>Fetching Watchlist. Please Wait...</span>
					<Spinner cls="spinner-border-sm ms-2" />
				</div>
			);
		else if (error)
			return (
				<div className="text-danger fw-bold pb-4">
					<span>
						Error Fetching Watchlist.{" "}
						<span
							className="pointer text--primary"
							onClick={() => refetch()}
						>
							TRY AGAIN
						</span>
					</span>
				</div>
			);
		else
			return data?.watchlist?.map(({ id, name }) => (
				<div
					key={id}
					className="d-flex align-items-center pointer  pb-4 "
					onClick={() => nextStep(id)}
				>
					<div
						className="rounded-circle flex__center border p-3 bg__gray me-4"
						style={{ height: "40px" }}
					>
						<Flash height="25" />
					</div>
					<p className="m-0 fw-bold ">{name}</p>
				</div>
			));
	};

	return (
		<div>
			<div className="px-4 pb-4">
				<ModalHeader
					type={SECONDARY}
					toggle={toggle}
					title="Add Item to Your Watchlist"
				/>
				{render()}

				{showForm ? (
					<AddWatchListForm
						toggle={toggle}
						onSubmit={toggleForm}
						back={toggleForm}
					/>
				) : (
					<>
						<div
							className="d-flex align-items-center pointer"
							onClick={toggleForm}
						>
							<div className="med-circl rounded-circle border p-3 bg__gray me-4">
								<Add height="20" />
							</div>
							<p className="m-0 fw-bold ">Create New List</p>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default UsersWatchlist;
