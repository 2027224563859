import React from "react";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import SuccessState from "utils/Modal/Response/SuccessState";

const CancelOrderForm = ({
	id,
	successMsg,
	loading,
	toggle,
	refetch,
	handleAPICall,
}) => {
	return (
		<div className="text-center pb-4">
			<p className="heading4 border-bottom">
				Cancel Order <b>({id})</b>
			</p>

			{successMsg ? (
				<SuccessState
					btnText="Done"
					msg={successMsg}
					toggle={refetch}
				/>
			) : (
				<>
					<div className="pt-4 pb-5">
						Are you sure you want to cancel this order?
					</div>

					<div className="flex__between px-3">
						<button
							className="btn btn--lg btn--primary--bordered me-3"
							onClick={toggle}
						>
							No
						</button>
						<button
							className="btn btn--lg btn--secondary ms-3"
							onClick={handleAPICall}
							disabled={loading}
						>
							{loading ? <CustomSpinner /> : "Yes"}
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default CancelOrderForm;
