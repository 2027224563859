import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { useApolloClient, useMutation } from "@apollo/client";
import Disclaimer from "../../Onboarding/utils/Disclaimer/Disclaimer";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import CustomPassword from "utils/CustomInputBox/CustomPassword/CustomPassword";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";
import OnboardingHeader from "../../Onboarding/utils/OnboardingHeader/OnboardingHeader";
import MFAValidationModal from "./MFAValidationModal/MFAValidationModal";
import FooterLink from "../common/FooterLink/FooterLink";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import Routes from "../../Dashboard/routes";
import { LOGIN_USER } from "api/mutations/auth";
import Toast from "utils/Alert/Toast/Toast";
import { resetApp } from "redux/actions/appActions";
import { updateMode } from "redux/actions/modeActions";
import { ERROR, OTP_CAP } from "../../constants";
import { storeUserData } from "storage/sessionStorage";
import { getError } from "utils/helpers";
import CustomCheckBox from "utils/CustomCheckBox/CustomCheckBox";
import AppContext from "components/Dashboard/common/AppContext";
import { useEffect } from "react";

const Login = ({ history, resetApp, updateMode }) => {
	const { mode } = useContext(AppContext);
	const [formErrors, setFormErrors] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const [showModal, setShowModal] = useState(false);
	const [loginToken, setLoginToken] = useState("");
	const [rememberUsername, setRememberUsername] = useState(false);
	const [request, setRequest] = useState({
		username: mode?.username || "",
		pass: "",
		landingPage: Routes[0],
	});

	useEffect(() => {
		mode?.username && setRememberUsername(true);
		// eslint-disable-next-line
	}, []);

	const toggleModal = (token) => {
		token && setLoginToken(token);
		setShowModal((prev) => !prev);
	};

	const client = useApolloClient();
	const url = request?.landingPage?.landingURL || request?.landingPage?.path;

	const [loginUser, { loading }] = useMutation(LOGIN_USER, {
		onCompleted: (loginData) => {
			const { token, status } = loginData?.login || {};
			if (status === OTP_CAP) toggleModal(token);
			else {
				client.clearStore();
				resetApp();
				storeUserData({ accessToken: loginData?.login?.token });
				updateMode({
					username: rememberUsername ? request?.username : "",
				});
				history.replace(url); //route to specified url
				window.location.reload();
			}
		},
		onError: (error) => {
			console.log("graphQLErrors", error.graphQLErrors);
			setErrorMsg(getError(error));
		},
	});

	const handleSelect = (name, value) => {
		setRequest((prev) => ({
			...prev,
			[name]: value,
		}));
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleChange = ({ target: { name, value } }) => {
		handleSelect(name, value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = { ...request };
		delete data.landingPage;
		const { formErrors, isValid } = formValidator(data);
		if (isValid) {
			setErrorMsg();
			loginUser({
				variables: {
					data: {
						username: data?.username,
						password: data?.pass,
					},
				},
			});
		} else setFormErrors(formErrors);
	};

	return (
		<div className="px-sm-5 px-0">
			<Toast msg={errorMsg} variant={ERROR} />

			<OnboardingHeader title="Login to continue" />

			<form onSubmit={handleSubmit}>
				<div className="row justify-content-cente pt-4">
					<div className="col-12 px-5">
						<CustomInputBox
							type="username"
							name="username"
							label="Username"
							placeholder="Username"
							value={request?.username}
							onChange={handleChange}
							error={formErrors?.username}
							required
						/>
					</div>

					<div className="col-12 mb-4">
						<div className="row">
							<div className="col-sm-6 px-5">
								<CustomPassword
									name="pass"
									label="Password"
									placeholder="***********"
									value={request?.pass}
									onChange={handleChange}
									error={formErrors?.pass}
									required
								/>
							</div>
							<div className="col-sm-6 px-5">
								<CustomSelect
									name="landingPage"
									label="Landing Page"
									placeholder="Select Landing Page"
									value={request?.landingPage}
									onChange={(e) =>
										handleSelect("landingPage", e)
									}
									error={formErrors?.landingPage}
									options={Routes}
									getOptionLabel={({ name }) => name}
									getOptionValue={({ landingURL, path }) =>
										landingURL || path
									}
								/>
							</div>

							<div className="col-sm-6 col-12 px-5">
								<div className="d-flex align-items-center">
									<div className="my-2">
										<CustomCheckBox
											label="Remember Username"
											checked={rememberUsername}
											onClick={() =>
												setRememberUsername(
													(prev) => !prev
												)
											}
										/>
									</div>
								</div>
							</div>

							<div className="col-sm-6 col-12 px-5 text-end text--gray">
								<Disclaimer history={history} isLogin />
							</div>
						</div>
					</div>

					<div className="col-sm-6 col-12 px-5 mb-5">
						<button
							className="btn btn--lg btn--primary"
							disabled={loading}
						>
							{loading ? <Spinner /> : "login"}
						</button>
					</div>
				</div>
			</form>

			<FooterLink
				text="Forgot Password?"
				link={() => history.push("/auth/forgot-password")}
			/>

			{showModal && (
				<MFAValidationModal
					url={url}
					token={loginToken}
					toggle={toggleModal}
					updateUsername={() => {
						updateMode({
							username: rememberUsername ? request?.username : "",
						});
					}}
				/>
			)}
		</div>
	);
};

export default connect(({ mode }) => ({ mode }), { resetApp, updateMode })(
	Login
);
