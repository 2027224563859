import React, { useState } from "react";
import { ReactComponent as Trash } from "assets/images/icons/trash.svg";
import { ReactComponent as TrashRound } from "assets/images/icons/trash-round.svg";
import DeleteWatchListItemModal from "../modals/DeleteWatchListItemModal";

const DeleteWatchlistItemButton = ({ id, stock, round, useText, isItem }) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	return (
		<>
			{round ? (
				<TrashRound
					height="20px"
					width="20px"
					onClick={toggle}
					className="pointer"
				/>
			) : useText ? (
				<span onClick={toggle}>Delete</span>
			) : (
				<Trash onClick={toggle} className="pointer" />
			)}

			{showModal && (
				<DeleteWatchListItemModal
					id={id}
					toggle={toggle}
					stock={stock}
					isItem={isItem}
				/>
			)}
		</>
	);
};

export default DeleteWatchlistItemButton;
