import React, { useState } from "react";
import { SECONDARY } from "../../components/constants";
import { truncate } from "../helpers";
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader/ModalHeader";

const ViewMore = ({ text, count = 20, title = "Description" }) => {
	const [showModal, setShowModal] = useState(false);

	const handleClick = () => setShowModal((prev) => !prev);

	return (
		<>
			{text?.length > count ? (
				<div className="pointer">
					{truncate(text, count)}
					<span className="text--primary" onClick={handleClick}>
						view
					</span>
				</div>
			) : (
				<span>{text}</span>
			)}

			<Modal show={showModal}>
				<ModalHeader
					type={SECONDARY}
					toggle={handleClick}
					title={title}
				/>
				<div className="p-4 text-center mb-3">
					<span className="text-break">{text}</span>
				</div>
			</Modal>
		</>
	);
};

export default ViewMore;
