import React from "react";
import { fetchUserData } from "storage/sessionStorage";
import Table from "utils/Table/Table";

const headings = ["Account Name", "Account Number", "Bank Name"];

const BankInfoTable = () => {
	const { settlement_account } = fetchUserData();

	const getTableData = () => {
		return settlement_account?.map(
			({ account_name, account_number, bank_name }) => ({
				account_name,
				account_number,
				bank_name,
			})
		);
	};
	return (
		<Table
			data={getTableData()}
			headings={headings}
			cls="full__height faint__bg "
			rowClick={() => {}}
		/>
	);
};

export default BankInfoTable;
