import React, { useState } from "react";
import DashboardWatchlistNav from "./DashboardWatchlistNav/DashboardWatchlistNav";
import TopGainer from "./TopGainer/TopGainer";
import TopLoser from "./TopLoser/TopLoser";
import AllDashWatchList from "./AllDashWatchList/AllDashWatchList";
import SnapShot from "./SnapShot/SnapShot";

const DashboardWatchlist = ({ history }) => {
	const [count, setCount] = useState(0);

	const slideRight = () =>
		count === 3 ? setCount(0) : setCount((prev) => prev + 1);

	const slideLeft = () =>
		count === 0 ? setCount(3) : setCount((prev) => prev - 1);

	const render = () => {
		switch (count) {
			case 0:
			default:
				return <AllDashWatchList history={history} />;

			case 1:
				return <TopGainer />;

			case 2:
				return <TopLoser />;

			case 3:
				return <SnapShot />;
		}
	};
	return (
		<div className="dash__watchlist div__wrapper">
			<DashboardWatchlistNav
				count={count}
				slideLeft={slideLeft}
				slideRight={slideRight}
			/>
			{render()}
		</div>
	);
};

export default DashboardWatchlist;
