import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Menu, MenuItem } from "@material-ui/core";
import { useApolloClient } from "@apollo/client";
import { ReactComponent as Caret } from "assets/images/icons/caret.svg";
import { ReactComponent as Settings } from "assets/images/icons/setting.svg";
import { ReactComponent as Alert } from "assets/images/icons/alert.svg";
import { ReactComponent as Avatar } from "assets/images/icons/user.svg";
import { deleteUserData, fetchUserData } from "storage/sessionStorage";
import { resetApp } from "redux/actions/appActions";
import { BootIntercom } from "../../../Popups/extra";
import { switchMode, updateMode } from "redux/actions/modeActions";
import AppContext from "components/Dashboard/common/AppContext";

const OthersMenu = ({ history, resetApp, switchMode, updateMode }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const { url } = fetchUserData()?.profile_picture || {};

	const client = useApolloClient();
	const { mode } = useContext(AppContext);

	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => setAnchorEl(null);

	const handleLogout = async () => {
		switchMode(mode.meriMode);
		handleClose();
		deleteUserData();
		client.clearStore();
		resetApp();
		BootIntercom();
		mode.username && updateMode({ username: mode.username });
		history.replace("/auth/login");
	};

	return (
		<div className="d-inline-block">
			<Link to="/settings" className="menu__link">
				<Settings width="18px" />
			</Link>
			<Link to="/notifications" className="menu__link">
				<Alert width="18px" />
			</Link>

			<div
				className="d-inline-block pointer"
				aria-controls="filter"
				aria-haspopup="true"
				onClick={handleClick}
			>
				{url ? (
					<img
						src={url}
						alt=""
						className="menu__link  rounded-circle"
						height="30px"
						width="30px"
					/>
				) : (
					<Avatar className="menu__link" height="30px" width="30px" />
				)}

				<Caret className="ms-3 dark" />
			</div>
			<Menu
				id="filter"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				style={{ marginTop: "30px" }}
				className="pointer"
				disableScrollLock={true}
			>
				<MenuItem
					onClick={() => {
						handleClose();
						history.push("/profile/account");
					}}
				>
					My Profile
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						history.push("/profile/account/user/referral");
					}}
				>
					Referral
				</MenuItem>

				<MenuItem onClick={handleLogout}>Logout</MenuItem>
			</Menu>
		</div>
	);
};

export default connect(null, { resetApp, updateMode, switchMode })(OthersMenu);
