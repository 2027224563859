import { gql } from "@apollo/client";

export const GET_USER_INFO = gql`
	query GetUserInfo {
		registration_status {
			bvn
			completed
			documents
			identity
			liveness_check
		}
		settlement_account(limit: 10) {
			account_name
			account_number
			bank_code
			bank_name
			sort_code
			reference
			user {
				settlement_type {
					value
				}
			}
		}
		user {
			kyc_information {
				user {
					country {
						name
					}
					email
					first_name
					last_name
					middle_name
					phone
				}
				state_of_origin
			}
			active
			email
			email_verified
			first_name
			last_name
			username
			middle_name
			phone
			dob
			mfa
			referral_code
			type_id
		}
		account {
			account_name
			account_number
			balance
			bank_name
			currency
			margin
			reference
			settlement_account_reference
		}
		next_of_kin {
			address
			email
			name
			phone
			relationship
		}
		cash_balance {
			balance
		}
		investment_profile {
			annual_income
			knowledge_id
			net_worth
			objective_id
			risk_tolerance_id
			source_of_wealth_id
		}
		margin_request {
			message
			status
		}
		admin_config {
			name
			description
			type
			string_value
			number_value
		}
		profile_picture {
			url
		}
		order_fee {
			cscs_alert
			commission_percentage
			cscs_percentage
			exchange_percentage
			sec_percentage
			stamp_duty_percentage
			type
		}
	}
`;

export const GET_PORTFOLIO_ACCOUNT = gql`
	query GetPortfolioAccount {
		portfolio_accounts {
			account_no
			exchange
			id
			margin
			portfolio_type
			valuation
			name
			label
			margin_details {
				call_value
				equity_value
				min_maintain_value
				portfolio_value
				trading_power
			}
		}
	}
`;

export const PORTFOLIO_SUMMARY = gql`
	query PortfolioSummary(
		$time: timestamptz
		$filters: portfolio_summary_daily_bool_exp = {}
	) {
		portfolio_summary_daily(
			distinct_on: time
			order_by: { time: asc }
			where: $filters
		) {
			time
			value
		}
	}
`;
