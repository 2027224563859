import React from "react";
import AddMarketToWatchlistButton from "../../button/AddMarketToWatchlistButton/AddMarketToWatchlistButton";
import SubmitOrderButton from "../../button/SubmitOrderButton/SubmitOrderButton";

const MarketUpdateTopButtons = ({ loading, stock }) => {
	return (
		<div className="flex__between mt-1  mb-5">
			<SubmitOrderButton loading={loading} stock={stock} />
			<SubmitOrderButton loading={loading} stock={stock} isSell />
			<AddMarketToWatchlistButton loading={loading} stock={stock?.name} />
		</div>
	);
};

export default MarketUpdateTopButtons;
