import React, { useState } from "react";
import FileUpload from "utils/FileUpload/FileUpload";
import { formValidator } from "utils/FormValidator/FormValidator";
import { ReactComponent as SuccessIcon } from "assets/images/icons/success.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/closecover.svg";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import API from "api/rest";
import { ERROR, margin_req_status } from "../../../../../constants";
import { getError, getUploadProgress } from "utils/helpers";
import Toast from "utils/Alert/Toast/Toast";

const SubmitMarginAgreementForm = ({
	confirm,
	toggle,
	nextStep,
	updateRegStatus,
}) => {
	const [file, setFile] = useState("");
	const [formErrors, setFormErrors] = useState({});
	const [errorMsg, setErrorMsg] = useState("");
	const [loading, setLoading] = useState(false);
	const [percent, setPercent] = useState(0);

	const handleImage = (files) => {
		const file = files[0];
		if (file && ["application/pdf"].includes(file.type)) {
			setFile(file);
			setFormErrors({});
		} else
			setFormErrors({
				file: "Please upload a valid document (pdf only)",
			});
	};

	const handleAPICall = async () => {
		try {
			const formData = new FormData();
			formData.append("agreement", file);

			setLoading(true);
			setErrorMsg();

			const response = await API.post(
				"files/upload-margin-agreement",
				formData,
				{
					onUploadProgress: (progressEvent) =>
						getUploadProgress(progressEvent, setPercent),
				}
			);

			if (response?.status === 200) {
				setLoading(false);
				updateRegStatus({ marginRequest: margin_req_status.CREATED });
				nextStep();
			}
		} catch (error) {
			setLoading(false);
			setErrorMsg(getError(error));
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const { formErrors, isValid } = formValidator({ file });
		isValid ? handleAPICall() : setFormErrors(formErrors);
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<div className="px-4 pt-4">
				<div className="flex__between border-bottom pb-2">
					{!confirm && (
						<span className="heading3 ">
							Submit/Upload Margin Agreement
						</span>
					)}
					<CloseIcon
						className="pointer ms-auto"
						width={18}
						onClick={toggle}
					/>
				</div>

				{confirm ? (
					<Success nextStep={nextStep} />
				) : (
					<form onSubmit={handleSubmit} className="text-center mt-5">
						<FileUpload
							name="file"
							id="file"
							file={file}
							percent={percent}
							error={formErrors?.file}
							onChange={handleImage}
							validFileTypes={["application/pdf"]}
						/>

						<div className="form-group px-5 col-8 mx-auto pt-4">
							<button
								type="submit"
								className="btn btn--lg btn--primary"
								disabled={!file || loading}
							>
								{loading ? <Spinner /> : "Submit"}
							</button>
						</div>
					</form>
				)}
			</div>
		</>
	);
};

const Success = () => {
	return (
		<div className="text-center mt-5 fw-bold text--gray">
			<SuccessIcon width="35" />

			<p className="my-4">
				Margin Agreement successfully submitted! <br /> We will review
				your application & get back to you within the next few days.
			</p>
		</div>
	);
};

export default SubmitMarginAgreementForm;
