import React, { useState } from "react";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import { ReactComponent as BrailleDot } from "assets/images/icons/brailledot.svg";
import EditWatchlistButton from "../../buttons/EditWatchlistButton/EditWatchlistButton";
import DeleteWatchlistButton from "../../buttons/DeleteWatchlistButton/DeleteWatchlistButton";
import AddStocksToWatchlistButton from "../../buttons/AddStocksToWatchlistButton/AddStocksToWatchlistButton";

const WatchListActions = ({ watchlist }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => setAnchorEl(null);

	return (
		<>
			<IconButton onClick={handleClick}>
				<BrailleDot
					aria-controls="filter"
					aria-haspopup="true"
					className="gray"
				/>
			</IconButton>

			<Menu
				id="filter"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				style={{ marginTop: "40px" }}
				className="pointer"
				disableScrollLock={true}
			>
				<MenuItem onClick={handleClose}>
					<EditWatchlistButton useText watchlist={watchlist} />
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<AddStocksToWatchlistButton watchlist={watchlist} />
				</MenuItem>
				<MenuItem onClick={handleClose}>
					<DeleteWatchlistButton useText id={watchlist?.id} />
				</MenuItem>
			</Menu>
		</>
	);
};

export default WatchListActions;
