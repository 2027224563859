import React, { useState } from "react";
import { ReactComponent as Trash } from "assets/images/icons/trash.svg";
import RemoveCardModal from "../../modal/RemoveCardModal/RemoveCardModal";

const RemoveCardButton = ({ account_number }) => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	return (
		<>
			<span onClick={toggle} className="pointer fw-bold">
				<Trash className="danger me-2" />{" "}
				<span className="d-md-inline-block d-none"> Remove </span>
			</span>

			{showModal && (
				<RemoveCardModal
					toggle={toggle}
					account_number={account_number}
				/>
			)}
		</>
	);
};

export default RemoveCardButton;
