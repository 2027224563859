import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Toast from "utils/Alert/Toast/Toast";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { getError, toCurrency } from "utils/helpers";
import LineText from "utils/LineText/LineText";
import ModalHeader from "utils/Modal/ModalHeader/ModalHeader";
import { ERROR, SECONDARY } from "../../../../../constants";
import { REQUEST_OTP } from "api/mutations/auth";

const AccountReview = ({ request, toggle, nextStep }) => {
	const [errorMsg, setErrorMsg] = useState();

	const { account, amount } = request || {};
	const { account_number, account_name, bank_name } = account || {};

	const [requestOtp, { loading }] = useMutation(REQUEST_OTP, {
		onCompleted: () => nextStep(),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleAPICall = () => {
		setErrorMsg();
		requestOtp({ variables: { data: { mutation: "withdraw" } } });
	};

	return (
		<div>
			<Toast msg={errorMsg} variant={ERROR} />

			<div className="text-center ">
				<ModalHeader type={SECONDARY} toggle={toggle} title="Review" />

				<div className="px-sm-5 px-2 pb-4">
					<LineText title="Bank Account Name" value={account_name} />

					<LineText title="Bank" value={bank_name} />
					<LineText title="Bank Account" value={account_number} />
					<LineText
						title="Amount"
						value={`NGN ${toCurrency(amount)}`}
					/>
					<LineText
						title="Withdrawal Fee "
						value={`NGN ${toCurrency(300)}`}
					/>

					<div className="flex__between fw-bold">
						<span className="text-nowrap">Total</span>{" "}
						<div className="border-bottom w-100 mx-2" />
						<span className="text-nowrap">{`NGN ${toCurrency(
							parseInt(amount) + 300
						)}`}</span>
					</div>

					<div className="flex__between mt-5">
						<button
							className="btn btn--lg btn--primary--bordered me-3"
							onClick={toggle}
						>
							Cancel
						</button>
						<button
							className="btn btn--lg btn--primary ms-3"
							onClick={handleAPICall}
							disabled={loading}
						>
							{loading ? <Spinner /> : "Confirm"}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccountReview;
