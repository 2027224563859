import { gql } from "@apollo/client";

export const ATTACH_BVN = gql`
	mutation AttachBvn($data: AttachBVNInput!) {
		attach_bvn(data: $data) {
			status
		}
	}
`;

export const ATTACH_NIN = gql`
	mutation AttachNinKin($kyc: kyc_information_insert_input!) {
		insert_kyc_information_one(object: $kyc) {
			state_of_origin
		}
	}
`;

export const ATTACH_KIN = gql`
	mutation AttachKin($kin: next_of_kin_insert_input!) {
		insert_next_of_kin_one(object: $kin) {
			name
		}
	}
`;

export const ATTACH_SETTLEMENT = gql`
	mutation AttachSettlement($account: settlement_account_insert_input!) {
		insert_settlement_account_one(object: $account) {
			account_name
		}
	}
`;

export const FINISH_REGISTRATION = gql`
	mutation FinishRegistration {
		finish_registration(data: { action: true }) {
			message
			status
		}
	}
`;
