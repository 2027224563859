import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ResearchNavs from "./ResearchNavs/ResearchNavs";
import ResearchRoutes from "./ResearchRoutes";
import "./Reasearch.scss";

const Research = ({ history }) => {
	const getRoutes = () => (
		<Switch>
			{ResearchRoutes.map(({ path, exact, component }, i) => (
				<Route
					key={i}
					path={path}
					exact={exact}
					component={component}
				/>
			))}
			<Route
				exact
				path="*"
				component={() => <Redirect to="/research" />}
			/>
		</Switch>
	);
	return (
		<div className="profile row">
			<ResearchNavs history={history} />
			<div className="col px-5 py-4 research__content">{getRoutes()}</div>
		</div>
	);
};

export default Research;
