import React, { useState } from "react";
import { ReactComponent as AlertIcon } from "assets/images/icons/alert.svg";
import GetNotifiedModal from "../../modal/GetNotifiedModal/GetNotifiedModal";

const GetNotifiedButton = () => {
	const [showModal, setShowModal] = useState(false);

	const toggle = () => setShowModal((prev) => !prev);

	return (
		<>
			<AlertIcon
				className="ms-4 primary-light pointer"
				onClick={toggle}
			/>

			{showModal && <GetNotifiedModal toggle={toggle} />}
		</>
	);
};

export default GetNotifiedButton;
