import React from "react";
import Select from "react-select";

const CustomSelect = ({
	label,
	name,
	value,
	error,
	isMulti,
	options,
	placeholder,
	required,
	disabled,
	getOptionLabel,
	getOptionValue,
	formGroupCls,
	onChange,
	...rest
}) => {
	const cls = `form-group-select${isMulti ? "--is-multi" : ""}`;
	const isRequired = required ? "required" : "";
	const isError = error ? "_error" : "";

	return (
		<div className={`form-group ${formGroupCls}`}>
			{label && (
				<label htmlFor={name} className={`form-group-label ${isRequired}`}>
					{label}
				</label>
			)}

			<div className={`${cls}${isError}`}>
				<Select
					inputId={name}
					name={name}
					placeholder={placeholder}
					isMulti={isMulti}
					options={options}
					value={value}
					isLoading={!options}
					isDisabled={disabled}
					getOptionLabel={getOptionLabel}
					getOptionValue={getOptionValue}
					classNamePrefix={cls}
					onChange={onChange}
					{...rest}
				/>
			</div>
			{typeof error === "string" && (
				<small className="input-text-error">{error}</small>
			)}
		</div>
	);
};

export default CustomSelect;
