import { timeDateFormat } from "utils/helpers";

export const getData = (data) => {
	const timer = "time";
	return data?.map(({ t, price }) => ({
		value: price,
		[timer]: timeDateFormat(t)?.date,
		// [timer]: format ? timeDateFormat(t)?.date : new Date(t).toLocaleDateString(),
	}));
};

export const getVolumeData = (data) => {
	const timer = "time";
	return data?.map(({ t, price }, i) => ({
		value: price,
		[timer]: timeDateFormat(t)?.date,
		color: getColor(i, price, data),
	}));
};

const getColor = (i, price, data) => {
	const prev = data[i - 1]?.price || price;
	if (i === 0) return "rgba(0, 150, 136, 0.8)";
	else {
		if (price >= prev) return "rgba(0, 150, 136, 0.8)";
		else return "rgba(255,82,82, 0.8)";
	}
};

export const getBarData = (data) => {
	const timer = "time";
	return data?.map(({ t, open, close, high, low }) => ({
		open,
		close,
		low,
		high,
		[timer]: timeDateFormat(t)?.date,
	}));
};
