import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import NinDetails from "../NinDetails/NinDetails";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { ATTACH_NIN } from "api/mutations/onboarding";
import Toast from "utils/Alert/Toast/Toast";
import { getError } from "utils/helpers";
import { ERROR } from "../../../../../constants";
import { formValidator } from "utils/FormValidator/FormValidator";
import { fetchUserData } from "storage/sessionStorage";

const NINDetailsWrapper = ({
	isProfile,
	kycData,
	updateNinInfo,
	updateRegStatus,
}) => {
	const [formErrors, setFormErrors] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const { ninInfo } = kycData || {};

	const { status } = fetchUserData();

	const [attachNin, { loading }] = useMutation(ATTACH_NIN, {
		onCompleted: () => updateRegStatus({ nin: true, identity: true }),
		onError: (error) => setErrorMsg(getError(error)),
	});

	const handleChange = ({ target: { name, value } }) => {
		handleSelect(name, value);
	};

	const handleSelect = (name, value) => {
		updateNinInfo({ [name]: value });
		setFormErrors((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleAPICall = (e) => {
		e.preventDefault();
		setErrorMsg();
		const { mothers_maiden_name, nationality } = ninInfo || {};
		const { residence_street, residence_city } = ninInfo || {};
		const { employer_name, postal_code, profession } = ninInfo || {};
		const { state_of_origin, lga_of_origin, religion } = ninInfo || {};
		const { residence_state, employer_address, marital_status } =
			ninInfo || {};
		const payload = {
			mothers_maiden_name,
			nationality: nationality?.name,
			state_of_origin,
			lga_of_origin,
			residence_street,
			residence_city,
			employer_name,
			postal_code,
			profession,
			religion,
			residence_state,
			employer_address,
			marital_status,
		};

		const { formErrors, isValid } = formValidator(payload);
		isValid
			? attachNin({ variables: { kyc: payload } })
			: setFormErrors(formErrors);
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<form onSubmit={handleAPICall} className="p-4">
				{!status?.identity && (
					<CustomInputBox
						label="NIN Info"
						value={ninInfo?.nin}
						disabled
					/>
				)}

				<div className="bvn__info">
					<NinDetails
						isProfile={isProfile}
						ninInfo={ninInfo}
						formErrors={formErrors}
						handleChange={handleChange}
						handleSelect={handleSelect}
					/>

					<div className="col-12 mt-3 flex__between">
						<button
							className="btn btn--lg btn--primary--bordered me-3 "
							onClick={() => updateRegStatus({ completed: true })}
						>
							Skip
						</button>
						<button
							className="btn btn--lg btn--primary ms-3 "
							disabled={loading}
						>
							{loading ? <Spinner /> : "Continue"}
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default NINDetailsWrapper;
