import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { ORDER_TERMS } from "api/queries/stock";
import Modal from "utils/Modal/Modal";
import SuccessState from "utils/Modal/Response/SuccessState";
import ReviewOrder from "../common/ReviewOrder/ReviewOrder";
import SellStockConfirmation from "./SellStockConfirmation";
import SellStockPortfolioList from "./SellStockPortfolioList";
import SellStockOptions from "./SellStockOptions";
import { SELL } from "../../../../constants";

const SellStock = ({ stock, toggle }) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [order, setOrder] = useState({ ...stock, type: SELL });
	const [successMsg, setSuccessMsg] = useState("");

	const { data } = useQuery(ORDER_TERMS);

	const nextStep = (data = {}, msg) => {
		setOrder((prev) => ({ ...prev, ...data }));
		setSuccessMsg(msg);
		setCurrentStep((prev) => prev + 1);
	};

	const prevStep = () => setCurrentStep((prev) => prev - 1);

	const render = () => {
		switch (currentStep) {
			case 1:
			default:
				return (
					<SellStockPortfolioList
						stock={stock}
						nextStep={nextStep}
						toggle={toggle}
					/>
				);
			case 2:
				return (
					<SellStockOptions
						order={order}
						nextStep={nextStep}
						toggle={toggle}
						orderTerms={data?.order_term || []}
					/>
				);
			case 3:
				return (
					<ReviewOrder
						order={order}
						nextStep={nextStep}
						prevStep={prevStep}
						toggle={toggle}
					/>
				);
			case 4:
				return (
					<SellStockConfirmation
						order={order}
						nextStep={nextStep}
						prevStep={prevStep}
						toggle={toggle}
					/>
				);
			case 5:
				return (
					<SuccessState
						msg={successMsg}
						btnText="Done"
						toggle={toggle}
					/>
				);
		}
	};

	return <Modal show={true}>{render()}</Modal>;
};

export default SellStock;
