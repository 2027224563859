import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import SmallSelect from "utils/Select/SmallSelect/SmallSelect";
import FilterModal from "../../Orders/OrderHistory/FilterModal/FilterModal";
import CorporateBenefitDate from "./CorporateBenefitDate/CorporateBenefitDate";
import CorporateBenefitTable from "./CorporateBenefitTable/CorporateBenefitTable";
import ExportAsCSV from "utils/ExportAsCSV/ExportAsCSV";
import Toast from "utils/Alert/Toast/Toast";
import { GET_CORPORATE_BENEFIT } from "api/queries/reasearch";
import { ERROR } from "../../../constants";
import { benefitExport } from "../extra";
import { getError } from "utils/helpers";

const CorporateBenefit = () => {
	const [value, setValue] = useState("Dividends Declared So Far");
	const [showFilter, setShowFilter] = useState(false);
	const [benefits, setBenefits] = useState([]);
	const [benefit, setBenefit] = useState({});

	const toggle = () => setShowFilter(!showFilter);

	const [getBenefit, { error, loading }] = useLazyQuery(
		GET_CORPORATE_BENEFIT,
		{
			onCompleted: ({ corporate_benefit }) => {
				setBenefits(corporate_benefit);
				setBenefit(corporate_benefit?.[0] || {});
			},
		}
	);

	console.log();

	useEffect(() => {
		getBenefit();
		// eslint-disable-next-line
	}, []);

	const render = () => {
		switch (value) {
			case "Dividends Declared So Far":
			default:
				return (
					<CorporateBenefitTable
						loading={loading}
						data={benefits}
						setBenefit={setBenefit}
					/>
				);

			case "Upcoming Dates":
				return (
					<CorporateBenefitDate
						loading={loading}
						data={benefits}
						benefit={benefit}
						setBenefit={setBenefit}
					/>
				);
		}
	};

	return (
		<>
			{error && <Toast msg={getError(error)} variant={ERROR} />}

			<p className="heading2 m-0">Corporate Benefit</p>

			<div className="d-flex align-items-center mb-4 flex-wrap">
				<div className="form-group my-4" style={{ width: "170px" }}>
					<SmallSelect
						value={value}
						name="currentChart"
						onChange={({ target }) => {
							console.log("target", target?.value);
							setValue(target?.value);
						}}
						options={[
							"Dividends Declared So Far",
							"Upcoming Dates",
						]}
					/>
				</div>

				{benefits?.length > 0 && (
					<ExportAsCSV
						cls="ms-5"
						data={benefits}
						headers={benefitExport}
						filename="corporate-benefit"
					/>
				)}
			</div>
			{showFilter && <FilterModal toggle={toggle} />}
			{render()}
		</>
	);
};

export default CorporateBenefit;
