import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import BankDetails from "./BankDetails/BankDetails";
import AttachDocuments from "./AttachDocuments/AttachDocuments";
import BankSelect from "utils/Select/BankSelect/BankSelect";
import { RESOLVE_BANK } from "api/queries/others/bank";
import Spinner from "utils/CustomSpinner/CustomSpinner";
import { ATTACH_SETTLEMENT } from "api/mutations/onboarding";
import Toast from "utils/Alert/Toast/Toast";
import { getError, getUploadProgress } from "utils/helpers";
import API from "api/rest";
import { ERROR } from "../../../../constants";

const AttachSettlement = ({
	bankInfo,
	bankVerified,
	documents,
	updateRegStatus,
	updateBankInfo,
	liveness_check,
}) => {
	const [formErrors, setFormErrors] = useState({});
	const [errorMsg, setErrorMsg] = useState();
	const [isLoading, setLoading] = useState(false);
	const [percent, setPercent] = useState(0);
	const [request, setRequest] = useState({
		passport: "",
		utility: "",
		identity: "",
		signature: "",
	});
	const { account_number, bank } = bankInfo || {};

	useEffect(() => {
		if (account_number?.length === 10 && bank) {
			setErrorMsg();
			resolveBank({
				variables: { account_number, bank_code: bank?.code },
			});
		}
		// eslint-disable-next-line
	}, [account_number, bank]);

	const [resolveBank, { loading, error }] = useLazyQuery(RESOLVE_BANK, {
		onCompleted: (data) => {
			const { resolve_bank = {} } = data || {};
			updateRegStatus({ bankVerified: true });
			updateBankInfo({
				...resolve_bank,
				active: true,
				bank_code: bank?.code,
				bank_name: bank?.name,
				sort_code: bank?.sort_code,
			});
		},
		onError: () => updateRegStatus({ bankVerified: false }),
	});

	const [attachSettlement, { loading: qLoading }] = useMutation(
		ATTACH_SETTLEMENT,
		{
			onCompleted: () => {
				setLoading(false);
				updateRegStatus({ settlement: true });
			},
			onError: (error) => {
				setErrorMsg(getError(error));
				setLoading(false);
			},
		}
	);

	const handleAPICall = async (account) => {
		const formData = new FormData();
		formData.append("passport", request.passport);
		formData.append("utility", request.utility);
		formData.append("identity", request.identity);
		formData.append("signature", request.signature);

		setLoading(true);
		setErrorMsg();

		await API.post("/files/upload-documents", formData, {
			onUploadProgress: (progressEvent) =>
				getUploadProgress(progressEvent, setPercent),
		})
			.then(() => {
				updateRegStatus({ documents: true });
				attachSettlement({ variables: { account } });
			})
			.catch((error) => {
				setLoading(false);
				setErrorMsg(getError(error));
			});
	};

	const handleChange = (name, value) => {
		name === "account_number" && (value = value.replace(/[^0-9]/g, ""));
		updateBankInfo({ [name]: value });
		setError(name, "");
	};

	const handleForm = (name, file) => {
		setRequest((prev) => ({
			...prev,
			[name]: file,
		}));
		setError(name, "");
	};

	const setError = (name, value) => {
		setFormErrors((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSettlement = () => {
		const account = { ...bankInfo };
		delete account?.bank;
		delete account?.__typename;
		if (documents) {
			setErrorMsg();
			attachSettlement({ variables: { account } });
		} else handleAPICall(account);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let payload = { bank, account_number };
		if (!documents) payload = { ...payload, ...request };
		const { formErrors, isValid } = formValidator(payload);
		isValid ? handleSettlement() : setFormErrors(formErrors);
	};

	const render = () => {
		if (loading)
			return (
				<div className="text-end fw-bold text--gray mt-n4 pb-4">
					<span>Please Wait...</span>
					<Spinner cls="spinner-border-sm ms-2" />
				</div>
			);
		else if (error)
			return (
				<div className="text-end text-danger fw-bold mt-n4 pb-4">
					<span>Error Validating Account</span>
				</div>
			);
		else return "";
	};

	return (
		<>
			<Toast msg={errorMsg} variant={ERROR} />

			<form onSubmit={handleSubmit} className="p-4 bvn__info doc">
				<BankSelect
					name="bank"
					label="Select Bank Name"
					placeholder="Select Bank"
					error={formErrors?.bank}
					value={bank}
					onChange={(e) => handleChange("bank", e)}
					required
				/>

				<CustomInputBox
					name="account_number"
					label="Enter your Account Number"
					placeholder="10 digit account number"
					value={account_number}
					onChange={({ target }) =>
						handleChange("account_number", target?.value)
					}
					error={formErrors?.account_number}
					maxLength={10}
					disabled={loading}
					required
				/>

				{render()}

				{bankVerified && (
					<>
						<BankDetails
							bankInfo={bankInfo}
							handleChange={handleChange}
						/>

						{!documents && (
							<AttachDocuments
								request={request}
								formErrors={formErrors}
								handleForm={handleForm}
								setError={setError}
								liveness_check={liveness_check}
								updateRegStatus={updateRegStatus}
								percent={percent}
							/>
						)}

						<div className="d-flex col-12 pt-3">
							<button
								className="btn btn--lg btn--primary--bordered me-2"
								disabled={loading || qLoading}
								onClick={() =>
									updateRegStatus({ completed: true })
								}
							>
								Skip
							</button>

							<button
								type="submit"
								className="btn btn--lg btn--primary ms-2"
								disabled={loading || qLoading}
							>
								{isLoading || qLoading ? <Spinner /> : "Finish"}
							</button>
						</div>
					</>
				)}
			</form>
		</>
	);
};

export default AttachSettlement;
