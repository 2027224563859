import React, { useState } from "react";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import { ReactComponent as BrailleDot } from "assets/images/icons/brailledot.svg";
import AddMarketToWatchlistButton from "../../../../MarketUpdates/button/AddMarketToWatchlistButton/AddMarketToWatchlistButton";

const FreequentlyTradedActions = () => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => setAnchorEl(null);

	return (
		<>
			<IconButton onClick={handleClick}>
				<BrailleDot
					aria-controls="filter"
					aria-haspopup="true"
					className="gray"
				/>
			</IconButton>

			<Menu
				id="filter"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				style={{ marginTop: "40px" }}
				className="pointer"
				disableScrollLock={true}
			>
				<MenuItem onClick={handleClose}>
					<AddMarketToWatchlistButton
						useText
						handleClose={handleClose}
					/>
				</MenuItem>
				<MenuItem onClick={handleClose}>Remove</MenuItem>
			</Menu>
		</>
	);
};

export default FreequentlyTradedActions;
