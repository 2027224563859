import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { fetchUserData } from "storage/sessionStorage";
import { formValidator } from "utils/FormValidator/FormValidator";
import CustomSelect from "utils/Select/CustomSelect/CustomSelect";
import CustomInputBox from "utils/CustomInputBox/CustomInputBox";
import { getError } from "utils/helpers";
import { QUICK_TRADE_FORM } from "api/queries/stock";
import { SUBMIT_ORDER } from "api/mutations/stock";
import Toast from "utils/Alert/Toast/Toast";
import CustomSpinner from "utils/CustomSpinner/CustomSpinner";
import * as All from "../../../../../../constants";
import { UPDATE_ORDER } from "api/mutations/orders";
import { SUBMIT_ORDER_2 } from "api/mutations/stock";

const { BUY, EQUITY, ERROR, LIMIT } = All;
const { MARKET, BOND } = All;

const QuickTradeAllForms = ({
	toggle,
	order,
	setStock,
	setSuccessMsg,
	setAccount,
}) => {
	let { portfolio_accounts = [] } = fetchUserData();
	const [formError, setFormError] = useState({});
	const [errorMsg, setErrorMsg] = useState("");
	const [instrument, setInstrument] = useState(EQUITY);
	const [data, setData] = useState([]);
	const [request, setRequest] = useState({
		account: portfolio_accounts?.[0] || {},
		name: "",
		price_type: MARKET,
		price_limit: "",
		quantity: 1,
		type: BUY,
		term: "",
		stop_price: "",
	});

	const btnClass = request.type === BUY ? "btn--primary" : "btn--secondary";
	let isEquity = instrument === EQUITY;
	let marginable = isEquity && request?.account.margin;

	const getPayload = () => {
		const filters = {
			type: { _iregex: instrument },
		};
		marginable && (filters.marginable = { _eq: Boolean(marginable) });
		!marginable &&
			(filters.exchange = { _iregex: request?.account?.exchange });
		return { filters };
	};

	const [getStocks, { loading }] = useLazyQuery(QUICK_TRADE_FORM, {
		variables: getPayload(),
		onCompleted: (allData) => {
			setData(allData);
			order && updateForms(allData);
		},
	});

	const updateForms = ({ stock = [] }) => {
		const security = stock?.find(
			({ name }) => name === order?.securityName
		);
		handleSelect("name", security);
	};

	useEffect(() => {
		if (order) {
			let { portfolioLabel, instrumentType, priceType } = order || {};
			setInstrument(instrumentType);

			portfolioLabel = portfolioLabel.split("/")?.[1] ?? "";

			const account = portfolio_accounts.find(
				(acct) => acct.name === portfolioLabel?.trim()
			);
			account && handleSelect("account", account);
			setRequest((prev) => ({
				...prev,
				priceType,
				quantity: order?.quantityPending,
				type: order?.orderType,
			}));
		}
		// eslint-disable-next-line
	}, [order]);

	useEffect(() => {
		getStocks({ variables: getPayload() });
		handleSelect("name", "");
		// eslint-disable-next-line
	}, [instrument, request.account]);

	useEffect(() => {
		setStock((prev) => ({ ...prev, order: request }));
		// eslint-disable-next-line
	}, [request]);

	const QUERY = request?.term ? SUBMIT_ORDER : SUBMIT_ORDER_2;

	const [quickOrder, { error, loading: quickLoading }] = useMutation(QUERY, {
		onCompleted: ({ submit_order }) => setSuccessMsg(submit_order?.message),
		onError: (err) => setErrorMsg(getError(err)),
	});

	const [updateOrder, { loading: updateLoading }] = useMutation(
		UPDATE_ORDER,
		{
			onCompleted: ({ update_order }) =>
				setSuccessMsg(update_order?.message),
			onError: (err) => setErrorMsg(getError(err)),
		}
	);

	const handleChange = ({ target: { name, value } }) => {
		handleSelect(name, value);
	};

	const handleSelect = (name, value) => {
		name === "name" && setStock(value);
		name === "account" && setAccount(value);
		setRequest((prev) => ({
			...prev,
			[name]: value,
		}));
		setFormError((prev) => ({
			...prev,
			[name]: "",
		}));
	};

	const handleInstrument = (e) => {
		setInstrument(e);
		handleSelect("name", "");
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const payload = { ...request };
		payload.price_type === MARKET && delete payload.price_limit;
		delete payload?.term;
		delete payload?.stop_price;
		const { formErrors, isValid } = formValidator(payload);
		isValid ? submitForm(request) : setFormError(formErrors);
	};

	// 	$stop_price: Float

	const submitForm = (data) => {
		const { name, term, price_limit, account } = data || {};
		const variables = {
			...data,
			name: name?.name,
			account: account?.name,
			price_limit: price_limit ? parseFloat(price_limit) : price_limit,
		};
		term?.value && (variables.term = term?.value);
		if (order) {
			variables.id = order.id;
			variables.stop_price = request?.stop_price;
		}
		order ? updateOrder({ variables }) : quickOrder({ variables });
	};

	const qLoading = quickLoading || updateLoading;

	return (
		<form onSubmit={handleSubmit} className="row pt-4">
			{error && <Toast msg={errorMsg} variant={ERROR} />}
			<Toast msg={errorMsg} variant={ERROR} />

			<div className="col-12 ">
				<CustomSelect
					name="account"
					label="Select Portfolio"
					placeholder="Select Portfolio"
					loading={loading}
					value={request?.account}
					onChange={(e) => handleSelect("account", e)}
					error={formError?.account}
					options={portfolio_accounts}
					getOptionLabel={({ label }) => label}
					required
				/>
			</div>

			<div className="col-12 ">
				<CustomSelect
					name="instrument"
					label="Instrument Type"
					placeholder="Select Instrument Type"
					value={[instrument]}
					onChange={handleInstrument}
					options={[EQUITY, BOND]}
					getOptionLabel={(option) => option}
					required
				/>
			</div>

			<div className="col-12 ">
				<CustomSelect
					name="name"
					label="Symbol"
					placeholder="Select Symbol"
					value={request?.name}
					onChange={(e) => handleSelect("name", e)}
					error={formError?.name}
					options={data?.stock}
					getOptionLabel={({ label, name }) => `${label} (${name})`}
					required
				/>
			</div>

			<div className="col-6 ">
				<CustomSelect
					name="type"
					label="Order Type"
					placeholder="Select Order Type"
					value={[request?.type]}
					onChange={(e) => handleSelect("type", e)}
					error={formError?.type}
					options={["BUY", "SELL"]}
					getOptionLabel={(option) => option}
					required
				/>
			</div>

			<div className="col-6 ">
				<CustomInputBox
					name="quantity"
					label="Quantity"
					type="number"
					min={1}
					placeholder="Enter Quantity"
					value={request?.quantity}
					onChange={handleChange}
					error={formError?.quantity}
					required
				/>
			</div>

			{!order && (
				<div className="col-12 ">
					<CustomSelect
						name="term"
						label="Order Term"
						placeholder="Enter Order Term"
						value={[request?.term]}
						error={formError?.term}
						options={[
							{ comment: "Select Order Term", value: "" },
							...(data?.order_term || []),
						]}
						onChange={(e) => handleSelect("term", e)}
						getOptionLabel={({ comment }) => comment}
						getOptionValue={({ value }) => value}
					/>
				</div>
			)}

			<div className="col-6">
				<CustomSelect
					name="price_type"
					label="Price Type"
					placeholder="Select Price Type"
					value={[request?.price_type]}
					onChange={(e) => handleSelect("price_type", e)}
					error={formError?.price_type}
					options={["MARKET", "LIMIT"]}
					getOptionLabel={(option) => option}
					required
				/>
			</div>

			{request.price_type === LIMIT && (
				<div className="col-6">
					<CustomInputBox
						name="price_limit"
						label="Price Limit"
						placeholder="Enter Price Limit"
						value={request?.price_limit}
						onChange={handleChange}
						error={formError?.price_limit}
					/>
				</div>
			)}

			{order && (
				<div className="col-6">
					<CustomInputBox
						type="number"
						name="stop_price"
						label="Stop Price"
						min={0}
						placeholder="Enter Stop Price "
						value={request?.stop_price}
						onChange={handleChange}
						error={formError?.stop_price}
					/>
				</div>
			)}

			<div className="col-12 flex__between mt-3">
				<button
					className="btn btn--lg btn--primary--bordered me-2"
					type="button"
					onClick={toggle}
				>
					Cancel
				</button>

				<button
					className={`btn btn--lg ms-2 ${btnClass}`}
					disabled={qLoading}
					type="submit"
				>
					{qLoading ? (
						<CustomSpinner />
					) : request.type === BUY ? (
						"Buy"
					) : (
						"Sell"
					)}
				</button>
			</div>
		</form>
	);
};

export default QuickTradeAllForms;
