import React from "react";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";

const header = ["Top Gainers", "Top Losers"];

const MarketStatusNav = ({ showGainer, toggle }) => {
	const title = showGainer ? header[0] : header[1];

	return (
		<div className="bg__gray flex__between py-2 px-3 rounded">
			<BackIcon width="19px" onClick={toggle} className="pointer" />
			<span className="heading4">{title}</span>
			<div>
				<BackIcon
					width="19px"
					className="rot-180 ms-3 pointer"
					onClick={toggle}
				/>
			</div>
		</div>
	);
};

export default MarketStatusNav;
