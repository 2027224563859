import { useQuery } from "@apollo/client";
import React from "react";
import { GET_NEWS } from "api/queries/news";
import LoadingNews from "components/Dashboard/DashContent/DashContentLeftView/LatestNews/LoadingNews/LoadingNews";
import { ReactComponent as Redirect } from "assets/images/icons/redirect.svg";
import { getStockSector } from "../../../DashContent/utils";
import NewsIcon from "assets/images/images/news.png";
import { getError, truncate } from "utils/helpers";
import { ERROR } from "components/constants";
import Toast from "utils/Alert/Toast/Toast";
import News from "./News/News";

const LatestNewsResearch = ({ isResearch }) => {
	const { loading, error, data: newData } = useQuery(GET_NEWS);

	const data = newData?.news;

	const firstNew = data?.length > 0 ? data[0] : {};
	const { imageUrl, url, category, summary, headLine } = firstNew || {};

	const firstCol = isResearch ? "col-sm-5" : "col-sm-6";
	const secCol = isResearch ? "col-sm-7" : "col-sm-6";

	return (
		<div className="latest__news">
			{error && <Toast msg={getError(error)} variant={ERROR} />}

			{!isResearch && <p className="heading4">Latest News</p>}

			{loading ? (
				<LoadingNews count={3} />
			) : (
				<>
					<div className="row div__wrapper">
						<div
							className={`${firstCol} new__image__wrapper p-sm-0 p-2`}
						>
							<img
								src={imageUrl || NewsIcon}
								alt=""
								className="img-fluid "
							/>
						</div>
						<div className={`${secCol} rel p-4 fw-bold`}>
							<div className="flex__between">
								{getStockSector(category[0] || "")}

								<a href={url} target="_blank" rel="noreferrer">
									<Redirect className="d-sm-none d-md-inline-block" />
								</a>
							</div>

							<p className="heading4 mt-3">{headLine}</p>
							<span className="text--gray pb-5">
								{truncate(summary, 200)}
							</span>
						</div>
					</div>

					<div className="row pt-5 ">
						{data?.map(
							(val, i) => i !== 0 && <News news={val} key={i} />
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default LatestNewsResearch;
