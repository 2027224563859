import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Toast from "utils/Alert/Toast/Toast";
import MarketOverviewTable from "./MarketOverviewTable/MarketOverviewTable";
import MarketOverviewFilters from "./MarketOverviewFilters/MarketOverviewFilters";
import MarketOverviewSelectedStock from "./MarketOverviewSelectedStock/MarketOverviewSelectedStock";
import { ERROR } from "../../../constants";
// import { STOCK_DAY_SERIES_WITHOUT_TIME } from "api/subscription/investment";
import { STOCK_WITHOUT_TIME } from "api/queries/stock";
// import { marketOverviewData } from "../data";

const MarketOverview = () => {
	const [currentStock, setCurrentStock] = useState({});
	const [market, setMarket] = useState([]);
	const [filters, setFilters] = useState({
		type: "",
		exchange: "",
	});

	const { loading, error } = useQuery(STOCK_WITHOUT_TIME, {
		fetchPolicy: "no-cache",
		onCompleted: ({ stock }) => {
			setCurrentStock(stock.length ? stock[0] : {});
			setMarket(stock);
		},
		variables: { ...filters },
	});

	const rowClick = ({ id }) => {
		const stock = market?.find(({ label }) => label === id);
		!loading && setCurrentStock(stock);
	};

	return (
		<>
			{error && <Toast msg="Error Processing Request" variant={ERROR} />}

			<div>
				<span className="heading2 m-0">Market Overview</span>

				<MarketOverviewFilters
					filters={filters}
					setFilters={setFilters}
					setCurrentStock={setCurrentStock}
				/>

				<div className="">
					<div className="left__view">
						<MarketOverviewTable
							loading={loading}
							stocks={market}
							rowClick={rowClick}
						/>
					</div>

					<MarketOverviewSelectedStock
						stock={currentStock}
						loading={loading}
					/>
				</div>
			</div>
		</>
	);
};

export default MarketOverview;
