import React from "react";
import { useSubscription } from "@apollo/client";
import { TOP_LOSER } from "api/subscription/watchlist";
import Toast from "utils/Alert/Toast/Toast";
import WatchListLoader from "../../../../WatchList/common/WatchListLoader/WatchListLoader";
import TopStockWrapper from "../extra/TopStockWrapper/TopStockWrapper";
import { getError } from "utils/helpers";
import { ERROR } from "../../../../../constants";

const TopLoser = () => {
	const { loading, error, data } = useSubscription(TOP_LOSER);

	return (
		<>
			{error && (
				<Toast
					msg={getError(error)}
					variant={ERROR}
					toastId="top_loser"
				/>
			)}
			<div className="mt-3 pb-3 accord">
				{loading ? (
					<WatchListLoader count={3} />
				) : (
					<ul className="bottom__border">
						{data?.top_losers?.map((item, i) => (
							<TopStockWrapper item={item} loser />
						))}
					</ul>
				)}
			</div>
		</>
	);
};

export default TopLoser;
