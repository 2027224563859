import React, { useState } from "react";
import MarketTrades from "../../MarketUpdates/MarketUpdateRightView/MarketTrades/MarketTrades";
import Bids from "./Bids/Bids";
import MarketCaps from "./MarketCaps/MarketCaps";
import Offers from "./Offers/Offers";
import StatusTablesNav from "./StatusTablesNav/StatusTablesNav";

const mainHeader = ["Market Trades", "Bids", "Offers", "Market Caps"];

const StatusTables = ({ stock, loading, cls }) => {
	const { bid_levels, offer_levels, trades } = stock || {};

	const [currentIndex, setCurrentIndex] = useState(0);
	const headerLength = mainHeader.length - 1;

	const next = () =>
		currentIndex === headerLength
			? setCurrentIndex(0)
			: setCurrentIndex(currentIndex + 1);

	const prev = () =>
		currentIndex === 0
			? setCurrentIndex(headerLength)
			: setCurrentIndex(currentIndex - 1);

	const render = () => {
		switch (currentIndex) {
			case 0:
			default:
				return <MarketTrades loading={loading} currency="NGN" trade={trades} />;
			case 1:
				return <Bids bids={bid_levels} loading={loading} />;
			case 2:
				return <Offers offers={offer_levels} loading={loading} />;
			case 3:
				return <MarketCaps stock={stock} />;
		}
	};

	return (
		<div className=" my-4">
			<StatusTablesNav
				currentIndex={currentIndex}
				header={mainHeader}
				next={next}
				prev={prev}
			/>
			<div className={`accord_300 ${cls}`}>{render()}</div>
		</div>
	);
};

export default StatusTables;
