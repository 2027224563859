import React from "react";
import { connect } from "react-redux";
import { ReactComponent as Logo } from "assets/images/icons/logo.svg";
import { ReactComponent as Logo2 } from "assets/images/icons/Logo-white.svg";
import { DARK_MODE } from "components/constants";
import "./MeriLoader.scss";

const MeriLoader = ({ mode, hideLogo, cls }) => {
	const CurrentLogo = mode.meriMode === DARK_MODE ? Logo2 : Logo;
	return (
		<div className={`loading ${cls}`}>
			<div className="text-center">
				{!hideLogo && <CurrentLogo className="" />}
				<h2> </h2>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	);
};

export default connect(({ mode }) => ({ mode }))(MeriLoader);
