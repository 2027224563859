import React from "react";
import { ReactComponent as UploadIcon } from "assets/images/icons/file.svg";
import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-up.svg";
import { ReactComponent as Snap } from "assets/images/icons/snap.svg";

const FileUpload = ({
	label,
	id,
	name,
	className = "",
	validFileTypes = [],
	percent = "0",
	file,
	error,
	isSnap,
	refFile,
	isScan,
	onChange,
	onSnapClick,
	click,
}) => {
	return (
		<div className="flex__between file__upload">
			<div className="icon__wrapper">
				<UploadIcon />
			</div>

			<div className="w-100 ps-2 pe-3">
				<small className="fw-bold flex__between text--gray">
					{label}
					<strong className="ms-auto">{percent + "%"}</strong>
				</small>
				<div className="progress">
					<div
						className="progress-bar progress-bar-striped bg-success"
						role="progressbar"
						style={{ width: `${percent}%` }}
						aria-valuenow={percent}
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
				{error ? (
					<small className="text-danger fw-bold d-block">
						{error}
					</small>
				) : (
					<small className="text--primary fw-bold d-block">
						{file?.name}
					</small>
				)}
			</div>

			<input
				type="file"
				id={id}
				name={name}
				ref={refFile}
				className={`file-field ${className}`}
				accept={validFileTypes.join(", ")}
				onChange={({ target: { files } }) => onChange(files)}
			/>

			{percent !== 100 && (
				<>
					{isSnap ? (
						<div
							className="text-center ms-3"
							style={{ width: "110px" }}
						>
							<Snap
								className="pointer"
								width="30"
								onClick={onSnapClick}
							/>
						</div>
					) : isScan ? (
						<label
							className="btn btn--xm btn--primary px-3 ms-3"
							style={{ fontSize: "9px" }}
							onClick={click}
						>
							Upload/Scan
						</label>
					) : (
						<label
							htmlFor={id}
							className="btn btn--xm btn--primary px-3 ms-3"
							style={{ fontSize: "9px", width: "103px" }}
						>
							<ArrowIcon height="10px" className="me-2" /> Upload
						</label>
					)}
				</>
			)}
		</div>
	);
};

export default FileUpload;
