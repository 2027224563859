import React, { useState } from "react";
import { useSubscription } from "@apollo/client";
import { AccordionDetails, Accordion } from "@material-ui/core";
import { ReactComponent as BackIcon } from "assets/images/icons/back.svg";
import Table from "utils/Table/Table";
import { bigTable, mobileTable } from "../common/TableList";
import AddWatchlistButton from "../buttons/AddWatchlistButton/AddWatchlistButton";
import DefaultWatchListCard from "../common/DefaultWatchListCard/DefaultWatchListCard";
import WatchListLoader from "../common/WatchListLoader/WatchListLoader";
import { GET_WATCHLIST } from "api/subscription/watchlist";
import Toast from "utils/Alert/Toast/Toast";
import { ERROR } from "../../../constants";
import { getError } from "utils/helpers";

const AllWatchList = ({ history }) => {
	const [expanded, setExpanded] = useState(false);

	const { loading, error, data } = useSubscription(GET_WATCHLIST);

	const handleChange = (panel) => (_, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<>
			{error && (
				<Toast
					msg={getError(error)}
					variant={ERROR}
					toastId="watchlist_main"
				/>
			)}

			<div className="col-lg-8 col-12 left__view">
				<div className="flex__between">
					<span className="flex__between">
						<BackIcon
							height="20px"
							width="20px"
							onClick={() => history.push("/")}
							className="pointer"
						/>
						<span className="heading2 ms-4">All Watchlist</span>
					</span>
					<AddWatchlistButton />
				</div>

				{loading ? (
					<WatchListLoader />
				) : (
					<>
						{data?.watchlist && (
							<div className="d-md-block d-none faint__primary">
								<Accordion
									expanded={expanded === "panel1"}
									onChange={handleChange("panel1")}
								>
									<AccordionDetails>
										<Table
											data={bigTable()}
											cls="full__height"
										/>
									</AccordionDetails>
								</Accordion>
							</div>
						)}

						<div className="d-md-block d-none faint__primary">
							{data?.watchlist?.map(
								({ id, name, watchlist_items }) => (
									<Accordion
										key={id}
										expanded={expanded === id}
										onChange={handleChange(id)}
									>
										<DefaultWatchListCard
											name={name}
											history={history}
											id={id}
										/>

										<AccordionDetails>
											{watchlist_items?.length === 0 ? (
												<div>
													<p className="heading3 text-danger text-center">
														No Item Found In This
														Watchlist
													</p>
												</div>
											) : (
												<Table
													data={bigTable(
														watchlist_items
													)}
													cls="full__height mb-4"
													rowClick={() => {}}
												/>
											)}
										</AccordionDetails>
									</Accordion>
								)
							)}
						</div>
					</>
				)}

				<div className="d-md-none d-block mt-4 faint__primary">
					{data?.watchlist?.map(({ id, name, watchlist_items }) => (
						<Accordion key={id}>
							<DefaultWatchListCard
								id={id}
								name={name}
								history={history}
							/>

							<AccordionDetails>
								{watchlist_items?.length === 0 ? (
									<div>
										<p className="heading3 text-danger text-center">
											No Item Found In This Watchlist
										</p>
									</div>
								) : (
									<Table
										data={mobileTable(watchlist_items)}
										cls="full__height"
									/>
								)}
							</AccordionDetails>
						</Accordion>
					))}
				</div>
			</div>
		</>
	);
};

export default AllWatchList;
