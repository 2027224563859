export const stock_sectors = {
	OTEHRS_FINANCE: "Other Financial Institutions",
	AGRICULTURE: "Agriculture",
	AGRO_ALLIED: "Agriculture/Agro Allied",
	AIRLINE: "Airline Service",
	AIRLINES: "Airline Services",
	AUTO: "Automobile and Tyre",
	BANKING: "Banking",
	BREWERIES: "Breweries",
	BUILDING: "Building Materials",
	ESTATE: "CONSTRUCTION/REAL ESTATE",
	ESTATES: "Real Estate",
	CONSUMER: "Consumer Good",
	CHEMICAL: "Chemical and Paints",
	COMMERCIAL: "Commercial Services",
	COMPUTER: "Computer and Office Equipment",
	CONGLOMERATES: "Conglomerates",
	CONSTRUCTION: "Construction",
	ENGINEERING: "Engineering Technology",
	FINANCIAL: "Financial Services",
	FOOD: "Food Beverages and Tobacco",
	HEALTH: "Healthcare",
	HOTEL: "Hotels and Tourism",
	ICT: "ICT",
	INDUSTRIAL: "Industrial Goods",
	DOMESTICS: "Industrial Domestic Products",
	INSURANCE: "Insurance",
	MANUFACTURING: "Manufacturing & Allied",
	MONTAGES: "Mortgage Companies",
	OIL: "Oil and Gas",
	OTHERS: "Others",
	PETROLEUM: "Petroleum (Marketing)",
	PRINTING: "Printing and Publishing",
	ROAD: "Road Transportation",
	SERVICES: "Services",
	TECH: "Tech",
};
